import React from 'react';
import { NewsCardSm } from 'src/components';
import { Link } from 'react-router-dom';
import { PressReleseCard2loader } from '../loadingAnimation/cardloader';
import { selectWatchlistNews } from 'src/pages/Watchlist/Watchlist.selector';
import { useSelector } from 'react-redux';

export default function OverviewNews() {
  const news = useSelector(selectWatchlistNews);

  return (
    <div className='overview-news-section-container'>
      <div className='overview-news-section-header'>
        <h1 className='financials-section-header'>Stock News</h1>
        <h2 className='financials-section-header'>
          <Link to='news/stock'>MORE</Link>
        </h2>
      </div>
      <div>
        {news?.isLoading ? (
          <>
            <PressReleseCard2loader />
            <PressReleseCard2loader />
          </>
        ) : (
          news?.data?.slice(0, 5).map((data, index) => <NewsCardSm item={data} key={index} />)
        )}
      </div>
    </div>
  );
}
