/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable indent */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AddressElement, useStripe, PaymentElement, useElements, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import { selectSubscriptionCheckoutInfo } from '../subscription.selector';
import { IError } from 'src/lib/types/error';

function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const checkoutData = useSelector(selectSubscriptionCheckoutInfo);
  const [processing, setProcessing] = useState(false);
  const [isError, setIsError] = useState<IError | null>({ message: '', status: 200 });
  const [steps, setSteps] = useState(1);

  const handlePrevious = () => {
    if (steps > 1) {
      setSteps((s) => s - 1);
    }
  };

  const handleNext = () => {
    if (steps < 2) {
      setSteps((s) => s + 1);
    }
  };

  const handleCheckout = async () => {
    setIsError(null);
    if (!stripe || !elements) {
      return;
    }
    setProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: 'http://localhost:3000/subscription/paymentsuccess',
      },
    });
    if (error.type === 'card_error' || error.type === 'validation_error') {
      if (error.message) setIsError({ message: error.message, status: 200 });
    } else {
      setIsError({ message: 'An unexpected error occured', status: 200 });
    }

    setProcessing(false);
  };

  return (
    <div className='checkoutOne w-[80%] mx-auto my-[4rem] grid grid-cols-2 max-md:grid-cols-1'>
      <div className='checkoutOne__left  width: 100%; bg-[#091A3A] border-transparent rounded border-[0.1rem] p-[4rem]'>
        <h1 className='text-[2.4rem] font-[600] text-[$color-secondary]'>
          Total : <span className='color:$color-white font-[800]'>${checkoutData.price}</span>
        </h1>
        <h1 className='text-[1.8rem] font-[600] my-[2rem]'>Purchases :</h1>
        <div className='checkoutOne__checkCards mb-[2rem]'>
          <div
            className='subscriptionCard p-[1.5rem]  display: flex;justify-content: space-between; align-items: center;
           border-transparent bg-[#081E47] rounded-[0.4rem]'>
            <div className='subscriptionCard__left  display: flex;flex-direction: column; gap-y-[1rem]'>
              <p className='text-[2.4rem] font-600 '>{checkoutData.name}</p>
              <p className=' text-[1.4rem] font-600'>
                Subscription Type :
                <span className='text-[1.4rem] font-600 color:$color-primary'>
                  {checkoutData.type ? 'Yearly' : 'Monthly'}
                </span>
              </p>
            </div>
            <div className='subscriptionCard__right text-[1.4rem] '>
              Price : <span>${checkoutData.price}</span>
            </div>
          </div>
        </div>
        <div className='checkoutOne__summary'>
          <h1 className='text-[1.8rem] font-600 text-[$color-secondary]'>Summary :</h1>
          <div className=' display: flex;flex-direction: column; my-[2rem]'>
            <div className=' display: flex;justify-content: space-between; align-items: center; my-[1rem] text-[1.4rem] font-[600]'>
              <p>Items Subtotal</p>
              <p>
                $<span>{checkoutData.price}</span>
              </p>
            </div>
            <div className=' display: flex;justify-content: space-between; align-items: center; my-[1rem] text-[1.4rem] font-[600]'>
              <p>Quantity</p>
              <p>1</p>
            </div>

            <div className=' display: flex;justify-content: space-between; align-items: center; my-[1rem] text-[1.4rem] font-[600]'>
              <p>Grand Total</p>
              <p className='color:$color-primary  text-[2.4rem]'>
                $<span>{checkoutData.price}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Email */}
      <div className='checkoutOne__right  width: 100%;  display: flex;flex-direction: column;'>
        <div
          className={`checkoutOne__sectionOne  width: 100%; p-[4rem]  display: flex;flex-direction: column; gap-y-[4rem] ${
            steps === 1 ? '' : 'hidden'
          }`}>
          <div className='checkoutOne__form'>
            <AddressElement options={{ mode: 'billing' }} />
          </div>
          <div className='checkoutOne__sectionOneButton'>
            <button
              type='button'
              onClick={handleNext}
              className='button is-black nomad-btn
               submit border-[0.4rem]
               rounded-[0.4rem] border-transparent
                py-[0.5rem] px-[4rem] bg-[$color-primary]
                text-[1.4rem] font-[600] text-[#000]'>
              Next
            </button>
          </div>
        </div>
        <div
          className={`checkoutOne__sectionTwo  width: 100%; p-[4rem]  display: flex;flex-direction: column; gap-y-[4rem] ${
            steps === 2 ? '' : 'hidden'
          }`}>
          <h4 className='text-[2.4rem] font-[600] text-[$color-secondary]'>Enter Payment Details</h4>

          <PaymentElement />

          {isError && (
            <div className='text-red-500text-align: center; font-semibold text-xl tracking-wider'>
              {isError.message}
            </div>
          )}
          <div className='submit-btn  display: flex;justify-content: space-between; align-items: center;'>
            <button
              type='button'
              className='button is-black nomad-btn submit
              border-[0.4rem] rounded-[0.4rem]
              border-transparent py-[0.5rem]
              px-[4rem] bg-[$color-primary]
              text-[1.4rem] font-[600] text-[#000]'
              onClick={handlePrevious}>
              Back
            </button>

            <button
              type='button'
              disabled={processing || !stripe || !elements}
              className='button is-black nomad-btn submit
              border-[0.4rem] rounded-[0.4rem]
              border-transparent py-[0.5rem]
              px-[4rem] bg-[$color-primary]
              text-[1.4rem] font-[600] text-[#000]'
              onClick={() => handleCheckout()}>
              {processing ? 'PROCESSING' : 'PAY'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
interface IWindow extends Window {
  env: {
    REACT_APP_STRIPE_PK: string;
    // Add other environment variables as needed
  };
}
declare const window: IWindow;
export default function Chechout() {
  const { state } = useLocation();
  const stripePromise = loadStripe(window.env.REACT_APP_STRIPE_PK);
  const [clientSecret] = useState(state.clientSecret);

  return (
    <main className='pt-[15rem]'>
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret,
          appearance: {
            theme: 'night',
          },
        }}>
        <CheckoutForm />
      </Elements>
    </main>
  );
}
