import React from 'react';
import { Link } from 'react-router-dom';
import { ChatBubbleLeftEllipsisIcon, ArrowDownTrayIcon, EnvelopeIcon } from '@heroicons/react/20/solid';
import { data } from 'src/static-data';
import { ifirstLogo } from 'src/assets/Images';
export function Footer() {
  return (
    <footer className='footer'>
      <div className='footer-social'>
        <img src={ifirstLogo} alt='Logo' />
        <div className='footer-social-icons'>
          {data.socials.map((social, index) => (
            <img src={social} key={index} alt='social-icons' />
          ))}
        </div>
      </div>
      <div className='footer-quick-links'>
        {data.footerData.map((footerItem) => (
          <div className='footer-links' key={footerItem.id}>
            <h1>{footerItem.header}</h1>
            <ul>
              {footerItem.links.map((link, index) => (
                <li key={index}>
                  <Link to={link.url}>{link.label}</Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </footer>
  );
}

export function SupportsFooter() {
  return (
    <div className='support-footer'>
      <Link className='support-footer-links' to='/'>
        <ChatBubbleLeftEllipsisIcon />
        <p>Live Chat</p>
      </Link>
      <Link className='support-footer-links' to='/'>
        <ArrowDownTrayIcon />
        <p>Download Guides</p>
      </Link>
      <Link className='support-footer-links' to='/support/ticket'>
        <EnvelopeIcon />
        <p>Submit Request</p>
      </Link>
    </div>
  );
}
