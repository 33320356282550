import * as echarts from 'echarts';
import React, { useEffect, useRef, useMemo, memo, useCallback } from 'react';

export const TopbarGraph = memo(({ data, color, height = '40px' }) => {
  const chartRef = useRef(null);

  const xAxisData = useMemo(() => data.map((item) => item.datetime), [data]);

  const createChart = useCallback(() => {
    const chart = echarts.init(chartRef.current);
    const option = {
      xAxis: {
        type: 'category',
        data: xAxisData,
        show: false,
      },
      yAxis: {
        type: 'value',
        show: false,
        min: 'dataMin',
        max: 'dataMax',
      },
      grid: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
      tooltip: {
        trigger: 'axis',
        formatter: () => '',
        axisPointer: {
          type: 'shadow',
        },
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderColor: 'rgba(0, 0, 0, 0.8)',
        textStyle: {
          color: '#ffffff',
        },
      },
      series: [
        {
          type: 'line',
          showSymbol: false, // Hide symbols (dots)
          color: color,
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: color },
              { offset: 1, color: 'transparent' },
            ]),
          },
          data: data.map((item) => item.close),
        },
      ],
    };
    chart.setOption(option);

    // Clean up the chart instance when the component unmounts
    return () => {
      chart.dispose();
    };
  }, [xAxisData, data, color]);

  useEffect(() => {
    const cleanup = createChart();

    return () => {
      cleanup();
    };
  }, [createChart]);

  return <div ref={chartRef} style={{ width: '100%', height: height }} />;
});
