export function PressReleaseCard({ data }) {
  return (
    <div className='pressrelease-card'>
      <div className='pressrelease-card-container'>
        <div className='pressrelease-card-dt'>
          <h2 title={data.title} className='card-title'>
            {capitalizeFirstLetter(data.title)}
          </h2>
          <p>{data.date.split(' ')[0]}</p>
        </div>
        <p>{capitalizeFirstLetter(data.text)}</p>
      </div>
    </div>
  );
}

export function PressReleaseCard2({ data, image }) {
  return (
    <div tabIndex={0} className='collapsed '>
      <div className='collapse-title '>
        <div className='overview-press-release'>
          <figure>
            <img src={image} alt='' />
          </figure>
          <div>
            <h3>{capitalizeFirstLetter(data.title)}</h3>
            <p>{data?.date} </p>
          </div>
        </div>
      </div>
      <div className='collapse-content '>
        <p className='overview-press-release-p '>{data.text}</p>
      </div>
    </div>
  );
}
function capitalizeFirstLetter(text) {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}
