import { createSlice } from '@reduxjs/toolkit';
const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: null,
};

const screachSlice = createSlice({
  name: 'screach',
  initialState: INITIAL_STATE,
  reducers: {
    fetchSearch(state, action) {
      state.isLoading = true;
      state.data = null;
    },
    fetchSearchSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchSearchFailure(state, action) {
      state.isLoading = false;
      state.data = [];
      state.error = action.payload;
    },
  },
});
export const { fetchSearch, fetchSearchSuccess, fetchSearchFailure } = screachSlice.actions;
export const searchReducer = screachSlice.reducer;
