import { rankingDummyIcon } from 'src/assets/Images';
export default function IfirstRanking() {
  return (
    <main className='ifirstranking'>
      <section className='ifirstranking__Top'>
        <h1>VISUAL REPRESENTATIONS</h1>
        <div className='ifirstranking__TopRight'>
          <div className='ifirstranking__Container'>
            <h2>Fundamentals</h2>
            <div className='ifirstranking__Value'>10</div>
          </div>
          <div className='ifirstranking__Container'>
            <h2>Technicals</h2>
            <div className='ifirstranking__Value'>10</div>
          </div>
          <div className='ifirstranking__Container'>
            <h2>Equidity</h2>
            <div className='ifirstranking__Value'>10</div>
          </div>
        </div>
      </section>
      <section className='ifirstranking__Bottom'>
        <div className='ifirstranking__BottomChart'>
          <img src={rankingDummyIcon} alt='' />
        </div>
      </section>
    </main>
  );
}
