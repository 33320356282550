import React, { useEffect, useRef, memo, useMemo, useCallback } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import * as echarts from 'echarts/core';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { selectMarketEconomicIndicator } from 'src/pages/Markets/markets.selector';
import { fetchMarketEconomicIndicator } from 'src/pages/Markets/markets.reducer';
import { IEconomicIndicatorData } from 'src/lib/types/markets';
const indicators = [
  { title: 'CPI', key: 'CPI' },
  { title: 'GDP', key: 'GDP' },
  { title: 'Inflation Rate', key: 'inflationRate' },
  { title: 'Unemployment Rate', key: 'unemploymentRate' },
];
export default function MarketsEconomicIndicator() {
  const dispatch = useDispatch();
  const indicator = useSelector(selectMarketEconomicIndicator);
  useEffect(() => {
    dispatch(fetchMarketEconomicIndicator());
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 2500, min: 1367 },
      items: 3,
    },
    largeTablet: {
      breakpoint: { max: 1366, min: 1281 },
      items: 2,
    },
    nestHubLarge: {
      breakpoint: { max: 1280, min: 1025 },
      items: 2,
    },
    nestHubMedium: {
      breakpoint: { max: 1024, min: 913 },
      items: 2,
    },
    nestHubSmall: {
      breakpoint: { max: 912, min: 769 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 768, min: 465 },
      items: 1,
      centerMode: true, // Ensures centering when there is only one item
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      centerMode: true, // Ensures centering when there is only one item
    },
  };

  return (
    <>
      {indicator?.data ? (
        <section className='markets-economic-indicators'>
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={false}
            arrows={true}
            showDots={false}
            keyBoardControl={true}
            containerClass='carousel-container'
            itemClass='carousel-item' // Apply a class to each item
          >
            {indicators.map(({ title, key }, index) => (
              <figure key={index} className='markets-economic-indicator' style={{ marginBottom: '5rem' }}>
                <h1>{title}</h1>
                <FinancialTrendGraph3
                  data={indicator?.data[key]}
                  valueKey={'value'}
                  dateKey='date'
                  color='rgba(0, 122, 255, 1)'
                />
              </figure>
            ))}
          </Carousel>
        </section>
      ) : (
        ''
      )}
    </>
  );
}
export interface IFinancialgraph {
  data: IEconomicIndicatorData[];
  color: string;
  height?: string;
  dateKey: string;
  valueKey: string;
}
export interface IFinancialgraph {
  data: IEconomicIndicatorData[];
  color: string;
  height?: string;
  dateKey: string;
  valueKey: string;
}

const FinancialTrendGraph3 = memo(({ data, color, height = '250px', dateKey, valueKey }: IFinancialgraph) => {
  const chartRef = useRef(null);

  const xAxisData = useMemo(
    () => data.map((item: IEconomicIndicatorData) => item[dateKey].split('T')[0]),
    [data, dateKey],
  );
  const numberFormatter = (value) => {
    value = +value;
    const absValue = Math.abs(value); // Get the absolute value to format
    let formattedValue = '';

    if (absValue >= 1e9) {
      formattedValue = `${(absValue / 1e9).toFixed(2)}B`; // Billion
    } else if (absValue >= 1e6) {
      formattedValue = `${(absValue / 1e6).toFixed(2)}M`; // Million
    } else if (absValue >= 1e3) {
      formattedValue = `${(absValue / 1e3).toFixed(2)}K`; // Thousand
    } else {
      formattedValue = absValue.toString(); // Less than 1000
    }

    return value < 0 ? `-${formattedValue}` : formattedValue; // Add the negative sign back if needed
  };
  const createChart = useCallback(() => {
    const chart = echarts.init(chartRef.current);
    const option = {
      xAxis: {
        type: 'category',
        data: xAxisData,
        show: true,
        axisLine: {
          lineStyle: {
            color: '#ffffff', // White axis line
          },
        },

        axisLabel: {
          color: '#ffffff',
        },
      },
      yAxis: {
        type: 'value',
        min: 'dataMin',
        max: 'dataMax',
        axisLine: {
          lineStyle: {
            color: '#ffffff', // White axis line
          },
        },
        axisLabel: {
          color: '#ffffff', // White axis labels
          formatter: numberFormatter,
        },
        splitLine: {
          // lineStyle: {
          //   color: '#25334D', // Grey grid lines
          // },'
          show: false,
        },
      },
      grid: {
        left: 0, // Remove left margin
        right: 0, // Remove right margin
        top: 30,
        bottom: 30,
        containLabel: true, // Ensure labels are not cut off
      },
      tooltip: {
        trigger: 'axis',
        formatter: (
          params: {
            data: any;
            dataIndex: string | number;
          }[],
        ) => {
          const data = params[0].data;
          const date = xAxisData[params[0].dataIndex];
          const formattedDate = new Date(date).toLocaleDateString();
          const valueColor = data >= 0 ? '$color-primary' : '#cc0133';
          return `Date: ${formattedDate}<br/><span style="color: ${valueColor}">Value: ${data}</span>`;
        },
        axisPointer: {
          type: 'shadow',
        },
        backgroundColor: '#091A3A',
        borderColor: '#25334D',
        textStyle: {
          color: '#ffffff',
        },
      },
      series: [
        {
          type: 'line',
          showSymbol: false, // Hide symbols (dots)
          color: color,
          areaStyle: {
            color: color,
          },
          data: data?.map((item: { [x: string]: any }) => item[valueKey]),
        },
      ],
    };

    chart.setOption(option);

    // Clean up the chart instance when the component unmounts
    return () => {
      chart.dispose();
    };
  }, [xAxisData, data, color, valueKey]);

  useEffect(() => {
    const cleanup = createChart();

    return () => {
      cleanup();
    };
  }, [createChart]);

  return <div ref={chartRef} style={{ width: '100%', height: height }} />;
});
