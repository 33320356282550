import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SplitPane, { Pane } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
import downArrow from './svg/downArrow.svg';
import moreHorizontal from './svg/moreHorizontal.svg';
import sideArrowRight from './svg/sideArrowRight.svg';
import sideArrowLeft from './svg/sideArrowLeft.svg';
import editLogo from './svg/editLogo.svg';
import Droppabled from './droppable';
import { NewsCard } from 'src/components';

export default function MyWorkStation() {
  const layoutCSS1 = {
    height: '100%',
    weidth: '100%',
  };
  const [sizes, setSizes] = useState(['25%', '50%']);
  const [hiddenRightPane, setHiddenRightPane] = useState(false);
  const [hiddenLeftPane, setHiddenLeftPane] = useState(false);
  const [drag, setDrag] = useState(false);
  const [ticker, setTicker] = useState('BTCUSD');
  const [isSummaryActive, setIsSummaryActive] = useState(true);
  const [isNewsActive, setIsNewsActive] = useState(false);
  const [isMarketDepthActive, setIsMarketDepthActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  // const searchMutation = useMutation(postTickerSearch, {
  //   onSuccess: (resp) => setFilteredData(resp.data.data.tickers),
  // });
  // const handleChange = (event) => {
  //   const newSearchTerm = event.target.value;
  //   setSearchTerm(newSearchTerm);

  //   // Delay execution of search function
  //   setTimeout(() => {
  //     searchMutation.mutate({ search: newSearchTerm });
  //   }, 500);
  // };

  return (
    <>
      <div className='h-screen w-full max-h-screen'>
        <div className='h-[5%] flex justify-between items-center border-b-2 border-[#26395C] px-[0.8rem]'>
          <div className='flex items-center gap-[10rem] max-lg:gap-[4rem]'>
            <Link to='/'>
              <div className='w-[100%]  max-lg:w-[5rem] max-xl:h-[.8rem] h-[3rem] '>
                <img src='/Images/Logos/iFirstLogo-LightMode.svg' alt='' className='w-[100%] h-[100%]' />
              </div>
            </Link>
            <div className={`nav__searchbarWorkstation relative`}>
              <img src='/Images/SearchComponentImages/SearchIcon.svg' alt='Search Icon' />
              {/* <input type='search' placeholder={'Search....'} value={searchTerm} onChange={handleChange} /> */}
              {/* <OutsideClickHandler onOutsideClick={() => setFilteredData([])}> */}
              {filteredData.length > 0 ? (
                <ul
                  className='absolute top-[110%] left-0 bg-[#1E222D] w-[45rem]
       border border-[#ffffff26]  overflow-hidden z-[20] rounded-md'>
                  {filteredData?.map((item, index) => (
                    <li
                      className='w-full border-b border-[#ffffff26]
                         bg-[#1E222D] p-3 hover:bg-[#21314E]  cursor-pointer'
                      onClick={() => {
                        // nav(`/watchlist/${item}/${marketType}`);
                        setFilteredData([]);
                      }}
                      key={index}>
                      <div className='flex items-center  justify-between'>
                        <div className='flex items-center gap-4 '>
                          <div className='h-4 w-4 rounded-full overflow-hidden'>
                            <img src={item.logo} alt='logo' />
                          </div>
                          <h1 className='font-bold text-xl'>{item.ticker}</h1>
                        </div>
                        <span className='font-normal text-lg  text-ellipsis whitespace-'>{item.name}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                ''
              )}
              {/* </OutsideClickHandler> */}
            </div>
          </div>
        </div>

        <div className='h-[6%] w-full border-b-2 border-[#26395C] p-2 flex items-center'>
          <div className='w-[25%] max-w-[25%] px-2'>
            <h1 className='uppercase text-3xl font-bold'>My Workstation</h1>
          </div>
          <div className='flex gap-2 items-center'>
            <PageCard pageName='Tech Stocks' itemCount={25} active />
            <PageCard pageName='Tech Stocks' itemCount={25} />
            <PageCard pageName='Tech Stocks' itemCount={25} />
            <PageCard pageName='Tech Stocks' itemCount={25} />
            <PageCard pageName='Tech Stocks' itemCount={25} />
          </div>
        </div>
        <div className='h-[89%] flex'>
          <div className={`${hiddenRightPane ? 'w-[100vw]' : ' w-[75vw]'}  h-full`}>
            <SplitPane
              split='vertical'
              sizes={sizes}
              onChange={setSizes}
              onDragStart={() => setDrag(true)}
              onDragEnd={() => setDrag(false)}
              resizerSize={10}>
              <Pane minSize={0} maxSize='40%'>
                <div className='h-full'>
                  <div className='bg-[#26395C] p-4 flex justify-between items-center'>
                    <div className='flex items-center gap-2'>
                      <h1 className='uppercase text-xl font-bold'>watchlist</h1>
                      <span>
                        <img src={downArrow} alt='' />
                      </span>
                    </div>
                    <span>
                      <img src={moreHorizontal} alt='' />
                    </span>
                  </div>
                  <div className=' h-full '>
                    <Droppabled setTicker={setTicker} />
                  </div>
                </div>
              </Pane>
              <div style={{ ...layoutCSS1 }} className='relative '>
                <div className={`${drag ? 'z-10 w-full' : 'z-0'}     absolute left-0  top-0 bottom-0`} />
                <span
                  className=' bg-[#26395C] rounded-tr-full	rounded-br-full	 text-5xl absolute left-0 
                z-20 bottom-10 p-4 pl-5 '
                  onClick={() => {
                    const a = !hiddenLeftPane ? [0, sizes[1]] : ['25%', sizes[1]];
                    setSizes(a);
                    setHiddenLeftPane(!hiddenLeftPane);
                  }}>
                  <img src={hiddenLeftPane ? sideArrowRight : sideArrowLeft} alt='' className='w-3' />
                </span>
                <span
                  className=' bg-[#26395C] rounded-tl-full	rounded-bl-full	 text-5xl absolute
                 right-0 z-20 top-20 p-4 pl-5 '
                  onClick={() => setHiddenRightPane(!hiddenRightPane)}>
                  <img src={hiddenRightPane ? sideArrowLeft : sideArrowRight} alt='' className='w-3' />
                </span>
                <div className=' h-full  w-full z-30 flex flex-col' id='tvchart'>
                  <div className='w-full  h-16    text-lg font-semibold '>
                    <div className='h-full flex flex-row gap-3 px-12  items-center justify-end'>
                      <div className='w-[60%]  flex flex-row  justify-start gap-4 items-center'>
                        <button className='h-[3.2rem] px-5 bg-red-500 rounded-lg block'>Buy 34.4</button>
                        <button className='h-[3.2rem] px-5  bg-blue-500 rounded-lg block'>Sell 454.5</button>
                      </div>
                    </div>
                  </div>
                  <div className={`h-full w-full `}>
                    {/* <TVChartContainer className='h-full  w-full' tick={ticker} /> */}
                  </div>
                  <div
                    className='w-full flex gap-3 h-16 py-2   
                  justify-center bg-[#1F3152] text-lg font-semibold'>
                    <span>Screener</span>
                    <span>Heatmaps</span>
                    <span>Recommendation</span>
                    <span>Backtesting</span>
                    <span>Broker Terminal</span>
                    <span>Orders</span>
                  </div>
                </div>
              </div>
            </SplitPane>
          </div>
          <div className={`${hiddenRightPane ? 'hidden' : ' h-full w-[25vw]'} border-l-4 border-[#26395C] `}>
            <div className='h-full overflow-y-scroll left-pane '>
              <div className='w-full py-8'>
                <div className='w-full flex justify-center px-8 py-8 gap-6'>
                  <button
                    type='button'
                    onClick={() => {
                      setIsSummaryActive(true);
                      setIsMarketDepthActive(false);
                      setIsNewsActive(false);
                    }}
                    className={`${isSummaryActive ? 'bg-[#29CB97] text-[#041432]' : 'bg-[#26395C] text-white'} 
                    rounded-xl font-bold p-5 py-4  text-2xl`}>
                    Summary
                  </button>
                  <button
                    type='button'
                    onClick={() => {
                      setIsMarketDepthActive(true);
                      setIsNewsActive(false);
                      setIsSummaryActive(false);
                    }}
                    className={`${isMarketDepthActive ? 'bg-[#29CB97] text-[#041432]' : 'bg-[#26395C] text-white'} 
                    rounded-xl font-bold p-5 py-4  text-2xl`}>
                    Market Depth
                  </button>
                  <button
                    type='button'
                    onClick={() => {
                      setIsNewsActive(true);
                      setIsSummaryActive(false);
                      setIsMarketDepthActive(false);
                    }}
                    className={`${isNewsActive ? 'bg-[#29CB97] text-[#041432]' : 'bg-[#26395C] text-white'} 
                    rounded-xl font-bold p-5 py-4  text-2xl`}>
                    News
                  </button>
                </div>
                {isNewsActive ? <News /> : ''}
                {/* {isSummaryActive ? <KeyStatistic /> : ''} */}
                {isMarketDepthActive ? <MarketDepth /> : ''}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function PageCard({ pageName, itemCount, active = false }) {
  const [editable, setEditable] = React.useState(false);
  const [page, setPage] = React.useState('');
  React.useEffect(() => {
    setPage(pageName);
  }, [pageName]);
  return (
    <div className={`${active ? 'bg-[#0D2044]' : ''} flex rounded-lg gap-2  p-3 items-center cursor-pointer `}>
      {editable ? (
        <input
          className='text-white  text-xl e font-bold bg-[#040d24] w-28 outline-none outline-[#21C998] rounded'
          value={page}
          onChange={(e) => {
            setPage(e.target.value);
          }}
        />
      ) : (
        <span className={`${active ? 'text-[#21C998]' : ''} text-xl font-bold `}>{page}</span>
      )}

      <span className='text-[#027AFF] text-lg font-semibold'>{`(${itemCount} Items)`}</span>
      {editable ? (
        <span className='text-[#21C998] font-semibold' onClick={() => setEditable(false)}>
          Save
        </span>
      ) : (
        <span onClick={() => setEditable(true)}>
          <img src={editLogo} alt='' />
        </span>
      )}
    </div>
  );
}

function News() {
  const [newsData, setNewsData] = useState(null);

  // const newsMutate = useMutation(postMarketNews, {
  //   onSuccess: (response) => {
  //     setNewsData(response.data.data);
  //   },

  //   // onError: (error) => setIsError(error.message),
  // });
  // useEffect(() => {
  //   newsMutate.mutate({
  //     market_type: 'UsStocks',
  //     ticker: 'AAPL',
  //   });
  // }, []);
  return (
    <div className='flex flex-col px-16 py-8 gap-4'>
      <div className='text-[#007AFF] text-2xl font-bold'>All News</div>
      {newsData?.slice(0, 10).map((e, i) => (
        <>
          <NewsCard data={e} key={i} />
        </>
      ))}
      <Link className='text-[#007AFF] text-lg cursor-pointer' to={`/watchlist/${'AAPL'}/news/stock`}>
        more news...
      </Link>
    </div>
  );
}

function ValueCalc(con) {
  if (con.toUpperCase() === 'STRONG SELL') return 18;
  if (con.toUpperCase() === 'STRONG BUY') return 88;
  if (con.toUpperCase() === 'SELL') return 36;
  if (con.toUpperCase() === 'NEUTRAL') return 50;
  if (con.toUpperCase() === 'BUY') return 72;
}

function MarketDepth() {
  return (
    <>
      <div className='sidebar__header'>
        <img src={''} alt='' />
        <h2>{'symbol'}</h2>
      </div>
      <p>{'name'}</p>
      <div className='sidebar__details'>
        <div className='sidebar__detailsLeft'>
          <div className='sidebar__priceLeft'>
            <h1>
              {'price'}
              <span>USD</span>
            </h1>
            {/* <p>Closed {'time'}</p> */}
          </div>
          <div className='sidebar__priceRight'>
            <h3>{'change'}</h3>
            <h3>{'changesPercentage'}</h3>
          </div>
        </div>
      </div>
      <BidAskTable />
    </>
  );
}

export function BidAskTable() {
  return (
    <div className=' border-t-2 border-b-2	 border-[#26395C] px-16 py-8 '>
      <h1 className='capitalize text-[#007AFF] font-bold text-2xl'>ASK TABLE</h1>
      <div className=' w-10/12 py-8'>
        <table className=' table-fixed w-full uppercase'>
          <thead className=' text-[#8F8F8F]  text-2xl'>
            <tr>
              <th className='text-left w-3/12'>Offer</th>
              <th className='text-right w-4/12'>ORDERS</th>
              <th className='text-right w-3/12'>QTY</th>
            </tr>
          </thead>
          <tbody className='text-2xl font-semibold'>
            <tr className=' w-full  '>
              <td className='py-4 text-left w-3/12 '>252.88</td>
              <td className=' w-4/12 text-right'>252.88</td>
              <td className=' w-3/12 text-right'>252.88</td>
            </tr>
          </tbody>
          <tfoot className='text-2xl text-[#58A8FF] font-semibold'>
            <tr className=' w-full  '>
              <td className='py-4 text-left w-5/12 '>Total</td>
              <td />
              <td className=' w-5/12 text-right'>252.88</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}
