import React, { useRef, useEffect } from 'react';
import * as echarts from 'echarts';

export default function Speedometer({ value, color }) {
  const chartRef = useRef(null);
  const textPicker = (v) => {
    if (v < 20) {
      return 'Extreme Greed';
    }
    if (v >= 20 && v < 40) {
      return 'Fear';
    }
    if (v >= 40 && v < 60) {
      return 'Neutral';
    }

    if (v >= 60 && v < 80) {
      return 'Greed';
    }
    if (v >= 80) {
      return 'Extreme Greed';
    }
    return '';
  };
  useEffect(() => {
    const chart = echarts.init(chartRef.current);

    const option = {
      series: [
        {
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          pointer: {
            show: true,
            length: '70%',
            width: 6,
            // Customize the pointer style
            itemStyle: {
              color: 'white', // Change the pointer color
            },
          },
          axisLabel: {
            show: false, // Hide the axis labels and ticks
          },
          axisTick: {
            show: false, // Hide the ticks
          },
          splitLine: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              width: 14,
              color: [
                [0.2, '#C72B2E'],
                [0.4, '#E76134'],
                [0.6, '#FFC87C'],
                [0.8, '#86C342'],
                [1, '#339F41'],
              ],
            },
          },
          detail: {
            formatter: '{value}',
            offsetCenter: [0, '30%'],
            color,
          },
          data: [
            {
              value,
              detail: {
                fontSize: 20,
              },
            },
          ],
        },
      ],
      graphic: [
        {
          type: 'text',
          left: 'center',
          top: '64%',
          style: {
            text: textPicker(value),
            fill: color,
            fontSize: 16,
          },
        },
        {
          type: 'text',
          left: '3%',
          top: '55%',
          style: {
            text: 'Extereme',
            fill: '#C72B2E',
            fontSize: 16,
          },
        },
        {
          type: 'text',
          left: '8%',
          top: '60%',
          style: {
            text: 'Fear',
            fill: '#C72B2E',
            fontSize: 16,
          },
        },
        {
          type: 'text',
          right: '5%',
          top: '55%',
          style: {
            text: ' Extereme',
            fill: '#339F41',
            fontSize: 16,
          },
        },
        {
          type: 'text',
          right: '8%',
          top: '60%',
          style: {
            text: 'Greed',
            fill: '#339F41',
            fontSize: 16,
          },
        },
      ],
    };

    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [value]);

  return <div ref={chartRef} style={{ width: '100%', height: '300px' }} />;
}
