/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/prefer-default-export */
import React, { useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';

export const EsgGraph = ({ data }) => {
  // Memoized x-axis data
  const xAxisData = useMemo(() => data.map((item) => item.date.split('-')[0]), [data]);

  // Memoized y-axis configuration
  const yAxisOption = useMemo(
    () => [
      {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#FFFF',
          },
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255, 255, 255, 0.09)',
          },
        },
        axisLabel: {
          formatter: '{value}',
        },
      },
      {
        type: 'value',
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: true,
        },
      },
    ],
    [],
  );

  // Line chart series options
  const lineSeries = useMemo(
    () => [
      {
        type: 'line',
        name: 'Governance Score',
        data: data.map((item) => item.governance_score),
        yAxisIndex: 0,
        itemStyle: {
          color: '#1C91D1',
        },
        symbol: 'none',
      },
      {
        type: 'line',
        name: 'Social Score',
        data: data.map((item) => item.social_score),
        yAxisIndex: 0,
        itemStyle: {
          color: '#00ff00',
        },
        symbol: 'none',
      },
      {
        type: 'line',
        name: 'Environmental Score',
        data: data.map((item) => item.environmental_score),
        yAxisIndex: 0,
        itemStyle: {
          color: '#A14AA3',
        },
        symbol: 'none',
      },
      {
        type: 'line',
        name: 'ESG Score',
        data: data.map((item) => item.esg_score),
        yAxisIndex: 0,
        itemStyle: {
          color: '#DC3546',
        },
        symbol: 'none',
      },
    ],
    [data],
  );

  // Memoized chart options
  const chartOptions = useMemo(
    () => ({
      xAxis: {
        type: 'category',
        data: xAxisData,
        axisLabel: {
          color: '#ffffff',
        },
        axisLine: {
          lineStyle: {
            color: '#ffffff',
          },
        },
      },
      yAxis: yAxisOption,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderColor: 'rgba(0, 0, 0, 0.8)',
        textStyle: {
          color: '#ffffff',
        },
      },
      legend: {
        data: ['ESG Score', 'Governance Score', 'Social Score', 'Environmental Score'],
        textStyle: {
          color: '#ffffff',
        },
        padding: [10, 10, 10, 10],
      },
      series: lineSeries,
    }),
    [xAxisData, yAxisOption, lineSeries],
  );

  // Render the chart using ReactEcharts
  return <ReactEcharts option={chartOptions} style={{ width: '100%', height: '400px' }} />;
};
