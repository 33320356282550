import React from 'react';
import Input from 'src/components/InputElements/input';
import { useForm } from '@tanstack/react-form';
import { Button } from 'src/components';
import { useDispatch, useSelector } from 'react-redux';
import TextField from 'src/components/InputElements/textField.component';
import { Dialog, DialogContent, DialogClose, DialogTrigger } from 'src/components/Modal/dialogbox';
import { selectProfile, selectProfileEmail, selectProfileEmailInit } from 'src/pages/Profile/profile.selector';
import { emailUpdate, emailUpdateInitiate } from 'src/pages/Profile/profile.reducer';
interface IPwdForm {
  otp: string;
  email: string;
}

export default function UpdateEmail() {
  const dispatch = useDispatch();
  const profileData = useSelector(selectProfile);
  const otpId = useSelector(selectProfileEmailInit);
  const emailUpdateStatus = useSelector(selectProfileEmail);
  const passwordForm = useForm<IPwdForm>({
    defaultValues: {
      otp: '',
      email: '',
    },
    onSubmit: async ({ value }) => {
      dispatch(
        emailUpdate({
          email: value.email,
          otp_val: value.otp,
          otp_id: otpId?.data,
        }),
      );
    },
  });

  return (
    <Dialog>
      <div>
        <div className='profile-pwd-update-btn-wrapper'>
          <Input type='email' name='password' disabled value={profileData?.data?.email} />
          <DialogTrigger
            className='btn profile-pwd-update-btn'
            onClick={() => {
              dispatch(emailUpdateInitiate({}));
            }}>
            Update Email
          </DialogTrigger>
        </div>
      </div>
      <DialogContent className='profile-pwdmodal'>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            passwordForm.handleSubmit();
          }}>
          <div>
            <passwordForm.Field
              name='otp'
              validators={{
                onChange: ({ value }) => (!value ? 'Otp is required' : null),
              }}
              children={(field) => <TextField field={field} placeholder='One Time password' />}
            />
            <passwordForm.Field
              name='email'
              validators={{
                onChange: ({ value }) => (!value ? 'New email is required' : null),
              }}
              children={(field) => <TextField field={field} placeholder='New Email' />}
            />
          </div>
          {emailUpdateStatus?.data ? <h2 className='ca-nodata'>Email Updated Successfully</h2> : ''}
          {emailUpdateStatus?.error ? <h2 className='ca-nodata'>Email Updation Failed</h2> : ''}
          <div className='profile-pwdmodal-btns'>
            {emailUpdateStatus?.data || emailUpdateStatus?.error ? (
              <DialogClose className='btn-grey btn-rounded btn-lg btn'>Close</DialogClose>
            ) : (
              <>
                <DialogClose className='btn-grey btn-rounded btn-lg btn'>Cancel</DialogClose>
                <Button label='Update' type='submit' className='btn-primary btn-rounded btn-lg ' />
              </>
            )}
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}
