import { useState, useEffect } from 'react';
import { WS_EVENT } from 'src/constants/ws.constant';
import * as XLSX from 'xlsx';

export function getSiblings(e) {
  const siblings: HTMLElement[] = [];
  // if no parent, return no sibling
  if (!e.parentNode) {
    return siblings;
  }
  // first child of the parent node
  let sibling = e.parentNode.firstChild;

  // collecting siblings
  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== e) {
      siblings.push(sibling);
    }
    sibling = sibling.nextSibling;
  }
  return siblings;
}
export function convertDate(inputDate) {
  const [year, month, day] = inputDate.split('-');
  return `${day}-${month}-${year}`;
}
export const findMaxIndex = (dataArray) => {
  let maxIndex = 0;
  dataArray?.forEach((data, i) => {
    if (data && data.length > 0) {
      if (data?.length > dataArray[maxIndex].length) {
        maxIndex = i;
      }
    }
  });
  return maxIndex;
};

export function getNormalizedKeys(obj) {
  const keys: string[] = [];
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        keys.push(...getNormalizedKeys(obj[key]));
      } else {
        keys.push(key);
      }
    }
  }
  return keys;
}

export function flattenObject(obj) {
  const flattened = {};

  function recurse(obj, currentKey = '') {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = currentKey ? `${currentKey}.${key}` : key;
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          recurse(obj[key], newKey);
        } else {
          flattened[newKey] = obj[key];
        }
      }
    }
  }

  recurse(obj);
  return flattened;
}

export function flattenArrays(arrays) {
  const flattenedObject = {};

  arrays.forEach((array) => {
    array.forEach((obj) => {
      const ticker = obj.ticker;
      if (!flattenedObject[ticker]) {
        flattenedObject[ticker] = { ticker };
      }
      Object.assign(flattenedObject[ticker], obj);
    });
  });

  return flattenedObject;
}

export function convertNumberToString(num: string) {
  const Num = parseInt(num);
  if (Num >= 1e9) {
    return (Num / 1e9).toFixed(1) + 'B';
  } else if (Num >= 1e6) {
    return (Num / 1e6).toFixed(1) + 'M';
  } else if (Num >= 1e3) {
    return (Num / 1e3).toFixed(1) + 'K';
  } else {
    return num?.toString();
  }
}
export function convertToNumber(str) {
  const suffixes = {
    K: 1e3, // Thousand
    M: 1e6, // Million
    B: 1e9, // Billion
    T: 1e12, // Trillion
  };

  // Extract the numerical part and the suffix
  const numPart = parseFloat(str); // Extract the numerical value
  const suffix = str.slice(-1).toUpperCase(); // Extract the suffix and convert to uppercase

  // Check if the suffix is valid and present in the suffixes object
  if (suffixes[suffix]) {
    return numPart * suffixes[suffix]; // Convert based on the suffix
  } else {
    return numPart; // If no suffix, return the numeric part as is
  }
}

export function getColorValue(text = '') {
  switch (text) {
    case 'Strong Sell':
      return 'sentissell';
    case 'Sell':
      return 'sentisell';
    case 'Neutral':
      return 'sentiHold';
    case 'Buy':
      return 'sentibuy';
    case 'Strong Buy':
      return 'sentisbuy';
    case 'Outperforming':
      return 'sentibuy';
    case 'Underperforming':
      return 'sentisell';
    case 'Underweight':
      return 'sentibuy';
    case 'Overweight':
      return 'sentisell';
    default:
      return 'sentiHold'; // Default color if text doesn't match any condition
  }
}
export function DebouncedInput({ value: initialValue, onChange, debounce = 500, ...props }) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      className=' bg-[#474747] border border-[#1E222D] rounded-md text-white outline-none p-2  w-full'
    />
  );
}

export function TableFilter({ column }) {
  const columnFilterValue = column.getFilterValue();
  const { filterVariant } = column.columnDef.meta ?? {};

  return filterVariant === 'range' ? (
    <div className='reports-filter '>
      <div className=' flex flex-col '>
        <h2 className='text-left '>Filter</h2>
        <div className=' flex w-full '>
          <DebouncedInput
            type='number'
            value={columnFilterValue?.[0] ?? ''}
            onChange={(value) => column.setFilterValue((old) => [value, old?.[1]])}
            placeholder='Min'
          />
          <DebouncedInput
            type='number'
            value={columnFilterValue?.[1] ?? ''}
            onChange={(value) => column.setFilterValue((old) => [old?.[0], value])}
            placeholder='Max'
          />
        </div>
      </div>
    </div>
  ) : (
    <>
      <DebouncedInput
        type='text'
        value={columnFilterValue ?? ''}
        onChange={(value) => column.setFilterValue(value || undefined)}
        placeholder={`Search... `}
        list={column.id + 'list'}
      />
    </>
  );
}

export const findObjectOrPrevious = (symbol, websocketData, setData) => {
  if (websocketData.event === WS_EVENT.WS_EVENT_SERVER_LIVE_QUOTE) {
    const matchedObject = websocketData?.data?.find((obj) => obj.symbol === symbol);

    if (matchedObject) {
      setData(matchedObject);
    }
  }
};

export const activeElement = (valid) => (valid ? 'activeTf' : '');
export const formattedDate = (dateInput: string | Date): string => {
  const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;

  if (isNaN(date.getTime())) {
    return '';
  }

  // Format the date
  const formattedDate = `${date.toLocaleString('en-US', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()}`;

  return formattedDate;
};

export const exportTableToExcel = (id, tableName) => {
  // Get the HTML table element
  const table = document.getElementById(id);

  // Convert HTML table to a worksheet
  const worksheet = XLSX.utils.table_to_sheet(table);

  // Create a new workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Export the workbook to Excel
  XLSX.writeFile(workbook, `${tableName}.xlsx`);
};
