import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'src/components';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useCountdownTimer } from 'src/hooks/hooks';
import { useForm } from '@tanstack/react-form';
import { yupValidator } from '@tanstack/yup-form-adapter';
import TextField from 'src/components/InputElements/textField.component';
import {
  brandLogoGoogle,
  brandLogoFacebook,
  brandLogoLinkedIn,
  brandLogoTwitter,
  brandLogoApple,
} from 'src/assets/Images';
import { fetchOtpLogin, fetchLogin, updateCounter, setAuthorizationCred } from 'src/pages/Authentication/auth.reducer';
import {
  selectLoginOtp,
  selectResendCount,
  selectAuthorizationCred,
  selectLogin,
} from 'src/pages/Authentication/auth.selector';

import { UserDataUpdate } from 'src/lib/auth';
import { MotionDiv } from 'src/components/motion/motion.component';
function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginOtpData = useSelector(selectLoginOtp);
  const loginData = useSelector(selectLogin);
  const [seconds, resetTimer] = useCountdownTimer();
  const resendTimeCount = useSelector(selectResendCount);
  const authorizationCreds = useSelector(selectAuthorizationCred);

  const sigInInitiateForm = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
    onSubmit: async ({ value }) => logInInitiateHandler(value),
    validatorAdapter: yupValidator(),
  });
  const sigInForm = useForm({
    defaultValues: {
      otp: '',
    },
    onSubmit: async ({ value }) => LogInHandler(value),
    validatorAdapter: yupValidator(),
  });

  const logInInitiateHandler = (values: { username: any; password: any }) => {
    const { username, password } = values;
    dispatch(fetchOtpLogin({ username, password }));
    dispatch(
      setAuthorizationCred({
        username,
        password,
      }),
    );
    resetTimer(30);
  };
  const LogInHandler = (values: { otp: any }) => {
    const { otp } = values;
    dispatch(fetchLogin({ username: authorizationCreds.username, otp_id: loginOtpData.data, otp_num: otp }));
  };

  useEffect(() => {
    if (loginData?.data) {
      UserDataUpdate(loginData?.data, dispatch, navigate);
    }
  }, [dispatch, loginData, navigate]);
  const getClassName = (isValid) => (isValid ? ' btn-disabled' : 'btn-primary');
  return (
    <section className='auth'>
      <div className='auth-container'>
        <div className='auth-header'>
          <h1>
            Welcome <span>Back</span>
          </h1>
          <p>Login with your</p>
        </div>
        {!loginOtpData.data ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              sigInInitiateForm.handleSubmit();
            }}
            className='auth-form'>
            <div className=' auth-form-ui'>
              <sigInInitiateForm.Field
                name='username'
                validators={{
                  onChange: Yup.string().required('Username is required'),
                }}
                children={(field) => {
                  return <TextField field={field} svg='user' placeholder='Username' />;
                }}
              />

              <sigInInitiateForm.Field
                name='password'
                validators={{
                  onChange: Yup.string().required('Password is required'),
                }}
                children={(field) => {
                  return <TextField field={field} type='password' svg='password' placeholder='Password' />;
                }}
              />

              {loginOtpData.error && <div className='auth-error'>{loginOtpData.error.message}</div>}
            </div>
            <div className='auth-addtional-data'>
              <p>
                Don&lsquo;t have an account? <Link to='/register'>Sign Up</Link>
              </p>
              <Link to='/forgot-password'>I forgot password or cant sign in</Link>
            </div>
            <sigInInitiateForm.Subscribe
              selector={(state) => state}
              children={({ isFieldsValid }) => (
                <Button
                  type='submit'
                  disabled={!isFieldsValid}
                  className={`btn  btn-rounded  btn-lg ${getClassName(!isFieldsValid)}`}>
                  {loginOtpData.isLoading ? (
                    <div className='loader-container'>
                      <div className='loader' />
                    </div>
                  ) : (
                    'Submit'
                  )}
                </Button>
              )}
            />
          </form>
        ) : (
          <MotionDiv
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ delay: 0.2, duration: 1.5, type: 'spring', stiffness: 80, damping: 20 }}
            className='auth-form'>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                sigInForm.handleSubmit();
              }}
              className='auth-form-ui'>
              <sigInForm.Field
                name='otp'
                validators={{
                  onChange: Yup.string()
                    .length(6, 'OTP must be of length 6')
                    .matches(/^\d+$/, 'OTP must contain only digits')
                    .required('OTP is required'),
                }}
                children={(field) => {
                  return <TextField field={field} placeholder='Otp' svg='otp' />;
                }}
              />

              {loginData.error && <div className='auth-error '>{loginData.error.message}</div>}
              <div className='auth-form-action'>
                <button
                  type='button'
                  className={`btn btn-rounded btn-lg ${getClassName(seconds || resendTimeCount.counter >= 3)}`}
                  disabled={Boolean(seconds || resendTimeCount.counter >= 3)}
                  onClick={() => {
                    dispatch(
                      fetchOtpLogin({
                        username: authorizationCreds.username,
                        password: authorizationCreds.password,
                      }),
                    );
                    resetTimer(30);
                    dispatch(updateCounter());
                  }}>
                  Resend{' '}
                  {seconds > 0 ? (
                    <span className='countdown'>
                      <span style={{ '--value': seconds } as React.CSSProperties} />
                    </span>
                  ) : (
                    ''
                  )}
                </button>
                <sigInForm.Subscribe
                  selector={(state) => state}
                  children={({ isTouched, isFieldsValid }) => (
                    <Button
                      type='submit'
                      disabled={!(isTouched && isFieldsValid)}
                      className={`btn btn-primary btn-rounded btn-lg ${getClassName(!(isTouched && isFieldsValid))}`}>
                      {loginData.isLoading ? (
                        <div className='loader-contianer'>
                          <div className='loader' />
                        </div>
                      ) : (
                        'Submit'
                      )}
                    </Button>
                  )}
                />
              </div>
            </form>
          </MotionDiv>
        )}
        <div className='auth-alternate-login-divider'>
          <div>or login with</div>
        </div>
        <div className='auth-alternate-login'>
          <img src={brandLogoGoogle} alt='google' />
          <img src={brandLogoFacebook} alt='facebook' />
          <img src={brandLogoLinkedIn} alt='linkedin' />
          <img src={brandLogoTwitter} alt='twitter' />
          <img src={brandLogoApple} alt='apple' />
        </div>
      </div>
    </section>
  );
}

export default Login;
