import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import moreVertical from './svg/moreVertical.svg';
import popUp from './svg/popUp.svg';
import cross from './svg/cross.svg';
// import aapl from './aapl.svg';

const group = [
  {
    groupName: 'Stocks',
    groupid: 'Stocks',
    tickers: [
      { id: '14', name: 'AAPL' },
      { id: '15', name: 'GOOGL' },
    ],
  },
  {
    groupName: 'Crypto',
    groupid: 'Crypto',
    tickers: [
      { id: '11', name: 'BTC' },
      { id: '13', name: 'ETH' },
    ],
  },
  {
    groupName: 'Commo',
    groupid: 'Commo',
    tickers: [
      { id: '17', name: 'GOLD' },
      { id: '18', name: 'SILVER' },
    ],
  },
];
export default function Droppabled({ setTicker }) {
  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    // If the destination is null or the item is dropped in the same position, do nothing
    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      return;
    }

    // Find the source and destination groups in the data
    const sourceGroup = group.find((g) => g.groupid === source.droppableId);
    const destinationGroup = group.find((g) => g.groupid === destination.droppableId);

    // Find the dragged item in the source group
    const draggedItem = sourceGroup.tickers.find((t) => t.id === draggableId);

    // Remove the item from the source group
    sourceGroup.tickers.splice(source.index, 1);

    // Insert the item into the destination group at the specified index
    destinationGroup.tickers.splice(destination.index, 0, draggedItem);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className='flex gap-2 text-[#889BBD] text-xl font-semibold  w-full text-center p-3 min-w-[20rem] '>
        <span className=' w-1/6 '>Symbol</span>
        <span className=' w-1/6 '>Last</span>
        <span className=' w-1/6 ' />
        <span className=' w-1/6 '>Chg</span>
        <span className=' w-1/6 '>Chg%</span>
        <span className=' w-1/6 '>Timeframe</span>
        <span className=' hidden lg:block' />
      </div>
      {group.map((e) => (
        <DropGroup key={e.groupid} data={e.tickers} id={e.groupid} groupName={e.groupName} setTicker={setTicker} />
      ))}
    </DragDropContext>
  );
}

function DropGroup({ data, id, groupName, setTicker }) {
  return (
    <Droppable droppableId={id} key={id}>
      {(provided) => (
        <div className='flex flex-col gap-1  text-xl font-semibold  w-full  text-center py-3  min-w-[20rem]'>
          <span className='text-[#889BBD] text-left p-4'>{groupName}</span>
          <div className='flex flex-col gap-2' ref={provided.innerRef} {...provided.droppableProps}>
            {data.map((item, index) => (
              <DropItem key={item.id} data={item} index={index} setTicker={setTicker} />
            ))}
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  );
}

function DropItem({ data, index, setTicker }) {
  return (
    <Draggable key={data.id} draggableId={data.id} index={index}>
      {(provided) => (
        <div
          className='flex  gap-2  text-xl font-semibold  w-full 
          text-center py-2  border-b-2 border-[#26395C] z-0 px-3 lg:px-0  min-w-[20rem]'
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={() => setTicker(data.name)}>
          <div className='w-[10vw]'>
            {/* <span className='h-4 w-4'>
              <img src={aapl} alt='' />
            </span> */}
            {data.name}
          </div>
          <span className=' w-1/6 '>Last</span>
          <span className=' w-1/6 ' />
          <span className=' w-1/6 '>Chg</span>
          <span className=' w-1/6 '>Chg%</span>
          <span className=' w-1/6 '>Timeframe</span>
          <span className=' w-1/6 hidden lg:flex   gap-2 items-center'>
            <span className='cursor-pointer'>
              <img src={popUp} alt='' className='w-5' />
            </span>
            <span className='cursor-pointer'>
              <img src={cross} alt='' className='w-4' />
            </span>
            <span className='cursor-pointer'>
              <img src={moreVertical} alt='' className='w-2' />
            </span>
          </span>
        </div>
      )}
    </Draggable>
  );
}
