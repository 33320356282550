import React from 'react';
import { PortfolioHeader } from 'src/components/Header/header.component';
import {
  PortfolioSectorArrowOne,
  PortfolioSectorArrowTwo,
  PortfolioSectorArrowThree,
  PortfolioSectorArrowFour,
  PortfolioSectorArrowFive,
  PortfolioSectorArrowSix,
  PortfolioSectorArrowSeven,
  PortfolioSectorArrowEight,
  PortfolioSectorArrowNine,
  PortfolioSectorArrowTen,
  PortfolioSectorArrowEleven,
} from 'src/assets/Images';

function Sector() {
  return (
    <section className='navbar-fix'>
      <PortfolioHeader text={['Manage your investment with', <br />, 'Sector Portfolio']} />
      <div className='sector'>
        <div className='sector__top'>
          <div className='sector__left'>
            <h4>What is Thematic Investing?</h4>
            <p>
              Technological innovations, demographic and social trends, fast urbanisation and environmental challenges,
              etc… The world is changing and alongside all its ecosystem. An increasingly complex, connected and ever
              changing world, where unprecedented digital innovations are disrupting the value, and at a time when we
              are facing major human and climate challenges to build the world of tomorrow for future generations.
            </p>
            <p>
              In a context of market turmoil, thematic investing stands out as an alternative to cyclical hazards. The
              Equity market has been polarizing between passive strategies (index funds and ETFs) and higher conviction
              active strategies. Deviating from a constraining reference index, thematic funds are gaining ground as
              core holdings within a portfolio by bringing extra alpha.
            </p>
            <p>
              Note: A thematic investment approach is not for all investors. Anybody with a short-term investment
              horizon should not consider our investment funds. Our investment strategies might substantially differ
              from standard benchmar​ks and can experience periods of high volatility. Longer-term investors could
              potentially benefit from the attractive growth prospects of the megatrend.
            </p>
          </div>
          <div className='sector__right'>
            <h4>Who is it for ?</h4>
            <p>
              A thematic approach offers an understandable way to invest in tomorrow’s winners and act for the future.
              By selecting a theme, investors could give a sense besides its investments and return: a sense as the
              ability to under- stand concretely the companies’ activities.
            </p>
            <p>Thematic investing is suited for investors: </p>
            <ul>
              <li>Having a long-term investment horizon and consen-ting to a high level of risk</li>
              <li>
                Wishing to benefit from a higher return than that of equity markets on the long term while accepting
                periods of underperformance due to short term market variations
              </li>
            </ul>
          </div>
        </div>
        <div className='sector__title'>
          <h2>find items...</h2>
        </div>
        <div className='sector__bottom'>
          <img src={PortfolioSectorArrowOne} alt='' className='sector--arrowOne' />
          {/* Circle One */}
          <div className='sector__circleOne'>
            <h1>Information Technology</h1>
          </div>
          {/* Circle Two */}
          <img src={PortfolioSectorArrowTwo} alt='' className='sector--arrowTwo' />
          <div className='sector__circleTwo'>
            <h1>Health Care</h1>
          </div>
          {/* Circle Three */}
          <img src={PortfolioSectorArrowThree} alt='' className='sector--arrowThree' />
          <div className='sector__circleThree'>
            <h1>Financials</h1>
          </div>
          {/* Circle Four */}
          <img src={PortfolioSectorArrowFour} alt='' className='sector--arrowFour' />
          <div className='sector__circleFour'>
            <h1>Energy</h1>
          </div>
          {/* Circle Five */}
          <img src={PortfolioSectorArrowFive} alt='' className='sector--arrowFive' />
          <div className='sector__circleFive'>
            <h1>Consumer Staples</h1>
          </div>
          {/* Circle Six */}
          <img src={PortfolioSectorArrowSix} alt='' className='sector--arrowSix' />
          <div className='sector__circleSix'>
            <h1>Consumer Discretionary</h1>
          </div>
          {/* Circle Seven */}
          <img src={PortfolioSectorArrowSeven} alt='' className='sector--arrowSeven' />
          <div className='sector__circleSeven'>
            <h1>Communication Service</h1>
          </div>

          {/* Circle Eight */}
          <img src={PortfolioSectorArrowEight} alt='' className='sector--arrowEight' />
          <div className='sector__circleEight'>
            <h1>Industrials</h1>
          </div>

          {/* Circle Nine */}
          <img src={PortfolioSectorArrowNine} alt='' className='sector--arrowNine' />
          <div className='sector__circleNine'>
            <h1>Utilities</h1>
          </div>

          {/* Circle Ten */}
          <img src={PortfolioSectorArrowTen} alt='' className='sector--arrowTen' />
          <div className='sector__circleTen'>
            <h1>Real Estate</h1>
          </div>

          {/* Circle Eleven */}
          <img src={PortfolioSectorArrowEleven} alt='' className='sector--arrowEleven' />
          <div className='sector__circleEleven'>
            <h1>Materials </h1>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sector;
