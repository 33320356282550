import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
function IRecommendFundamentalView({ fundamentalData }) {
  return (
    <section className='irecommendFundamentalTable'>
      <table>
        <thead>
          <tr>
            <th>TICKER</th>
            <th>NAME</th>
            <th>SECTOR</th>
            <th>P/E</th>
            <th>P/B</th>
            <th>P/FCF</th>
            <th>ROE</th>
            <th>ROA</th>
            <th>EPS</th>
            <th>DIV. YIELD</th>
            <th>SIGNAL</th>
            <th>DURATION</th>
            <th>DATE</th>
            <th>
              <EllipsisVerticalIcon className='size-8' />
            </th>
          </tr>
        </thead>
        <tbody>
          {fundamentalData.map((item) => (
            <tr key={item.id}>
              <td>
                <img src={item.logo} alt='' />
                <p>{item.ticker}</p>
              </td>
              <td>{item.name}</td>
              <td>{item.sector}</td>
              <td>{item.PE}</td>
              <td>{item.PB}</td>
              <td>{item.PFCF}</td>
              <td>{item.ROE}</td>
              <td>{item.ROA}</td>
              <td>{item.EPS}</td>
              <td>{item.yield}</td>
              <td>{item.signal}</td>
              <td>{item.duration}</td>
              <td>10/10/2023</td>
              <td />
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}

export { IRecommendFundamentalView };

// I-Recommend Technical View Table :
function IRecommendTechnicalView({ technicalData }) {
  return (
    <div className='irecommendTechnicalTable'>
      <table>
        <thead>
          <tr>
            <th>TICKER</th>
            <th>NAME</th>
            <th>SECTOR</th>
            <th>BENCHMARK</th>
            <th>VOLATILITY</th>
            <th>MOMENTUM</th>
            <th>SENTIMENT</th>
            <th>CYCLE</th>
            <th>SIGNAL</th>
            <th>DATE</th>
            <th>
              <EllipsisVerticalIcon className='size-8' />
            </th>
          </tr>
        </thead>
        <tbody>
          {technicalData.map((item) => (
            <tr key={item.id}>
              <td>
                <img src={item.logo} alt='' />
                <p>{item.ticker}</p>
              </td>
              <td>{item.name}</td>
              <td>{item.sector}</td>
              <td>{item.benchmark}</td>
              <td>{item.volatility}</td>
              <td>{item.momentum}</td>
              <td>{item.sentiment}</td>
              <td>{item.cycle}</td>
              <td>{item.signal}</td>
              <td>{item.date}</td>
              <td />
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export { IRecommendTechnicalView };
