import { put, takeLatest } from 'redux-saga/effects';
import {
  fetchWatchlistOverview,
  fetchWatchlistOverviewFailure,
  fetchWatchlistOverviewSuccess,
  fetchWatchlistRatio,
  fetchWatchlistRatioFailure,
  fetchWatchlistRatioSuccess,
  fetchWatchlistEsg,
  fetchWatchlistEsgFailure,
  fetchWatchlistEsgSuccess,
  fetchWatchlistFinancial,
  fetchWatchlistFinancialFailure,
  fetchWatchlistFinancialSuccess,
  fetchWatchlistDividend,
  fetchWatchlistDividendFailure,
  fetchWatchlistDividendSuccess,
  fetchWatchlistStockSplit,
  fetchWatchlistStockSplitFailure,
  fetchWatchlistStockSplitSuccess,
  fetchWatchlistAnalystEstimate,
  fetchWatchlistAnalystEstimateFailure,
  fetchWatchlistAnalystEstimateSuccess,
  fetchWatchlistAnalystRecommendation,
  fetchWatchlistAnalystRecommendationFailure,
  fetchWatchlistAnalystRecommendationSuccess,
  fetchWatchlistOwnership,
  fetchWatchlistOwnershipFailure,
  fetchWatchlistOwnershipSuccess,
  fetchWatchlistRatings,
  fetchWatchlistRatingsFailure,
  fetchWatchlistRatingsSuccess,
  fetchWatchlistSocialSentiment,
  fetchWatchlistSocialSentimentFailure,
  fetchWatchlistSocialSentimentSuccess,
  fetchWatchlistPressRelease,
  fetchWatchlistPressReleaseFailure,
  fetchWatchlistPressReleaseSuccess,
  fetchWatchlistNews,
  fetchWatchlistNewsFailure,
  fetchWatchlistNewsSuccess,
  fetchWatchlistBarsSuccess,
  fetchWatchlistBarsFailure,
  fetchWatchlistBars,
} from './Watchlist.reducer';
import {
  getWatchlistAnalystEstimate,
  getWatchlistAnalystRecomendation,
  getWatchlistCorporateActionDividend,
  getWatchlistCorporateActionSplit,
  getWatchlistESG,
  getWatchlistFinancial,
  getWatchlistOverview,
  getWatchlistOwnership,
  getWatchlistPressRelease,
  getWatchlistRatingGrades,
  getWatchlistRatio,
  getWatchlistSocialsentiment,
} from 'src/repositories/watchlist/watchlist';
import { postMarketNews } from 'src/repositories/news/news';
import { IError } from 'src/lib/types/error';
import { postTvBars } from 'src/repositories/tv/tv';

function* fetchWatchlistOverviewSaga(action) {
  try {
    const data = yield getWatchlistOverview(action.payload).then((response) => response);
    yield put(fetchWatchlistOverviewSuccess(data?.data?.data));
  } catch (error) {
    yield put(fetchWatchlistOverviewFailure(error as IError));
  }
}

export function* watchWatchlistOverviewSaga() {
  yield takeLatest(fetchWatchlistOverview.type, fetchWatchlistOverviewSaga);
}

function* fetchWatchlistRatioSaga(action) {
  try {
    const data = yield getWatchlistRatio(action.payload).then((response) => response);
    yield put(fetchWatchlistRatioSuccess(data.data.data));
  } catch (error) {
    yield put(fetchWatchlistRatioFailure(error as IError));
  }
}

export function* watchWatchlistRatioSaga() {
  yield takeLatest(fetchWatchlistRatio.type, fetchWatchlistRatioSaga);
}

function* fetchWatchlistEsgSaga(action) {
  try {
    const data = yield getWatchlistESG(action.payload).then((response) => response);
    yield put(fetchWatchlistEsgSuccess(data.data.data));
  } catch (error) {
    yield put(fetchWatchlistEsgFailure(error as IError));
  }
}

export function* watchWatchlistEsgSaga() {
  yield takeLatest(fetchWatchlistEsg.type, fetchWatchlistEsgSaga);
}

function* fetchWatchlistFinancialSaga(action) {
  try {
    const data = yield getWatchlistFinancial(action.payload).then((response) => response);
    yield put(fetchWatchlistFinancialSuccess(data.data.data));
  } catch (error) {
    yield put(fetchWatchlistFinancialFailure(error as IError));
  }
}

export function* watchWatchlistFinancialsSaga() {
  yield takeLatest(fetchWatchlistFinancial.type, fetchWatchlistFinancialSaga);
}

function* fetchWatchlistDividendSaga(action) {
  try {
    const data = yield getWatchlistCorporateActionDividend(action.payload).then((response) => response);
    yield put(fetchWatchlistDividendSuccess(data.data.data));
  } catch (error) {
    yield put(fetchWatchlistDividendFailure(error as IError));
  }
}

export function* watchWatchlistDividendsSaga() {
  yield takeLatest(fetchWatchlistDividend.type, fetchWatchlistDividendSaga);
}

function* fetchWatchlistStockSplitSaga(action) {
  try {
    const data = yield getWatchlistCorporateActionSplit(action.payload).then((response) => response);
    yield put(fetchWatchlistStockSplitSuccess(data.data.data));
  } catch (error) {
    yield put(fetchWatchlistStockSplitFailure(error as IError));
  }
}

export function* watchWatchlistStockSplitsSaga() {
  yield takeLatest(fetchWatchlistStockSplit.type, fetchWatchlistStockSplitSaga);
}

function* fetchWatchlistAnalystEstimateSaga(action) {
  try {
    const data = yield getWatchlistAnalystEstimate(action.payload).then((response) => response);
    yield put(fetchWatchlistAnalystEstimateSuccess(data.data.data));
  } catch (error) {
    yield put(fetchWatchlistAnalystEstimateFailure(error as IError));
  }
}

export function* watchWatchlistAnalystEstimatesSaga() {
  yield takeLatest(fetchWatchlistAnalystEstimate.type, fetchWatchlistAnalystEstimateSaga);
}

function* fetchWatchlistAnalystRecommendationSaga(action) {
  try {
    const data = yield getWatchlistAnalystRecomendation(action.payload).then((response) => response);
    yield put(fetchWatchlistAnalystRecommendationSuccess(data.data.data));
  } catch (error) {
    yield put(fetchWatchlistAnalystRecommendationFailure(error as IError));
  }
}

export function* watchWatchlistAnalystRecommendationsSaga() {
  yield takeLatest(fetchWatchlistAnalystRecommendation.type, fetchWatchlistAnalystRecommendationSaga);
}

function* fetchWatchlistOwnershipSaga(action) {
  try {
    const data = yield getWatchlistOwnership(action.payload).then((response) => response);
    yield put(fetchWatchlistOwnershipSuccess(data.data.data));
  } catch (error) {
    yield put(fetchWatchlistOwnershipFailure(error as IError));
  }
}

export function* watchWatchlistOwnershipsSaga() {
  yield takeLatest(fetchWatchlistOwnership.type, fetchWatchlistOwnershipSaga);
}

function* fetchWatchlistRatingsSaga(action) {
  try {
    const data = yield getWatchlistRatingGrades(action.payload).then((response) => response);
    yield put(fetchWatchlistRatingsSuccess(data.data.data));
  } catch (error) {
    yield put(fetchWatchlistRatingsFailure(error as IError));
  }
}

export function* watchWatchlistRatingssSaga() {
  yield takeLatest(fetchWatchlistRatings.type, fetchWatchlistRatingsSaga);
}

function* fetchWatchlistSocialSentimentSaga(action) {
  try {
    const data = yield getWatchlistSocialsentiment(action.payload).then((response) => response);
    yield put(fetchWatchlistSocialSentimentSuccess(data.data.data));
  } catch (error) {
    yield put(fetchWatchlistSocialSentimentFailure(error as IError));
  }
}

export function* watchWatchlistSocialSentimentsSaga() {
  yield takeLatest(fetchWatchlistSocialSentiment.type, fetchWatchlistSocialSentimentSaga);
}

function* fetchWatchlistPressReleaseSaga(action) {
  try {
    const data = yield getWatchlistPressRelease(action.payload).then((response) => response);
    yield put(fetchWatchlistPressReleaseSuccess(data.data.data));
  } catch (error) {
    yield put(fetchWatchlistPressReleaseFailure(error as IError));
  }
}

export function* watchWatchlistPressReleasesSaga() {
  yield takeLatest(fetchWatchlistPressRelease.type, fetchWatchlistPressReleaseSaga);
}

function* fetchWatchlistNewsSaga(action) {
  try {
    const data = yield postMarketNews(action.payload).then((response) => response);
    yield put(fetchWatchlistNewsSuccess(data.data.data));
  } catch (error) {
    yield put(fetchWatchlistNewsFailure(error as IError));
  }
}

export function* watchWatchlistNewsSaga() {
  yield takeLatest(fetchWatchlistNews.type, fetchWatchlistNewsSaga);
}
function* fetchWatchlistBarsSaga(action) {
  try {
    const data = yield postTvBars(action.payload).then((response) => response);
    yield put(fetchWatchlistBarsSuccess(data.data.data));
  } catch (error) {
    yield put(fetchWatchlistBarsFailure(error as IError));
  }
}

export function* watchWatchlistBarsSaga() {
  yield takeLatest(fetchWatchlistBars.type, fetchWatchlistBarsSaga);
}
