import React from 'react';
import { motion, MotionProps } from 'framer-motion';

interface ReusableMotionProps extends MotionProps {
  children: React.ReactNode;
  className?: string;
  isVisible?: boolean;
}
interface ReusableMotionButtonProps extends MotionProps {
  children: React.ReactNode;
  className?: string;
  isVisible?: boolean;
  type: 'button' | 'submit' | 'reset'; // More specific types for the button element
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
interface ReusableMotionImgProps extends MotionProps {
  className?: string;
  isVisible?: boolean;
  src: string;
  alt?: string;
}
export const MotionDiv: React.FC<ReusableMotionProps> = ({ children, className, ...motionProps }) => {
  return (
    <motion.div className={className} {...motionProps}>
      {children}
    </motion.div>
  );
};

export const MotionImg: React.FC<ReusableMotionImgProps> = ({ children, className, ...motionProps }) => {
  return <motion.img className={className} {...motionProps} />;
};
export const MotionH1: React.FC<ReusableMotionProps> = ({ children, className, ...motionProps }) => {
  return (
    <motion.h1 className={className} {...motionProps}>
      {children}
    </motion.h1>
  );
};
export const MotionH2: React.FC<ReusableMotionProps> = ({ children, className, ...motionProps }) => {
  return (
    <motion.h2 className={className} {...motionProps}>
      {children}
    </motion.h2>
  );
};
export const MotionH3: React.FC<ReusableMotionProps> = ({ children, className, ...motionProps }) => {
  return (
    <motion.h3 className={className} {...motionProps}>
      {children}
    </motion.h3>
  );
};
export const MotionP: React.FC<ReusableMotionProps> = ({ children, className, isVisible = true, ...motionProps }) => {
  return (
    <motion.p className={className} {...motionProps}>
      {children}
    </motion.p>
  );
};
export const MotionButton: React.FC<ReusableMotionButtonProps> = ({ children, className, ...motionProps }) => {
  return (
    <motion.button className={className} {...motionProps}>
      {children}
    </motion.button>
  );
};
