import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ShareIcon, TrashIcon, HandThumbUpIcon } from '@heroicons/react/20/solid';
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  TelegramShareButton,
  TelegramIcon,
} from 'react-share';
import { Button, ModalWrapper, Para } from 'src/components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClubDetail, postClubComment, postClubLike } from '../club.reducer';
import { selectClubDetails } from '../club.selector';
import { IClubContent } from 'src/lib/types/club';
import { cardDummyImage, dummyUser, notificationWarning } from 'src/assets/Images';
import { loggedIn } from 'src/lib/auth';
import { convertNumberToString } from 'src/lib/utils/utils';
import Skeleton from 'react-loading-skeleton';
import { selectCurrentUser } from 'src/store/user/user.selector';
export default function ClubDetails() {
  const dispatch = useDispatch();
  const details = useSelector(selectClubDetails);
  const user = useSelector(selectCurrentUser);
  const [confirmModal, setConfirmModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [like, setLike] = useState(0);
  const [comments, setComments] = useState<{ comment: string; created_at: string; user_name: string }[]>([]);
  const [hasLiked, setHasLiked] = useState(false);
  const { id } = useParams<{ id: string }>();

  const isSignedIn = loggedIn();
  useEffect(() => {
    if (details?.data) {
      setHasLiked(details?.data?.has_liked);
      setLike(details?.data?.likes);
      setComments(details?.data?.comments_list);
    }
  }, [details?.data]);

  const commentRef = useRef<HTMLInputElement>(null);

  const commentHandler = () => {
    if (commentRef.current && commentRef.current.value !== '') {
      dispatch(postClubComment({ content_id: id, comment: commentRef.current.value }));
      if (commentRef.current != null) {
        const comment = commentRef.current.value;
        setComments((prev) => [...prev, { comment, created_at: new Date().toDateString(), user_name: user.userName }]);
      }
      commentRef.current.value = '';
    }
  };
  const formattedDate = (date) =>
    date
      ? new Date(date).toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        })
      : ' ';
  useEffect(() => {
    dispatch(fetchClubDetail({ id }));
  }, [id, dispatch]);

  return (
    <>
      <section className='club-body-container navbar-fix'>
        <div className='club-body-container-main'>
          {details?.isLoading ? (
            <div className='academy-details-body'>
              <Skeleton className='img' />
              <Skeleton height='2.1rem' width='50%' />
              <Skeleton height='3.1rem' />
              <Skeleton height='2.1rem' />
            </div>
          ) : (
            <div className='academy-details-body'>
              <img src={details?.data?.image_long || cardDummyImage} alt='' />
              <h3 className='club-details-category'>{details?.data?.category}</h3>
              <h1 className='academy-details-header'>{details?.data?.headline}</h1>
              <h2 className='club-content-author'>
                By <span>{details?.data?.user_name}</span>
              </h2>
              <div className='club-details-info'>
                <div className='club-details-info-dt'>
                  <span>
                    {`${new Date(details?.data?.created_at).toLocaleString('en-US', {
                      month: 'long',
                    })} ${new Date(details?.data?.created_at).getDate()},${new Date(
                      details?.data?.created_at,
                    ).getFullYear()}` || ' '}
                  </span>
                  <div />
                  <span>{details?.data?.time_to_read} min read</span>
                </div>
                <div className='club-details-info-lk'>
                  <div
                    className='club-details-info-lk'
                    onClick={() => {
                      dispatch(postClubLike({ content_id: details?.data?.id, like: hasLiked }));
                      if (hasLiked) setLike((prevCount) => prevCount - 1);
                      else setLike((prevCount) => prevCount + 1);
                      setHasLiked(!hasLiked);
                    }}>
                    <HandThumbUpIcon className='size-8' />
                    Like
                    <span>{convertNumberToString(like.toString())}</span>
                  </div>
                  <div className='club-details-info-lk ' onClick={() => setShareModal(true)}>
                    <p>Share</p>
                    <ShareIcon className='size-8' />
                  </div>
                </div>
              </div>

              <Para url={details?.data?.details} />
            </div>
          )}

          <div className='club-comment'>
            {isSignedIn ? (
              <>
                <h1>Leave a comment</h1>
                <div className='club-add-comment'>
                  <input type='text' placeholder='Write your comment.....' ref={commentRef} />
                  <Button type='button' className='btn-primary btn-lg' label='Submit' onClick={commentHandler} />
                </div>
              </>
            ) : null}
            <h1 className='clubDetails__other'>Other comments</h1>
            {details?.data &&
              comments.map((item, i) => (
                <div className='clubDetails__userComment' key={i}>
                  <div className='clubDetails__userCommentTop'>
                    <div className='clubDetails__timing'>
                      <img src={dummyUser} alt='' />
                      <h1>{item?.user_name}</h1>
                      <span>{formattedDate(item?.created_at)}</span>

                      {/* {isSignedIn && <TrashIcon className='size-8' onClick={() => setConfirmModal(true)} />} */}
                    </div>
                  </div>
                  <div className='clubDetails__userCommentBottom'>
                    <p>{item?.comment}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className='club-container-sub'>
          {details?.data &&
            details?.data?.similar_contents.map((item: IClubContent, i: number) => (
              <Link to={`${item.id}`} key={i}>
                {item.headline}
              </Link>
            ))}
        </div>
      </section>

      <ModalWrapper open={confirmModal} onClose={() => setConfirmModal(false)}>
        <div className='clubConfirmModal'>
          <img src={notificationWarning} alt='warning-icon' />
          <h1>Do you want to delete this comment?</h1>
          <div className='clubConfirmModal--Btn'>
            <Button className='btn-primary' label='Cancel' onClick={() => setConfirmModal(!confirmModal)} />
            <Button className='btn--transparent clubConfirmModal--customBtn' label='Confirm' />
          </div>
        </div>
      </ModalWrapper>
      {/* Share Post Modal */}
      <ModalWrapper open={shareModal} onClose={() => setShareModal(false)}>
        <div className='clubShareModal'>
          <div className='clubShareModal__Main'>
            <FacebookShareButton url={window.location.href}>
              <FacebookIcon iconFillColor='white' round size={52} />
            </FacebookShareButton>

            <WhatsappShareButton url={window.location.href}>
              <WhatsappIcon iconFillColor='white' round size={52} />
            </WhatsappShareButton>

            <EmailShareButton url={window.location.href}>
              <EmailIcon iconFillColor='white' round size={52} />
            </EmailShareButton>

            <TelegramShareButton url={window.location.href}>
              <TelegramIcon iconFillColor='white' round size={52} />
            </TelegramShareButton>
          </div>
          <div className='clubShareModal--btn'>
            <Button className='btn-primary btn-rounded' label='Cancel' onClick={() => setShareModal(!shareModal)} />
          </div>
        </div>
      </ModalWrapper>
    </>
  );
}
