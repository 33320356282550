import { createSelector } from 'reselect';
const selectWatchlistOverviewState = (state: { watchlistOverview: any }) => state.watchlistOverview;
export const selectWatchlistOverview = createSelector([selectWatchlistOverviewState], (state) => state);
const selectWatchlistRatioState = (state: { watchlistRatio: any }) => state.watchlistRatio;
export const selectWatchlistRatio = createSelector([selectWatchlistRatioState], (state) => state);
const selectWatchlistEsgState = (state: { watchlistEsg: any }) => state.watchlistEsg;
export const selectWatchlistEsg = createSelector([selectWatchlistEsgState], (state) => state);
const selectWatchlistFinancialState = (state: { watchlistFinancial: any }) => state.watchlistFinancial;
export const selectWatchlistFinancial = createSelector([selectWatchlistFinancialState], (state) => state);
const selectWatchlistDividendState = (state: { watchlistDividend: any }) => state.watchlistDividend;
export const selectWatchlistDividend = createSelector([selectWatchlistDividendState], (state) => state);
const selectWatchlistStockSplitState = (state: { watchlistStockSplit: any }) => state.watchlistStockSplit;
export const selectWatchlistStockSplit = createSelector([selectWatchlistStockSplitState], (state) => state);

const selectWatchlistAnalystEstimateState = (state: { watchlistAnalystEstimate: any }) =>
  state.watchlistAnalystEstimate;
export const selectWatchlistAnalystEstimate = createSelector([selectWatchlistAnalystEstimateState], (state) => state);
const selectWatchlistAnalystRecommendationState = (state: { watchlistAnalystRecommendation: any }) =>
  state.watchlistAnalystRecommendation;
export const selectWatchlistAnalystRecommendation = createSelector(
  [selectWatchlistAnalystRecommendationState],
  (state) => state,
);
const selectWatchlistOwnershipState = (state: { watchlistOwnership: any }) => state.watchlistOwnership;
export const selectWatchlistOwnership = createSelector([selectWatchlistOwnershipState], (state) => state);
const selectWatchlistRatingsState = (state: { watchlistRatings: any }) => state.watchlistRatings;
export const selectWatchlistRatings = createSelector([selectWatchlistRatingsState], (state) => state);
const selectWatchlistSocialSentimentState = (state: { watchlistSocialSentiment: any }) =>
  state.watchlistSocialSentiment;
export const selectWatchlistSocialSentiment = createSelector([selectWatchlistSocialSentimentState], (state) => state);
const selectWatchlistPressReleaseState = (state: { watchlistPressRelease: any }) => state.watchlistPressRelease;
export const selectWatchlistPressRelease = createSelector([selectWatchlistPressReleaseState], (state) => state);
const selectWatchlistNewsState = (state: { watchlistNews: any }) => state.watchlistNews;
export const selectWatchlistNews = createSelector([selectWatchlistNewsState], (state) => state);
