import React from 'react';
import StockpeerCard from '../Card/stockpeerCard';
import Skeleton from 'react-loading-skeleton';
import Carousel from 'react-multi-carousel';

export default function Stockpeers({ data, isLoading }) {
  const responsive = {
    desktop: {
      breakpoint: { max: 2500, min: 1367 },
      items: 3,
    },
    largeTablet: {
      breakpoint: { max: 1366, min: 1281 },
      items: 3,
    },
    nestHubLarge: {
      breakpoint: { max: 1280, min: 1025 },
      items: 2,
    },
    nestHubMedium: {
      breakpoint: { max: 1024, min: 913 },
      items: 2,
    },
    nestHubSmall: {
      breakpoint: { max: 912, min: 769 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 768, min: 465 },
      items: 1,
      centerMode: true, // Ensures centering when there is only one item
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      centerMode: true, // Ensures centering when there is only one item
    },
  };
  return (
    <section>
      <h1 className='financials-section-header'>PEERS</h1>
      <div className='stockpeer-contianer'>
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={false}
          arrows={true}
          // centerMode={true}
          showDots={false}
          // customLeftArrow={CustomLeftArrow}
          // customRightArrow={CustomRightArrow}
          keyBoardControl={true}
          containerClass='stockpeer-contianer'>
          {isLoading ? (
            <Skeleton className='stockpeer' />
          ) : (
            data?.stock_peers?.map((item, i) => <StockpeerCard item={item} key={i} />)
          )}
        </Carousel>
      </div>
    </section>
  );
}
