import { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { Banner, Cta, Blog, Stats, Footer, Navbar } from 'src/components';
import { marketingCurve1, marketingCurve2, marketingBall1, marketingBall2, marketingMoneyBag } from 'src/assets/Images';
import { MotionImg } from 'src/components/motion/motion.component';
function Marketing() {
  const [isVisibleOne, setIsVisibleOne] = useState(false);
  const [isVisibleTwo, setIsVisibleTwo] = useState(false);
  const [isVisibleThree, setIsVisibleThree] = useState(false);
  const [isVisibleFour, setIsVisibleFour] = useState(false);
  const [isVisibleFive, setIsVisibleFive] = useState(false);

  const handleVisibilityChangeThree = (isVisibleThree) => {
    setIsVisibleThree(isVisibleThree);
  };
  const isVisibleProp = (visible, prop1, prop2) => (visible ? prop1 : prop2);
  return (
    <main className='marketing'>
      <Navbar topbarVisible={true} />
      <section className='pt-[20rem]'>
        <Banner />
      </section>
      <div className='marketing__BGOne'>
        <VisibilitySensor partialVisibility onChange={(visible) => setIsVisibleOne(visible)}>
          <MotionImg
            src={marketingCurve1}
            initial={{ x: -200, opacity: 0, scale: 0.5 }}
            animate={{ x: isVisibleProp(isVisibleOne, 0, -200), opacity: isVisibleProp(isVisibleOne, 1, 0), scale: 1 }}
            transition={{ duration: 1.5, delay: 1 }}
          />
        </VisibilitySensor>
      </div>
      <section>
        <Cta />
      </section>
      <div className='marketing__BGTwo'>
        <VisibilitySensor partialVisibility onChange={(visible) => setIsVisibleTwo(visible)}>
          <MotionImg
            src={marketingCurve2}
            alt='bgTwo'
            initial={{ x: -200, opacity: 0, scale: 0.5 }}
            animate={{ x: isVisibleProp(isVisibleTwo, 0, 100), opacity: isVisibleProp(isVisibleTwo, 1, 0), scale: 1 }}
            transition={{ duration: 1.5, delay: 2 }}
          />
        </VisibilitySensor>
      </div>
      <div className='marketing__BGThree'>
        <VisibilitySensor partialVisibility onChange={handleVisibilityChangeThree}>
          <MotionImg
            src={marketingBall1}
            initial={{ scale: 0.5 }}
            animate={{ scale: isVisibleProp(isVisibleThree, 1, 0.5) }}
            transition={{ duration: 4 }}
            style={{ visibility: isVisibleThree ? 'visible' : 'hidden' }}
            className='quant__widthContainer--BGTwo'
          />
        </VisibilitySensor>
      </div>
      <section>
        <a href='#blog'>
          <Blog />
        </a>
      </section>
      <div className='marketing__BGFour'>
        <VisibilitySensor partialVisibility onChange={(visible) => setIsVisibleFour(visible)}>
          <MotionImg
            src={marketingBall2}
            initial={{ x: -200, opacity: 0, scale: 0.5 }}
            animate={{
              x: isVisibleProp(isVisibleFour, 0, -100),
              opacity: isVisibleProp(isVisibleFour, 1, 0),
              scale: 1,
            }}
            transition={{ duration: 1.5, delay: 1 }}
          />
        </VisibilitySensor>
      </div>
      <div className='marketing__BGFive'>
        <VisibilitySensor partialVisibility onChange={(visible) => setIsVisibleFive(visible)}>
          <MotionImg
            src={marketingMoneyBag}
            initial={{ x: -200, opacity: 0, scale: 0.5 }}
            animate={{ x: isVisibleProp(isVisibleFive, 0, 100), opacity: isVisibleProp(isVisibleFive, 1, 0), scale: 1 }}
            transition={{ duration: 1.5, delay: 1 }}
          />
        </VisibilitySensor>
      </div>
      <section className='marketing__stats'>
        <Stats />
      </section>
      <section className='marketing__footer'>
        <Footer />
      </section>
    </main>
  );
}

export default Marketing;
