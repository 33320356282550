/* eslint-disable max-len */
/* eslint-disable indent */
import { apiClient } from '../apiClient';
import { config } from 'src/lib/config';

export const getTvConfig = async (params: undefined) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_CHARTING_BACKEND + config.API_TV_CONFIG;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const getTvSymbols = async (params: undefined) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_CHARTING_BACKEND + '/symbols';
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const postTvBars = async (payload: { timeframe: any; from: any; to: any; mkt_type: any; ticker: any }) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_CHARTING_BACKEND + config.API_TV_BARS;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};
