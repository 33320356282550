import React from 'react';
import { PressReleaseCard2 } from 'src/components';
import { Link } from 'react-router-dom';
import { PressReleseCard2loader } from '../loadingAnimation/cardloader';
export default function OverviewPressrelease({ data, isLoading }) {
  return (
    <div className='overview-news-section-container'>
      <div className='overview-news-section-header'>
        <h1 className='financials-section-header'>Press Release</h1>
        <h2 className='financials-section-header'>
          <Link to='news/press-release'>MORE</Link>
        </h2>
      </div>
      {isLoading ? (
        <>
          <PressReleseCard2loader />
          <PressReleseCard2loader />
        </>
      ) : (
        data?.press_release?.map((item, i) => <PressReleaseCard2 data={item} key={i} image={data?.profile?.image} />)
      )}
    </div>
  );
}
