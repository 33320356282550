import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

export default function EsgArc({
  Width,
  Height,
  innerRadius,
  needleValue,
  needleGap,
  needleWidth,
  needleRadii,
  name,
  colorReading,
}) {
  const svgRef = useRef();

  useEffect(() => {
    const margin = { top: 10, right: 10, bottom: 20, left: 20 };
    const radius = Math.min(Width, Height) - margin.top - margin.bottom;
    const centerX = Width / 2;
    const centerY = Height - margin.bottom;
    const svg = d3
      .select(svgRef.current)
      .attr('viewBox', `0 0 ${Width} ${Height}`)
      .append('g')
      .attr('transform', `translate(${centerX},${centerY})`);
    svg
      .append('text')
      .attr('class', 'percentage-text')
      .attr('x', 0)
      .attr('y', 10)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(name)
      .style('fill', '#ffff')
      .style('font-size', '7px');
    svg
      .append('text')
      .attr('class', 'percentage-text')
      .attr('x', centerX / 3)
      .attr('y', 0)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(`${needleValue}%`)
      .style('fill', colorReading)
      .style('font-size', '7px');
    const minValue = 0;
    const maxValue = 100;
    const range = maxValue - minValue;
    const segmentWidth = radius / 5;
    const segmentHeight = radius;
    const segmentPadding = 0.005 * range;

    const gaugeScale = d3
      .scaleLinear()
      .domain([minValue, maxValue])
      .range([-Math.PI / 2, Math.PI / 4]);

    const segments = [
      {
        value: range * 0.16,
        colorNormal: '#BDBDBD',
      },
      {
        value: range * 0.16,
        colorNormal: '#BDBDBD',
      },
      {
        value: range * 0.16,
        colorNormal: '#BDBDBD',
      },
      {
        value: range * 0.16,
        colorNormal: '#BDBDBD',
      },
      {
        value: range * 0.16,
        colorNormal: '#BDBDBD',
      },
      {
        value: range * 0.16,
        colorNormal: '#BDBDBD',
      },
      {
        value: range * 0.16,
        colorNormal: '#BDBDBD',
      },
      {
        value: range * 0.16,
        colorNormal: '#BDBDBD',
      },
    ];

    const arc = d3
      .arc()
      .innerRadius(radius - innerRadius * 2)
      .outerRadius(radius)
      .startAngle((d, i) => gaugeScale(minValue + (i * range) / 8 + segmentPadding))
      .endAngle((d, i) => gaugeScale(minValue + ((i + 1) * range) / 8 - segmentPadding));

    const gauge = svg.append('g');

    gauge
      .selectAll('path')
      .data(segments)
      .enter()
      .append('path')
      .attr('d', arc)
      .style('fill', (d, i) => (i <= Math.floor(needleValue / (range / 8)) ? colorReading : d.colorNormal));

    const needleHeight = segmentHeight;

    const needle = gauge.append('g').attr('transform', `rotate(-90)`);

    const needleCircle = needle
      .append('circle')
      .attr('r', needleRadii || 0.1)
      .style('fill', 'none')
      .style('stroke', '#ffffff')
      .style('stroke-width', needleRadii / 2 || 0.005);
    const needleRect = needle
      .append('line')
      .style('stroke', '#ffffff')
      .style('stroke-width', needleWidth)
      .attr('x1', 0)
      .attr('y1', -needleGap)
      .attr('x2', 0)
      .attr('y2', -segmentHeight + needleGap);
    const needleAngle = gaugeScale(needleValue);

    needle
      .transition()
      .duration(500)
      .ease(d3.easeBounce)
      .attr('transform', `rotate(${(needleAngle * 180) / Math.PI})`);
  }, [needleValue]);

  return <svg ref={svgRef} />;
}
