import React, { useEffect, useRef, useMemo, memo, useCallback } from 'react';
import * as echarts from 'echarts';

export const MultipleSentimentGraph = memo(({ selectedRows, isTwitter, selectedData, max }) => {
  const colors = ['#ff5252', '#2979ff', '#4caf50', '#ff9100', '#ff80ab'];
  const chartRef = useRef(null);
  const xAxisData = useMemo(
    () => selectedData[max || 0]?.map((item) => item.date.slice(5, 16)) || [],
    [selectedData, max],
  );

  const yAxisOption = useMemo(
    () => [
      {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#FFFF',
          },
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255, 255, 255, 0.09)',
          },
        },
        axisLabel: {
          formatter: '{value} ',
        },
      },
      {
        type: 'value',
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: true,
        },
      },
    ],
    [],
  );

  const lineSeries = useMemo(() => {
    const series = [];

    selectedData.forEach((selectedRow, index) => {
      if (selectedRow && selectedRow.length > 0) {
        const symbol = selectedRow[0]?.symbol || '';
        if (symbol) {
          series.push({
            type: 'line',
            name: symbol,
            data: selectedRow.map((value) =>
              ((!isTwitter ? value.stocktwitsSentiment : value.twitterSentiment) * 100).toFixed(2),
            ),
            yAxisIndex: 0,
            itemStyle: {
              color: colors[index],
            },
            symbol: 'none',
          });
        }
      }
    });

    return series;
  }, [selectedData, isTwitter]);
  const createChart = useCallback(() => {
    const chart = echarts.init(chartRef.current);
    const option = {
      xAxis: {
        type: 'category',
        data: xAxisData,
        axisLabel: {
          color: '#ffffff',
          rotate: 45,
        },
        axisLine: {
          lineStyle: {
            color: '#ffffff',
          },
        },
      },
      yAxis: yAxisOption,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderColor: 'rgba(0, 0, 0, 0.8)',
        textStyle: {
          color: '#ffffff',
        },
      },
      legend: {
        data: selectedRows,
        textStyle: {
          color: '#ffffff',
        },
        padding: [10, 10, 10, 10],
      },
      series: [...lineSeries],
    };
    chart.setOption(option);
    // Clean up the chart instance when the component unmounts
    return () => {
      chart.dispose();
    };
  }, [xAxisData, yAxisOption, lineSeries, selectedRows]);

  useEffect(() => {
    const cleanup = createChart();

    return () => {
      cleanup();
    };
  }, [createChart]);

  return <div ref={chartRef} style={{ width: '100%', height: '400px' }} />;
});
