import { createSelector } from 'reselect';

const loginOtp = (state: { loginOtp: any }) => state.loginOtp;
const login = (state: { login: any }) => state.login;
const registerOtp = (state: { registerOtp: any }) => state.registerOtp;
const register = (state: { register: any }) => state.register;
const forgotPassOtp = (state: { forgotPasswordOtp: any }) => state.forgotPasswordOtp;
const forgotPass = (state: { forgotPassword: any }) => state.forgotPassword;
const cred = (state: { cred: any }) => state.cred;
const counter = (state: { resendCounter: any }) => state.resendCounter;
export const selectLoginOtp = createSelector([loginOtp], (state) => state);
export const selectLogin = createSelector([login], (state) => state);
export const selectRegisterOtp = createSelector([registerOtp], (state) => state);
export const selectRegister = createSelector([register], (state) => state);
export const selectForgotPassOtp = createSelector([forgotPassOtp], (state) => state);
export const selectForgotPass = createSelector([forgotPass], (state) => state);
export const selectAuthorizationCred = createSelector([cred], (state) => state);
export const selectResendCount = createSelector([counter], (state) => state);
