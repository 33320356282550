import React, { useEffect, useRef, useState, memo, useMemo, useCallback } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import 'react-calendar/dist/Calendar.css';
import CountryFlag from 'react-country-flag';
import Calendar from 'react-calendar';
import { PrimaryHeader, MarketsNews } from 'src/components';
import { DataCardTwo, DataCardThree } from 'src/components/Card/card.component';
import * as echarts from 'echarts/core';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { convertToNumber } from 'src/lib/utils/utils';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import MarketSidebar from 'src/components/marketsComponents/marketSidebar';
import {
  selectMarketEconomicCalender,
  selectMarketEconomicIndicator,
  selectMarketEodPrice,
  selectMarketHoliday,
  selectMarketNews,
  selectMarketPERatio,
  selectMarketSectorPerformance,
  selectMarketTopStocks,
  selectMarketTreasuryRate,
} from 'src/pages/Markets/markets.selector';
import {
  fetchMarketEconomicCalender,
  fetchMarketEconomicIndicator,
  fetchMarketEodPrice,
  fetchMarketHoliday,
  fetchMarketNews,
  fetchMarketPERatios,
  fetchMarketSectorsPerformance,
  fetchMarketTopStocks,
  fetchMarketTreasuryRate,
} from 'src/pages/Markets/markets.reducer';
import {
  IEconomicIndicatorData,
  IMarketEconomicCalenderEventData,
  IMarketHoliday,
  IMarketResp,
  IMarketTopStocks,
} from 'src/lib/types/markets';
import { activeElement } from 'src/lib/utils/utils';
export default function MarketNews() {
  const [marketType, setMarketType] = useState('');
  const dispatch = useDispatch();
  const news = useSelector(selectMarketNews);
  useEffect(() => {
    dispatch(fetchMarketNews({}));
  }, []);

  return (
    <section className='markets-news'>
      <h1>Latest News</h1>
      <ul className=' menu menu-horizontal '>
        <li
          className={`${activeElement(!marketType)} `}
          onClick={() => {
            setMarketType('');
            dispatch(fetchMarketNews({}));
          }}>
          General
        </li>
        <li
          className={`${activeElement(marketType === 'UsStocks')} `}
          onClick={() => {
            setMarketType('UsStocks');
            dispatch(fetchMarketNews({ mkt_type: 'UsStocks' }));
          }}>
          Stocks
        </li>
        <li
          className={`${activeElement(marketType === 'Fx')} `}
          onClick={() => {
            setMarketType('Fx');
            dispatch(fetchMarketNews({ mkt_type: 'Fx' }));
          }}>
          Forex
        </li>
        <li
          className={`${activeElement(marketType === 'Crypto')} `}
          onClick={() => {
            setMarketType('Crypto');
            dispatch(fetchMarketNews({ mkt_type: 'Crypto' }));
          }}>
          Crypto
        </li>
      </ul>

      {news?.data
        ? news?.data?.slice(0, 4).map((item: any, index: number) => <MarketsNews data={item} key={index} />)
        : ''}

      <Link to='/subscription' className='markets-news-more'>
        more...
      </Link>
    </section>
  );
}
