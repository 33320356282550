/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

export default function BuySellGauge({
  Width,
  Height,
  innerRadius,
  textSize,
  textColor,
  needleValue,
  needleGap,
  needleWidth,
  needleRadii,
}) {
  const svgRef = useRef();
  useEffect(() => {
    const margin = { top: 30, right: 10, bottom: 30, left: 20 };
    const radius = Math.min(Width, Height) - margin.top - margin.bottom;
    const centerX = Width / 2;
    const centerY = Height - margin.bottom;
    const svg = d3
      .select(svgRef.current)
      .attr('viewBox', `0 0 ${Width} ${Height}`)
      .append('g')
      .attr('transform', `translate(${centerX},${centerY})`);
    const minValue = 0;
    const maxValue = 100;
    const gaugeScale = d3
      .scaleLinear()
      .domain([minValue, maxValue])
      .range([-Math.PI / 2, Math.PI / 2]);
    const arc = d3
      .arc()
      .innerRadius(radius - innerRadius)
      .outerRadius(radius)
      .startAngle(gaugeScale(minValue));
    const gauge = svg.append('g');
    gauge
      .append('path')
      .datum({ endAngle: gaugeScale(maxValue) })
      .attr('d', arc)
      .style('fill', '#ffffff');
    const gradient = gauge
      .append('defs')
      .append('linearGradient')
      .attr('id', 'gauge-gradient')
      .attr('gradientUnits', 'userSpaceOnUse')
      .attr('x1', -radius)
      .attr('y1', 0)
      .attr('x2', radius)
      .attr('y2', 0);
    gradient.append('stop').attr('offset', '0%').attr('stop-color', '#FF9900');
    gradient.append('stop').attr('offset', '100%').attr('stop-color', '#0C7365');
    const rect = gauge
      .append('rect')
      .attr('x', -radius)
      .attr('y', -radius)
      .attr('width', radius * 2)
      .attr('height', radius * 2)
      .style('fill', 'url(#gauge-gradient)')
      .style('clip-path', 'url(#gauge-clip-path)');

    gauge
      .append('clipPath')
      .attr('id', 'gauge-clip-path')
      .append('path')
      .datum({ endAngle: gaugeScale(needleValue) })
      .attr(
        'd',
        arc.endAngle((d) => d.endAngle),
      );

    const needleHeight = radius - radius / 10;
    const needle = gauge.append('g').attr('transform', `rotate(-90)`);
    const needleCircle = needle
      .append('circle')
      .attr('r', needleRadii || 0.1)
      .style('fill', 'none')
      .style('stroke', '#ffffff')
      .style('stroke-width', needleRadii / 2 || 0.005);
    const needleRect = needle
      .append('line')
      .style('stroke', '#ffffff')
      .style('stroke-width', needleWidth)
      .attr('x1', 0)
      .attr('y1', -needleGap)
      .attr('x2', 0)
      .attr('y2', -needleHeight + needleGap);
    const needleAngle = gaugeScale(needleValue);
    gauge
      .append('g')
      .attr('class', 'index1')
      .attr('x', 0)
      .attr('y', 0)
      .style('font-size', '13px')
      .style('font-weight', '600')
      .attr('transform', `rotate(${(gaugeScale(minValue) * 180) / Math.PI}) translate(0, -${Height * 0.9})`)
      .append('text')
      .attr('x', 0)
      .attr('y', 0)
      .style('fill', '#ffff')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr('transform', 'rotate(90)')
      .append('tspan')
      .text('Strong')
      .attr('dy', '-0.5em')
      .append('tspan')
      .text('Sell')
      .attr('x', 0)
      .attr('dy', '1em');
    gauge
      .append('g')
      .attr('class', 'index2')
      .attr('x', 0)
      .attr('y', 0)
      .style('font-size', '13px')
      .style('font-weight', '600')
      .attr('transform', `rotate(${(gaugeScale(maxValue) * 180) / Math.PI}) translate(0, -${Height * 0.9})`)
      .append('text')
      .attr('x', 0)
      .attr('y', 0)
      .style('fill', '#ffff')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr('transform', 'rotate(-90)')
      .append('tspan')
      .text('Strong')
      .attr('dy', '-0.5em')
      .append('tspan')
      .text('Buy')
      .attr('x', 0)
      .attr('dy', '1em');
    gauge
      .append('g')
      .attr('class', 'index3')
      .attr('x', 0)
      .attr('y', 0)
      .style('font-size', '13px')
      .style('font-weight', '600')
      .attr('transform', `rotate(${(gaugeScale(maxValue - 20) * 180) / Math.PI}) translate(0, -${Height * 0.8})`)
      .append('text')
      .attr('x', 0)
      .attr('y', 0)
      .style('fill', '#ffff')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr('transform', 'rotate(-55)')
      .append('tspan')
      .append('tspan')
      .text('Buy')
      .attr('x', 0);
    gauge
      .append('g')
      .attr('class', 'index4')
      .attr('x', 0)
      .attr('y', 0)
      .style('font-size', '13px')
      .style('font-weight', '600')
      .attr('transform', `rotate(${(gaugeScale(minValue + 20) * 180) / Math.PI}) translate(0, -${Height * 0.8})`)
      .append('text')
      .attr('x', 0)
      .attr('y', 0)
      .style('fill', '#ffff')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr('transform', 'rotate(55)')
      .append('tspan')
      .append('tspan')
      .text('Sell')
      .attr('x', 0);
    gauge
      .append('g')
      .attr('class', 'index5')
      .attr('x', 0)
      .attr('y', 0)
      .style('font-size', '13px')
      .style('font-weight', '600')
      .attr('transform', `rotate(${(gaugeScale(minValue + 50) * 180) / Math.PI}) translate(0, -${Height * 0.8})`)
      .append('text')
      .attr('x', 0)
      .attr('y', 0)
      .style('fill', '#ffff')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr('transform', 'rotate(0)')
      .append('tspan')
      .append('tspan')
      .text('Neutral')
      .attr('x', 0);

    needle
      .transition()
      .duration(500)
      .ease(d3.easeBounce)
      .attr('transform', `rotate(${(needleAngle * 180) / Math.PI})`);
  }, [needleValue]);
  return <svg ref={svgRef} />;
}
