import { put, takeLatest } from 'redux-saga/effects';
import {
  getMarketsEconomicCalendar,
  getMarketsEconomicIndicator,
  getMarketsNews,
  getMarketsPERatios,
  getMarketsSectorsPerformance,
  getMarketsTopStocks,
  getMarketsTradingHours,
  getMarketsTreasuryRates,
  postMarketGetEodPrice,
} from 'src/repositories/markets/markets';
import {
  fetchMarketEconomicIndicator,
  fetchMarketEconomicIndicatorSuccess,
  fetchMarketEconomicIndicatorFailure,
  fetchMarketHoliday,
  fetchMarketHolidaySuccess,
  fetchMarketHolidayFailure,
  fetchMarketTreasuryRate,
  fetchMarketTreasuryRateSuccess,
  fetchMarketTreasuryRateFailure,
  fetchMarketEconomicCalender,
  fetchMarketEconomicCalenderSuccess,
  fetchMarketEconomicCalenderFailure,
  fetchMarketEodPriceSuccess,
  fetchMarketEodPrice,
  fetchMarketEodPriceFailure,
  fetchMarketPERatios,
  fetchMarketPERatiosFailure,
  fetchMarketPERatiosSuccess,
  fetchMarketSectorsPerformance,
  fetchMarketSectorsPerformanceFailure,
  fetchMarketSectorsPerformanceSuccess,
  fetchMarketTopStocks,
  fetchMarketTopStocksFailure,
  fetchMarketTopStocksSuccess,
  fetchMarketNews,
  fetchMarketNewsFailure,
  fetchMarketNewsSuccess,
} from './markets.reducer';
import { IError } from 'src/lib/types/error';

function* fetchMarketEconomicIndicatorSaga(_action) {
  try {
    const data = yield getMarketsEconomicIndicator().then((response) => response);
    yield put(fetchMarketEconomicIndicatorSuccess(data.data.data));
  } catch (error) {
    yield put(fetchMarketEconomicIndicatorFailure(error as IError));
  }
}

export function* watchMarketEconomicIndicatorDataSaga() {
  yield takeLatest(fetchMarketEconomicIndicator.type, fetchMarketEconomicIndicatorSaga);
}

function* fetchMarketHolidaySaga(_action) {
  try {
    const data = yield getMarketsTradingHours().then((response) => response);
    yield put(fetchMarketHolidaySuccess(data.data.data));
  } catch (error) {
    yield put(fetchMarketHolidayFailure(error as IError));
  }
}

export function* watchMarketHolidayDataSaga() {
  yield takeLatest(fetchMarketHoliday.type, fetchMarketHolidaySaga);
}

function* fetchMarketTreasuryRateSaga(_action) {
  try {
    const data = yield getMarketsTreasuryRates().then((response) => response);
    yield put(fetchMarketTreasuryRateSuccess(data.data.data));
  } catch (error) {
    yield put(fetchMarketTreasuryRateFailure(error as IError));
  }
}

export function* watchMarketTreasuryRateDataSaga() {
  yield takeLatest(fetchMarketTreasuryRate.type, fetchMarketTreasuryRateSaga);
}

function* fetchMarketEconomicCalenderSaga(_action) {
  try {
    const data = yield getMarketsEconomicCalendar().then((response) => response);
    yield put(fetchMarketEconomicCalenderSuccess(data.data.data));
  } catch (error) {
    yield put(fetchMarketEconomicCalenderFailure(error as IError));
  }
}

export function* watchMarketEconomicCalenderDataSaga() {
  yield takeLatest(fetchMarketEconomicCalender.type, fetchMarketEconomicCalenderSaga);
}

function* fetchMarketEodPriceSaga(action) {
  try {
    const data = yield postMarketGetEodPrice(action.payload).then((response) => response);
    yield put(fetchMarketEodPriceSuccess(data.data.data));
  } catch (error) {
    yield put(fetchMarketEodPriceFailure(error as IError));
  }
}

export function* watchMarketEodPriceDataSaga() {
  yield takeLatest(fetchMarketEodPrice.type, fetchMarketEodPriceSaga);
}

function* fetchMarketSectorsPerformanceSaga(_action) {
  try {
    const data = yield getMarketsSectorsPerformance().then((response) => response);
    yield put(fetchMarketSectorsPerformanceSuccess(data.data.data));
  } catch (error) {
    yield put(fetchMarketSectorsPerformanceFailure(error as IError));
  }
}

export function* watchMarketSectorsPerformanceDataSaga() {
  yield takeLatest(fetchMarketSectorsPerformance.type, fetchMarketSectorsPerformanceSaga);
}

function* fetchMarketPERatiosSaga(_action) {
  try {
    const data = yield getMarketsPERatios().then((response) => response);
    yield put(fetchMarketPERatiosSuccess(data.data.data));
  } catch (error) {
    yield put(fetchMarketPERatiosFailure(error as IError));
  }
}

export function* watchMarketPERatiosDataSaga() {
  yield takeLatest(fetchMarketPERatios.type, fetchMarketPERatiosSaga);
}

function* fetchMarketTopStocksSaga(_action) {
  try {
    const data = yield getMarketsTopStocks(_action.payload).then((response) => response);
    yield put(fetchMarketTopStocksSuccess(data.data.data));
  } catch (error) {
    yield put(fetchMarketTopStocksFailure(error as IError));
  }
}

export function* watchMarketTopStocksDataSaga() {
  yield takeLatest(fetchMarketTopStocks.type, fetchMarketTopStocksSaga);
}

function* fetchMarketNewsSaga(_action) {
  try {
    const data = yield getMarketsNews(_action.payload).then((response) => response);
    yield put(fetchMarketNewsSuccess(data.data.data));
  } catch (error) {
    yield put(fetchMarketNewsFailure(error as IError));
  }
}

export function* watchMarketNewsDataSaga() {
  yield takeLatest(fetchMarketNews.type, fetchMarketNewsSaga);
}
