import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
type AuthHocProps = {
  isAuth: boolean;
  children: ReactNode;
};
export function GuestGaurd(props: AuthHocProps) {
  const { children, isAuth } = props;
  if (isAuth) {
    return <>{children}</>;
  }
  return <Navigate to='/login' />;
}

export function AuthGuard(props: AuthHocProps) {
  const { children, isAuth } = props;
  if (isAuth) {
    return <Navigate to='/' />;
  }
  return <>{children}</>;
}
