import React, { useState, useRef, useEffect } from 'react';
import { ToggleButton, PrimaryHeader } from 'src/components';
import AccountSubsciption from '../subscription/subscription.router';
import { whiteCircle } from 'src/assets/Images';
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import UpdatePassword from '../../../components/Profile/Updatepassword';
import UpdateEmail from '../../../components/Profile/UpdateEmail';
import ProfileForm from '../../../components/Profile/ProfileForm';
import { useDispatch } from 'react-redux';
import { fetchProfile, profilePicUpdate } from '../profile.reducer';

export default function Profile() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProfile());
  }, []);

  const [navToggle, setNavToggle] = useState(1);
  const [file, setFile] = useState<Blob | null>(null);

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFile(file);
    }
  };

  return (
    <main className='profile navbar-fix'>
      <PrimaryHeader text='Profile' className='color-primary' />
      <section>
        <div className='profile__container'>
          <div className='profile__toggleContainer'>
            <p>Choose theme color:</p>
            <div className='profile__header'>
              <div className='profile__header--toggleButton'>
                <p>Quant</p>
                <ToggleButton className='blue' id='toggle_btn_1' />
              </div>
              <div className='profile__header--toggleButton'>
                <p>iPortfolio</p>
                <ToggleButton className='grey' id='toggle_btn_2' />
              </div>
              <div className='profile__header--toggleButton'>
                <p>iProfile</p>
                <ToggleButton className='purple' id='toggle_btn_3' />
              </div>
            </div>
          </div>

          <hr />
          <div className='profile__body'>
            <div className='profile__sidebar'>
              <h1>Quick Links</h1>
              <div className='profile__Links'>
                <ul>
                  <li
                    onClick={() => setNavToggle(1)}
                    className={navToggle === 1 ? 'navToggle__active' : 'navToggle__normal'}>
                    <div className='profile__singleLink'>
                      <img src={whiteCircle} alt='' />
                      User Profile
                    </div>
                    <ArrowRightIcon className='size-8' />
                  </li>
                </ul>
              </div>
            </div>
            {navToggle === 1 ? (
              <div className='profile__formContainer'>
                <div className='profile__containerTop'>
                  <div className='profile__container--Left'>
                    <h1 className=' block'>Public Information</h1>

                    <div className='profile__uploader'>
                      <div className={` h-[10rem] `}>
                        <label htmlFor='Avater'>
                          <input
                            type='file'
                            accept='image/*'
                            id='Avater'
                            name='Avater'
                            hidden
                            ref={hiddenFileInput}
                            onChange={handleChange}
                          />
                        </label>
                        <div className='fileUpload__container'>
                          <div className='pd'>
                            <span>PD</span>
                          </div>
                          <div className={`flex-[8] mx-[2rem] flex flex-col gap-y-[1rem] `}>
                            <div className='p1'>JPG or PNG. Max size of 700KB</div>
                            <div className='p__container'>
                              <div
                                className='p2 cursor-pointer'
                                onClick={() => {
                                  if (file) dispatch(profilePicUpdate({ files: file }));
                                }}>
                                Upload photo
                              </div>
                              <div className='p3 cursor-pointer' onClick={handleClick}>
                                Select photo
                              </div>
                            </div>
                          </div>
                          {/* {<h2></h2>} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='profile__container--Right'>
                    <h1>Private Details</h1>
                    <UpdateEmail />

                    <UpdatePassword />
                  </div>
                </div>

                <ProfileForm />
              </div>
            ) : null}
            {navToggle === 2 ? (
              <div className='profile__sub'>
                <AccountSubsciption />
              </div>
            ) : null}
          </div>
        </div>
      </section>
    </main>
  );
}
