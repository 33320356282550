import React, { createRef, useEffect, useMemo, useRef } from 'react';
import { widget } from '../../charting_library';
import { postTvBars } from 'src/repositories/tv/tv.ts';
import { subscribeOnStream, unsubscribeFromStream } from './streaming.js';
import { useSelector } from 'react-redux';
import { selectTvConfig, selectTvSymbol } from './tv.selctor.ts';
import { useDispatch } from 'react-redux';
import { fetchTvConfig, fetchTvSymbol } from './tv.reducer.ts';

const lastBarsCache = new Map();
const createDatafeed = (config, symbols, websocketData) => ({
  onReady: (callback) => {
    const configurationData = {
      supported_resolutions: config.supported_resolutions,
      exchanges: config.exchanges,
      symbols_types: config.symbols_types,
    };
    setTimeout(() => callback(configurationData));
  },
  searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
    const newSymbols = symbols?.filter((symbol) => {
      const isExchangeValid = exchange === '' || symbol.exchange === exchange;
      const isFullSymbolContainsInput = symbol.full_name.toLowerCase().includes(userInput.toLowerCase());
      const isSymbolContainsInput = symbol.symbol.toLowerCase().includes(userInput.toLowerCase());
      return isExchangeValid && (isFullSymbolContainsInput || isSymbolContainsInput);
    });
    onResultReadyCallback(newSymbols);
  },
  resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
    const symbolItem = symbols?.find(({ symbol, full_name }) => symbol === symbolName || full_name === symbolName);
    if (!symbolItem) {
      onResolveErrorCallback('cannot resolve symbol');
      return;
    }
    const symbolInfo = {
      ticker: symbolItem.full_name,
      name: symbolItem.symbol,
      description: symbolItem.description,
      type: symbolItem.type,
      session: '0000-0000',
      timezone: 'America/New_York',
      exchange: symbolItem.exchange,
      minmov: 1,
      pricescale: 100,
      has_intraday: true,
      intraday_multipliers: ['5', '15', '30', '60'],
      visible_plots_set: 'ohlcv',
      has_weekly_and_monthly: false,
      has_seconds: false,
      supported_resolutions: ['5', '15', '30', '60', '240', '1D', ...config.supported_resolutions],
      volume_precision: 2,
      data_status: 'endofday',
    };
    onSymbolResolvedCallback(symbolInfo);
  },
  getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
    const { from, to, firstDataRequest } = periodParams;
    try {
      const result = await postTvBars({
        timeframe: resolution,
        from,
        to,
        mkt_type: symbolInfo.type,
        ticker: symbolInfo.name,
      });
      if (result.data.data.length === 0) {
        onHistoryCallback([]);
        return;
      }
      const bars = result.data.data
        .filter((bar) => Date.parse(bar.datetime) >= from * 1000 && Date.parse(bar.datetime) < to * 1000)
        .map((bar) => ({
          time: Date.parse(bar.datetime),
          low: bar.low,
          high: bar.high,
          open: bar.open,
          close: bar.close,
          volume: bar.volume,
        }));
      if (firstDataRequest) {
        lastBarsCache.set(symbolInfo.symbol, { ...bars[bars.length - 1] });
      }
      onHistoryCallback(bars, { noData: false });
    } catch (err) {
      onErrorCallback(err);
    }
  },
  subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
    subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscriberUID,
      onResetCacheNeededCallback,
      lastBarsCache.get(symbolInfo.symbol),
    );
  },
  unsubscribeBars: (subscriberUID) => {
    unsubscribeFromStream(subscriberUID);
  },
});

export default function TVChartContainer({ className, tick = 'BTCUSD', featuresetDisableList }) {
  const websocketData = useSelector((state) => state.websocket);
  const tvref = createRef();
  const tvWidgetRef = useRef(null);

  const config = useSelector(selectTvConfig);
  const symbols = useSelector(selectTvSymbol);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTvConfig());
    dispatch(fetchTvSymbol());
  }, []);
  const datafeed = useMemo(() => createDatafeed(config, symbols, websocketData), [config, symbols, tick]);
  useEffect(() => {
    if (tvWidgetRef.current) return; // Prevent multiple widget initialization

    const tvWidget = new widget({
      symbol: tick,
      interval: '1D',
      autosize: true,
      container: tvref.current,
      disabled_features: featuresetDisableList,
      custom_css_url: `${window.location.origin}/style.css`,
      debug: true,
      load_last_chart: true,
      datafeed,
      library_path: '../../charting_library/charting_library.js',
      overrides: {
        'paneProperties.backgroundType': 'solid',
        'paneProperties.background': '#041432',
      },
    });
    tvWidgetRef.current = tvWidget;
    return () => {
      if (tvWidgetRef.current) {
        tvWidgetRef.current.remove();
        tvWidgetRef.current = null;
      }
    };
  }, [featuresetDisableList, datafeed, tick]);
  useEffect(() => {
    if (tvWidgetRef.current && tick !== '') {
      tvWidgetRef.current.onChartReady(() => {
        tvWidgetRef.current.activeChart().setSymbol(tick);
      });
    }
  }, [tick]);

  return <div ref={tvref} className={className} />;
}
