import React from 'react';
import { IExpertDetailsHeader, IExpertHeader } from 'src/components/Header/header.component';
import {
  iExpertDummyImageOne,
  iExpertDummyImageTwo,
  iExpertDummyImageThree,
  iExpertDummyImageFour,
} from 'src/assets/Images';

function IExpert() {
  return (
    <section className='navbar-fix'>
      <IExpertHeader text='i-Expert' />
      <main className='iexpert'>
        <IExpertDetailsHeader />
        <section className='iexpert__body'>
          <section className='iexpert__main'>
            <section className='iexpert__left'>
              <article className='iexpert__mainCard'>
                <h1>Trend</h1>
                <article className='iexpert__card'>
                  <div className='iexpert__cardTop'>
                    <h3>
                      Short : <span>Up</span>
                    </h3>
                    <h3>
                      Intermediate : <span>Down</span>
                    </h3>
                    <h3>
                      Long : <span>Up</span>
                    </h3>
                  </div>
                  <div className='iexpert__cardBottom'>
                    <img src={iExpertDummyImageOne} alt='' />
                  </div>
                </article>
              </article>
              {/* Card Two */}
              <article className='iexpert__mainCard'>
                <h1>Ranking</h1>
                <article className='iexpert__card'>
                  <div className='iexpert__cardTop'>
                    <h3>
                      Fundamental rating : <span>2</span>
                    </h3>
                    <h3>
                      Technical rating : <span>4</span>
                    </h3>
                    <h3>
                      Liquidity rating : <span>8</span>
                    </h3>
                  </div>
                  <div className='iexpert__cardBottom'>
                    <img src={iExpertDummyImageTwo} alt='' />
                  </div>
                </article>
              </article>
              {/* Card Three */}
              <article className='iexpert__mainCard'>
                <h1>Relative Performance</h1>
                <article className='iexpert__card'>
                  <div className='iexpert__cardTop'>
                    <h3>
                      Benchmark : <span>Outperforming</span>
                    </h3>
                  </div>
                  <div className='iexpert__cardBottom'>
                    <img src={iExpertDummyImageThree} alt='' />
                  </div>
                </article>
              </article>
              {/* Card Four */}
              <article className='iexpert__mainCard'>
                <h1>Sentiment</h1>
                <article className='iexpert__card'>
                  <div className='iexpert__cardTop'>
                    <h3>
                      Sentiment : <span>Optimism (57%)</span>
                    </h3>
                  </div>
                  <div className='iexpert__cardBottom'>
                    <img src={iExpertDummyImageFour} alt='' />
                  </div>
                </article>
              </article>
            </section>
            <section className='iexpert__right'>
              <h1>Details Informations</h1>
              <article className='iexpert__rightContainer'>
                {/* First */}
                <p>Trend</p>
                <div className='iexpert__rightContainer--T'>
                  <div className='iexpert__rightDetails'>
                    <p>Short</p>
                    <h4>Up</h4>
                  </div>
                  <div className='iexpert__rightDetails'>
                    <p>Intermediate</p>
                    <h4>Long</h4>
                  </div>
                  <div className='iexpert__rightDetails'>
                    <p>Long</p>
                    <h4>Up</h4>
                  </div>
                </div>
                {/* Second */}
                <div className='iexpert__rightContainer--Linear'>
                  <p>Smoothness</p>
                  <h4>Smooth</h4>
                </div>
                <div className='iexpert__rightContainer--T mt-[-1.5rem]'>
                  <div className='iexpert__rightDetails'>
                    <p>Current Signal</p>
                    <h4 className='text-[#24D92B]'>BUY</h4>
                  </div>
                  <div className='iexpert__rightDetails'>
                    <p>Current Signal Date</p>
                    <h4>18/12/18</h4>
                  </div>
                </div>
                {/* Third */}
                <div className='iexpert__rightContainer--Linear'>
                  <p>Weekly Signal</p>
                  <h4 className='text-[#FF3434]'>SELL</h4>
                </div>
                {/* Fourth */}
                <div className='iexpert__rightContainer--Linear'>
                  <p>Weekly Signal Date</p>
                  <h4>18/12/18</h4>
                </div>
                {/* Fifth */}
                <div className='iexpert__rightContainer--Linear'>
                  <p>Benchmark</p>
                  <h4 className='text-[#24D92B]'>Outperforming</h4>
                </div>
                {/* Sixth */}
                <div className='iexpert__rightContainer--Linear'>
                  <p>Fundamental Rating</p>
                  <h4>2</h4>
                </div>
                {/* Seventh */}
                <div className='iexpert__rightContainer--Linear'>
                  <p>Technical Rating</p>
                  <h4>4</h4>
                </div>
                {/* Eighth */}
                <div className='iexpert__rightContainer--Linear'>
                  <p>Liquidity Rating</p>
                  <h4>6</h4>
                </div>
                {/* Nineth */}
                <div className='iexpert__rightContainer--Linear'>
                  <p>Volatility</p>
                  <h4 className='text-[#FF3434]'>Low</h4>
                </div>
                {/* Tenth */}
                <div className='iexpert__rightContainer--Linear'>
                  <p>Momentum</p>
                  <h4 className='text-[#FFB300]'>Neutral</h4>
                </div>
                {/* Eleventh */}
                <div className='iexpert__rightContainer--Linear'>
                  <p>Sentiment</p>
                  <h4 className='text-[#00E4FF]'>Optimism</h4>
                </div>
                {/* Twelveth */}
                <div className='iexpert__rightContainer--Linear'>
                  <p>Cycle</p>
                  <h4 className='text-[#24D92B]'>Cycle Peak</h4>
                </div>
              </article>
            </section>
          </section>
        </section>
      </main>
    </section>
  );
}

export default IExpert;
