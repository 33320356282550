import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { BuySellGauge } from 'src/components';
import { selectWatchlistAnalystRecommendation } from '../../Watchlist.selector';
import { fetchWatchlistAnalystRecommendation } from '../../Watchlist.reducer';
import { ProjectionGraph } from 'src/components/graph/ProjectionGraph';
const InfoSection = ({ title, avgPriceTarget, publishers, isLoading }) => (
  <article className='recommendation__One'>
    <h1>{title}</h1>
    <div className='recommendation__One--Target'>
      <h3>Average Price Target</h3>
      <Icon />
    </div>
    <div className='recommendation__One--Price'>{isLoading ? <Skeleton /> : avgPriceTarget}</div>
    {!isLoading && (
      <div className='recommendation__One--Count'>
        <Icon />
        <h4>{`Based on ${publishers} Analysts`}</h4>
      </div>
    )}
  </article>
);

const Icon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
    strokeWidth={1.5}
    stroke='currentColor'
    className='w-[2rem] h-[2rem]'>
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z'
    />
  </svg>
);

function AnalystRecommendation() {
  const { ticker } = useParams();
  const dispatch = useDispatch();
  const recommendation = useSelector(selectWatchlistAnalystRecommendation);
  const bars = useSelector((state) => state.watchlistBars?.data);

  useEffect(() => {
    dispatch(fetchWatchlistAnalystRecommendation({ ticker }));
  }, [ticker, dispatch]);

  const { data, isLoading } = recommendation || {};
  const priceTargetSummary = data?.price_target_summary || {};
  const publishers = priceTargetSummary.publishers;

  const needleValue = useMemo(() => {
    const reco = data?.upgrades_downgrades_consensus?.consensus;
    switch (reco) {
      case 'Buy':
        return 75;
      case 'Strong Buy':
        return 95;
      case 'Sell':
        return 45;
      case 'Strong Sell':
        return 15;
      case 'Neutral':
      case 'Hold':
        return 50;
      default:
        return 50;
    }
  }, [data]);

  return (
    <main className='recommendation'>
      <div className='recommendation__Container'>
        <InfoSection
          title='Last Month'
          avgPriceTarget={priceTargetSummary.last_month_avg_price_target}
          publishers={publishers}
          isLoading={isLoading}
        />
        <InfoSection
          title='Last Quarter'
          avgPriceTarget={priceTargetSummary.last_quarter_avg_price_target}
          publishers={publishers}
          isLoading={isLoading}
        />
        <InfoSection
          title='Last Year'
          avgPriceTarget={priceTargetSummary.last_year_avg_price_target}
          publishers={publishers}
          isLoading={isLoading}
        />
        <article className='recommendation__Four'>
          {recommendation?.data?.price_target_consensus && (
            <ProjectionGraph
              data={bars}
              color={'blue'}
              height='40rem'
              targets={recommendation?.data?.price_target_consensus}
            />
          )}
        </article>
        <article className='recommendation__Five'>
          {isLoading ? (
            <Skeleton width='100%' height='20rem' />
          ) : data?.upgrades_downgrades_consensus?.consensus ? (
            <div>
              <BuySellGauge
                Width={400}
                Height={200}
                innerRadius={10}
                needleGap={4}
                segmentTexts={['STRONG SELL', 'SELL', 'NEUTRAL', 'HOLD', 'BUY', 'STRONG BUY']}
                textSize={6}
                textColor='#b2b5be'
                needleValue={needleValue}
                needleWidth={4}
                needleRadii={3}
              />
            </div>
          ) : null}
        </article>
      </div>
    </main>
  );
}

export default AnalystRecommendation;
