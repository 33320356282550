import { put, takeLatest } from 'redux-saga/effects';
import {
  getScreenerFlagData,
  getScreenerMetaData,
  postCommodtScreenerColumnData,
  postCryptoScreenerColumnData,
  postForexScreenerColumnData,
  postScreenerFlagData,
  postStockScreenerColumnData,
} from 'src/repositories/screener/screener';
import {
  fetchscreenerMetaData,
  fetchscreenerMetaDataFailure,
  fetchscreenerMetaDataSuccess,
  fetchScreenerFlaggedColumn,
  fetchScreenerFlaggedColumnFailure,
  fetchScreenerFlaggedColumnSuccess,
  postScreenerFlaggedColumn,
  fetchScreenerFilterDataSuccess,
  fetchScreenerFilterDataFailure,
  fetchScreenerFilterData,
} from './Screener.reducer';
import { IError } from 'src/lib/types/error';
function* fetchScreenerMetaData(_action) {
  try {
    const json = yield getScreenerMetaData().then((response) => response);
    yield put(fetchscreenerMetaDataSuccess(json.data.data));
  } catch (error) {
    yield put(fetchscreenerMetaDataFailure(error as IError));
  }
}

export function* watchScreenerMetaDataSaga() {
  yield takeLatest(fetchscreenerMetaData.type, fetchScreenerMetaData);
}

function* fetchScreenerFlaggedColumnData(_action) {
  try {
    const json = yield getScreenerFlagData().then((response) => response);
    yield put(fetchScreenerFlaggedColumnSuccess(json.data.data));
  } catch (error) {
    yield put(fetchScreenerFlaggedColumnFailure(error as IError));
  }
}

export function* watchScreenerFlaggedColumnSaga() {
  yield takeLatest(fetchScreenerFlaggedColumn.type, fetchScreenerFlaggedColumnData);
}

function* postScreenerFlaggedColumnData(action) {
  try {
    yield postScreenerFlagData(action.payload).then((response) => response);
    // yield put(fetchScreenerFlaggedColumnSuccess(json.data.data));
  } catch (error) {
    // yield put(fetchScreenerFlaggedColumnFailure(error as IError));
  }
}

export function* watchScreenerFlaggedColumnPostSaga() {
  yield takeLatest(postScreenerFlaggedColumn.type, postScreenerFlaggedColumnData);
}

function* fetchScreenerFilter(action) {
  try {
    let json;
    if (action.payload.market_type === 'UsStocks')
      json = yield postStockScreenerColumnData(action.payload).then((response) => response);
    if (action.payload.market_type === 'Crypto')
      json = yield postCryptoScreenerColumnData(action.payload).then((response) => response);
    if (action.payload.market_type === 'Commodts')
      json = yield postCommodtScreenerColumnData(action.payload).then((response) => response);
    if (action.payload.market_type === 'Fx')
      json = yield postForexScreenerColumnData(action.payload).then((response) => response);

    yield put(fetchScreenerFilterDataSuccess(json.data.data));
  } catch (error) {
    yield put(fetchScreenerFilterDataFailure(error as IError));
  }
}

export function* watchScreenerFilterDataSaga() {
  yield takeLatest(fetchScreenerFilterData.type, fetchScreenerFilter);
}
