import React, { useState } from 'react';
import { Button, ModalWrapper } from 'src/components';
import {
  RiskProfileErrorIcon,
  RiskProfileLoadIcon,
  RiskProfileSearchIconTwo,
  RiskProfileSearchIcon,
  RiskProfileWarningIcon,
  RiskProfileCircleIcon,
  RiskProfileDummySpeedometer,
  RiskProfileDummyJim,
  RiskProfileDummyMark,
  RiskProfileDummyPaul,
  ProfileCloseIcon,
} from 'src/assets/Images';

const moreTraders = [
  {
    id: 1,
    name: 'Paul Tudor Jonse II',
    img: RiskProfileDummyPaul,
  },
  {
    id: 2,
    name: 'Jim Simons',
    img: RiskProfileDummyJim,
  },
  {
    id: 3,
    name: 'Mark Minervini',
    img: RiskProfileDummyMark,
  },
];

function RiskResult() {
  const [modalOne, setModalOne] = useState(false);
  const [modalTwo, setModalTwo] = useState(false);
  const [modalThree, setModalThree] = useState(false);
  const [modalFour, setModalFour] = useState(false);
  const [modalFive, setModalFive] = useState(false);
  return (
    <>
      <section className='riskResult'>
        <hr />
        <section className='riskResult__container'>
          <div className='riskResult__header'>
            <h1>Risk Assessment</h1>
            <p>Based on your answers, your risk profile is analysed as below :</p>
          </div>
          <div className='riskResult__chart'>
            <img src={RiskProfileDummySpeedometer} alt='' />
          </div>
          <div className='riskResult__outcome'>
            <p>
              Risk Capacity <span>Moderate</span>
            </p>
            <h2>
              We are honored to informed you that, You are a <span>Moderate Trader</span>!
            </h2>
            <p
              // onClick={() => setModalOne(true)}
              // onClick={() => setModalTwo(true)}
              // onClick={() => setModalThree(true)}
              // onClick={() => setModalFive(true)}
              onClick={() => setModalFour(true)}>
              Recommended items
            </p>
          </div>
          <div className='riskResult__details'>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
            industry&apos;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
            scrambled it to.
            <br />
            <br />
            Make a type specimen book. It has survived not only five centuries, but also the leap into electronic
            typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
            sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
            <br />
            <br />
            Make a type specimen book. It has survived not only five centuries, but also the leap into electronic
            typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset
            sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </div>
          <div className='riskResult__bottom'>
            <h1>You are not alone</h1>
            <p>Some of the most famous traders in history have been swing traders:</p>
            <div className='riskResult__moreTraders'>
              {moreTraders.map((item) => (
                <article key={item.id} className='riskResult__card'>
                  <h3>{item.name}</h3>
                  <img src={item.img} alt='' />
                </article>
              ))}
            </div>
            <div className='riskResult__characteristics'>
              <h3>Your trader style be characterized by:</h3>
              <ul>
                <li>
                  <img src={RiskProfileCircleIcon} alt='' />
                  Patience
                </li>
                <li>
                  <img src={RiskProfileCircleIcon} alt='' />
                  Technical Knowledge
                </li>
                <li>
                  <img src={RiskProfileCircleIcon} alt='' />
                  Experience
                </li>
                <li>
                  <img src={RiskProfileCircleIcon} alt='' />
                  Eomposure
                </li>
                <li>
                  <img src={RiskProfileCircleIcon} alt='' />
                  Comprehensive
                </li>
              </ul>
              <h2>
                Recommended instruments for a Moderate Trader <span>Please click here</span>
              </h2>
            </div>
          </div>
        </section>
      </section>
      {/* Modal One */}
      <ModalWrapper open={modalOne} onClose={setModalOne}>
        <div className='riskmodalone'>
          <img src={ProfileCloseIcon} alt='ProfileCloseIcon' onClick={() => setModalOne(!modalOne)} />
          <div className='riskmodalone__container'>
            <img src={RiskProfileWarningIcon} alt='' />
            <h1>We found some open trades. Risk profile change is not permitted</h1>
            <button type='button' onClick={() => setModalOne(!modalOne)}>
              OK
            </button>
          </div>
        </div>
      </ModalWrapper>
      {/* Modal Two */}
      <ModalWrapper open={modalTwo} onClose={setModalTwo}>
        <div className='riskmodaltwo'>
          <img src={ProfileCloseIcon} alt='closeIcon' onClick={() => setModalTwo(!modalTwo)} />
          <div className='riskmodaltwo__container'>
            <img src={RiskProfileSearchIconTwo} alt='' />
            <h1>We found tickers in your watchlist & custom portfolio</h1>
            <p>If YES, we need to reset all items. Do you agree with us.</p>
          </div>
          <div className='riskmodaltwo__btns'>
            <button className='riskmodaltwo--btn' type='button'>
              NO
            </button>
            <Button text='YES' className='btn--green' />
          </div>
        </div>
      </ModalWrapper>
      {/* Modal Three */}
      <ModalWrapper open={modalThree} onClose={setModalThree}>
        <div className='riskmodalthree'>
          <img src={ProfileCloseIcon} alt='closeIcon' onClick={() => setModalThree(!modalThree)} />
          <div className='riskmodalthree__container'>
            <h1>Are you sure, you want to reset all items</h1>
          </div>
          <div className='riskmodalthree__btns'>
            <button onClick={() => setModalThree(!modalThree)} className='riskmodalthree--btn' type='button'>
              NO
            </button>
            <Button text='YES' className='btn--green' />
          </div>
        </div>
      </ModalWrapper>
      {/* Modal Four */}
      <ModalWrapper open={modalFour} onClose={setModalFour}>
        <div className='riskmodalfour'>
          <img src={ProfileCloseIcon} alt='closeIcon' onClick={() => setModalFour(!modalFour)} />
          <div className='riskmodalfour__container'>
            <img src={RiskProfileSearchIcon} alt='search-icon' />
            <h1>Checking for open trades</h1>
            <div className='riskmodalfour-buffer'>
              <img src={RiskProfileLoadIcon} alt='load-icon' />
              <p>Please wait.....</p>
            </div>
          </div>
        </div>
      </ModalWrapper>
      {/* Modal Five */}
      <ModalWrapper open={modalFive} onClose={setModalFive}>
        <div className='riskmodalfive'>
          <img src={ProfileCloseIcon} alt='closeIcon' onClick={() => setModalFive(!modalFive)} />
          <div className='riskmodalfive__container'>
            <img src={RiskProfileErrorIcon} alt='search-icon' />
            <h1>Change of risk profile is not premitted</h1>
            <div className='riskmodalfive-btn'>
              <Button text='OK' className='btn--green' />
            </div>
          </div>
        </div>
      </ModalWrapper>
    </>
  );
}

export default RiskResult;
