import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IWebsocket } from 'src/lib/types/websocket';
const initialState: IWebsocket = {
  event: '',
  data: null,
};

const message = createSlice({
  name: 'message',
  initialState: initialState,
  reducers: {
    putMessage: (state, action: PayloadAction<IWebsocket>) => {
      state.event = action.payload.event;
      state.status = action.payload.status;
      state.data = action.payload.data || state.data;
    },
  },
});

export const { putMessage } = message.actions;
export const messageReducer = message.reducer;

const messageCarousel = createSlice({
  name: 'messageCarousel',
  initialState: initialState,
  reducers: {
    putMessageCarousel: (state, action: PayloadAction<IWebsocket>) => {
      state.event = action.payload.event;
      state.status = action.payload.status;
      state.data = action.payload.data || state.data;
    },
  },
});

export const { putMessageCarousel } = messageCarousel.actions;
export const messageCarouselReducer = messageCarousel.reducer;
