import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from 'src/lib/types/error';
import { ITvResp, ITvConfig, ITvSymbol } from 'src/lib/types/tv';
const INITIAL_STATE: ITvResp = {
  isLoading: false,
  data: null,
  error: null,
};

const tvConfigSlice = createSlice({
  name: 'tvConfig',
  initialState: INITIAL_STATE,
  reducers: {
    fetchTvConfig(state) {
      state.isLoading = true;
    },
    fetchTvConfigSuccess(state, action: PayloadAction<ITvConfig>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchTvConfigFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});
export const { fetchTvConfig, fetchTvConfigSuccess, fetchTvConfigFailure } = tvConfigSlice.actions;
export const tvConfigReducer = tvConfigSlice.reducer;
const tvSymbolChangeSlice = createSlice({
  name: 'tvSymbolChange',
  initialState: { ticker: 'BTCUSD' },
  reducers: {
    setTvSymbol(state, action) {
      state.ticker = action.payload.ticker;
    },
  },
});
export const { setTvSymbol } = tvSymbolChangeSlice.actions;
export const tvSymbolChangeReducer = tvSymbolChangeSlice.reducer;

const tvSymbolSlice = createSlice({
  name: 'tvSymbol',
  initialState: INITIAL_STATE,
  reducers: {
    fetchTvSymbol(state) {
      state.isLoading = true;
    },
    fetchTvSymbolSuccess(state, action: PayloadAction<ITvSymbol>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchTvSymbolFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});
export const { fetchTvSymbol, fetchTvSymbolSuccess, fetchTvSymbolFailure } = tvSymbolSlice.actions;
export const tvSymbolReducer = tvSymbolSlice.reducer;
