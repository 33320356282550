import { put, takeLatest } from 'redux-saga/effects';
import {
  getProfileInfo,
  postProfileInfo,
  postEmailUpdateInit,
  postEmailUpdate,
  postPasswordInit,
  postPassword,
  postProfilePicUpdate,
} from 'src/repositories/mgmt/mgmt';
import {
  emailUpdate,
  emailUpdateFailure,
  emailUpdateInitiate,
  emailUpdateInitiateFailure,
  emailUpdateInitiateSuccess,
  emailUpdateSuccess,
  fetchProfile,
  fetchProfileFailure,
  fetchProfileSuccess,
  passwordUpdate,
  passwordUpdateFailure,
  passwordUpdateInitiate,
  passwordUpdateInitiateFailure,
  passwordUpdateInitiateSuccess,
  passwordUpdateSuccess,
  postProfile,
  postProfileFailure,
  postProfileSuccess,
  profilePicUpdate,
  profilePicUpdateFailure,
  profilePicUpdateSuccess,
} from './profile.reducer';
import { IError } from 'src/lib/types/error';

function* fetchProfileSaga(action) {
  try {
    const data = yield getProfileInfo(action.payload).then((response) => response);
    yield put(fetchProfileSuccess(data.data.data));
  } catch (error) {
    yield put(fetchProfileFailure(error as IError));
  }
}

export function* watchProfileDataSaga() {
  yield takeLatest(fetchProfile.type, fetchProfileSaga);
}

function* postProfileSaga(action) {
  try {
    const data = yield postProfileInfo(action.payload).then((response) => response);
    yield put(postProfileSuccess(data.data.data));
  } catch (error) {
    yield put(postProfileFailure(error as IError));
  }
}

export function* watchPostProfileDataSaga() {
  yield takeLatest(postProfile.type, postProfileSaga);
}

function* emailUpdateInitiateSaga(action) {
  try {
    const data = yield postEmailUpdateInit(action.payload).then((response) => response);
    yield put(emailUpdateInitiateSuccess(data.data.data));
  } catch (error) {
    yield put(emailUpdateInitiateFailure(error as IError));
  }
}

export function* watchEmailUpdateInitiateDataSaga() {
  yield takeLatest(emailUpdateInitiate.type, emailUpdateInitiateSaga);
}

function* emailUpdateSaga(action) {
  try {
    const data = yield postEmailUpdate(action.payload).then((response) => response);
    yield put(emailUpdateSuccess(data.data.data));
  } catch (error) {
    yield put(emailUpdateFailure(error as IError));
  }
}

export function* watchEmailUpdateDataSaga() {
  yield takeLatest(emailUpdate.type, emailUpdateSaga);
}

function* passwordUpdateInitiateSaga(action) {
  try {
    const data = yield postPasswordInit(action.payload).then((response) => response);
    yield put(passwordUpdateInitiateSuccess(data.data.data));
  } catch (error) {
    yield put(passwordUpdateInitiateFailure(error as IError));
  }
}

export function* watchPasswordUpdateInitiateDataSaga() {
  yield takeLatest(passwordUpdateInitiate.type, passwordUpdateInitiateSaga);
}

function* passwordUpdateSaga(action) {
  try {
    const data = yield postPassword(action.payload).then((response) => response);
    yield put(passwordUpdateSuccess(data.data.data));
  } catch (error) {
    yield put(passwordUpdateFailure(error as IError));
  }
}

export function* watchPasswordUpdateDataSaga() {
  yield takeLatest(passwordUpdate.type, passwordUpdateSaga);
}

function* profilePicUpdateSaga(action) {
  try {
    const data = yield postProfilePicUpdate(action.payload).then((response) => response);
    yield put(profilePicUpdateSuccess(data.data.data));
  } catch (error) {
    yield put(profilePicUpdateFailure(error as IError));
  }
}

export function* watchProfilePicUpdateDataSaga() {
  yield takeLatest(profilePicUpdate.type, profilePicUpdateSaga);
}
