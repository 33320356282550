import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from 'src/lib/types/error';
import {
  IMarketEconomicIndicator,
  IMarketResp,
  IMarketHolidayData,
  IMarketTreasuryRateData,
  IMarketEconomicCalenderData,
  IMarketEodPriceData,
  IMarketEodPriceReq,
  IMarketSectorsPerformanceData,
  IMarketPERatiosData,
  IMarketTopStocksData,
} from 'src/lib/types/markets';

const INITIAL_STATE: IMarketResp = {
  isLoading: false,
  data: null,
  error: null,
};

const marketEconomicIndicatorSlice = createSlice({
  name: 'marketEconomicIndicator',
  initialState: INITIAL_STATE,
  reducers: {
    fetchMarketEconomicIndicator(state) {
      state.isLoading = true;
    },
    fetchMarketEconomicIndicatorSuccess(state, action: PayloadAction<IMarketEconomicIndicator>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchMarketEconomicIndicatorFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});
export const {
  fetchMarketEconomicIndicator,
  fetchMarketEconomicIndicatorSuccess,
  fetchMarketEconomicIndicatorFailure,
} = marketEconomicIndicatorSlice.actions;
export const marketEconomicIndicatorReducer = marketEconomicIndicatorSlice.reducer;

const marketHolidaySlice = createSlice({
  name: 'marketHoliday',
  initialState: INITIAL_STATE,
  reducers: {
    fetchMarketHoliday(state) {
      state.isLoading = true;
    },
    fetchMarketHolidaySuccess(state, action: PayloadAction<IMarketHolidayData>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchMarketHolidayFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});
export const { fetchMarketHoliday, fetchMarketHolidaySuccess, fetchMarketHolidayFailure } = marketHolidaySlice.actions;
export const marketHolidayReducer = marketHolidaySlice.reducer;

const marketEconomicCalenderSlice = createSlice({
  name: 'marketEconomicCalender',
  initialState: INITIAL_STATE,
  reducers: {
    fetchMarketEconomicCalender(state) {
      state.isLoading = true;
    },
    fetchMarketEconomicCalenderSuccess(state, action: PayloadAction<IMarketEconomicCalenderData>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchMarketEconomicCalenderFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});
export const { fetchMarketEconomicCalender, fetchMarketEconomicCalenderSuccess, fetchMarketEconomicCalenderFailure } =
  marketEconomicCalenderSlice.actions;
export const marketEconomicCalenderReducer = marketEconomicCalenderSlice.reducer;

const marketTreasuryRateSlice = createSlice({
  name: 'marketTreasuryRate',
  initialState: INITIAL_STATE,
  reducers: {
    fetchMarketTreasuryRate(state) {
      state.isLoading = true;
    },
    fetchMarketTreasuryRateSuccess(state, action: PayloadAction<IMarketTreasuryRateData>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchMarketTreasuryRateFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});
export const { fetchMarketTreasuryRate, fetchMarketTreasuryRateSuccess, fetchMarketTreasuryRateFailure } =
  marketTreasuryRateSlice.actions;
export const marketTreasuryRateReducer = marketTreasuryRateSlice.reducer;

const marketEodPriceSlice = createSlice({
  name: 'marketEodPrice',
  initialState: INITIAL_STATE,
  reducers: {
    fetchMarketEodPrice(state, action: PayloadAction<IMarketEodPriceReq>) {
      state.isLoading = true;
    },
    fetchMarketEodPriceSuccess(state, action: PayloadAction<IMarketEodPriceData[]>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchMarketEodPriceFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});
export const { fetchMarketEodPrice, fetchMarketEodPriceSuccess, fetchMarketEodPriceFailure } =
  marketEodPriceSlice.actions;
export const marketEodPriceReducer = marketEodPriceSlice.reducer;

const marketSectorsPerformanceSlice = createSlice({
  name: 'marketSectorsPerformance',
  initialState: INITIAL_STATE,
  reducers: {
    fetchMarketSectorsPerformance(state) {
      state.isLoading = true;
    },
    fetchMarketSectorsPerformanceSuccess(state, action: PayloadAction<IMarketSectorsPerformanceData[]>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchMarketSectorsPerformanceFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});
export const {
  fetchMarketSectorsPerformance,
  fetchMarketSectorsPerformanceSuccess,
  fetchMarketSectorsPerformanceFailure,
} = marketSectorsPerformanceSlice.actions;
export const marketSectorsPerformanceReducer = marketSectorsPerformanceSlice.reducer;

const marketPERatiosSlice = createSlice({
  name: 'marketPERatios',
  initialState: INITIAL_STATE,
  reducers: {
    fetchMarketPERatios(state) {
      state.isLoading = true;
    },
    fetchMarketPERatiosSuccess(state, action: PayloadAction<IMarketPERatiosData>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchMarketPERatiosFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});
export const { fetchMarketPERatios, fetchMarketPERatiosSuccess, fetchMarketPERatiosFailure } =
  marketPERatiosSlice.actions;
export const marketPERatiosReducer = marketPERatiosSlice.reducer;
const marketTopStocksSlice = createSlice({
  name: 'marketTopStocks',
  initialState: INITIAL_STATE,
  reducers: {
    fetchMarketTopStocks(state) {
      state.isLoading = true;
    },
    fetchMarketTopStocksSuccess(state, action: PayloadAction<IMarketTopStocksData>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchMarketTopStocksFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});
export const { fetchMarketTopStocks, fetchMarketTopStocksSuccess, fetchMarketTopStocksFailure } =
  marketTopStocksSlice.actions;
export const marketTopStocksReducer = marketTopStocksSlice.reducer;

const marketNewsSlice = createSlice({
  name: 'marketNews',
  initialState: INITIAL_STATE,
  reducers: {
    fetchMarketNews(state, payload) {
      state.isLoading = true;
    },
    fetchMarketNewsSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchMarketNewsFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});
export const { fetchMarketNews, fetchMarketNewsSuccess, fetchMarketNewsFailure } = marketNewsSlice.actions;
export const marketNewsReducer = marketNewsSlice.reducer;
