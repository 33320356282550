import React, { useEffect, useState } from 'react';
import HTMLReactParser from 'html-react-parser';
import { axiosClientS3 } from 'src/repositories/apiClient';

export default function Para({ url }) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axiosClientS3({
          method: 'get',
          url: url,
        });
        setData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (url) {
      fetchData();
    }
  }, [url]);

  if (isLoading) return <p>....</p>;
  if (error) return <p>{error.message}</p>;

  return <div className='text-justify text-[1.5rem]'>{data ? HTMLReactParser(data) : ''}</div>;
}
