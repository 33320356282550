/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

export default function FearGreedGauge({
  Width,
  Height,
  innerRadius,
  colors,
  textSize,
  needleValue,
  needleGap,
  needleWidth,
  needleRadii,
}) {
  const svgRef = useRef();

  useEffect(() => {
    // Remove previous elements
    d3.select(svgRef.current).selectAll('*').remove();
    const margin = { top: 0, right: 70, bottom: 50, left: 70 };
    const radius = Math.min(Width, Height) - 2 * margin.right - 30;
    const centerX = Width / 2;
    const centerY = Height - margin.bottom;

    const svg = d3
      .select(svgRef.current)
      .attr('viewBox', `0 0 ${Width} ${Height}`)
      .append('g')
      .attr('transform', `translate(${centerX},${centerY})`);
    const minValue = 0;
    const maxValue = 100;
    const range = maxValue - minValue;
    const segmentWidth = radius / 6;
    const segmentHeight = radius;

    const gaugeScale = d3
      .scaleLinear()
      .domain([minValue, maxValue])
      .range([-Math.PI / 2, Math.PI / 2]);

    const segments = [
      { value: range * 0.167, color: colors[0] },
      { value: range * 0.167, color: colors[1] },
      { value: range * 0.167, color: colors[2] },
      { value: range * 0.167, color: colors[3] },
      { value: range * 0.167, color: colors[4] },
      { value: range * 0.167, color: colors[5] },
    ];

    const arc = d3
      .arc()
      .innerRadius(radius - innerRadius)
      .outerRadius(radius)
      .startAngle((d, i) => gaugeScale(minValue + (i * range) / 6))
      .endAngle((d, i) => gaugeScale(minValue + ((i + 1) * range) / 6));

    const gauge = svg.append('g');

    gauge
      .selectAll('path')
      .data(segments)
      .enter()
      .append('path')
      .attr('d', arc)
      .style('fill', (d) => d.color);

    const needleHeight = segmentHeight;

    const needle = gauge.append('g').attr('transform', `rotate(-90)`);

    const needleCircle = needle
      .append('circle')
      .attr('r', needleRadii || 0.1)
      .style('fill', 'none')
      .style('stroke', '#ffffff')
      .style('stroke-width', needleRadii / 2 || 0.005);

    // d3.selectAll('line').remove();
    const needleRect = needle
      .append('line')
      .style('stroke', '#ffffff')
      .style('stroke-width', needleWidth)
      .attr('x1', 0)
      .attr('y1', -needleGap)
      .attr('x2', 0)
      .attr('y2', -segmentHeight + needleGap);
    const needleAngle = gaugeScale(needleValue);

    const getSegmentColor = (angle) => {
      let segmentIndex;
      if (needleValue < 68) {
        segmentIndex = Math.floor(angle + Math.PI - 1);
      } else {
        segmentIndex = Math.ceil(angle + Math.PI);
      }

      return colors[segmentIndex];
    };

    const needleCircle1 = gauge
      .append('circle')
      .attr('r', segmentHeight * 0.15)
      .attr('fill', getSegmentColor(needleAngle))
      .attr('stroke', getSegmentColor(needleAngle))
      .attr('stroke-width', 2)
      .attr('transform', `rotate(${(needleAngle * 180) / Math.PI}) translate(0, -${segmentHeight * 1.2})`);
    gauge
      .append('text')
      .attr('class', 'index')
      .attr('x', -radius)
      .attr('y', margin.bottom / 2)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Extreme Fear')
      .style('fill', '#C72B2E')
      .style('font-size', '12px')
      .style('font-weight', '600');

    gauge
      .append('text')
      .attr('class', 'index')
      .attr('x', radius)
      .attr('y', margin.bottom / 2)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Extreme Greed')
      .style('fill', '#339F41')
      .style('font-size', '12px')
      .style('font-weight', '600');
    // needleCircle1
    gauge
      .append('text')
      .attr('class', 'index')
      .attr('x', 0)
      .attr('y', 0)

      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(needleValue)
      .style('fill', '#ffff')
      .style('font-size', '13px')
      .style('font-weight', '600')
      .attr('transform', `rotate(${(needleAngle * 180) / Math.PI}) translate(0, -${segmentHeight * 1.2})`);
    gauge
      .append('text')
      .attr('class', 'index')
      .attr('x', 0)
      .attr('y', 0)

      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text('Fear')
      .style('fill', getSegmentColor(needleAngle))
      .style('font-size', '15px')
      .style('font-weight', '600')
      .attr('transform', `rotate(${((needleAngle - 0.3) * 180) / Math.PI}) translate(0, -${segmentHeight * 1.2})`);

    needle
      .transition()
      .duration(500)
      .ease(d3.easeBounce)
      .attr('transform', `rotate(${(needleAngle * 180) / Math.PI})`);
  }, [needleValue]);

  return <svg ref={svgRef} />;
}
