import React from 'react';
import { data } from 'src/static-data';

function Statistics() {
  return (
    <section className='trades'>
      <div className='trades__table'>
        <table>
          <thead>
            <tr>
              <th>Ticker Trade</th>
              <th>Entry</th>
              <th>Exit</th>
              <th>% change</th>
              <th>Profit</th>
              <th>Cum Profit</th>
              <th>Bars</th>
              <th>Scale In/Out</th>
            </tr>
          </thead>
          <tbody>
            {data.trades?.map((item) => (
              <tr key={item.id}>
                <td>{item.ticket}</td>
                <td>{item.entry}</td>
                <td>{item.exit}</td>
                <td>{item.change}</td>
                <td>{item.profit}</td>
                <td>{item.cumProfit}</td>
                <td>{item.bars}</td>
                <td>{item.scale}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='trades__more'>
        <h4>more...</h4>
      </div>
    </section>
  );
}

export default Statistics;
