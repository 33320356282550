import { createSlice } from '@reduxjs/toolkit';
const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: null,
};

const liveQuoteSlice = createSlice({
  name: 'liveQuote',
  initialState: INITIAL_STATE,
  reducers: {
    fetchLiveQuote(state, action) {
      state.isLoading = true;
      state.data = null;
    },
    fetchLiveQuoteSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchLiveQuoteFailure(state, action) {
      state.isLoading = false;
      state.data = [];
      state.error = action.payload;
    },
  },
});
export const { fetchLiveQuote, fetchLiveQuoteSuccess, fetchLiveQuoteFailure } = liveQuoteSlice.actions;
export const liveQuoteReducer = liveQuoteSlice.reducer;
