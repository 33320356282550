import React from 'react';
import HTMLReactParser from 'html-react-parser';
import { Link } from 'react-router-dom';
import { IAcademyContent } from 'src/lib/types/academy';
import { cardDummyImage } from 'src/assets/Images';

const hashtags = (hashtags) =>
  hashtags
    .split(',')
    .map((tag: string) => `#${tag}`)
    .join(' ');
export function AcademyCard({ item }: { item: IAcademyContent }) {
  return (
    <Link to={`/academy/${item?.id}`} key={item.id} className='card bg-base-100   shadow-xl'>
      <figure>
        <img alt='' src={item.image_short || cardDummyImage} />
      </figure>
      <div className='card-body'>
        <h2 className='card-title'>{item.headline}</h2>
        <p>{HTMLReactParser(item.short_content)}</p>
        <div className='card-actions academyc'>{hashtags(item.hash_tags)}</div>
      </div>
    </Link>
  );
}
