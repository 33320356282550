import React, { useState } from 'react';
import Ownership from './Ownership/Ownership.router';
import RatingGrades from './RatingsGrades/RatingGrades.router';
import AnalystRecommendation from './AnalystRecommendations/AnalystRecommendation.router';
import SocialSentiment from './SocialSentiment/SocialSentiment.router';
import AnalystEstimate from './AnalystEstimate/AnalystEstimate.router';

export default function DeepAnalysis() {
  const [active, setActive] = useState('tab-1');
  const getClassName = (val) => `deepanalysis-navbar-item ${val ? 'active' : ''}`;
  return (
    <nav className='deepanalysis'>
      <div className='deepanalysis-navbar'>
        <div onClick={() => setActive('tab-1')} className={getClassName(active === 'tab-1')}>
          Ownership
        </div>
        <div onClick={() => setActive('tab-2')} className={getClassName(active === 'tab-2')}>
          Ratings and Grades
        </div>
        <div onClick={() => setActive('tab-3')} className={getClassName(active === 'tab-3')}>
          Analyst Recommendations
        </div>
        <div onClick={() => setActive('tab-4')} className={getClassName(active === 'tab-4')}>
          Social Sentiment
        </div>
        <div onClick={() => setActive('tab-5')} className={getClassName(active === 'tab-5')}>
          Analyst Estimates
        </div>
      </div>
      <div className='deepAnalysis__table'>
        {active === 'tab-1' && <Ownership />}
        {active === 'tab-2' && <RatingGrades />}
        {active === 'tab-3' && <AnalystRecommendation />}
        {active === 'tab-4' && <SocialSentiment />}
        {active === 'tab-5' && <AnalystEstimate />}
      </div>
    </nav>
  );
}
