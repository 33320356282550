import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ISubscriptionResp,
  ISubscriptionCheckoutResp,
  ISubscriptionMetaDataResp,
  ISubscriptionCheckoutReq,
} from 'src/lib/types/subscription';
import { IError } from 'src/lib/types/error';
const INITIAL_STATE: ISubscriptionResp = {
  isLoading: false,
  data: null,
  error: null,
};
const INITIAL_CHECKOUT_STATE = {
  name: '',
  price: '',
  isYear: false,
  subscriptionId: '',
};
const SubscriptionMetaDataSlice = createSlice({
  name: 'SubscriptionMetaData',
  initialState: INITIAL_STATE,
  reducers: {
    fetchSubscriptionMetaData(state) {
      state.isLoading = true;
    },
    fetchSubscriptionMetaDataSuccess(state, action: PayloadAction<ISubscriptionMetaDataResp>) {
      state.isLoading = false;
      const v = {};
      for (const p of action.payload.product_features) {
        const feature: string[] = [];
        for (const f of Object.values(p.feature_map)) {
          feature.push(f.feature);
        }

        v[p.product] = feature;
      }
      const data = {
        yearlyPlans: [action.payload.yearly_plans[0], action.payload.yearly_plans[2], action.payload.yearly_plans[1]],
        monthlyPlans: [
          action.payload.monthly_plans[0],
          action.payload.monthly_plans[2],
          action.payload.monthly_plans[1],
        ],
        onetimePlans: action.payload.one_time_plans,
        currency: action.payload.currency,
        features: action.payload.category_features,
        featuresMap: v,
      };
      state.data = data;
      state.error = null;
    },
    fetchSubscriptionMetaDataFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchSubscriptionMetaData, fetchSubscriptionMetaDataSuccess, fetchSubscriptionMetaDataFailure } =
  SubscriptionMetaDataSlice.actions;
export const SubscriptionMetaDataReducer = SubscriptionMetaDataSlice.reducer;

const SubscriptionCheckoutSlice = createSlice({
  name: 'SubscriptionCheckout',
  initialState: INITIAL_STATE,
  reducers: {
    fetchSubscriptionCheckout(state, action: PayloadAction<ISubscriptionCheckoutReq>) {
      state.isLoading = true;
    },
    fetchSubscriptionCheckoutSuccess(state, action: PayloadAction<ISubscriptionCheckoutResp>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchSubscriptionCheckoutFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchSubscriptionCheckout, fetchSubscriptionCheckoutSuccess, fetchSubscriptionCheckoutFailure } =
  SubscriptionCheckoutSlice.actions;
export const SubscriptionCheckoutReducer = SubscriptionCheckoutSlice.reducer;

const SubscriptionCheckoutInfoSlice = createSlice({
  name: 'SubscriptionCheckoutInfo',
  initialState: INITIAL_CHECKOUT_STATE,
  reducers: {
    setSubscriptionCheckoutInfo(state, action) {
      state.name = action.payload.name;
      state.price = action.payload.price;
      state.isYear = action.payload.isYear;
      state.subscriptionId = action.payload.subscriptionId;
    },
    setSubscriptionCheckoutSubId(state, action) {
      state.subscriptionId = action.payload.subscriptionId;
    },
  },
});

export const { setSubscriptionCheckoutInfo, setSubscriptionCheckoutSubId } = SubscriptionCheckoutInfoSlice.actions;
export const SubscriptionCheckoutInfoReducer = SubscriptionCheckoutInfoSlice.reducer;
