import { createSlice } from '@reduxjs/toolkit';
const INITIAL_STATE = {
  isLoading: false,
  error: null,
  data: [],
};
export const screenerMetaDataSlice = createSlice({
  name: 'screenerMetaData',
  initialState: INITIAL_STATE,
  reducers: {
    fetchscreenerMetaData(state) {
      state.isLoading = true;
    },
    fetchscreenerMetaDataSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchscreenerMetaDataFailure(state, action) {
      state.isLoading = false;
      state.data = [];
      state.error = action.payload;
    },
  },
});

export const { fetchscreenerMetaData, fetchscreenerMetaDataSuccess, fetchscreenerMetaDataFailure } =
  screenerMetaDataSlice.actions;
export const screenerMetaDataReducer = screenerMetaDataSlice.reducer;

const INITIAL_STATE1 = { index: '', marketType: 'UsStocks' };

export const screenerMarketSlice = createSlice({
  name: 'screenerMarket',
  initialState: INITIAL_STATE1,
  reducers: {
    setScreenerMarket(state, action) {
      state.marketType = action.payload.marketType;
    },
    setScreenerIndex(state, action) {
      state.index = action.payload.index;
    },
  },
});

export const { setScreenerMarket, setScreenerIndex } = screenerMarketSlice.actions;
export const screenerMarketReducer = screenerMarketSlice.reducer;

export const screenerFlaggedColumnSlice = createSlice({
  name: 'screenerFlaggedColumn',
  initialState: {
    flaggedColumns: ['AAPL'],
    isLoading: false,
    error: null,
  },
  reducers: {
    setScreenerFlaggedColumn(state, action) {
      state.flaggedColumns = action.payload.cols;
    },
    fetchScreenerFlaggedColumn(state) {
      state.isLoading = true;
    },
    fetchScreenerFlaggedColumnSuccess(state, action) {
      state.isLoading = false;
      if (action.payload === '') state.flaggedColumns = [];
      else {
        const tickers = action.payload.split(',');
        state.flaggedColumns = tickers;
      }
      state.error = null;
    },
    fetchScreenerFlaggedColumnFailure(state, action) {
      state.isLoading = false;
      state.flaggedColumns = [];
      state.error = action.payload;
    },
    postScreenerFlaggedColumn(state, action) {},
  },
});

export const {
  setScreenerFlaggedColumn,
  fetchScreenerFlaggedColumn,
  fetchScreenerFlaggedColumnSuccess,
  fetchScreenerFlaggedColumnFailure,
  postScreenerFlaggedColumn,
} = screenerFlaggedColumnSlice.actions;
export const screenerFlaggedColumnReducer = screenerFlaggedColumnSlice.reducer;

export const screenerFilterDataSlice = createSlice({
  name: 'screenerFilterData',
  initialState: INITIAL_STATE,
  reducers: {
    fetchScreenerFilterData(state, action) {
      state.isLoading = true;
    },
    fetchScreenerFilterDataSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchScreenerFilterDataFailure(state, action) {
      state.isLoading = false;
      state.data = [];
      state.error = action.payload;
    },
  },
});

export const { fetchScreenerFilterData, fetchScreenerFilterDataSuccess, fetchScreenerFilterDataFailure } =
  screenerFilterDataSlice.actions;
export const screenerFilterDataReducer = screenerFilterDataSlice.reducer;
