import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { notificationWarning } from 'src/assets/Images';
export default function Notification({ showNotification, error, setNotification }) {
  useEffect(() => {
    if (showNotification) {
      document.body.classList.add('overlay-open');
    } else {
      document.body.classList.remove('overlay-open');
    }
  }, [showNotification]);
  return (
    <>
      {showNotification &&
        createPortal(
          <div className='overlay'>
            <div className='notification'>
              <div>
                <img src={notificationWarning} alt='' />
              </div>
              <div>{error}</div>
              <button type='submit' className='btn btn--grey' onClick={() => setNotification(false)}>
                Close
              </button>
            </div>
          </div>,
          document.getElementById('modal'),
        )}

      <div />
    </>
  );
}
