import { createSelector } from 'reselect';

const selectAcademyMetaDataState = (state: { academyMetaData: any }) => state.academyMetaData;
const selectAcademyContentState = (state: { academyContent: any }) => state.academyContent;
const selectAcademyDetailState = (state: { academyDetails: any }) => state.academyDetails;

export const selectAcademyMetaData = createSelector(
  [selectAcademyMetaDataState],
  (academyMetaDataState) => academyMetaDataState.data,
);

export const selectAcademyContent = createSelector(
  [selectAcademyContentState],
  (academyContentData) => academyContentData,
);

export const selectAcademyDetails = createSelector([selectAcademyDetailState], (state) => state);
