import React, { useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import 'react-calendar/dist/Calendar.css';

import { PrimaryHeader } from 'src/components';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import MarketSidebar from 'src/components/marketsComponents/marketSidebar';
import MarketsEconomicIndicator from 'src/components/marketsComponents/economicIndicator';
import MarketsHoliday from 'src/components/marketsComponents/marketHolidays';
import MarketNews from 'src/components/marketsComponents/marketNews';
import MarketsTreasuryRates from 'src/components/marketsComponents/tresuaryRates';

export default function Markets() {
  const [tab, setTab] = useState('one');

  return (
    <>
      <main className='navbar-fix'>
        <PrimaryHeader text='Markets' className='color-primary' />
        <section className='markets'>
          <div className='markets__left'>
            <div className='markets__btns'>
              <button type='button' onClick={() => setTab('one')} className={tab === 'one' ? 'active' : ''}>
                Markets
              </button>
              <button type='button' onClick={() => setTab('two')} className={tab === 'two' ? 'active' : ''}>
                Movements
              </button>
            </div>
            <div className='markets__Visible block max-xl:hidden'>
              <MarketNews />
              <MarketsTreasuryRates />
              <MarketsHoliday />
            </div>

            <div className='markets__res xl:hidden'>
              {tab === 'one' ? (
                <>
                  <MarketNews />
                  <MarketsTreasuryRates />
                  <MarketsHoliday />
                </>
              ) : (
                ''
              )}
              {tab === 'two' ? (
                <>
                  <MarketSidebar />
                </>
              ) : (
                ''
              )}
            </div>
          </div>

          <MarketSidebar />
        </section>
        <MarketsEconomicIndicator />
      </main>
    </>
  );
}
