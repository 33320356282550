import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import EconomicCalendarComponent from 'src/components/marketsComponents/economicCalender';
import { selectMarketTreasuryRate } from 'src/pages/Markets/markets.selector';
import { fetchMarketTreasuryRate } from 'src/pages/Markets/markets.reducer';
import { IMarketResp } from 'src/lib/types/markets';

export default function MarketsTreasuryRates() {
  const dispatch = useDispatch();
  const treasuryRate: IMarketResp = useSelector(selectMarketTreasuryRate);
  useEffect(() => {
    dispatch(fetchMarketTreasuryRate());
  }, []);

  return (
    <section className='markets__sectionTwo'>
      <EconomicCalendarComponent />
      <div className='markets__sectionTwoRight'>
        <h1>Treasury Rates</h1>
        <div className='markets__sectionTwo__Table'>
          <table>
            <thead>
              <tr>
                <th>Tenure</th>
                <th>Rate</th>
              </tr>
            </thead>
            <tbody>
              {treasuryRate?.data &&
                Object.entries(treasuryRate?.data)
                  ?.slice(1)
                  .map(([date, rate], index) => (
                    <tr key={index}>
                      <td className='capitalize'>{date}</td>
                      <td>{rate}</td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}
