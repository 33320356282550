import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { useParams } from 'react-router-dom';

export default function News() {
  const { market } = useParams();

  return (
    <>
      <div className='watchlistnews-btns'>
        <NavLink end to='stock' className='watchlistnews-nav'>
          Stock News
        </NavLink>

        {market === 'stocks' ? (
          <NavLink end className='watchlistnews-nav' to='press-release'>
            Press Release
          </NavLink>
        ) : (
          ''
        )}
      </div>
      <Outlet />
    </>
  );
}
