import React from 'react';
import HTMLReactParser from 'html-react-parser';
import { HandThumbUpIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import { IClubContent } from 'src/lib/types/club';
import { cardDummyImage } from 'src/assets/Images';
import { convertNumberToString, formattedDate } from 'src/lib/utils/utils';
export function ClubCard({ item }: { item: IClubContent }) {
  return (
    <Link to={item?.id} key={item.id} className='card bg-base-100 shadow-xl'>
      <figure>
        <img alt='' src={item.image_short || cardDummyImage} />
      </figure>
      <div className='card-body'>
        <h2 className='card-title'>{item.headline}</h2>
        <p>{HTMLReactParser(item.short_content)}</p>
        <div className='card-actions club '>
          <div>{formattedDate(item.updated_at)}</div>
          <div className='card-actions-like'>
            <HandThumbUpIcon className='h-5 w-5' aria-hidden={true} />
            <div>{convertNumberToString(String(item.likes))}</div>
          </div>
        </div>
      </div>
    </Link>
  );
}
