import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

export function PriceBar({ width, height, dateRange, high, low, currentValue, startValue }) {
  const chartRef = useRef(null);

  useEffect(() => {
    const margin = { top: 40, right: 10, bottom: 10, left: 10 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // Remove the old SVG
    d3.select(chartRef.current).selectAll('*').remove();

    const chartNode = d3.select(chartRef.current).attr('viewBox', `0 0 ${width} ${height}`).append('g');

    const xScale = d3.scaleLinear().domain([low, high]).range([margin.left, innerWidth]);

    const chart = chartNode.append('g').attr('transform', `translate(${margin.left}, ${margin.top})`);

    chart
      .append('rect')
      .attr('x', xScale(low))
      .attr('y', innerHeight / 2 - 5)
      .attr('width', xScale(high) - xScale(low))
      .attr('height', 10)
      .style('fill', ' #2D3C59');

    const currentValueX = xScale(currentValue);
    const startValueX = xScale(startValue);
    const barWidth = Math.abs(currentValueX - startValueX);
    const barX = Math.min(currentValueX, startValueX);
    chart
      .append('rect')
      .attr('x', barX)
      .attr('y', innerHeight / 2 - 5)
      .attr('width', barWidth)
      .attr('height', 10)
      .style('fill', () => (startValue > currentValue ? '#FF0021' : '#00FF49'));

    const currentValueY = innerHeight / 2 + 5;
    const currentValueTrianglePoints = [
      [currentValueX, currentValueY],
      [currentValueX - 5, currentValueY + 10],
      [currentValueX + 5, currentValueY + 10],
    ];
    chart.append('polygon').attr('points', currentValueTrianglePoints).style('fill', '#ffffff');

    chart
      .selectAll('text')
      .data([low, high])
      .enter()
      .append('text')
      .attr('x', (d, i) => (i === 0 ? margin.left : innerWidth - margin.right))
      .attr('y', -5)
      .text((d) => d)
      .style('text-anchor', (d, i) => (i === 0 ? 'start' : 'end'))
      .attr('fill', '#ffffff')
      .style('font-size', '15px')
      .style('font-weight', '600');

    chart
      .append('text')
      .attr('x', innerWidth / 2)
      .attr('y', -5)
      .text(`${dateRange} ${currentValue}`)
      .style('text-anchor', 'middle')
      .attr('fill', '#ffffff')
      .style('font-size', '15px')
      .style('font-weight', '600');
  }, [width, height]);

  return <svg ref={chartRef} width={width} height={height} />;
}
