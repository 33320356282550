import { createSelector } from 'reselect';

const selectSubscriptionMetaDataState = (state: { subscriptionMetaData: any }) => state.subscriptionMetaData;
const selectSubscriptionCheckoutState = (state: { subscriptionCheckout: any }) => state.subscriptionCheckout;
const selectSubscriptionCheckoutInfoState = (state: { subscriptionCheckoutInfo: any }) =>
  state.subscriptionCheckoutInfo;

export const selectSubscriptionMetaData = createSelector([selectSubscriptionMetaDataState], (state) => state);
export const selectSubscriptionCheckout = createSelector([selectSubscriptionCheckoutState], (state) => state);
export const selectSubscriptionCheckoutInfo = createSelector([selectSubscriptionCheckoutInfoState], (state) => state);
