import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import LoadingAnimation from 'src/assets/loading_animation/LOADING.json';
export default function Loader() {
  return (
    <div className='if-loader'>
      <Player src={LoadingAnimation} className='player' loop autoplay />
    </div>
  );
}
