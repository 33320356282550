import React from 'react';
import { TOLEditIcon, TOLRemoveIcon, TOLCircleIcon } from 'src/assets/Images';
import { data } from 'src/static-data';

function SubmittedOrders() {
  return (
    <section className='submittedorders'>
      <table>
        <thead>
          <tr>
            <th>Ticker</th>
            <th>Price</th>
            <th>Action</th>
            <th>QTY</th>
            <th>Date & Time</th>
            <th>Status</th>
            <th>iFIR$T ID</th>
            <th>Broker Name</th>
            <th>Broker ID</th>
            <th>Type</th>
            <th>Manage</th>
          </tr>
        </thead>
        <tbody>
          {data.submittedOrders.map((item) => (
            <tr key={item.id}>
              <td>
                <img src={TOLCircleIcon} alt='' />
                {item.ticker}
              </td>
              <td>{item.price}</td>
              <td>{item.action}</td>
              <td>{item.qty}</td>
              <td>{item.dateTime}</td>
              <td>{item.status}</td>
              <td>{item.iFirstID}</td>
              <td>{item.broker}</td>
              <td>{item.brokerID}</td>
              <td>{item.type}</td>
              <td>
                <img src={TOLEditIcon} alt='' />
                <img src={TOLRemoveIcon} alt='' />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}

export default SubmittedOrders;
