/* eslint-disable max-len */
/* eslint-disable indent */
import { apiClient } from 'src/repositories/apiClient';
import { config } from 'src/lib/config';

export const getScreenerMetaData = async () => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SCREENER + config.API_SCREENER_COLUMNS_META_DATA;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
  });
  return res;
};
export const getHeatMapMetaData = async () => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SCREENER + config.API_HEATMAP_COLUMNS_META_DATA;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
  });
  return res;
};
export const getScreenerFlagData = async () => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SCREENER + config.API_SCREENER_FLAG_TICKERS;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
  });
  return res;
};
export const getScreenerPinData = async () => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SCREENER + config.API_SCREENER_PIN_TICKERS;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
  });
  return res;
};

export const postStockScreenerData = async (payload: {
  market_type: any;
  market_index: string;
  default_category: any;
  time_frame: string;
}) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SCREENER + config.API_SCREENER_COLUMNS_STOCK_DEFAULT_CATEGORY;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};
export const postCryptoScreenerData = async (payload: {
  market_type: any;
  market_index: string;
  default_category: any;
  time_frame: string;
}) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SCREENER + config.API_SCREENER_COLUMNS_CRYPTO_DEFAULT_CATEGORY;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};
export const postCommodtScreenerData = async (payload: {
  market_type: any;
  market_index: string;
  default_category: any;
  time_frame: string;
}) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SCREENER + config.API_SCREENER_COLUMNS_COMMODT_DEFAULT_CATEGORY;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};
export const postForexScreenerData = async (payload: {
  market_type: any;
  market_index: string;
  default_category: any;
  time_frame: string;
}) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SCREENER + config.API_SCREENER_COLUMNS_FOREX_DEFAULT_CATEGORY;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};
export const postForexScreenerColumnData = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SCREENER + config.API_SCREENER_FOREX_COLUMNS;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: payload,
  });
  return res;
};
export const postCryptoScreenerColumnData = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SCREENER + config.API_SCREENER_CRYPTO_COLUMNS;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: payload,
  });
  return res;
};
export const postCommodtScreenerColumnData = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SCREENER + config.API_SCREENER_COMMODT_COLUMNS;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: payload,
  });
  return res;
};
export const postStockScreenerColumnData = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SCREENER + config.API_SCREENER_STOCK_COLUMNS;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: payload,
  });
  return res;
};

export const postScreenerFlagData = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SCREENER + config.API_SCREENER_FLAG_TICKERS;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: {
      ...payload,
    },
  });
  return res;
};

export const postScreenerPinData = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SCREENER + config.API_SCREENER_PIN_TICKERS;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: {
      ...payload,
    },
  });
  return res;
};

export const postScreenerUserColumnData = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_SCREENER + config.API_SCREENER_UPDATE_USER_COLUMNS;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: {
      ...payload,
    },
  });
  return res;
};
