import { Link } from 'react-router-dom';
export default function ImageFullCard({ item }) {
  return (
    <Link to={`${item.id}`}>
      <h1 className='card-title'>{item.category}</h1>
      <div className='card bg-base-100 image-full w-96 shadow-xl'>
        <figure>
          <img src={item.image_short} alt='Shoes' />
        </figure>
        <div className='card-body'>
          <p>{item.headline}</p>
        </div>
      </div>
    </Link>
  );
}
