import Skeleton from 'react-loading-skeleton';

export function NewsCardloader() {
  return (
    <a href='#' target='_blank' rel='noreferrer' className='card bg-base-100 shadow-xl'>
      <Skeleton height='22rem' width='100%' />
      <div className='card-body'>
        <Skeleton height={30} width='80%' />
        <Skeleton count={3} />
      </div>
    </a>
  );
}

export function PressReleseCardloader() {
  return (
    <div className='pressrelease-card'>
      <div className='pressrelease-card-container'>
        <Skeleton height={20} />
        <Skeleton height={30} />
      </div>
    </div>
  );
}

export function PressReleseCard2loader() {
  return (
    <div className='overview-press-release'>
      <figure>
        <Skeleton height='5rem' />
      </figure>
      <div>
        <h3>
          <Skeleton />
        </h3>
        <p>
          <Skeleton />
        </p>
      </div>
    </div>
  );
}
