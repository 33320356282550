import React, { useState, useEffect } from 'react';
import { getColorValue, TableFilter } from 'src/lib/utils/utils';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
} from '@tanstack/react-table';
import {
  Pagination,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverClose,
  Select,
} from 'src/components';
import { BarsArrowDownIcon, BarsArrowUpIcon, ArrowDownIcon, ArrowUpIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import { selectCurrentReportState } from 'src/pages/Reports/Reports.selector';
import Tableloader from '../loadingAnimation/tableloader';
const pageNumber = [10, 20, 50, 100];

export default function ReportsTable({ headers, headersLabelMap, headersKeyMap }) {
  const reportData = useSelector(selectCurrentReportState);
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [rowPinning] = useState('');
  const [itemCount, setItemCount] = useState(10);
  const table = useReactTable({
    columns: headers,
    data: reportData?.data,
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters,
      rowPinning,
    },
    getCoreRowModel: getCoreRowModel(),
  });
  const setNumberOfItems = (size) => {
    table.setPageSize(size);
  };

  return (
    <div className='reports-table '>
      <div className='reports-table-container'>
        <table className='table table-pin-rows ' id='reports-table'>
          <thead className=''>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className=''>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} className={` ${header.column.getIsSorted() ? 'color-secondary' : ''} `}>
                    <TableHeaderRow header={header} table={table} headersLabelMap={headersLabelMap} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {reportData?.isLoading ? (
            <Tableloader count={headers.length} />
          ) : reportData?.error ? (
            <tbody className='td-error'>
              <div className='td-error-div'>Internal Server Error</div>
            </tbody>
          ) : (
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  <ReportsTableRow row={row} headersKeyMap={headersKeyMap} />
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>

      <div className='reports-paginate '>
        <div className='reports-page-items '>
          <Select
            options={pageNumber}
            selected
            onChange={(val) => {
              setNumberOfItems(val);
              setItemCount(val);
            }}
          />
          <div className='reports-page-items-info '>
            <span>{itemCount < reportData?.data?.length ? itemCount : reportData?.data?.length}</span> of{' '}
            <span>{reportData?.data?.length}</span> items
          </div>
        </div>

        <div className='reports-page-selection'>
          <Pagination
            totalPages={table.getPageCount() || 1}
            onPageChange={(value) => {
              table.setPageIndex(value - 1);
            }}
          />
        </div>
      </div>
    </div>
  );
}

function TableHeaderRow({ header, table, headersLabelMap }) {
  const getToolTipClass = (sorting, key) =>
    `reports-tooltip-option  ${sorting === key ? 'reports-tooltip-option-active' : ''}`;
  return (
    <>
      <Popover>
        <PopoverTrigger className='w-full'>
          {!header.isPlaceholder && !header.column.id.includes('image') ? (
            <>
              <Tooltip>
                <TooltipTrigger
                  className={` reports-table-header  ${
                    headersLabelMap[flexRender(header.column.columnDef.header, header.getContext())].dataType ===
                    'string'
                      ? 'justify-start'
                      : 'justify-end'
                  } ${header?.column?.getFilterValue() ? 'reports-table-header-val' : ''}`}>
                  <div className=' reports-column-order '>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {
                      {
                        asc: <ArrowUpIcon className='size-6' />,
                        desc: <ArrowDownIcon className='size-6' />,
                      }[header.column.getIsSorted()]
                    }
                  </div>

                  {header?.column?.getFilterValue() ? (
                    header.column.getFilterValue() instanceof Array ? (
                      <div className='reports-filter-val'>
                        {header.column.getFilterValue()[0] ? (
                          <span>Min: {header.column.getFilterValue()[0] ? header.column.getFilterValue()[0] : ''}</span>
                        ) : null}
                        {header.column.getFilterValue()[1] ? (
                          <span>Max: {header.column.getFilterValue()[1] ? header.column.getFilterValue()[1] : ''}</span>
                        ) : null}
                      </div>
                    ) : (
                      <div className='reports-filter-val'>
                        <span>Value: {header.column.getFilterValue()}</span>
                      </div>
                    )
                  ) : (
                    ''
                  )}
                </TooltipTrigger>
                <TooltipContent className='Tooltip'>
                  {headersLabelMap[flexRender(header.column.columnDef.header, header.getContext())].label}
                </TooltipContent>
              </Tooltip>
            </>
          ) : (
            <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
          )}
        </PopoverTrigger>
        <PopoverContent>
          <div className=' reports-tooltip'>
            <PopoverClose
              className={getToolTipClass(header.column.getIsSorted(), 'asc')}
              onClick={() => header.column.toggleSorting(false)}>
              <BarsArrowDownIcon className='size-7' /> Ascending
            </PopoverClose>
            <PopoverClose
              className={getToolTipClass(header.column.getIsSorted(), 'desc')}
              onClick={() => header.column.toggleSorting(true)}>
              <BarsArrowUpIcon className='size-7' />
              Descending
            </PopoverClose>
            <PopoverClose
              className='reports-tooltip-option'
              onClick={() => {
                header.column.clearSorting();
                header.column.getFilterValue() instanceof Array
                  ? header.column.setFilterValue([])
                  : header.column.setFilterValue('');
              }}>
              <XMarkIcon className='size-7' />
              Clear
            </PopoverClose>
            <TableFilter column={header.column} table={table} />
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
}

function ReportsTableRow({ row, headersKeyMap }) {
  const getTrClass = (cell) =>
    headersKeyMap[cell.id.replace('_', ' ').split(' ')[1]]
      ? headersKeyMap[cell.id.replace('_', ' ').split(' ')[1]]?.dataType === 'string'
        ? 'text-left'
        : 'text-right'
      : '';
  const performanceClass = (val) => (val ? 'sentibuy' : 'sentisell');
  return (
    <>
      {row.getVisibleCells().map((cell) =>
        [
          'one_day',
          'five_day',
          'one_month',
          'three_month',
          'six_month',
          'year_to_date',
          'one_year',
          'five_year',
          'three_year',
          'ten_year',
          'max',
        ].some((period) => cell.id.includes(period)) ? (
          <td key={cell.id} className={` ${getTrClass(cell)} ${performanceClass(cell.getValue() > 0)}`}>
            {`${cell.getValue()}%`}
          </td>
        ) : cell.id.toLowerCase().includes('image') ? (
          <td key={cell.id}>
            <img src={cell.getValue()} alt='' className='reports-table-ticker-img' />
          </td>
        ) : cell.id.toLowerCase().includes('url') ? (
          <td key={cell.id} className='reports-link-row color-secondary'>
            <a href={cell.getValue()} target='_blank' rel='noreferrer'>
              Link
            </a>
          </td>
        ) : cell.id.toLowerCase().includes('_ticker') && !cell.id.toLowerCase().includes('_ticker_') ? (
          <td
            key={cell.id}
            className={`screener-ticker-row-options ${getTrClass(cell)} ${getColorValue(cell.getValue())}`}>
            {cell.getValue()}
          </td>
        ) : (
          <td key={cell.id} className={`${getTrClass(cell)} ${getColorValue(cell.getValue())}`}>
            {cell.getValue()}
          </td>
        ),
      )}
    </>
  );
}
