import { put, takeLatest } from 'redux-saga/effects';
import { getMarketInfo } from 'src/repositories/markets/markets';
import { fetchMarketInfo, fetchMarketInfoFailure, fetchMarketInfoSuccess } from './Marketinfo.reducer';
function* fetchMarketInfoSaga(action) {
  try {
    const data = yield getMarketInfo().then((response) => response);
    yield put(fetchMarketInfoSuccess(data.data.data));
  } catch (error) {
    yield put(fetchMarketInfoFailure(error));
  }
}

export function* watchMarketInfoSaga() {
  yield takeLatest(fetchMarketInfo.type, fetchMarketInfoSaga);
}
