export enum FinancialDataType {
  Income = 'income_statement_',
  BalanceSheet = 'balance_sheet_statement_',
  Cashflow = 'cash_flow_statement_',
  Revenue = 'revenue',
  EIBTDA = 'eibtda',
  EBIT = 'ebit',
  NetIncome = 'netIncome',
  SGExpense = 'expense',
  EPS = 'eps',
}
export enum StatementTf {
  Annual = 'annual',
  Quarter = 'quarter',
}
export enum NavProductMenu {
  quant = 'quant',
  ipackage = 'ipackage',
  portfolio = 'portfolio',
}
