import React, { useState } from 'react';
import { PrimaryHeader, SearchBar, Select } from 'src/components';
import SubmittedOrders from '../SubmittedOrders/SubmittedOrders.router';
import ExecutedOrders from '../ExecutedOrders/ExecutedOrders.router';
import PositionHoldings from '../PositionHoldings/PositionHoldings.router';

import { TOLDownloadIcon } from 'src/assets/Images';

const dropdownOptions = ['Submitted Orders', 'Executed Orders', 'Position Holdings'];

function TradeOrdersList() {
  const [headerText, setHeaderText] = useState('Submitted Orders');
  return (
    <section className='navbar-fix'>
      <PrimaryHeader text='Trade Orders List' className='color-primary' />
      <main className='tradeorderlist'>
        <section className='tradeorderlist__top'>
          <div className='tradeorderlist__left'>
            <div className='tradeorderlist__dropdown'>
              <h3>Options</h3>
              <Select options={dropdownOptions} title='Submitted Orders' setDropdown={setHeaderText} />
            </div>
            <SearchBar className='tradeorderlist__searchbar' />
          </div>
          <div className='tradeorderlist__left'>
            <img src={TOLDownloadIcon} alt='' />
          </div>
        </section>
        <hr />
        <section className='tradeorderlist__body'>
          <section className='tradeorderlist__main'>
            {headerText === 'Submitted Orders' ? <SubmittedOrders /> : ''}
            {headerText === 'Executed Orders' ? <ExecutedOrders /> : ''}
            {headerText === 'Position Holdings' ? <PositionHoldings /> : ''}
          </section>
        </section>
      </main>
    </section>
  );
}

export default TradeOrdersList;
