import { apiClient } from 'src/repositories/apiClient';
import { config } from 'src/lib/config';

export const postInitiateSignin = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MGMT_SERVICE + config.MGMT_SIGNIN_INITIATE;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};
export const postInitiateSignup = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MGMT_SERVICE + config.MGMT_SIGNUP_INITIATE;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};
export const postSignin = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MGMT_SERVICE + config.MGMT_SIGNIN;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};
export const postSignup = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MGMT_SERVICE + config.MGMT_SIGNUP;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};
export const postInitiateForgotPassword = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MGMT_SERVICE + config.MGMT_FORGOT_PASSWORD_INITIATE;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};
export const postForgotPassword = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MGMT_SERVICE + config.MGMT_FORGOT_PASSWORD;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};

export const getProfileInfo = async (params: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MGMT_SERVICE + config.API_PROFILE;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const postProfileInfo = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MGMT_SERVICE + config.API_PROFILE;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};
export const postProfilePicUpdate = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MGMT_SERVICE + config.API_PROFILE_AVATAR;
  const res = await apiClient({
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    url: apiUrl,
    data: payload,
  });
  return res;
};
export const postEmailUpdateInit = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MGMT_SERVICE + config.API_PROFILE_EMAIL_INIT;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};
export const postEmailUpdate = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MGMT_SERVICE + config.API_PROFILE_EMAIL;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};
export const postPasswordInit = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MGMT_SERVICE + config.API_PROFILE_PASSWORD_INIT;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};
export const postPassword = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MGMT_SERVICE + config.API_PROFILE_PASSWORD;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};
export const getCarousalInfo = async (params: undefined) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MGMT_SERVICE + config.MGMT_CAROUSAL;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const postStockTickerDetails = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MGMT_SERVICE + '/stock-ticker-details';
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};
export const postNonStockTickerDetails = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MGMT_SERVICE + '/nonstock-ticker-details';
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};

export const postBidAsk = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MGMT_SERVICE + '/get-bidask-quotes';
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};

export const postLiveQuote = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MGMT_SERVICE + '/get-live-quotes';
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};
export const postTokenRefresh = async (payload: { token: string }) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MGMT_SERVICE + config.API_MGMT_TOKEN_REFRESH;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};

export const postTickerSearch = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MGMT_SERVICE + config.API_SEARCH;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};
