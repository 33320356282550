import React, { useState } from 'react';

export function HeatmapColorScale({ updateFilter }) {
  const [selectedFilter, setSelectedFilter] = useState({ value: null, type: null });

  const handleClick = (value, type) => {
    updateFilter(value, type);
    setSelectedFilter({ value, type });
  };

  const getClassNames = (value, type, baseClass) => {
    const isSelectedFilter = selectedFilter.value === value && selectedFilter.type === type;
    return `${baseClass} ${isSelectedFilter ? 'active' : ''}`;
  };

  return (
    <div className='heatmap-colorscale'>
      <div className='scale'>
        <span
          className='brick-one'
          onClick={() => {
            updateFilter(null, null);
            setSelectedFilter({ value: null, type: null });
          }}>
          Reset
        </span>
      </div>
      <div className='loser'>
        <h4>Top Losers</h4>
        <div>
          <div className={getClassNames(-4, 2, 'brick bg-5b')} onClick={() => handleClick(-4, 2)}>
            &lt; -4%
          </div>
          <div className={getClassNames(-3, 2, 'brick bg-4b')} onClick={() => handleClick(-3, 2)}>
            &lt; -3%
          </div>
          <div className={getClassNames(-2, 2, 'brick bg-3b')} onClick={() => handleClick(-2, 2)}>
            &lt; -2%
          </div>
          <div className={getClassNames(-1, 2, 'brick bg-2b')} onClick={() => handleClick(-1, 2)}>
            &lt; -1%
          </div>
          <div className={getClassNames(0, 2, 'brick bg-1b')} onClick={() => handleClick(0, 2)}>
            &lt; 0%
          </div>
        </div>
      </div>
      <div className='gainer'>
        <h4>Top Gainers</h4>
        <div>
          <div className={getClassNames(0, 3, 'brick bg-u')} onClick={() => handleClick(0, 3)}>
            0%
          </div>
          <div className={getClassNames(0, 1, 'brick bg-1u')} onClick={() => handleClick(0, 1)}>
            &gt; 0%
          </div>
          <div className={getClassNames(1, 1, 'brick bg-2u')} onClick={() => handleClick(1, 1)}>
            &gt; +1%
          </div>
          <div className={getClassNames(2, 1, 'brick bg-3u')} onClick={() => handleClick(2, 1)}>
            &gt; +2%
          </div>
          <div className={getClassNames(3, 1, 'brick bg-4u')} onClick={() => handleClick(3, 1)}>
            &gt; +3%
          </div>
          <div className={getClassNames(4, 1, 'brick bg-5u')} onClick={() => handleClick(4, 1)}>
            &gt; +4%
          </div>
        </div>
      </div>
    </div>
  );
}
