import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

export default function ZScoreBar({ high, low, currentValue, width, height }) {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartNode = d3.select(chartRef.current);

    // Clear previous chart content
    chartNode.selectAll('*').remove();

    // Set up the dimensions and margins
    const margin = { top: 40, right: 10, bottom: 10, left: 10 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    // Calculate segment widths
    const segmentWidth = innerWidth / 3;

    // Create the main chart group
    const chart = chartNode.append('g').attr('transform', `translate(${margin.left}, ${margin.top})`);

    // Create the red segment
    chart
      .append('rect')
      .attr('x', 0)
      .attr('y', innerHeight / 2 - 5)
      .attr('width', segmentWidth)
      .attr('height', 10)
      .style('fill', 'red');

    // Create the grey segment
    chart
      .append('rect')
      .attr('x', segmentWidth)
      .attr('y', innerHeight / 2 - 5)
      .attr('width', segmentWidth)
      .attr('height', 10)
      .style('fill', 'grey');

    // Create the green segment
    chart
      .append('rect')
      .attr('x', segmentWidth * 2)
      .attr('y', innerHeight / 2 - 5)
      .attr('width', segmentWidth)
      .attr('height', 10)
      .style('fill', 'green');

    // Create the current value indicator
    const xScale = d3.scaleLinear().domain([low, high]).range([0, innerWidth]);

    // Add scale tick values at segment intersections
    const ticks = [low, low + (high - low) / 3, low + ((high - low) * 2) / 3, high];
    const tickPositions = ticks.map((value) => xScale(value));

    chart
      .selectAll('.tick')
      .data(ticks)
      .enter()
      .append('text')
      .attr('x', (d, i) => tickPositions[i])
      .attr('y', innerHeight / 2 + 20)
      .text((d) => d.toFixed(0))
      .style('text-anchor', 'middle')
      .attr('fill', '#ffffff')
      .style('font-size', '12px');
    // Add text labels above the segments
    chart
      .append('text')
      .attr('x', segmentWidth / 2)
      .attr('y', -5)
      .text('Distress Zone')
      .style('text-anchor', 'middle')
      .attr('fill', '#ffffff')
      .style('font-size', '15px')
      .style('font-weight', '600');

    chart
      .append('text')
      .attr('x', innerWidth / 2)
      .attr('y', -5)
      .text('Grey Zone')
      .style('text-anchor', 'middle')
      .attr('fill', '#ffffff')
      .style('font-size', '15px')
      .style('font-weight', '600');

    chart
      .append('text')
      .attr('x', innerWidth - segmentWidth / 2)
      .attr('y', -5)
      .text('Safe Zone')
      .style('text-anchor', 'middle')
      .attr('fill', '#ffffff')
      .style('font-size', '15px')
      .style('font-weight', '600');
    const currentValueX = xScale(currentValue);
    chart
      .append('text')
      .attr('x', currentValueX)
      .attr('y', innerHeight / 2 + 20)
      .text(currentValue?.toFixed(2))
      .style('text-anchor', 'middle')
      .attr('fill', '#ffffff')
      .style('font-size', '15px')
      .style('font-weight', '600');

    // Create the currentValue area and triangle
    const currentValueY = innerHeight / 2 - 5; // Offset to position the triangle below the point

    const currentValueTrianglePoints = [
      [currentValueX, currentValueY],
      [currentValueX - 5, currentValueY - 10],
      [currentValueX + 5, currentValueY - 10],
    ];
    chart.append('polygon').attr('points', currentValueTrianglePoints).style('fill', '#ffffff');
  }, [high, low, currentValue]);

  return <svg ref={chartRef} width={width} height={height} />;
}
