import React, { useEffect, useState, useMemo } from 'react';
import { getColorValue, convertNumberToString, TableFilter } from 'src/lib/utils/utils';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
} from '@tanstack/react-table';
import { BarsArrowDownIcon, BarsArrowUpIcon, ArrowDownIcon, ArrowUpIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useSelector, useDispatch } from 'react-redux';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { selectScreenerFlaggedColumn } from 'src/pages/Screener/Screener.selector';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {
  Pagination,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverClose,
  Select,
} from 'src/components';
import { selectHeatmapState } from 'src/pages/Heatmap/heatmap.selector';
import { selectScreenerFilter } from 'src/pages/Screener/Screener.selector';
import { postScreenerFlaggedColumn, setScreenerFlaggedColumn } from 'src/pages/Screener/Screener.reducer';
const pageNumber = [10, 20, 50, 100];
export default function ScreenerTable({ dataKey, headers, headersLabelMap, headersKeyMap, advancedFilterActive }) {
  const screenerData = useSelector(selectHeatmapState);
  const filterData = useSelector(selectScreenerFilter);
  const [filteredScripts, setFilteredScript] = useState(['']);
  const [columnOrder, setColumnOrder] = useState(headers.map((column) => column.accessorKey));

  const [rowPinning, setRowPinning] = useState({
    top: [],
    bottom: [],
  });
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);

  const [itemCount, setItemCount] = useState(10);
  const table = useReactTable({
    columns: headers,
    data: screenerData?.data ? (dataKey ? screenerData?.data[dataKey] : screenerData?.data) : [],
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      columnOrder,
      sorting,
      columnFilters,
      rowPinning,
    },

    onColumnOrderChange: setColumnOrder,
    getCoreRowModel: getCoreRowModel(),
    keepPinnedRows: true,
  });
  const setNumberOfItems = (size) => {
    table.setPageSize(size);
  };

  useEffect(() => {
    if (advancedFilterActive && filterData.data) {
      const filteredTickers = filterData.data.map((e) => e.ticker);
      setColumnFilters([{ id: 'ticker', value: filteredTickers }]);
      const updatedData = screenerData.data.map((row) => {
        const filteredRow = filterData.data.find((filtered) => filtered.ticker === row.ticker);
        return filteredRow ? { ...row, ...filteredRow } : row;
      });
    }
  }, [filterData.data, advancedFilterActive]);

  return (
    <>
      <div className='screener-table '>
        <div className='reports-table-container'>
          <DndProvider backend={HTML5Backend}>
            <table className='table table-pin-rows  ' id='screener-table'>
              {screenerData?.error ? (
                <tbody className='td-error'>
                  <div className='td-error-div'>Internal Server Error</div>
                </tbody>
              ) : (
                <>
                  <thead className=''>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id} className=''>
                        {headerGroup.headers.map((header) => (
                          <TableHeaderRow header={header} table={table} headersLabelMap={headersLabelMap} />
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row) => (
                      <tr key={row.id}>
                        <ScreenerTableRow row={row} headersKeyMap={headersKeyMap} />
                      </tr>
                    ))}
                  </tbody>
                </>
              )}
            </table>
          </DndProvider>
        </div>

        <div className='reports-paginate '>
          <div className='reports-page-items '>
            <Select
              options={pageNumber}
              selected
              onChange={(val) => {
                setNumberOfItems(val);
                setItemCount(val);
              }}
            />
            <div className='reports-page-items-info '>
              <span>
                {itemCount < dataKey
                  ? screenerData?.data[dataKey]?.length
                  : screenerData?.data?.length
                  ? itemCount
                  : dataKey
                  ? screenerData?.data[dataKey]?.length
                  : screenerData?.data?.length}
              </span>
              of <span>{dataKey ? screenerData?.data[dataKey]?.length : screenerData?.data?.length}</span> items
            </div>
          </div>
          {table.getPageCount() ? (
            <div className='reports-page-selection'>
              <Pagination
                totalPages={table.getPageCount() || 1}
                onPageChange={(value) => {
                  table.setPageIndex(value - 1);
                }}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

function ScreenerTableRow({ row, headersKeyMap }) {
  const dispatch = useDispatch();
  const flagged = useSelector(selectScreenerFlaggedColumn);
  const getTrClass = (cell) =>
    headersKeyMap[cell.id.replace('_', ' ').split(' ')[1]]
      ? headersKeyMap[cell.id.replace('_', ' ').split(' ')[1]]?.dataType === 'string'
        ? 'text-left'
        : 'text-right'
      : '';
  const performanceClass = (val) => (val ? 'sentibuy' : 'sentisell');
  return (
    <>
      {row.getVisibleCells().map((cell) =>
        [
          'one_day',
          'five_day',
          'one_month',
          'three_month',
          'six_month',
          'year_to_date',
          'one_year',
          'five_year',
          'three_year',
          'ten_year',
          'max',
        ].some((period) => cell.id.includes(period)) ? (
          <td key={cell.id} className={` ${getTrClass(cell)} ${performanceClass(cell.getValue() > 0)}`}>
            {`${cell.getValue()}%`}
          </td>
        ) : cell.id.toLowerCase().includes('image') ? (
          <td key={cell.id} style={{ padding: 0 }}>
            <div
              className={`screener-flagged-image   ${
                flagged.flaggedColumns.includes(row.original.ticker) ? 'flagged' : ''
              }`}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 14 12'
                width='14'
                height='12'
                fill='#20c997'
                focusable='false'
                preserveAspectRatio='none'>
                <path d='M14 12l-4-6 4-6H0v12z'></path>
              </svg>

              <img src={cell.getValue()} alt='' className='reports-table-ticker-img' />
            </div>
          </td>
        ) : cell.id.toLowerCase().includes('url') ? (
          <td key={cell.id} className='reports-link-row color-secondary'>
            <a href={cell.getValue()} target='_blank' rel='noreferrer'>
              Link
            </a>
          </td>
        ) : cell.id.toLowerCase().includes('_ticker') && !cell.id.toLowerCase().includes('_ticker_') ? (
          <td
            key={cell.id}
            className={`screener-ticker-row-options ${getTrClass(cell)} ${getColorValue(cell.getValue())}`}>
            {convertNumberToString(cell.getValue())}
            <Popover placement='right'>
              <PopoverTrigger>
                <EllipsisVerticalIcon className='size-6 cursor-pointer' />
              </PopoverTrigger>
              <PopoverContent>
                <ul className='screener-row-tooltip'>
                  <PopoverClose>
                    <li
                      onClick={() => {
                        const cols =
                          flagged.flaggedColumns.length > 0
                            ? [...flagged.flaggedColumns, row.original.ticker]
                            : [row.original.ticker];

                        dispatch(setScreenerFlaggedColumn({ cols }));
                        dispatch(postScreenerFlaggedColumn({ tickers: cols, flag: true }));
                      }}>
                      Flag
                    </li>
                  </PopoverClose>
                  <PopoverClose>
                    <li
                      onClick={() => {
                        const cols = flagged.flaggedColumns.filter((e) => e !== row.original.ticker);
                        dispatch(setScreenerFlaggedColumn({ cols }));
                        dispatch(postScreenerFlaggedColumn({ tickers: [row.original.ticker], flag: false }));
                      }}>
                      Unflag
                    </li>
                  </PopoverClose>
                  <PopoverClose>
                    <li
                      onClick={() => {
                        dispatch(setScreenerFlaggedColumn({ cols: [] }));
                        dispatch(postScreenerFlaggedColumn({ tickers: [], flag: true }));
                      }}>
                      Unflag All
                    </li>
                  </PopoverClose>
                  {/* <PopoverClose>
                    <li
                      onClick={() => {
                        row.pin('top');
                      }}>
                      Pin
                    </li>
                  </PopoverClose>
                  <PopoverClose>
                    <li
                      onClick={() => {
                        row.pin(false);
                      }}>
                      Unpin
                    </li>
                  </PopoverClose> */}
                  {/* <PopoverClose>
                    <li>Open Chart</li>
                  </PopoverClose> */}
                </ul>
              </PopoverContent>
            </Popover>
          </td>
        ) : (
          <td key={cell.id} className={`${getTrClass(cell)} ${getColorValue(cell.getValue())}`}>
            {convertNumberToString(cell.getValue())}
          </td>
        ),
      )}
    </>
  );
}
const reorderColumn = (draggedColumnId, targetColumnId, columnOrder) => {
  columnOrder.splice(
    columnOrder.indexOf(targetColumnId),
    0,
    columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0],
  );
  return [...columnOrder];
};

function TableHeaderRow({ header, table, headersLabelMap }) {
  const { getState, setColumnOrder } = table;
  const { columnOrder } = getState();
  const { column } = header;

  // Drop target logic
  const [, dropRef] = useDrop({
    accept: 'column',
    drop: (draggedColumn) => {
      if (draggedColumn.id !== column.id) {
        const newColumnOrder = reorderColumn(draggedColumn.id, column.id, columnOrder);
        setColumnOrder(newColumnOrder);
      }
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: 'column',
    item: { id: column.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const getToolTipClass = (sorting, key) =>
    `reports-tooltip-option  ${sorting === key ? 'reports-tooltip-option-active' : ''}`;
  const getTrClass = (header) =>
    headersLabelMap[flexRender(header.column.columnDef.header, header.getContext())]
      ? headersLabelMap[flexRender(header.column.columnDef.header, header.getContext())]?.dataType === 'string'
        ? 'justify-start'
        : 'justify-end'
      : '';
  return (
    <th
      style={{ opacity: isDragging ? 0.5 : 1 }}
      ref={dropRef}
      key={header.id}
      className={`${header.column.getIsSorted() ? 'color-secondary' : ''}`}>
      <Popover>
        <PopoverTrigger className='w-full'>
          {!header.isPlaceholder && !header.column.id.includes('image') ? (
            <>
              <Tooltip ref={previewRef}>
                <TooltipTrigger
                  ref={dragRef}
                  className={`reports-table-header ${getTrClass(header)} ${
                    header?.column?.getFilterValue() ? 'reports-table-header-val' : ''
                  }`}>
                  <div className='report-column-order'>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {
                      {
                        asc: <ArrowUpIcon className='size-6' />,
                        desc: <ArrowDownIcon className='size-6' />,
                      }[header.column.getIsSorted()]
                    }
                  </div>
                  {header?.column?.getFilterValue() ? (
                    header.column.getFilterValue() instanceof Array ? (
                      <div className='reports-filter-val'>
                        {header.column.getFilterValue()[0] && <span>Min: {header.column.getFilterValue()[0]}</span>}
                        {header.column.getFilterValue()[1] && <span>Max: {header.column.getFilterValue()[1]}</span>}
                      </div>
                    ) : (
                      <div className='reports-filter-val'>
                        <span>Value: {header.column.getFilterValue()}</span>
                      </div>
                    )
                  ) : (
                    ''
                  )}
                </TooltipTrigger>
                <TooltipContent className='Tooltip'>
                  {headersLabelMap[flexRender(header.column.columnDef.header, header.getContext())]?.label}
                </TooltipContent>
              </Tooltip>
            </>
          ) : (
            <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
          )}
        </PopoverTrigger>
        <PopoverContent>
          <div className='reports-tooltip'>
            <PopoverClose
              className={getToolTipClass(header.column.getIsSorted(), 'asc')}
              onClick={() => header.column.toggleSorting(false)}>
              <BarsArrowDownIcon className='size-7' /> Ascending
            </PopoverClose>
            <PopoverClose
              className={getToolTipClass(header.column.getIsSorted(), 'desc')}
              onClick={() => header.column.toggleSorting(true)}>
              <BarsArrowUpIcon className='size-7' /> Descending
            </PopoverClose>
            <PopoverClose
              className='reports-tooltip-option'
              onClick={() => {
                header.column.clearSorting();
                header.column.getFilterValue() instanceof Array
                  ? header.column.setFilterValue([])
                  : header.column.setFilterValue('');
              }}>
              <XMarkIcon className='size-7' /> Clear
            </PopoverClose>
            <TableFilter column={header.column} table={table} />
          </div>
        </PopoverContent>
      </Popover>
    </th>
  );
}
