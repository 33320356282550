import * as echarts from 'echarts';
import React, { useEffect, useRef, useMemo, useCallback, memo } from 'react';

export const ProjectionGraph = memo(({ data, color, height = '40px', targets }) => {
  const chartRef = useRef(null);

  // Calculate the index to start the last 50% of the data
  const startIndex = useMemo(() => Math.floor(data.length / 2), [data]);

  // Slice the data to get the last 50% and add nulls for the remaining 50%
  const xAxisData = useMemo(() => {
    const actualData = data.map((_, i) => i);
    return actualData;
  }, [data, startIndex]);

  const mainSeriesData = useMemo(() => {
    const actualData = data.slice(startIndex).map((item) => item.close);
    const nulls = Array(startIndex).fill(null);
    return [...actualData, ...nulls];
  }, [data, startIndex]);

  const createConnectingLineData = (targetValue) => {
    const connection = Array(xAxisData.length).fill(null);
    const visibleData = data.slice(startIndex);

    if (visibleData.length > 1) {
      const midpointIndex = Math.floor(visibleData.length);

      connection[midpointIndex] = visibleData[midpointIndex - 1]?.close || 260;
      connection[connection.length - 1] = targetValue;
    }

    return connection;
  };

  const connectingLineDataConsensus = useMemo(
    () => createConnectingLineData(targets.targetConsensus),
    [data, startIndex, xAxisData.length, targets.targetConsensus],
  );
  const connectingLineDataHigh = useMemo(
    () => createConnectingLineData(targets.targetHigh),
    [data, startIndex, xAxisData.length, targets.targetHigh],
  );
  const connectingLineDataLow = useMemo(
    () => createConnectingLineData(targets.targetLow),
    [data, startIndex, xAxisData.length, targets.targetLow],
  );
  const connectingLineDataMedian = useMemo(
    () => createConnectingLineData(targets.targetMedian),
    [data, startIndex, xAxisData.length, targets.targetMedian],
  );
  const createChart = useCallback(() => {
    const chart = echarts.init(chartRef.current);
    const option = {
      title: {
        text: 'Sample Line Chart',
        show: false, // Hide title
      },
      tooltip: {
        show: false,
      },
      grid: {
        left: 40,
        right: 140,
        top: 60,
        bottom: 40,
        containLabel: false,
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
        show: false,
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: { show: false },
      },
      yAxis: {
        type: 'value',
        min: 'dataMin',
        max: 'dataMax',
        show: false, // Hide Y-axis
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: { show: false }, // No grid lines
      },
      series: [
        {
          name: 'Main Data',
          type: 'line',
          showSymbol: false,
          color: color,
          data: mainSeriesData,
          connectNulls: true, // Connect through null values
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: 'rgba(41, 98, 255, .5)' },
              { offset: 1, color: 'transparent' },
            ]),
          },
        },
        {
          name: 'Connection Line Consensus',
          type: 'line',
          data: connectingLineDataConsensus,
          symbol: 'circle', // Placeholder for symbol
          symbolSize: 0, // Size 0 to effectively hide it
          label: {
            show: true,
            formatter: (params) => {
              // Display the label only at the end of the line
              if (params.dataIndex === xAxisData.length - 1) {
                return `Current: ${targets?.targetConsensus}`;
              }
              return '';
            },
            position: 'end',
            color: 'white',
            backgroundColor: 'grey',
            borderColor: 'grey',
            borderWidth: 1,
            padding: [5, 8],
            fontSize: 14,
            borderRadius: 3,
          },
          itemStyle: {
            color: 'grey',
          },
          connectNulls: true, // Connect through null values
        },
        {
          name: 'Connection Line High',
          type: 'line',
          data: connectingLineDataHigh,
          symbol: 'circle', // Placeholder for symbol
          symbolSize: 0, // Size 0 to effectively hide it
          label: {
            show: true,
            formatter: (params) => {
              // Display the label only at the end of the line
              if (params.dataIndex === xAxisData.length - 1) {
                return `Max: ${targets.targetHigh}`;
              }
              return '';
            },
            position: 'end',
            color: 'white',
            backgroundColor: '#20c997',
            borderColor: '#20c997',
            borderWidth: 1,
            padding: [5, 8],
            fontSize: 14,
            borderRadius: 3,
          },
          itemStyle: {
            color: '#20c997',
          },
          connectNulls: true, // Connect through null values
        },
        {
          name: 'Connection Line Low',
          type: 'line',
          data: connectingLineDataLow,
          symbol: 'circle', // Placeholder for symbol
          symbolSize: 0, // Size 0 to effectively hide it
          label: {
            show: true,
            formatter: (params) => {
              // Display the label only at the end of the line
              if (params.dataIndex === xAxisData.length - 1) {
                return `Min: ${targets.targetLow}`;
              }
              return '';
            },
            position: 'end',
            color: 'white',
            backgroundColor: '#dc3546',
            borderColor: '#dc3546',
            borderWidth: 1,
            padding: [5, 8],
            fontSize: 14,
            borderRadius: 3,
          },
          itemStyle: {
            color: '#dc3546',
          },
          connectNulls: true, // Connect through null values
        },
        {
          name: 'Connection Line Median',
          type: 'line',
          data: connectingLineDataMedian,
          symbol: 'circle', // Placeholder for symbol
          symbolSize: 0, // Size 0 to effectively hide it
          label: {
            show: true,
            formatter: (params) => {
              // Display the label only at the end of the line
              if (params.dataIndex === xAxisData.length - 1) {
                return `Avg: ${targets.targetMedian}`;
              }
              return '';
            },
            position: 'end',
            color: 'white',
            backgroundColor: '#007aff',
            borderColor: '#007aff',
            borderWidth: 1,
            padding: [5, 8],
            fontSize: 14,
            borderRadius: 3,
          },
          itemStyle: {
            color: '#007aff',
          },
          connectNulls: true, // Connect through null values
        },
      ],
    };
    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [
    xAxisData,
    mainSeriesData,
    connectingLineDataConsensus,
    connectingLineDataHigh,
    connectingLineDataLow,
    connectingLineDataMedian,
    color,
    targets?.targetConsensus,
    targets?.targetHigh,
    targets?.targetLow,
    targets?.targetMedian,
  ]);

  useEffect(() => {
    const cleanup = createChart();

    return () => {
      cleanup();
    };
  }, [createChart]);

  return <div ref={chartRef} style={{ width: '100%', height }} />;
});
