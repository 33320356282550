import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import { data } from 'src/static-data';
import { aboutImage1, aboutImage2, aboutImage3 } from 'src/assets/Images';
import {
  MotionButton,
  MotionDiv,
  MotionH1,
  MotionH3,
  MotionImg,
  MotionP,
} from 'src/components/motion/motion.component';

export default function About() {
  const [servicesVisible, setServicesVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const onVisibilityChange = (isVisible) => {
    setServicesVisible(isVisible);
  };

  const onBannerVisibilityChange = (isVisible) => {
    setIsVisible(isVisible);
    setServicesVisible(isVisible);
  };

  const isVisibleProp = (visible: boolean, prop1, prop2) => (visible ? prop1 : prop2);
  return (
    <main className='about navbar-fix'>
      <section className='about__banner'>
        <VisibilitySensor partialVisibility onChange={onBannerVisibilityChange}>
          <MotionImg
            src={aboutImage1}
            alt=''
            className='banner-image'
            initial={{ x: -1000, opacity: 0 }}
            animate={{ x: isVisibleProp(isVisible, 0, -1000), opacity: isVisibleProp(isVisible, 1, 0) }}
            transition={{ duration: 1.5, delay: 0.5 }}
          />
        </VisibilitySensor>
        <div className='about__banner--data'>
          <VisibilitySensor partialVisibility onChange={onBannerVisibilityChange}>
            <MotionDiv
              className='banner-text'
              initial={{ y: -100, opacity: 0 }}
              animate={{ y: isVisibleProp(isVisible, 0, -100), opacity: isVisibleProp(isVisible, 1, 0) }}
              transition={{ duration: 1, delay: 1 }}>
              <h3>We&apos;re Consultax</h3>
              <h1>
                Financial Consulting <br /> for Your Business.
              </h1>
              <Link to='/quantlandingpage'>
                <MotionButton
                  initial={{ x: 1000, opacity: 0 }}
                  animate={{ x: isVisibleProp(isVisible, 0, 1000), opacity: isVisibleProp(isVisible, 1, 0) }}
                  transition={{ duration: 1, delay: 1.5 }}
                  type='button'>
                  GET STARTED
                </MotionButton>
              </Link>
            </MotionDiv>
          </VisibilitySensor>
        </div>
      </section>
      <section className='about__services'>
        {data.aboutPageData.sectionFirstData.map((item, index) => (
          <VisibilitySensor key={item.id} onChange={onVisibilityChange}>
            <MotionDiv
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: isVisibleProp(servicesVisible, 1, 0), y: isVisibleProp(servicesVisible, 0, 50) }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              className='about__service'>
              <img src={item.icon} alt='' />
              <h2>{item.heading}</h2>
              <p>{item.details}</p>
            </MotionDiv>
          </VisibilitySensor>
        ))}
      </section>
      <section className='about__bannerMiddle'>
        <div className='about__bannerMiddle--left'>
          <VisibilitySensor partialVisibility>
            {({ isVisible }) => (
              <MotionImg
                src={aboutImage2}
                alt=''
                initial={{ opacity: 0 }}
                animate={{ opacity: isVisibleProp(isVisible, 1, 0) }}
                transition={{ duration: 1.5, delay: 0.5 }}
              />
            )}
          </VisibilitySensor>
        </div>
        <div className='about__bannerMiddle--right'>
          <VisibilitySensor partialVisibility>
            {({ isVisible }) => (
              <MotionDiv
                initial={{ opacity: 0 }}
                animate={{ opacity: isVisibleProp(isVisible, 1, 0) }}
                transition={{ duration: 1, delay: 1 }}>
                <MotionH3
                  initial={{ y: -100 }}
                  animate={{ y: isVisibleProp(isVisible, 0, -100) }}
                  transition={{ duration: 1, delay: 1 }}>
                  WHO WE ARE
                </MotionH3>
                <MotionH1
                  initial={{ x: -100 }}
                  animate={{ x: isVisibleProp(isVisible, 0, -100) }}
                  transition={{ duration: 1, delay: 1.5 }}>
                  Our Mission, <br /> Values and Motto
                </MotionH1>
                <MotionP
                  initial={{ y: 100 }}
                  animate={{ y: isVisibleProp(isVisible, 0, 100) }}
                  transition={{ duration: 1, delay: 2 }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus bibendum congue urna, ut venenatis
                  orci molestie vitaemaui. Suscipit non lacus suscipit faucibus. Morbi feugiat sit amet rhoncus rhoncus
                  erat nec, tristique porta tellus. Etiam consequat felis.
                </MotionP>
              </MotionDiv>
            )}
          </VisibilitySensor>
        </div>
      </section>

      <section className='about__servicesSecond'>
        <h1>What we bring to you</h1>
        <div className='about__cardsMain'>
          {data.aboutPageData.sectionThirdData.map((item, index) => (
            <VisibilitySensor key={item.id} partialVisibility>
              {({ isVisible }) => (
                <MotionDiv
                  initial={{ opacity: 0, y: isVisibleProp(isVisible, -100, 100) }}
                  animate={{
                    opacity: isVisibleProp(isVisible, 1, 0),
                    y: isVisibleProp(isVisible, 0, isVisibleProp(index % 2 === 0, -100, 100)),
                  }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                  className='about__main'>
                  <div className='about__Container'>
                    <div className='about__Container--icon'>
                      <img src={item.icon} alt='' />
                    </div>
                    <div className='about__Container--content'>
                      <h2>{item.header}</h2>
                      <h4>{item.details}</h4>
                    </div>
                  </div>
                </MotionDiv>
              )}
            </VisibilitySensor>
          ))}
        </div>
      </section>
      <div className='about__bottom'>
        <VisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <div className='about__bottom--left'>
              <MotionH3
                initial={{ y: -100 }}
                animate={{ y: isVisibleProp(isVisible, 0, -100) }}
                transition={{ duration: 1, delay: 1 }}>
                OUR PROJECTS
              </MotionH3>
              <MotionH1
                initial={{ x: -100 }}
                animate={{ x: isVisibleProp(isVisible, 0, -100) }}
                transition={{ duration: 1, delay: 1.5 }}>
                We are <br /> The Leaders
              </MotionH1>
              <MotionP
                initial={{ y: 100 }}
                animate={{ y: isVisibleProp(isVisible, 0, 100) }}
                transition={{ duration: 1, delay: 2 }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus bibendum congue urna, ut venenatis orci
                molestie vitaemaui. Suscipit non lacus suscipit faucibus. Morbi feugiat sit amet rhoncus rhoncus erat
                nec, tristique porta tellus. Etiam consequat felis.
              </MotionP>
            </div>
          )}
        </VisibilitySensor>
        <div className='about__bottom--right'>
          <VisibilitySensor partialVisibility>
            {({ isVisible }) => (
              <MotionImg
                src={aboutImage3}
                alt=''
                initial={{ opacity: 0 }}
                animate={{ opacity: isVisibleProp(isVisible, 1, 0) }}
                transition={{ duration: 1.5, delay: 0.5 }}
              />
            )}
          </VisibilitySensor>
        </div>
      </div>
    </main>
  );
}
