import React, { useState } from 'react';
import { FinancialDataType, StatementTf } from 'src/lib/enum/enum';
import { useSelector } from 'react-redux';
import { selectWatchlistFinancial } from 'src/pages/Watchlist/Watchlist.selector';
import { activeElement } from 'src/lib/utils/utils';
import { data } from 'src/static-data';
import Skeleton from 'react-loading-skeleton';

export function FinancialTable() {
  const [tableTf, setTableTf] = useState(StatementTf.Annual);
  const [statementTypeTable, setStatementTypeTable] = useState(FinancialDataType.Income);
  const financials = useSelector(selectWatchlistFinancial);

  return (
    <section>
      <h1 className='financials-section-header'>All Financial Statement</h1>
      <div className='financials-navbar'>
        <ul className=' menu menu-horizontal '>
          <li
            className={activeElement(FinancialDataType.Income === statementTypeTable)}
            onClick={() => setStatementTypeTable(FinancialDataType.Income)}>
            Income Statement
          </li>
          <li
            className={activeElement(FinancialDataType.BalanceSheet === statementTypeTable)}
            onClick={() => setStatementTypeTable(FinancialDataType.BalanceSheet)}>
            Balance Sheet
          </li>
          <li
            className={activeElement(FinancialDataType.Cashflow === statementTypeTable)}
            onClick={() => setStatementTypeTable(FinancialDataType.Cashflow)}>
            Cashflow
          </li>
        </ul>
        <ul className=' menu menu-horizontal '>
          <li className={activeElement(StatementTf.Annual === tableTf)} onClick={() => setTableTf(StatementTf.Annual)}>
            Annual
          </li>
          <li
            className={activeElement(StatementTf.Quarter === tableTf)}
            onClick={() => setTableTf(StatementTf.Quarter)}>
            Quarter
          </li>
        </ul>
      </div>
      <div className='financials-table'>
        <table>
          <thead>
            <tr>
              {financials?.data?.[`${statementTypeTable}${tableTf}`] && (
                <th>Currency:{financials?.data?.[`${statementTypeTable}${tableTf}`][0]?.reported_currency}</th>
              )}
              {StatementTf.Annual === tableTf &&
                financials?.data?.[`${statementTypeTable}${tableTf}`]?.map((item, i) => (
                  <th key={`fdh1${i}`}>{item?.date?.split('-')[0]}</th>
                ))}
              {StatementTf.Quarter === tableTf &&
                financials?.data?.[`${statementTypeTable}${tableTf}`]?.map((item, i) => (
                  <th key={`fd1${i}`}>{`${item?.period} ${item?.filling_date.split('-')[0]}`}</th>
                ))}
            </tr>
          </thead>
          {statementTypeTable === FinancialDataType.BalanceSheet &&
            financials?.data?.[`${statementTypeTable}${tableTf}`] && (
              <tbody>
                {data.financialTableData.balanceSheet.map((definition, index) => (
                  <FinancialRow
                    isLoading={financials?.isLoading}
                    key={index}
                    label={definition.label}
                    dataKey={definition.key}
                    growthKey={definition.growth_key}
                    tableData={financials?.data?.[`${statementTypeTable}${tableTf}`]}
                  />
                ))}
              </tbody>
            )}
          {statementTypeTable === FinancialDataType.Income && financials?.data?.[`${statementTypeTable}${tableTf}`] && (
            <tbody>
              {data.financialTableData.income.map((definition, index) => (
                <FinancialRow
                  isLoading={financials?.isLoading}
                  key={index}
                  label={definition.label}
                  dataKey={definition.key}
                  growthKey={definition.growth_key}
                  tableData={financials?.data?.[`${statementTypeTable}${tableTf}`]}
                />
              ))}
            </tbody>
          )}
          {statementTypeTable === FinancialDataType.Cashflow &&
            financials?.data?.[`${statementTypeTable}${tableTf}`] && (
              <tbody>
                {data.financialTableData.cashFlow.map((definition, index) => (
                  <FinancialRow
                    isLoading={financials?.isLoading}
                    key={index}
                    label={definition.label}
                    dataKey={definition.key}
                    growthKey={definition.growth_key}
                    tableData={financials?.data?.[`${statementTypeTable}${tableTf}`]}
                  />
                ))}
              </tbody>
            )}
        </table>
      </div>
    </section>
  );
}
const FinancialRow = ({ label, dataKey, growthKey, tableData, isLoading }) => (
  <tr>
    {isLoading ? (
      <>
        <td>
          <Skeleton width={100} />
        </td>
        {tableData?.map((i) => (
          <td key={i}>
            <Skeleton />
          </td>
        ))}
      </>
    ) : (
      <>
        <td>{label}</td>
        {tableData?.map((item, i) => (
          <td key={`${dataKey}-${i}`}>
            <div>{item?.[dataKey]}</div>
            <div
              style={{
                color: item?.[growthKey] < 0 ? 'red' : 'green',
              }}>
              {(item?.[growthKey] * 100).toFixed(2) || 0}%
            </div>
          </td>
        ))}
      </>
    )}
  </tr>
);
