import React from 'react';

const data = [
  {
    id: 1,
    slNO: 'Quant (Equities & CFC)',
    topic: '$495 (January)',
    date: 'Inactive',
    queries: '04-01-2022',
    attachment: 'Pay',
  },
  {
    id: 2,
    slNO: 'Quant (Equities & CFC)',
    topic: '$495 (January)',
    date: 'Inactive',
    queries: '04-01-2022',
    attachment: 'Pay',
  },
  {
    id: 3,
    slNO: 'Quant (Equities & CFC)',
    topic: '$495 (January)',
    date: 'Inactive',
    queries: '04-01-2022',
    attachment: 'Pay',
  },
  {
    id: 4,
    slNO: 'Quant (Equities & CFC)',
    topic: '$495 (January)',
    date: 'Inactive',
    queries: '04-01-2022',
    attachment: 'Pay',
  },
  {
    id: 5,
    slNO: 'Quant (Equities & CFC)',
    topic: '$495 (January)',
    date: 'Inactive',
    queries: '04-01-2022',
    attachment: 'Pay',
  },
];

export default function AccountSubscription() {
  return (
    <section className='profile__subscription'>
      {/* Title */}
      <h1>Accounts & Subscription</h1>
      {/* Top Container */}
      <div className='profile__subscriptionTop'>
        {/* Top Left */}
        <div className='profile__subscriptionLeft'>
          <h2>Status</h2>
          <div className='profile__subscriptionLeft--box'>
            <h1>
              Active <span>(Current Plan)</span>
            </h1>
            <h1>114 days left</h1>
          </div>
        </div>
        {/* Top Right */}
        <div className='profile__subscriptionRight'>
          <div className='profile__boxLeft'>
            <div className='profile__boxData'>
              <h1>Plan :</h1>
              <h1>Quant (Equities & CFC)</h1>
            </div>
            <div className='profile__boxData'>
              <h1>Date :</h1>
              <h1>05-01-2022 (Monday)</h1>
            </div>
            <div className='profile__boxData'>
              <h1>Exp Date :</h1>
              <h1>04-01-2023 (Wednesday)</h1>
            </div>
          </div>
          <div className='profile__boxRight'>
            <h2>Price</h2>
            <h1>$ 595/m</h1>
          </div>
        </div>
      </div>
      <div className='profile__tableMain'>
        <table>
          <thead>
            <tr>
              <th>Plan</th>
              <th>Price</th>
              <th>Status</th>
              <th>End Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id}>
                <td title={item.slNO}>{item.slNO}</td>
                <td>{item.topic}</td>
                <td>{item.date}</td>
                <td>{item.queries}</td>
                <td>{item.attachment}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
}
