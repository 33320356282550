/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'src/components';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import validator from 'validator';
import { useCountdownTimer } from 'src/hooks/hooks';
import { useForm } from '@tanstack/react-form';
import { yupValidator } from '@tanstack/yup-form-adapter';
import TextField from 'src/components/InputElements/textField.component';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import {
  selectRegisterOtp,
  selectRegister,
  selectAuthorizationCred,
  selectResendCount,
} from 'src/pages/Authentication/auth.selector';
import {
  fetchOtpRegister,
  fetchRegister,
  updateCounter,
  setAuthorizationCred,
} from 'src/pages/Authentication/auth.reducer';
import { useSelector, useDispatch } from 'react-redux';
import { UserDataUpdate } from 'src/lib/auth';
import { MotionDiv } from 'src/components/motion/motion.component';
YupPassword(Yup);

declare module 'yup' {
  interface StringSchema {
    emailCheck(errorMessage: string): this;
    mobileCheck(errorMessage: string): this;
  }
}
Yup.addMethod(Yup.string, 'emailCheck', function (errorMessage) {
  return this.test('emailCheck', errorMessage, function (value) {
    const { path, createError } = this;
    return (typeof value === 'string' && validator.isEmail(value)) || createError({ path, message: errorMessage });
  });
});
Yup.addMethod(Yup.string, 'mobileCheck', function (errorMessage) {
  return this.test(` `, errorMessage, function (value) {
    const { path, createError } = this;
    if (typeof value === 'string')
      return validator.isMobilePhone(value) || createError({ path, message: errorMessage });
  });
});

function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const registerOtpData = useSelector(selectRegisterOtp);
  const registerData = useSelector(selectRegister);
  const resendTimeCount = useSelector(selectResendCount);
  const authorizationCreds = useSelector(selectAuthorizationCred); //intermediate creds
  const [seconds, resetTimer] = useCountdownTimer();

  const [error, setError] = useState('');
  const signUpInitiateForm = useForm({
    defaultValues: {
      email: '',
      mobile: { fullNumber: '', status: false },
    },
    onSubmit: async ({ value }) => signUpInitiateHandler(value),
    validatorAdapter: yupValidator(),
  });
  const signUpForm = useForm({
    defaultValues: {
      otp: '',
      username: authorizationCreds.username,
      password: '',
      confirmPassword: '',
    },
    onSubmit: async ({ value }) => signUpHandler(value),
    validatorAdapter: yupValidator(),
  });
  const getClassName = (isValid) => (isValid ? ' btn-disabled' : 'btn-primary');

  const signUpInitiateHandler = (values: { email: any; mobile: any }) => {
    const { email, mobile } = values;
    let mobileNum = mobile.fullNumber.replaceAll('-', '').replaceAll(' ', '');
    dispatch(fetchOtpRegister({ email, mobile_num: mobileNum }));
    dispatch(setAuthorizationCred({ ...authorizationCreds, email, mobile: mobileNum }));
    resetTimer(30);
  };
  const signUpHandler = (values: { otp: any; username: any; password: any; confirmPassword: any }) => {
    const { otp, username, password, confirmPassword } = values;
    if (password !== confirmPassword) {
      setError('Password & confirm should match');
      return;
    }
    dispatch(
      fetchRegister({
        username,
        otp_id: registerOtpData.data.otp_id,
        otp_num: otp,
        email: authorizationCreds.email,
        mobile: authorizationCreds.mobile,
        password,
      }),
    );

    dispatch(
      setAuthorizationCred({
        ...authorizationCreds,
        username,
      }),
    );
  };

  useEffect(() => {
    if (registerData?.data) {
      UserDataUpdate(registerData?.data, dispatch, navigate);
    }
  }, [registerData]);

  return (
    <section className='auth'>
      <div className='auth-container'>
        <div className='auth-header'>
          <h1>
            Create your <span>account</span>
          </h1>
          <p>We have more than 5,000,000 traders!</p>
        </div>
        {!registerOtpData?.data?.otp_id ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              signUpInitiateForm.handleSubmit();
            }}
            className='auth-form'>
            <div className='auth-form-ui '>
              <signUpInitiateForm.Field
                name='email'
                validators={{
                  onChange: Yup.string().emailCheck('Invalid email').required('Required'),
                }}
                children={(field) => {
                  return <TextField field={field} svg='email' type='email' placeholder='Email' />;
                }}
              />
              <signUpInitiateForm.Field
                name='mobile'
                validators={{
                  onChange: ({ value }) => {
                    if (!value.status) return 'Enter a valid mobile number';
                  },
                }}
                children={(field) => {
                  return (
                    <>
                      <IntlTelInput
                        fieldName={field.name}
                        onSelectFlag={(_v, _s, fullNumber, status) => {
                          field.handleChange({ status, fullNumber });
                        }}
                        onPhoneNumberChange={(status, _v, _c, fullNumber) => {
                          field.handleChange({ status, fullNumber });
                        }}
                        containerClassName='intl-tel-input'
                        inputClassName='form-control'
                      />
                      <div className='label'>
                        {field.state.meta.touchedErrors ? (
                          <span className='label-text-alt'>{field.state.meta.touchedErrors}</span>
                        ) : null}
                      </div>
                    </>
                  );
                }}
              />

              {error && <div className='auth-error'>{error}</div>}
              {registerOtpData.error && <div className='auth-error '>{registerOtpData.error.message}</div>}
            </div>
            <div className='auth-addtional-data'>
              <p className=''>
                Already have an account?{' '}
                <Link to='/login' className='color:$color-primary hover:underline'>
                  Sign In
                </Link>
              </p>
            </div>
            <signUpInitiateForm.Subscribe
              selector={(state) => state}
              children={({ isTouched, isFieldsValid }) => (
                <Button
                  type='submit'
                  disabled={!isFieldsValid}
                  className={`btn btn btn-rounded btn-lg ${getClassName(!isFieldsValid)}`}>
                  {registerOtpData.isLoading ? (
                    <div className='loader-container'>
                      <div className='loader' />
                    </div>
                  ) : (
                    'Create'
                  )}
                </Button>
              )}
            />
          </form>
        ) : (
          <MotionDiv
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ delay: 0.2, duration: 1.5, type: 'spring', stiffness: 80, damping: 20 }}
            className='auth-form'>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                signUpForm.handleSubmit();
              }}
              className='auth-form-ui'>
              <>
                <signUpForm.Field
                  name='username'
                  validators={{
                    onChange: Yup.string().required('Username is required'),
                  }}
                  children={(field) => {
                    return <TextField field={field} svg='user' placeholder='Username' />;
                  }}
                />
                <signUpForm.Field
                  name='otp'
                  validators={{
                    onChange: Yup.string()
                      .length(6, 'OTP must be of length 6')
                      .matches(/^\d+$/, 'OTP must contain only digits')
                      .required('OTP is required'),
                  }}
                  children={(field) => {
                    return <TextField field={field} svg='otp' placeholder='One Time Password' />;
                  }}
                />
                <signUpForm.Field
                  name='password'
                  validators={{
                    onChange: Yup.string()
                      .required('Password is required')
                      .min(
                        8,
                        'password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special',
                      )
                      .minLowercase(1, 'password must contain at least 1 lower case letter')
                      .minUppercase(1, 'password must contain at least 1 upper case letter')
                      .minNumbers(1, 'password must contain at least 1 number')
                      .minSymbols(1, 'password must contain at least 1 special character'),
                  }}
                  children={(field) => {
                    return <TextField field={field} type='password' svg='password' placeholder='Password' />;
                  }}
                />
                <signUpForm.Field
                  name='confirmPassword'
                  validators={{
                    onChange: Yup.string()
                      .required('Password is required')
                      .min(
                        8,
                        'password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special',
                      )
                      .minLowercase(1, 'password must contain at least 1 lower case letter')
                      .minUppercase(1, 'password must contain at least 1 upper case letter')
                      .minNumbers(1, 'password must contain at least 1 number')
                      .minSymbols(1, 'password must contain at least 1 special character'),
                  }}
                  children={(field) => {
                    return <TextField field={field} type='password' svg='password' placeholder='Confirm Password' />;
                  }}
                />

                {error && <div className='auth-error'>{error}</div>}
                {registerData.error && <div className='auth-error '>{registerData.error.message}</div>}
              </>
              <div className='auth-form-action'>
                <button
                  type='button'
                  className={`btn btn-rounded btn-lg ${getClassName(seconds || resendTimeCount >= 3)}`}
                  disabled={Boolean(seconds || resendTimeCount >= 3)}
                  onClick={() => {
                    dispatch(
                      fetchRegister({
                        email: authorizationCreds.email,
                        mobile_num: authorizationCreds.mobile,
                      }),
                    );
                    dispatch(updateCounter());
                    resetTimer(30);
                  }}>
                  Resend{' '}
                  {seconds > 0 ? (
                    <span className='countdown'>
                      <span style={{ '--value': seconds } as React.CSSProperties} />
                    </span>
                  ) : (
                    ''
                  )}
                </button>
                <signUpForm.Subscribe
                  selector={(state) => state}
                  children={({ isTouched, isFieldsValid }) => (
                    <Button
                      type='submit'
                      disabled={!(isTouched && isFieldsValid)}
                      className={`btn btn btn-rounded btn-lg  ${getClassName(!(isTouched && isFieldsValid))}`}>
                      {registerData.isLoading ? (
                        <div className='loader-contianer'>
                          <div className='loader' />
                        </div>
                      ) : (
                        'Submit'
                      )}
                    </Button>
                  )}
                />
              </div>
            </form>
          </MotionDiv>
        )}
        <div className='auth-add-info'>
          <p>
            By clicking continue,
            <br /> you agree to
            <span>Our Terms & Conditions.</span>
          </p>
        </div>
      </div>
    </section>
  );
}

export default Register;
