import { put, takeLatest } from 'redux-saga/effects';
import { postTickerSearch } from 'src/repositories/mgmt/mgmt';
import { fetchSearch, fetchSearchFailure, fetchSearchSuccess } from './reducer';

function* fetchSearchSaga(action) {
  try {
    const data = yield postTickerSearch(action.payload).then((response) => response);
    yield put(fetchSearchSuccess(data?.data?.data));
  } catch (error) {
    yield put(fetchSearchFailure(error));
  }
}

export function* watchSearchSaga() {
  yield takeLatest(fetchSearch.type, fetchSearchSaga);
}
