import { put, takeLatest } from 'redux-saga/effects';
import {
  fetchSubscriptionMetaData,
  fetchSubscriptionMetaDataFailure,
  fetchSubscriptionMetaDataSuccess,
  fetchSubscriptionCheckout,
  fetchSubscriptionCheckoutFailure,
  fetchSubscriptionCheckoutSuccess,
} from './subscription.reducer';
import { getSubscriptionMetaData, postSubscriptionData } from 'src/repositories/subscription/subscription';
import { IError } from 'src/lib/types/error';

function* fetchSubscriptionMetaDataSaga(action) {
  try {
    const data = yield getSubscriptionMetaData(action.payload).then((response) => response);
    yield put(fetchSubscriptionMetaDataSuccess(data.data.data));
  } catch (error) {
    yield put(fetchSubscriptionMetaDataFailure(error as IError));
  }
}

export function* watchSubscriptionMetaDataSaga() {
  yield takeLatest(fetchSubscriptionMetaData.type, fetchSubscriptionMetaDataSaga);
}

function* fetchSubscriptionCheckoutSaga(action) {
  try {
    const data = yield postSubscriptionData(action.payload).then((response) => response);
    yield put(fetchSubscriptionCheckoutSuccess(data.data.data));
  } catch (error) {
    yield put(fetchSubscriptionCheckoutFailure(error as IError));
  }
}

export function* watchSubscriptionCheckoutSaga() {
  yield takeLatest(fetchSubscriptionCheckout.type, fetchSubscriptionCheckoutSaga);
}
