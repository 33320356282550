import { FC } from 'react';
import { IButtonProps } from 'src/lib/types/button';

export const Button: FC<IButtonProps> = ({ label, isLoading = false, children, disabled, ...otherProps }) => {
  return (
    <button
      disabled={disabled || isLoading}
      {...otherProps}
      className={`btn ${isLoading ? 'btn-loading' : ''} ${otherProps.className || ''}`}>
      {isLoading ? 'Loading...' : children! ? children : label}
    </button>
  );
};
