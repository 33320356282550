import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from 'src/lib/types/error';
import { IMarketResp } from 'src/lib/types/markets';

const INITIAL_STATE: IMarketResp = {
  isLoading: false,
  data: null,
  error: null,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState: INITIAL_STATE,
  reducers: {
    fetchProfile(state) {
      state.isLoading = true;
    },
    fetchProfileSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchProfileFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});
export const { fetchProfile, fetchProfileSuccess, fetchProfileFailure } = profileSlice.actions;
export const profileReducer = profileSlice.reducer;

const postProfileSlice = createSlice({
  name: 'profileUpdate',
  initialState: INITIAL_STATE,
  reducers: {
    postProfile(state, action) {
      state.isLoading = true;
    },
    postProfileSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    postProfileFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});
export const { postProfile, postProfileSuccess, postProfileFailure } = postProfileSlice.actions;
export const postProfileReducer = postProfileSlice.reducer;

const emailUpdateInitiateSlice = createSlice({
  name: 'emailUpdateInit',
  initialState: INITIAL_STATE,
  reducers: {
    emailUpdateInitiate(state, action) {
      state.isLoading = true;
    },
    emailUpdateInitiateSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    emailUpdateInitiateFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});
export const { emailUpdateInitiate, emailUpdateInitiateSuccess, emailUpdateInitiateFailure } =
  emailUpdateInitiateSlice.actions;
export const emailUpdateInitiateReducer = emailUpdateInitiateSlice.reducer;

const emailUpdateSlice = createSlice({
  name: 'emailupdate',
  initialState: INITIAL_STATE,
  reducers: {
    emailUpdate(state, action) {
      state.isLoading = true;
    },
    emailUpdateSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    emailUpdateFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});
export const { emailUpdate, emailUpdateSuccess, emailUpdateFailure } = emailUpdateSlice.actions;
export const emailUpdateReducer = emailUpdateSlice.reducer;

const passwordUpdateInitiateSlice = createSlice({
  name: 'passwordUpdateInit',
  initialState: INITIAL_STATE,
  reducers: {
    passwordUpdateInitiate(state, action) {
      state.isLoading = true;
    },
    passwordUpdateInitiateSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    passwordUpdateInitiateFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});
export const { passwordUpdateInitiate, passwordUpdateInitiateSuccess, passwordUpdateInitiateFailure } =
  passwordUpdateInitiateSlice.actions;
export const passwordUpdateInitiateReducer = passwordUpdateInitiateSlice.reducer;

const passwordUpdateSlice = createSlice({
  name: 'passwordupdate',
  initialState: INITIAL_STATE,
  reducers: {
    passwordUpdate(state, action) {
      state.isLoading = true;
    },
    passwordUpdateSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    passwordUpdateFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});
export const { passwordUpdate, passwordUpdateSuccess, passwordUpdateFailure } = passwordUpdateSlice.actions;
export const passwordUpdateReducer = passwordUpdateSlice.reducer;

const profilePicUpdateSlice = createSlice({
  name: 'profilePicUpdate',
  initialState: INITIAL_STATE,
  reducers: {
    profilePicUpdate(state, action) {
      state.isLoading = true;
    },
    profilePicUpdateSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    profilePicUpdateFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});
export const { profilePicUpdate, profilePicUpdateSuccess, profilePicUpdateFailure } = profilePicUpdateSlice.actions;
export const profilePicUpdateReducer = profilePicUpdateSlice.reducer;
