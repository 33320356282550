import React from 'react';
import Input from 'src/components/InputElements/input';
import { useForm } from '@tanstack/react-form';
import TextField from 'src/components/InputElements/textField.component';
import { Dialog, DialogContent, DialogClose, DialogTrigger } from 'src/components/Modal/dialogbox';
import { Button } from 'src/components';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfilePassword, selectProfilePasswordInit } from 'src/pages/Profile/profile.selector';
import { passwordUpdate, passwordUpdateInitiate } from 'src/pages/Profile/profile.reducer';

interface IPwdForm {
  otp: string;
  current_password: string;
  create_password: string;
  repeat_password: string;
}

export default function UpdatePassword() {
  const dispatch = useDispatch();
  const otpId = useSelector(selectProfilePasswordInit);
  const pwdUpdateStatus = useSelector(selectProfilePassword);

  const passwordForm = useForm<IPwdForm>({
    defaultValues: {
      otp: '',
      current_password: '',
      create_password: '',
      repeat_password: '',
    },
    onSubmit: async ({ value }) => {
      dispatch(
        passwordUpdate({
          current_password: value.current_password,
          create_password: value.create_password,
          repeat_password: value.repeat_password,
          otp_id: otpId?.data,
          otp_val: value.otp,
        }),
      );
    },
  });
  return (
    <Dialog>
      <div className='profile-pwd-update-btn-wrapper'>
        <Input type='password' name='password' disabled value='000000000' />
        <DialogTrigger className='btn profile-pwd-update-btn' onClick={() => dispatch(passwordUpdateInitiate({}))}>
          Update Password
        </DialogTrigger>
      </div>
      <DialogContent className='profile-pwdmodal'>
        <h1>Update your password</h1>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            passwordForm.handleSubmit();
          }}>
          <div>
            <passwordForm.Field
              name='otp'
              validators={{
                onChange: ({ value }) => (!value ? 'Otp is required' : null),
              }}
              children={(field) => <TextField field={field} placeholder='One Time Password' />}
            />

            <passwordForm.Field
              name='current_password'
              validators={{
                onChange: ({ value }) => (!value ? 'Current password is required' : null),
              }}
              children={(field) => <TextField field={field} placeholder='Current Password' />}
            />
            <passwordForm.Field
              name='create_password'
              validators={{
                onChange: ({ value }) => (!value ? 'New password is required' : null),
              }}
              children={(field) => <TextField field={field} placeholder='New Password' />}
            />
            <passwordForm.Field
              name='repeat_password'
              validators={{
                onChange: ({ value }) => (!value ? 'Confim password is required' : null),
              }}
              children={(field) => <TextField field={field} placeholder='Confirm Password' />}
            />
          </div>
          {pwdUpdateStatus?.data ? <h2 className='ca-nodata'>Password Updated Successfully</h2> : ''}
          {pwdUpdateStatus?.error ? <h2 className='ca-nodata'>Password Updation Failed</h2> : ''}
          <div className='profile-pwdmodal-btns'>
            {pwdUpdateStatus?.data || pwdUpdateStatus?.error ? (
              <DialogClose className='btn-grey btn-rounded btn-lg btn'>Close</DialogClose>
            ) : (
              <>
                <DialogClose className='btn-grey btn-rounded btn-lg btn'>Cancel</DialogClose>
                <Button label='Update' type='submit' className='btn-primary btn-rounded btn-lg ' />
              </>
            )}
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}
