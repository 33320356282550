import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, PrimaryHeader, TextEditor, ModalWrapper, Select } from 'src/components';
import { useSelector, useDispatch } from 'react-redux';
import { selectClubMetaData, selectClubPostStatus } from '../club.selector';
import { fetchClubMetaData, postClubPost } from '../club.reducer';
import * as Yup from 'yup';

function Post() {
  const navigate = useNavigate();
  const [successModal, setSuccessModal] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [category, setCategory] = useState('');
  const [headline, setHeadline] = useState('');
  const [content, setContent] = useState('');
  const [errors, setErrors] = useState<{ category?: string; headline?: string; content?: string }>({});
  const metaData = useSelector(selectClubMetaData);
  const postStatus = useSelector(selectClubPostStatus);
  const dispatch = useDispatch();
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    category: Yup.string().required('Category is required'),
    headline: Yup.string().required('Headline is required'),
    content: Yup.string().required('Content is required'),
  });

  const validateForm = async () => {
    try {
      await validationSchema.validate({ category, headline, content }, { abortEarly: false });
      setErrors({});
      return true;
    } catch (err) {
      const validationErrors: { category?: string; headline?: string; content?: string } = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          if (error.path) {
            validationErrors[error.path] = error.message;
          }
        });
      }
      setErrors(validationErrors);
      return false;
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files?.[0];
    if (fileUploaded) {
      setFile(fileUploaded);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const isValid = await validateForm();
    if (!isValid) return;

    const formData = new FormData();
    if (file) {
      formData.append('files', file);
    }
    formData.append('category', category);
    formData.append('headline', headline);
    formData.append('details', content);
    dispatch(postClubPost(formData));
    setSuccessModal(true);
  };

  useEffect(() => {
    dispatch(fetchClubMetaData());
  }, [dispatch]);

  return (
    <main className='navbar-fix'>
      <div className='post'>
        <PrimaryHeader text='iFIR$T Club' className='color-primary' />
        <div className='post__main'>
          <h1>Create A Post</h1>
          <div className='post__bannerImg'>
            <img src={file ? URL.createObjectURL(file) : ''} alt='' />
          </div>
          <form className='post__formInputs'>
            <div className='post__formInputsDropzone' onClick={() => hiddenFileInput.current?.click()}>
              <label htmlFor='files'>
                <p>Add files or Drop files here</p>
              </label>
              <input
                type='file'
                accept='image/jpeg, image/png'
                name='files'
                hidden
                ref={hiddenFileInput}
                onChange={handleFileChange}
                required
              />
            </div>

            <div className='post__formInputsItems'>
              <label>Category</label>
              <Select options={metaData?.data?.categories} onChange={(e) => setCategory(e)} className='w-full' />
              {errors.category && <p className='error-message'>{errors.category}</p>}
            </div>

            <div className='post__formInputsItems'>
              <label>Headline</label>
              <input type='text' name='headline' value={headline} onChange={(e) => setHeadline(e.target.value)} />
              {errors.headline && <p className='error-message'>{errors.headline}</p>}
            </div>

            <div className='post__formInputsItems textEditor'>
              <label>Details</label>
              <TextEditor setContent={setContent} />
              {errors.content && <p className='error-message'>{errors.content}</p>}
            </div>

            <div className='post__button'>
              <Button type='submit' className='btn btn-primary btn-rounded' onClick={handleSubmit}>
                Publish
              </Button>
            </div>
          </form>

          <ModalWrapper open={successModal} onClose={() => setSuccessModal(false)}>
            <div className='postSuccessModal'>
              <h1>Post Submitted Successfully!</h1>
              <Button
                className='btn-primary btn-rounded'
                label='OK'
                onClick={() => {
                  setSuccessModal(false);
                  navigate(`/club/${postStatus?.data?.id}`);
                }}
              />
            </div>
          </ModalWrapper>
        </div>
      </div>
    </main>
  );
}

export default Post;
