import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { Button } from '../Button/button.component';
import { Link } from 'react-router-dom';
import { bannerWatchUs, bannerGlobe } from 'src/assets/Images';
import { MotionDiv } from '../motion/motion.component';
export default function Banner() {
  const [isVisible, setIsVisible] = useState(false);
  const isVisibleProp = (visible: boolean, prop1, prop2) => (visible ? prop1 : prop2);
  return (
    <VisibilitySensor partialVisibility onChange={setIsVisible}>
      <section className='banner'>
        <MotionDiv
          className='banner__main'
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: isVisibleProp(isVisible, 1, 0), y: isVisibleProp(isVisible, 0, -50) }}
          transition={{ duration: 2, ease: 'easeInOut' }}>
          <div className='banner__left'>
            <MotionDiv
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: isVisibleProp(isVisible, 1, 0), y: isVisibleProp(isVisible, 0, -20) }}
              transition={{ duration: 2, ease: 'easeInOut', delay: 0.5 }}>
              <h1>Most Trusted</h1>
            </MotionDiv>
            <MotionDiv
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: isVisibleProp(isVisible, 1, 0), y: isVisibleProp(isVisible, 0, -20) }}
              transition={{ duration: 2, ease: 'easeInOut', delay: 1 }}>
              <h1>Trading Advisor</h1>
            </MotionDiv>
            <MotionDiv
              initial={{ opacity: 0, x: -200 }}
              animate={{ opacity: isVisibleProp(isVisible, 1, 0), x: isVisibleProp(isVisible, 0, -200) }}
              transition={{ duration: 2, ease: 'easeInOut', delay: 1.5 }}>
              <h2>Individual Traders and Professional Institutions.</h2>
            </MotionDiv>
            <MotionDiv
              className='banner__buttons'
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: isVisibleProp(isVisible, 1, 0), y: isVisibleProp(isVisible, 0, -20) }}
              transition={{ duration: 2, ease: 'easeInOut', delay: 2 }}>
              <div className='banner__buttons'>
                <Link to='/register'>
                  <Button label='Get Started' className='btn-primary btn-lg btn-rounded Adjustments' />
                </Link>
                <div className='banner__buttons--watchUs'>
                  <img src={bannerWatchUs} alt='watchUs' />
                  <button type='button'>Watch Us</button>
                </div>
              </div>
            </MotionDiv>
          </div>
          <div className='banner__right'>
            <img src={bannerGlobe} alt='globe' />
          </div>
        </MotionDiv>
      </section>
    </VisibilitySensor>
  );
}
