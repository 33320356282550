import { put, takeLatest } from 'redux-saga/effects';
import {
  postStockScreenerData,
  postCommodtScreenerData,
  postForexScreenerData,
  postCryptoScreenerData,
  getHeatMapMetaData,
  postForexScreenerColumnData,
  postCryptoScreenerColumnData,
  postCommodtScreenerColumnData,
  postStockScreenerColumnData,
} from 'src/repositories/screener/screener';
import {
  fetchHeatmap,
  fetchHeatmapFailure,
  fetchHeatmapMetaDataSuccess,
  fetchHeatmapSuccess,
  fetchHeatmapMetaDataFailure,
  fetchHeatmapMetaData,
  fetchHeatmapFilterData,
  fetchHeatmapFilterDataSuccess,
  fetchHeatmapFilterDataFailure,
} from './heatmap.reducer';
import { IError } from 'src/lib/types/error';
function* fetchHeatmapData(action) {
  try {
    let json;
    if (action.payload.market_type === 'UsStocks')
      json = yield postStockScreenerData(action.payload).then((response) => response);
    if (action.payload.market_type === 'Crypto')
      json = yield postCryptoScreenerData(action.payload).then((response) => response);
    if (action.payload.market_type === 'Commodts')
      json = yield postCommodtScreenerData(action.payload).then((response) => response);
    if (action.payload.market_type === 'Fx')
      json = yield postForexScreenerData(action.payload).then((response) => response);
    yield put(fetchHeatmapSuccess(json.data.data));
  } catch (error) {
    yield put(fetchHeatmapFailure(error as IError));
  }
}

export function* watchHeatmapDataSaga() {
  yield takeLatest(fetchHeatmap.type, fetchHeatmapData);
}

function* fetchHeatmapMetadata(_action) {
  try {
    const json = yield getHeatMapMetaData().then((response) => response);
    yield put(fetchHeatmapMetaDataSuccess(json.data.data));
  } catch (error) {
    yield put(fetchHeatmapMetaDataFailure(error as IError));
  }
}

export function* watchHeatmapMetaDataSaga() {
  yield takeLatest(fetchHeatmapMetaData.type, fetchHeatmapMetadata);
}

function* fetchHeatmapFilter(action) {
  try {
    let json;
    if (action.payload.market_type === 'UsStocks')
      json = yield postStockScreenerColumnData(action.payload).then((response) => response);
    if (action.payload.market_type === 'Crypto')
      json = yield postCryptoScreenerColumnData(action.payload).then((response) => response);
    if (action.payload.market_type === 'Commodts')
      json = yield postCommodtScreenerColumnData(action.payload).then((response) => response);
    if (action.payload.market_type === 'Fx')
      json = yield postForexScreenerColumnData(action.payload).then((response) => response);

    yield put(fetchHeatmapFilterDataSuccess(json.data.data));
  } catch (error) {
    yield put(fetchHeatmapFilterDataFailure(error as IError));
  }
}

export function* watchHeatmapFilterDataSaga() {
  yield takeLatest(fetchHeatmapFilterData.type, fetchHeatmapFilter);
}
