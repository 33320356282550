import { put, takeLatest } from 'redux-saga/effects';
import {
  postInitiateSignin,
  postSignin,
  postInitiateForgotPassword,
  postForgotPassword,
  postSignup,
  postInitiateSignup,
} from 'src/repositories/mgmt/mgmt';
import {
  fetchOtpLogin,
  fetchOtpLoginFailure,
  fetchOtpLoginSuccess,
  fetchLogin,
  fetchLoginSuccess,
  fetchLoginFailure,
  fetchOtpRegister,
  fetchOtpRegisterFailure,
  fetchOtpRegisterSuccess,
  fetchRegister,
  fetchRegisterSuccess,
  fetchRegisterFailure,
  fetchOtpForgotPass,
  fetchOtpForgotPassFailure,
  fetchOtpForgotPassSuccess,
  fetchForgotPass,
  fetchForgotPassSuccess,
  fetchForgotPassFailure,
} from './auth.reducer';
import { IError } from 'src/lib/types/error';

function* fetchOtpLoginSaga(action) {
  try {
    const data = yield postInitiateSignin(action.payload).then((response) => response);
    yield put(fetchOtpLoginSuccess(data.data.data));
  } catch (error) {
    yield put(fetchOtpLoginFailure(error as IError));
  }
}

function* fetchLoginSaga(action) {
  try {
    const data = yield postSignin(action.payload).then((response) => response);
    yield put(fetchLoginSuccess(data.data.data));
  } catch (error) {
    yield put(fetchLoginFailure(error as IError));
  }
}

export function* watchOtpLoginSaga() {
  yield takeLatest(fetchOtpLogin.type, fetchOtpLoginSaga);
}

export function* watchLoginSaga() {
  yield takeLatest(fetchLogin.type, fetchLoginSaga);
}

function* fetchOtpRegisterSaga(action) {
  try {
    const data = yield postInitiateSignup(action.payload).then((response) => response);
    yield put(fetchOtpRegisterSuccess(data.data.data));
  } catch (error) {
    yield put(fetchOtpRegisterFailure(error as IError));
  }
}

function* fetchRegisterSaga(action) {
  try {
    const data = yield postSignup(action.payload).then((response) => response);
    yield put(fetchRegisterSuccess(data.data.data));
  } catch (error) {
    yield put(fetchRegisterFailure(error as IError));
  }
}

export function* watchOtpRegisterSaga() {
  yield takeLatest(fetchOtpRegister.type, fetchOtpRegisterSaga);
}

export function* watchRegisterSaga() {
  yield takeLatest(fetchRegister.type, fetchRegisterSaga);
}

function* fetchOtpForgotPassSaga(action) {
  try {
    const data = yield postInitiateForgotPassword(action.payload).then((response) => response);
    yield put(fetchOtpForgotPassSuccess(data.data.data));
  } catch (error) {
    yield put(fetchOtpForgotPassFailure(error as IError));
  }
}

function* fetchForgotPassSaga(action) {
  try {
    const data = yield postForgotPassword(action.payload).then((response) => response);
    yield put(fetchForgotPassSuccess(data.data.data));
  } catch (error) {
    yield put(fetchForgotPassFailure(error as IError));
  }
}

export function* watchOtpForgotPassSaga() {
  yield takeLatest(fetchOtpForgotPass.type, fetchOtpForgotPassSaga);
}

export function* watchForgotPassSaga() {
  yield takeLatest(fetchForgotPass.type, fetchForgotPassSaga);
}
