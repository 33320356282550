import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from 'src/lib/types/error';
import { IWatchlistResp, IWatchlistStockSplit } from 'src/lib/types/watchlist';
const INITIAL_STATE: IWatchlistResp = {
  isLoading: false,
  data: null,
  error: null,
};

const watchlistOverviewSlice = createSlice({
  name: 'watchlistOverview',
  initialState: INITIAL_STATE,
  reducers: {
    fetchWatchlistOverview(state, action: PayloadAction<any>) {
      state.isLoading = true;
      state.data = null;
    },
    fetchWatchlistOverviewSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchWatchlistOverviewFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchWatchlistOverview, fetchWatchlistOverviewSuccess, fetchWatchlistOverviewFailure } =
  watchlistOverviewSlice.actions;
export const watchlistOverviewReducer = watchlistOverviewSlice.reducer;

const watchlistRatioSlice = createSlice({
  name: 'watchlistRatio',
  initialState: INITIAL_STATE,
  reducers: {
    fetchWatchlistRatio(state, action: PayloadAction<any>) {
      state.isLoading = true;
      state.data = null;
    },
    fetchWatchlistRatioSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchWatchlistRatioFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchWatchlistRatio, fetchWatchlistRatioSuccess, fetchWatchlistRatioFailure } =
  watchlistRatioSlice.actions;
export const watchlistRatioReducer = watchlistRatioSlice.reducer;

const watchlistEsgSlice = createSlice({
  name: 'watchlistEsg',
  initialState: INITIAL_STATE,
  reducers: {
    fetchWatchlistEsg(state, action: PayloadAction<any>) {
      state.isLoading = true;
      state.data = null;
    },
    fetchWatchlistEsgSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchWatchlistEsgFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchWatchlistEsg, fetchWatchlistEsgSuccess, fetchWatchlistEsgFailure } = watchlistEsgSlice.actions;
export const watchlistEsgReducer = watchlistEsgSlice.reducer;

const watchlistFinancialSlice = createSlice({
  name: 'watchlistFinancial',
  initialState: INITIAL_STATE,
  reducers: {
    fetchWatchlistFinancial(state, action: PayloadAction<any>) {
      state.isLoading = true;
      state.data = null;
    },
    fetchWatchlistFinancialSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchWatchlistFinancialFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchWatchlistFinancial, fetchWatchlistFinancialSuccess, fetchWatchlistFinancialFailure } =
  watchlistFinancialSlice.actions;
export const watchlistFinancialReducer = watchlistFinancialSlice.reducer;

const watchlistDividendSlice = createSlice({
  name: 'watchlistDividend',
  initialState: INITIAL_STATE,
  reducers: {
    fetchWatchlistDividend(state, action: PayloadAction<any>) {
      state.isLoading = true;
      state.data = null;
    },
    fetchWatchlistDividendSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchWatchlistDividendFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchWatchlistDividend, fetchWatchlistDividendSuccess, fetchWatchlistDividendFailure } =
  watchlistDividendSlice.actions;
export const watchlistDividendReducer = watchlistDividendSlice.reducer;

const watchlistStockSplitSlice = createSlice({
  name: 'watchlistStockSplit',
  initialState: INITIAL_STATE,
  reducers: {
    fetchWatchlistStockSplit(state, action: PayloadAction<any>) {
      state.isLoading = true;
      state.data = null;
    },
    fetchWatchlistStockSplitSuccess(state, action: PayloadAction<IWatchlistStockSplit>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchWatchlistStockSplitFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchWatchlistStockSplit, fetchWatchlistStockSplitSuccess, fetchWatchlistStockSplitFailure } =
  watchlistStockSplitSlice.actions;
export const watchlistStockSplitReducer = watchlistStockSplitSlice.reducer;

const watchlistAnalystEstimateSlice = createSlice({
  name: 'watchlistAnalystEstimate',
  initialState: INITIAL_STATE,
  reducers: {
    fetchWatchlistAnalystEstimate(state, action: PayloadAction<any>) {
      state.isLoading = true;
      state.data = null;
    },
    fetchWatchlistAnalystEstimateSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchWatchlistAnalystEstimateFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const {
  fetchWatchlistAnalystEstimate,
  fetchWatchlistAnalystEstimateSuccess,
  fetchWatchlistAnalystEstimateFailure,
} = watchlistAnalystEstimateSlice.actions;
export const watchlistAnalystEstimateReducer = watchlistAnalystEstimateSlice.reducer;

const watchlistAnalystRecommendationSlice = createSlice({
  name: 'watchlistAnalystRecommendation',
  initialState: INITIAL_STATE,
  reducers: {
    fetchWatchlistAnalystRecommendation(state, action: PayloadAction<any>) {
      state.isLoading = true;
      state.data = null;
    },
    fetchWatchlistAnalystRecommendationSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchWatchlistAnalystRecommendationFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const {
  fetchWatchlistAnalystRecommendation,
  fetchWatchlistAnalystRecommendationSuccess,
  fetchWatchlistAnalystRecommendationFailure,
} = watchlistAnalystRecommendationSlice.actions;
export const watchlistAnalystRecommendationReducer = watchlistAnalystRecommendationSlice.reducer;
const watchlistOwnershipSlice = createSlice({
  name: 'watchlistOwnership',
  initialState: INITIAL_STATE,
  reducers: {
    fetchWatchlistOwnership(state, action: PayloadAction<any>) {
      state.isLoading = true;
      state.data = null;
    },
    fetchWatchlistOwnershipSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchWatchlistOwnershipFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchWatchlistOwnership, fetchWatchlistOwnershipSuccess, fetchWatchlistOwnershipFailure } =
  watchlistOwnershipSlice.actions;
export const watchlistOwnershipReducer = watchlistOwnershipSlice.reducer;
const watchlistRatingsSlice = createSlice({
  name: 'watchlistRatings',
  initialState: INITIAL_STATE,
  reducers: {
    fetchWatchlistRatings(state, action: PayloadAction<any>) {
      state.isLoading = true;
      state.data = null;
    },
    fetchWatchlistRatingsSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchWatchlistRatingsFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchWatchlistRatings, fetchWatchlistRatingsSuccess, fetchWatchlistRatingsFailure } =
  watchlistRatingsSlice.actions;
export const watchlistRatingsReducer = watchlistRatingsSlice.reducer;
const watchlistSocialSentimentSlice = createSlice({
  name: 'watchlistSocialSentiment',
  initialState: INITIAL_STATE,
  reducers: {
    fetchWatchlistSocialSentiment(state, action: PayloadAction<any>) {
      state.isLoading = true;
      state.data = null;
    },
    fetchWatchlistSocialSentimentSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchWatchlistSocialSentimentFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const {
  fetchWatchlistSocialSentiment,
  fetchWatchlistSocialSentimentSuccess,
  fetchWatchlistSocialSentimentFailure,
} = watchlistSocialSentimentSlice.actions;
export const watchlistSocialSentimentReducer = watchlistSocialSentimentSlice.reducer;

const watchlistPressReleaseSlice = createSlice({
  name: 'watchlistPressRelease',
  initialState: INITIAL_STATE,
  reducers: {
    fetchWatchlistPressRelease(state, action: PayloadAction<any>) {
      state.isLoading = true;
      state.data = null;
    },
    fetchWatchlistPressReleaseSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchWatchlistPressReleaseFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchWatchlistPressRelease, fetchWatchlistPressReleaseSuccess, fetchWatchlistPressReleaseFailure } =
  watchlistPressReleaseSlice.actions;
export const watchlistPressReleaseReducer = watchlistPressReleaseSlice.reducer;

const watchlistNewsSlice = createSlice({
  name: 'watchlistNews',
  initialState: INITIAL_STATE,
  reducers: {
    fetchWatchlistNews(state, action: PayloadAction<any>) {
      state.isLoading = true;
      state.data = null;
    },
    fetchWatchlistNewsSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchWatchlistNewsFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchWatchlistNews, fetchWatchlistNewsSuccess, fetchWatchlistNewsFailure } = watchlistNewsSlice.actions;
export const watchlistNewsReducer = watchlistNewsSlice.reducer;

const watchlistBarsSlice = createSlice({
  name: 'watchlistBars',
  initialState: INITIAL_STATE,
  reducers: {
    fetchWatchlistBars(state, action: PayloadAction<any>) {
      state.isLoading = true;
      state.data = null;
    },
    fetchWatchlistBarsSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchWatchlistBarsFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchWatchlistBars, fetchWatchlistBarsSuccess, fetchWatchlistBarsFailure } = watchlistBarsSlice.actions;
export const watchlistBarsReducer = watchlistBarsSlice.reducer;
