import React, { useState } from 'react';
import { useForm } from '@tanstack/react-form';
import TextField from 'src/components/InputElements/textField.component';
import { Button, ModalWrapper } from 'src/components';
import { useSelector } from 'react-redux';
import { selectProfile, selectProfileUpdate } from 'src/pages/Profile/profile.selector';
import { useDispatch } from 'react-redux';
import { postProfile } from 'src/pages/Profile/profile.reducer';

interface TicketFormValues {
  username: string;
  location: string;
  phone: string;
  aboutMe: string;
  facebookprofile: string;
  instagramprofile: string;
  youtubeprofile: string;
  website: string;
  name: string;
  company: string;
  street: string;
  state: string;
  city: string;
  country: string;
  zip: string;
}
export default function ProfileForm() {
  const [modalState, setModalState] = useState(true);
  const profileData = useSelector(selectProfile);
  const profileUpdate = useSelector(selectProfileUpdate);
  const dispatch = useDispatch();
  const profileForm = useForm<TicketFormValues>({
    defaultValues: {
      username: profileData?.data?.user_name || '',
      location: profileData?.data?.location || '',
      aboutMe: profileData?.data?.about_me || '',
      facebookprofile: profileData?.data?.facebook_profile || '',
      instagramprofile: profileData?.data?.instagram_profile || '',
      youtubeprofile: profileData?.data?.youtube_profile || '',
      website: profileData?.data?.website || '',
      name: profileData?.data?.name || '',
      company: profileData?.data?.company || '',
      street: profileData?.data?.street || '',
      city: profileData?.data?.city || '',
      state: profileData?.data?.state || '',
      country: profileData?.data?.country || '',
      zip: profileData?.data?.zip || '',
      phone: profileData?.data?.phone || '',
    },
    onSubmit: async ({ value }) => {
      const filteredValues = Object.fromEntries(
        Object.entries(value).filter(([_, v]) => v !== '' && v !== 0 && v !== null && v !== undefined),
      );
      dispatch(postProfile(filteredValues));
      setModalState(true);
    },
  });

  return (
    <>
      <form
        className='profile-form-container'
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          profileForm.handleSubmit();
        }}>
        <div className='profile-form'>
          <div className='profile-form-sections'>
            <profileForm.Field
              name='username'
              children={(field) => <TextField field={field} placeholder='Username' disabled />}
            />
            <profileForm.Field
              name='location'
              children={(field) => <TextField field={field} placeholder='Location' />}
            />
            <profileForm.Field
              name='aboutMe'
              children={(field) => <TextField field={field} placeholder='About Me' />}
            />
            <profileForm.Field
              name='facebookprofile'
              children={(field) => <TextField field={field} placeholder='Facebook Profile' />}
            />
            <profileForm.Field
              name='instagramprofile'
              children={(field) => <TextField field={field} placeholder='Instagram Profile' />}
            />
            <profileForm.Field
              name='youtubeprofile'
              children={(field) => <TextField field={field} placeholder='Youtube Profile' />}
            />
            <profileForm.Field name='website' children={(field) => <TextField field={field} placeholder='Website' />} />
          </div>
          <div className='profile-form-sections'>
            <profileForm.Field name='name' children={(field) => <TextField field={field} placeholder='Name' />} />
            <profileForm.Field name='phone' children={(field) => <TextField field={field} placeholder='Phone' />} />
            <profileForm.Field name='company' children={(field) => <TextField field={field} placeholder='Company' />} />
            <profileForm.Field name='street' children={(field) => <TextField field={field} placeholder='Street' />} />
            <profileForm.Field name='city' children={(field) => <TextField field={field} placeholder='City' />} />
            <profileForm.Field
              name='state'
              children={(field) => <TextField field={field} placeholder='State/Region' />}
            />
            <profileForm.Field name='country' children={(field) => <TextField field={field} placeholder='Country' />} />
            <profileForm.Field name='zip' children={(field) => <TextField field={field} placeholder='Zip' />} />
          </div>
        </div>

        <Button label='save' type='submit' className='btn-primary btn-lg ' />
      </form>
      <ModalWrapper open={Boolean(modalState && (profileUpdate?.data || profileUpdate?.error))} onClose={setModalState}>
        <div className='modal__PositiveContainer'>
          {profileUpdate?.data ? <h2>Profile Updated Successfully!</h2> : null}
          {profileUpdate?.error ? <h2>Profile Updation Failed!</h2> : null}
          <div className='modal__PositiveBtns'>
            <Button
              onClick={() => setModalState(!modalState)}
              label='Okay'
              className='btn-primary btn-rounded btn-lg'
            />
          </div>
        </div>
      </ModalWrapper>
    </>
  );
}
