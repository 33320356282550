import { IButtonProps } from 'src/lib/types/button';

export function ToggleButton({ className, id }: IButtonProps) {
  return (
    <div className={className}>
      <input type='checkbox' id={id} />
      <label htmlFor={id} />
    </div>
  );
}
