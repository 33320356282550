import React from 'react';
import { useNavigate } from 'react-router-dom';
// import { useGetSubscriptionStatus } from 'src/queries/subscription.query';
import { Button } from 'src/components/index';
// import { SubscriptionPaymentSuccessIcon, SubscriptionPaymentFailedIcon } from 'src/assets/Images';
import { subscriptionFailed } from 'src/assets/Images';
function Success() {
  const navigate = useNavigate();
  // const { data, error, refetch, isLoading } = useGetSubscriptionStatus({
  //   intentid: 'sas',
  // });
  // useEffect(() => {
  //   if (data) {
  //     if (data.status !== 200) {
  //       refetch();
  //     }
  //   }
  //   if (error) {
  //     refetch();
  //   }
  // }, [data, error]);
  return (
    <section className='success'>
      <div className='success__container'>
        {/* {isLoading ? <div>asd</div> : <img src={SubscriptionPaymentSuccessIcon} alt='' />} */}

        <h1>Thank you for your order</h1>
        <p className='text-[1.1rem] font-[500] text-gray-400'>
          We are currently processing your order and will send you aconfirmation email shortly.
        </p>
        <div className='success__buttons'>
          <Button className='btn--blue' label='Home' onClick={() => navigate('/')} />
        </div>
      </div>
    </section>
  );
}

export { Success };

function Failed() {
  return (
    <section className='success'>
      <div className='success__container'>
        <img src={subscriptionFailed} alt='' />
        <h1>Oops, payment failed!</h1>
        <div className='success__buttons'>
          <Button className='btn--blue' label='Go Back' />
        </div>
      </div>
    </section>
  );
}

export { Failed };
