import { forwardRef, useRef, useState, useEffect } from 'react';
import { selectMarketInfo } from 'src/store/MarketInfo/MarketInfo.selector';

import {
  autoUpdate,
  size,
  flip,
  useId,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  FloatingFocusManager,
  FloatingPortal,
} from '@floating-ui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSearch } from './reducer'; // Ensure this action is defined in your reducer

interface ItemProps {
  children: React.ReactNode;
  active: boolean;
}

const Item = forwardRef<HTMLDivElement, ItemProps & React.HTMLProps<HTMLDivElement>>(
  ({ children, active, ...rest }, ref) => {
    const id = useId();
    return (
      <div
        ref={ref}
        className='search-reco-item'
        role='option'
        id={id}
        aria-selected={active}
        {...rest}
        style={{
          ...rest.style,
        }}>
        {children}
      </div>
    );
  },
);

function Searchbox({ marketIndex = '', market = '', onClick }) {
  const marketInfo = useSelector(selectMarketInfo);

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const listRef = useRef<Array<HTMLElement | null>>([]);
  const dispatch = useDispatch();
  const searchItems = useSelector((state: any) => state.search);

  const { refs, floatingStyles, context } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange: setOpen,
    middleware: [
      flip({ padding: 10 }),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const role = useRole(context, { role: 'listbox' });
  const dismiss = useDismiss(context);
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([role, dismiss, listNav]);

  useEffect(() => {
    if (inputValue) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(
          fetchSearch({
            search: inputValue,
            index: marketIndex,
            market_type: marketInfo?.market_supported_map[market],
          }),
        );
      }, 300);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [inputValue, dispatch]);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setInputValue(value);

    if (value) {
      setOpen(true);
      setActiveIndex(0);
    } else {
      setOpen(false);
    }
  }

  return (
    <>
      <label htmlFor='search-box' className='input input-bordered input-field-label'>
        <MagnifyingGlassIcon className='size-8' />
        <input
          className='grow'
          id='search-box'
          {...getReferenceProps({
            ref: refs.setReference,
            onChange,
            value: inputValue,
            placeholder: 'Search Item',
            'aria-autocomplete': 'list',
            onKeyDown(event) {
              if (event.key === 'Enter' && activeIndex != null && searchItems?.data?.tickers[activeIndex]) {
                setInputValue(searchItems?.data?.tickers[activeIndex]);
                setActiveIndex(null);
                setOpen(false);
              }
            },
          })}
        />
        <FloatingPortal>
          {open && (
            <FloatingFocusManager context={context} initialFocus={-1} visuallyHiddenDismiss>
              <div
                className='search-reco'
                {...getFloatingProps({
                  ref: refs.setFloating,
                  style: {
                    ...floatingStyles,
                    overflowY: 'auto',
                  },
                })}>
                {searchItems?.data?.tickers?.length > 0
                  ? searchItems?.data?.tickers?.map((item, index) => (
                      <Item
                        {...getItemProps({
                          key: item.ticker,
                          ref(node) {
                            listRef.current[index] = node;
                          },
                          onClick() {
                            onClick(item.ticker);
                            setInputValue(item.ticker);
                            setOpen(false);
                            refs.domReference.current?.focus();
                          },
                        })}
                        active={activeIndex === index}>
                        <div className='search-reco-item-image'>
                          <img src={item.logo} alt='logo' />

                          <h1>{item.ticker}</h1>
                          <span>{item.name}</span>
                        </div>
                        <span>{item.index}</span>
                      </Item>
                    ))
                  : null}
              </div>
            </FloatingFocusManager>
          )}
        </FloatingPortal>
      </label>
    </>
  );
}

export default Searchbox;
