import React from 'react';

const welcomeData = [
  {
    id: 1,
    header: 'i-Expert',
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text ever since the 1500",
  },
  {
    id: 2,
    header: 'i-Recommend',
    details:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry's standard dummy text ever since the 1500",
  },
];

function Ipackage() {
  return (
    <main className='ipackage'>
      <section className='ipackage__main'>
        <section className='ipackage__top'>
          <h1>
            i-Package <span>(designed based on AI & ML)</span>
          </h1>
        </section>
        <section className='ipackage__bottom'>
          {welcomeData.map((data) => (
            <article key={data.id}>
              <h1>{data.header}</h1>
              <p>{data.details}</p>
            </article>
          ))}
        </section>
      </section>
    </main>
  );
}

export default Ipackage;
