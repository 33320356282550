import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectWatchlistOverview } from 'src/pages/Watchlist/Watchlist.selector';
import { convertDate, convertNumberToString } from 'src/lib/utils/utils';

export function WatchlistHeader() {
  const { ticker } = useParams();
  const overview = useSelector(selectWatchlistOverview)?.data?.profile || {};
  const [lastNonNullDataSource, setLastNonNullDataSource] = useState<any | null>(null);

  const liveQuote = useSelector((state: any) => state.liveQuote?.data?.[0] || null);
  const websocketData = useSelector((state: any) => state.websocket);

  const dataSource =
    (websocketData?.data && ticker && websocketData.data[ticker]) || liveQuote || lastNonNullDataSource || {};

  useEffect(() => {
    if (dataSource && (dataSource.price || dataSource.lp || dataSource.marketCap)) {
      setLastNonNullDataSource(dataSource);
    }
  }, [dataSource]);

  const price = dataSource?.price || dataSource?.lp || lastNonNullDataSource?.price || 'NA';
  const change = dataSource?.change || lastNonNullDataSource?.change || 'NA';
  const changesPercentage =
    dataSource?.changesPercentage !== undefined
      ? dataSource.changesPercentage.toFixed(2)
      : lastNonNullDataSource?.changesPercentage?.toFixed(2) || '0.00';
  const earningsAnnouncement =
    dataSource?.earningsAnnouncement?.split('T')[0] ||
    lastNonNullDataSource?.earningsAnnouncement?.split('T')[0] ||
    'NA';
  const eps = dataSource?.eps || lastNonNullDataSource?.eps || 'NA';
  const marketCap = convertNumberToString(dataSource?.marketCap || lastNonNullDataSource?.marketCap || 0);
  const pe = dataSource?.pe || lastNonNullDataSource?.pe || 'NA';
  const name = dataSource?.name || lastNonNullDataSource?.name || 'NA';
  const exchange = dataSource?.exchange || lastNonNullDataSource?.exchange || 'NA';
  return (
    <section className='watchlistheader'>
      <section className='watchlistheader__Container'>
        <section className='watchlistheader__Top'>
          <div className='watchlistheader__Left'>
            {overview.image && <img src={overview.image} alt='ticker' />}
            <span>{name}</span>
          </div>

          <div className='watchlistheader__Right'>
            <div className='watchlistheader__Right--L'>
              <div className='watchlistheader__Right--SubHeader'>
                <h1>
                  {price} <span>USD</span>
                </h1>
                <h3 className={change > 0 ? 'growth' : 'nogrowth'}>
                  {change} ({changesPercentage}%)
                </h3>
              </div>
            </div>
            <div className='watchlistheader__Right--R'>
              <p>{ticker}</p>
              {overview.image && <img src={overview.image} alt='ticker' />}
              <p>{exchange}</p>
            </div>
          </div>
        </section>
        <hr />
        <section className='watchlistheader__Bottom'>
          {earningsAnnouncement !== 'NA' && (
            <div className='watchlistheader__BLeft'>
              <h3>UPCOMING EARNINGS</h3>
              <h1>{convertDate(earningsAnnouncement)}</h1>
            </div>
          )}
          <div className='watchlistheader__BRight'>
            {eps !== 'NA' && (
              <div className='watchlistheader__Card'>
                <h3>EPS</h3>
                <h1>{eps}</h1>
              </div>
            )}
            {marketCap !== '0' && (
              <div className='watchlistheader__Card'>
                <h3>MARKET CAP</h3>
                <h1>{marketCap}</h1>
              </div>
            )}
            {pe !== 'NA' && (
              <div className='watchlistheader__Card'>
                <h3>P/E</h3>
                <h1>{pe}</h1>
              </div>
            )}
          </div>
        </section>
      </section>
    </section>
  );
}
