/* eslint-disable no-unused-vars */
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import Token from 'src/lib/token';
import SessionToken from 'src/lib/session-token';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'src/constants/token.constant';
import { postTokenRefresh } from 'src/repositories/mgmt/mgmt';
import { persistor } from 'src/store/store';
import { IJwtPayload } from './types/jwt';
import { clearCurrentUser, setCurrentUser } from 'src/store/user/user.reducer';
import { setLogin, setOtpLogin, setOtpRegister, setRegister } from 'src/pages/Authentication/auth.reducer';

export const isTokenExpired = (token: string) => {
  try {
    const tokenExpireTime = jwtDecode(token).exp;
    if (typeof tokenExpireTime === 'number') {
      const currentTime = moment().unix();
      if (tokenExpireTime - currentTime < 10000) {
        return true;
      }
    }
  } catch (err) {
    return false;
  }
  return false;
};

export const loggedIn = () => {
  const token = SessionToken.getToken(ACCESS_TOKEN_KEY);
  return !!token && !isTokenExpired(token);
};

export const logout = (dispatch, navigate) => {
  dispatch(clearCurrentUser());
  persistor.purge();
  SessionToken.removeToken(ACCESS_TOKEN_KEY);
  Token.removeToken(REFRESH_TOKEN_KEY);
  navigate('/login');
};

export const TokenValidate = async (): Promise<string | null | void> => {
  let access_token = SessionToken.getToken(ACCESS_TOKEN_KEY);
  let refresh_token = Token.getToken(REFRESH_TOKEN_KEY);

  if (!refresh_token) {
    window.location.href = '/login';
    return;
  }

  let accessTokenExpireTime: number | undefined;
  let refreshTokenExpireTime: number | undefined;
  if (access_token) {
    try {
      accessTokenExpireTime = jwtDecode(access_token).exp;
    } catch (error) {
      window.location.href = '/login';
      return;
    }
  }
  if (accessTokenExpireTime) {
    if (accessTokenExpireTime - moment().unix() < 10) {
      try {
        refreshTokenExpireTime = jwtDecode(refresh_token)?.exp;
      } catch (error) {
        window.location.href = '/login';
        return;
      }
      if (refreshTokenExpireTime) {
        if (refreshTokenExpireTime - moment().unix() > 10) {
          try {
            const res = await postTokenRefresh({ token: refresh_token });

            if (!res?.data?.data?.access_token.token) {
              // This block should not be reached; could indicate a backend issue.
              window.location.href = '/login';
              return;
            }

            // Refreshed the access token
            const { access_token: newAccessToken, refresh_token: newRefreshToken } = res.data.data;
            SessionToken.setToken(ACCESS_TOKEN_KEY, newAccessToken.token);
            Token.setToken(REFRESH_TOKEN_KEY, newRefreshToken.token);
            return newAccessToken.token;
          } catch (error) {
            console.error('Error refreshing access token:', error);
            window.location.href = '/login';
            return;
          }
        } else {
          SessionToken.removeToken(ACCESS_TOKEN_KEY);
          Token.removeToken(REFRESH_TOKEN_KEY);
          window.location.href = '/login';
        }
      }
      return;
    }
  }

  return access_token;
};

export const UserDataUpdate = (data, dispatch, navigate) => {
  if (data) {
    SessionToken.setToken(ACCESS_TOKEN_KEY, data?.access_token.token);
    Token.setToken(REFRESH_TOKEN_KEY, data?.refresh_token.token);
    const userDetails = jwtDecode<IJwtPayload>(data?.access_token.token);
    const payload = {
      accessToken: data?.access_token.token,
      accessTokenExp: data?.access_token.expiry,
      refreshToken: data?.refresh_token.token,
      refreshTokenExp: data?.refresh_token.expiry,
      userName: userDetails.user_name,
      subscriptionTier: '',
      riskProfile: userDetails.risk_profile_id,
    };
    dispatch(setCurrentUser(payload));
    dispatch(setLogin());
    dispatch(setRegister());
    dispatch(setOtpRegister());
    dispatch(setOtpLogin());
    navigate('/', { replace: true });
  }
};
