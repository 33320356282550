import React, { InputHTMLAttributes } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  className?: string;
  error?: string;
}

const Input: React.FC<InputProps> = ({
  name,
  label,
  required = false,
  className = '',
  error,
  disabled,
  value = '',
  ...otherProps
}) => {
  const errorClass = error ? 'error' : '';
  return (
    <div className={`input-field  ${className}`}>
      <label htmlFor={name} className={` input input-bordered input-field-label  ${className} ${errorClass}`}>
        {label && (
          <div className='label'>
            <span className='label-text-alt'>{label}</span>
          </div>
        )}
        <input
          id={name}
          value={value}
          className='grow'
          name={name}
          disabled={disabled}
          required={required}
          {...otherProps}
        />
      </label>
      {error && (
        <div className='label'>
          <span className='label-text-alt'>{error}</span>
        </div>
      )}
    </div>
  );
};

export default Input;
