import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import TVChartContainer from 'src/components/TvContainer/Tv.component';
import { PriceBar } from 'src/components/d3Components/priceBar';
import { useSelector } from 'react-redux';
import { selectWatchlistOverview } from '../Watchlist.selector';
import { watchlistOverview1, watchlistOverview2, watchlistOverview3 } from 'src/assets/Images';
import { useDispatch } from 'react-redux';
import { fetchWatchlistNews } from '../Watchlist.reducer';
import { selectMarketInfo } from 'src/store/MarketInfo/MarketInfo.selector';
import { convertNumberToString } from 'src/lib/utils/utils';
import { FinancialGraphComponent } from 'src/components/WatchlistComponents/incomeGraph';
import { AnalystEstimateGraphComponent } from 'src/components/WatchlistComponents/estimateGraph';
import { FinancialTredGraphComponent } from 'src/components/WatchlistComponents/financialTredGraph';
import { Stockgrades } from 'src/components/WatchlistComponents/stockgrades';
import Stockpeers from 'src/components/WatchlistComponents/stockpeer';
import OverviewPressrelease from 'src/components/WatchlistComponents/overviewPressrelease';
import OverviewNews from 'src/components/WatchlistComponents/overviewNews';

export default function OverView() {
  const dispatch = useDispatch();
  const overview = useSelector(selectWatchlistOverview);
  const marketInfo = useSelector(selectMarketInfo);
  const { ticker, market } = useParams();
  const [tick, setTick] = useState(null);
  const liveQuote = useSelector((state) => state.liveQuote?.data?.[0] || null);
  const dataSource = useMemo(() => tick || liveQuote || {}, [tick, liveQuote]);

  const dayHigh = dataSource?.dayHigh || 'NA';
  const dayLow = dataSource?.dayLow || 'NA';
  const open = dataSource?.open || 'NA';
  const price = dataSource?.price || 'NA';
  const yearHigh = dataSource?.yearHigh || 'NA';
  const yearLow = dataSource?.yearLow || 'NA';

  useEffect(() => {
    const mkt = market[0].toUpperCase() + market.slice(1, market.length);

    dispatch(
      fetchWatchlistNews({
        market_type: marketInfo.market_supported_map[mkt],
        ticker: ticker?.toUpperCase(),
      }),
    );
  }, [ticker, market]);
  return (
    <>
      <main className='overview'>
        <div className='overview__chart'>
          <TVChartContainer
            className='h-[80vh]  w-full'
            tick={ticker.toUpperCase()}
            featuresetDisableList={[
              'left_toolbar',
              'header_symbol_search',
              'header_compare',
              'header_undo_redo',
              'header_settings',
              'control_bar',
              'main_series_scale_menu',
              'context_menus',
              'timezone_menu',
            ]}
          />
        </div>
        {overview?.data ? (
          <>
            <section className='overview__detailsCard'>
              <article className='overview__Info'>
                <img src={watchlistOverview1} alt='' />
                <div className='overview__Info--Data'>
                  <p>{overview?.data?.profile?.sector}</p>
                  <h2>{overview?.data?.profile?.industry}</h2>
                </div>
              </article>
              <article className='overview__Info'>
                <img src={watchlistOverview2} alt='' />
                <div className='overview__Info--Data'>
                  <h2>{`With a market cap of ${convertNumberToString(
                    overview?.data?.profile?.mkt_cap?.toFixed(2),
                  )}B`}</h2>
                </div>
              </article>
              <article className='overview__Info'>
                <img src={watchlistOverview3} alt='' />
                <div className='overview__Info--Data'>
                  <h2>Beta: {overview?.data?.profile?.beta}</h2>
                </div>
              </article>
            </section>
            <section className='rating-matrics'>
              <div className='overview__MetricsOne'>
                <h1 className='financials-section-header'>KEY METRICS</h1>
                <article className='rating-matrics-grades'>
                  <div className='overview__PriceBarOne'>
                    {dayHigh !== 'NA' ? (
                      <PriceBar
                        high={dayHigh}
                        low={dayLow}
                        startValue={open}
                        currentValue={price}
                        width={600}
                        height={100}
                        dateRange="DAY'S RANGE"
                      />
                    ) : (
                      <h2 className='ca-nodata'>Data Not available</h2>
                    )}
                  </div>
                  <div className='overview__PriceBarTwo'>
                    {yearHigh !== 'NA' ? (
                      <PriceBar
                        high={yearHigh}
                        low={yearLow}
                        startValue={open}
                        currentValue={price}
                        width={600}
                        height={100}
                        dateRange='52 Week Range'
                      />
                    ) : (
                      <h2 className='ca-nodata'>Data Not available</h2>
                    )}
                  </div>
                </article>
              </div>

              <Stockgrades data={overview?.data} isLoading={overview?.isLoading} />
            </section>
            <Stockpeers data={overview?.data} isLoading={overview?.isLoading} />
            <AnalystEstimateGraphComponent
              data={overview?.data?.analyst_estimate}
              isLoading={overview?.isLoading}
              activeReported
              activeEstimated
              activeSurprise
            />

            <FinancialTredGraphComponent data={overview?.data} isLoading={overview?.isLoading} />
            <FinancialGraphComponent data={overview?.data?.financials} isLoading={overview?.isLoading} />
          </>
        ) : (
          ''
        )}
        <section className='overview-news-section'>
          {overview?.data ? <OverviewPressrelease data={overview?.data} isLoading={overview?.isLoading} /> : null}
          <OverviewNews />
        </section>
      </main>
    </>
  );
}
