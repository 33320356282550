import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'src/components/InputElements/select.component';

import { TopbarGraph } from '../graph/topbarGraph';
import { useSelector } from 'react-redux';

function WatchlistSidebar({ data }) {
  const { ticker, market } = useParams();
  const websocketData = useSelector((state) => state.websocket);

  const [isExpanded, setIsExpanded] = useState(false);
  const [lastNonNullDataSource, setLastNonNullDataSource] = useState(null);

  const bars = useSelector((state) => state.watchlistBars?.data);
  const liveQuote = useSelector((state) => state.liveQuote?.data?.[0] || null);
  const dataSource = (websocketData?.data && websocketData.data[ticker]) || liveQuote || {};

  // Update last non-null data source when a new non-null data source is available
  useEffect(() => {
    if (dataSource && (dataSource.changesPercentage || dataSource.dayHigh || dataSource.dayLow)) {
      setLastNonNullDataSource(dataSource);
    }
  }, [dataSource]);

  // Use lastNonNullDataSource values if dataSource is missing any values
  const changesPercentage =
    dataSource?.changesPercentage !== undefined
      ? dataSource.changesPercentage.toFixed(2)
      : lastNonNullDataSource?.changesPercentage?.toFixed(2) || '0.00';
  const dayHigh = dataSource?.dayHigh !== undefined ? dataSource.dayHigh : lastNonNullDataSource?.dayHigh || 'NA';
  const dayLow = dataSource?.dayLow !== undefined ? dataSource.dayLow : lastNonNullDataSource?.dayLow || 'NA';

  return (
    <>
      <div className={` sidebar content-container `}>
        <div className='sidebar__Top'>
          <p>PRICE CHART</p>
          {data?.downloads ? (
            <Select
              options={Object.keys(data?.downloads)}
              preSelectDisabled={true}
              onChange={(e) => {
                if (data?.downloads[e]) {
                  window.open(data?.downloads[e], '_blank');
                }
              }}
            />
          ) : null}
        </div>
        <table>
          <tr>
            <td>High</td>
            <td className='colorSetter'>{dayHigh}</td>
          </tr>
          <tr>
            <td>Low</td>
            <td className='colorSetter'>{dayLow}</td>
          </tr>
          <tr>
            <td>Returns</td>
            <td
              style={{
                color: changesPercentage >= 0 ? 'green' : 'red',
              }}>{`${changesPercentage}%`}</td>
          </tr>
        </table>
        <div className='sidebar__Graph'>
          {bars?.length > 0 && (
            <TopbarGraph data={bars} color={`${bars[0] > bars[bars.length - 1] ? '#ef4444' : 'blue'}`} height='200px' />
          )}
        </div>

        {market?.toLowerCase() === 'stocks' ? (
          <div className='sidebar__More'>
            <p>Profile</p>
            <table>
              <tr>
                <td>Sector</td>
                <td className='colorSetter'> {data?.profile?.sector || ''}</td>
              </tr>
              <tr>
                <td>Industry</td>
                <td className='colorSetter'>{data?.profile?.industry || ''} </td>
              </tr>
              <tr>
                <td>Address</td>
                <td className='colorSetter'>{data?.profile?.address || ''}</td>
              </tr>
            </table>
            <p className={`${isExpanded ? 'h-auto' : ' overflow-hidden  max-lg:h-[5rem] h-[10rem]'}`}>
              {data?.profile?.description || ''}
            </p>
          </div>
        ) : (
          ''
        )}
      </div>
      {market?.toLowerCase() === 'stocks' ? (
        isExpanded ? (
          <p
            className='text-center  my-[3rem] text-[1.4rem] font-[700] cursor-pointer text-blue-500'
            onClick={() => setIsExpanded(!isExpanded)}>
            Read less
          </p>
        ) : (
          <p
            className='text-center  my-[3rem] text-[1.4rem] font-[700] cursor-pointer text-blue-500'
            onClick={() => setIsExpanded(!isExpanded)}>
            Read more
          </p>
        )
      ) : (
        ''
      )}
    </>
  );
}

export default WatchlistSidebar;
