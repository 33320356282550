import { createSelector } from 'reselect';

const selectClubMetaDataState = (state: { clubMetaData: any }) => state.clubMetaData;
const selectClubContentState = (state: { clubContent: any }) => state.clubContent;
const selectClubDetailState = (state: { clubDetails: any }) => state.clubDetails;
const selectClubCommentState = (state: { clubComment: any }) => state.clubComment;
const selectClubLikeState = (state: { clubLike: any }) => state.clubLike;
const selectClubPostState = (state: { clubPost: any }) => state.clubPost;
export const selectClubMetaData = createSelector([selectClubMetaDataState], (clubMetaDataState) => clubMetaDataState);

export const selectClubContent = createSelector([selectClubContentState], (state) => state);
export const selectClubDetails = createSelector([selectClubDetailState], (state) => state);
export const selectClubComment = createSelector([selectClubCommentState], (state) => state);
export const selectClubLike = createSelector([selectClubLikeState], (state) => state);
export const selectClubPostStatus = createSelector([selectClubPostState], (state) => state);
