import React, { useEffect, useState } from 'react';
import 'quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

function TextEditor({ setContent }) {
  const [editorClassName, setEditorClassName] = useState('max-md:w-full;');
  // Toolbar configuration for the editor
  const modules = {
    toolbar: [
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      [{ indent: '-1' }, { indent: '+1' }],
      [
        {
          color: [
            '#000000',
            '#e60000',
            '#ff9900',
            '#ffff00',
            '#008a00',
            '#0066cc',
            '#9933ff',
            '#ffffff',
            '#facccc',
            '#ffebcc',
            '#ffffcc',
            '#cce8cc',
            '#cce0f5',
            '#ebd6ff',
            '#bbbbbb',
            '#f06666',
            '#ffc266',
            '#ffff66',
            '#66b966',
            '#66a3e0',
            '#c285ff',
            '#888888',
            '#a10000',
            '#b26b00',
            '#b2b200',
            '#006100',
            '#0047b2',
            '#6b24b2',
            '#444444',
            '#5c0000',
            '#663d00',
            '#666600',
            '#003700',
            '#002966',
            '#3d1466',
            'custom-color',
          ],
        },
      ],
    ],
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setEditorClassName('w-[60vw]');
      } else {
        setEditorClassName('max-md:w-full;');
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Supported formats for the editor
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'color',
    'bullet',
    'indent',
    'link',
    'image',
    'align',
    'size',
  ];

  return (
    <div className='dd' style={{ display: 'grid', justifyContent: 'center', minHeight: '10vh' }}>
      <ReactQuill
        theme='snow'
        modules={modules}
        formats={formats}
        placeholder='Write your content...'
        onChange={(content) => setContent(content)}
        // style={{ minHeight: '10vh', width: '40vw' }}
        className={`w-[40vw] ${editorClassName}`} // Combining fixed and dynamic class names
        style={{ minHeight: '10vh' }}
      />
    </div>
  );
}

export default TextEditor;
