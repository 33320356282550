// import { useState, useEffect } from 'react';

// export function useCountdownTimer() {
//   const [seconds, setSeconds] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       if (seconds > 0) {
//         setSeconds(seconds - 1);
//       } else {
//         clearInterval(interval);
//       }
//     }, 1000);

//     return () => clearInterval(interval);
//   }, [seconds]);

//   const resetTimer = (initialSeconds) => {
//     setSeconds(initialSeconds);
//   };

//   return [seconds, resetTimer];
// }
import { useState, useEffect, useRef } from 'react';

export function useCountdownTimer(): [number, (initialSeconds: number) => void] {
  const [seconds, setSeconds] = useState<number>(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    if (seconds > 0) {
      intervalRef.current = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else {
            clearInterval(intervalRef.current as NodeJS.Timeout);
            return 0;
          }
        });
      }, 1000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [seconds]);

  const resetTimer = (initialSeconds: number) => {
    setSeconds(initialSeconds);
  };

  return [seconds, resetTimer];
}
