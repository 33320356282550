/* eslint-disable max-len */
import { apiClient } from 'src/repositories/apiClient';
import { config } from 'src/lib/config';

export const getWatchlistOverview = async (params: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_360_VIEW_SERVICE + config.API_360_OVERVIEW;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const getWatchlistCompanyProfile = async (params: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_360_VIEW_SERVICE + config.API_360_COMPANY_PROFILE;
  const res = apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const getWatchlistFinancial = async (params: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_360_VIEW_SERVICE + config.API_360_INCOME_STATEMENT;
  const res = apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const getWatchlistESG = async (params: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_360_VIEW_SERVICE + config.API_360_ESG;
  const res = apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const getWatchlistRatio = async (params: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_360_VIEW_SERVICE + config.API_360_RATIOS;
  const res = apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const getWatchlistCorporateActionSplit = async (params: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_360_VIEW_SERVICE + config.API_360_CORPORATE_ACTION_SPLIT;
  const res = apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const getWatchlistCorporateActionDividend = async (params: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_360_VIEW_SERVICE + config.API_360_CORPORATE_ACTION_DIVIDEND;
  const res = apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const getWatchlistSocialsentiment = async (params: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_360_VIEW_SERVICE + config.API_360_DEEP_ANALYSIS_SOCIAL_SENTIMENT;
  const res = apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const getWatchlistRatingGrades = async (params: { ticker: any }) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_360_VIEW_SERVICE + config.API_360_DEEP_ANALYSIS_RATING;
  const res = apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const getWatchlistOwnership = async (params: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_360_VIEW_SERVICE + config.API_360_DEEP_ANALYSIS_OWNERSHIP;
  const res = apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const getWatchlistAnalystRecomendation = async (params: { ticker: string }) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_360_VIEW_SERVICE + config.API_360_DEEP_ANALYSIS_ANALYST_RECO;
  const res = apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const getWatchlistAnalystEstimate = async (params: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_360_VIEW_SERVICE + config.API_360_DEEP_ANALYSIS_ANALYST_EST;
  const res = apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const getWatchlistPressRelease = async (params: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_360_VIEW_SERVICE + config.API_360_PRESS_RELEASE;
  const res = apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
