import { SocialSentimentGraph, Speedometer, WatchlistHeader } from 'src/components';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectsocialSentiment } from '../socialsentiment.selector';
import {
  watchlistSocialSentimentStat,
  watchlistSocialSentimentComment,
  watchlistSocialSentimentLike,
} from 'src/assets/Images';
import { useEffect } from 'react';
import { fetchSocialSentiment } from '../socialsentiment.reducer';
import { fetchLiveQuote } from 'src/store/livequote/reducer';
import { sendMessage, connectWebSocket } from 'src/store/Websocket/Websocket.saga';
import { WS_EVENT } from 'src/constants/ws.constant';

function SocialSentiments({ source, ticker }) {
  const dispatch = useDispatch();
  const sentiment = useSelector(selectsocialSentiment);
  const websocketData = useSelector((state) => state.websocket);

  useEffect(() => {
    dispatch(fetchSocialSentiment({ ticker: ticker.toUpperCase() }));
    dispatch(fetchLiveQuote({ market_type: 'UsStocks', tickers: [ticker.toUpperCase()] }));
  }, []);
  const colorPicker = (value) => {
    if (value < 20) {
      return '#C72B2E';
    }
    if (value >= 20 && value < 40) {
      return '#E76134';
    }
    if (value >= 40 && value < 60) {
      return '#FFC87C';
    }

    if (value >= 60 && value < 80) {
      return '#86C342';
    }
    if (value >= 80) {
      return '#339F41';
    }
    return '';
  };
  useEffect(() => {
    if (websocketData?.data?.status !== WS_EVENT.WS_EVENT_SERVER_CONNECTED) {
      dispatch(connectWebSocket());
    }
    if (websocketData?.data?.status === WS_EVENT.WS_EVENT_SERVER_LOGIN_SUCCESS)
      dispatch(sendMessage({ event: WS_EVENT.WS_EVENT_CLIENT_SUBSCRIBE, data: `${ticker}` }));
    return () => {
      if (websocketData?.data?.status === WS_EVENT.WS_EVENT_SERVER_LOGIN_SUCCESS) {
        dispatch(sendMessage({ event: WS_EVENT.WS_EVENT_CLIENT_UNSUBSCRIBE, data: `${ticker}` }));
      }
    };
  }, [websocketData, ticker]);
  return (
    <>
      <div className='socialSentiment__box'>
        <WatchlistHeader />
      </div>
      <div className='socialSentiment__container'>
        <div className='socialSentiment__left'>
          <div className='socialSentiment__card'>
            <div>
              <p>{source === 'Twitter' ? sentiment?.data?.twitter_posts : sentiment?.data?.stocktwits_posts}</p>
              <p>Posts</p>
            </div>
            <img src={watchlistSocialSentimentStat} alt='' />
          </div>
          <div className='socialSentiment__card'>
            <div>
              <p>{source === 'Twitter' ? sentiment?.data?.twitter_comments : sentiment?.data?.stocktwits_comments}</p>
              <p>Comments</p>
            </div>
            <img src={watchlistSocialSentimentComment} alt='' />
          </div>
          <div className='socialSentiment__card'>
            <div>
              <p>{source === 'Twitter' ? sentiment?.data?.twitter_likes : sentiment?.data?.stocktwits_likes}</p>
              <p>Likes</p>
            </div>
            <img src={watchlistSocialSentimentLike} alt='' />
          </div>
        </div>
        <div className='socialSentiment__middle'>
          <div className='socialSentiment__mainChart'>
            {sentiment?.data && sentiment?.data?.sentiment.length !== 0 && (
              <SocialSentimentGraph
                data={[...(sentiment?.data?.sentiment || [])].reverse()}
                isTwitter={source === 'Twitter'}
              />
            )}
          </div>
        </div>
        <div className='socialSentiment__right'>
          <div className='socialSentiment__speedometer'>
            {sentiment?.data && sentiment?.data?.sentiment.length !== 0 && (
              <Speedometer
                value={(
                  (source !== 'Twitter'
                    ? sentiment?.data?.sentiment[sentiment?.data?.sentiment.length - 1].stocktwitsSentiment
                    : sentiment?.data?.sentiment[sentiment?.data?.sentiment.length - 1].twitterSentiment) * 100
                ).toFixed(0)}
                color={colorPicker(
                  (
                    (source !== 'Twitter'
                      ? sentiment?.data?.sentiment[sentiment?.data?.sentiment.length - 1].stocktwitsSentiment
                      : sentiment?.data?.sentiment[sentiment?.data?.sentiment.length - 1].twitterSentiment) * 100
                  ).toFixed(0),
                )}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default SocialSentiments;
