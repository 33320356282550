import React, { useEffect, useRef, useMemo, memo, useCallback } from 'react';
import * as echarts from 'echarts';
import { StatementTf } from 'src/lib/enum/enum';

export const FinancialTrendGraph = memo(({ data, tf }) => {
  const chartRef = useRef(null);

  const xAxisData = useMemo(
    () =>
      data.map((item) =>
        tf === StatementTf.Annual ? item.date.split('-')[0] : `${item.period} ${item.date.split('-')[0].slice(2)}`,
      ),
    [data],
  );

  const yAxisOption = useMemo(
    () => [
      {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#FFFF',
          },
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255, 255, 255, 0.09)',
          },
        },
        axisLabel: {
          formatter: '{value}',
        },
      },
      {
        type: 'value',
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: true,
        },
      },
    ],
    [],
  );
  const legend = ['Revenue Growth', 'Profit Growth'];
  const barSeries = useMemo(
    () => [
      {
        type: 'bar',
        name: 'Revenue Growth',
        data: data.map((item) => item.revenue_growth.toFixed(2)),
        yAxisIndex: 0,
        itemStyle: {
          color: 'rgba(32, 201, 151, 1)',
        },
      },
      {
        type: 'bar',
        name: 'Profit Growth',
        data: data.map((item) => item.gross_profit_growth.toFixed(2)),
        yAxisIndex: 0,
        itemStyle: {
          color: '#448aff',
        },
      },
    ],
    [data],
  );

  const createChart = useCallback(() => {
    const chart = echarts.init(chartRef.current);
    const option = {
      xAxis: {
        type: 'category',
        data: xAxisData,
        axisLabel: {
          color: '#ffffff',
        },
        axisLine: {
          lineStyle: {
            color: '#ffffff',
          },
        },
      },
      yAxis: yAxisOption,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderColor: 'rgba(0, 0, 0, 0.8)',
        textStyle: {
          color: '#ffffff',
        },
      },
      legend: {
        data: legend,
        textStyle: {
          color: '#ffffff',
        },
        padding: [10, 10, 10, 10],
      },
      series: [...barSeries],
    };
    chart.setOption(option);

    // Clean up the chart instance when the component unmounts
    return () => {
      chart.dispose();
    };
  }, [xAxisData, yAxisOption, barSeries]);

  useEffect(() => {
    const cleanup = createChart();

    return () => {
      cleanup();
    };
  }, [createChart]);

  return <div ref={chartRef} style={{ width: '100%', height: '400px' }} />;
});
