import React from 'react';
import { data } from 'src/static-data';

function Statistics() {
  return (
    <section className='statistics'>
      <div className='statistics__tableOne'>
        <table>
          <thead>
            <tr>
              <th />
              <th>All Trades</th>
              <th>Long Trades</th>
              <th>Short Trades</th>
            </tr>
          </thead>
          <tbody>
            {data.statistics.data?.map((item) => (
              <tr key={item.id}>
                <td>{item.title}</td>
                <td>{item.allTrades}</td>
                <td>{item.longTrades}</td>
                <td>{item.shortTrades}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <h3>All Trades</h3>
      <div className='statistics__tableOne'>
        <table>
          <thead className='hidden max-md:block '>
            <tr>
              <th />
              <th>All Trades</th>
              <th>Long Trades</th>
              <th>Short Trades</th>
            </tr>
          </thead>
          <tbody>
            {data.statistics.data1?.map((item) => (
              <tr key={item.id}>
                <td>{item.title}</td>
                <td>{item.allTrades}</td>
                <td>{item.longTrades}</td>
                <td>{item.shortTrades}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <h3>WINNERS</h3>
      <div className='statistics__tableOne'>
        <table>
          <thead className='hidden max-md:block '>
            <tr>
              <th />
              <th>All Trades</th>
              <th>Long Trades</th>
              <th>Short Trades</th>
            </tr>
          </thead>
          <tbody>
            {data.statistics.data1?.map((item) => (
              <tr key={item.id}>
                <td>{item.title}</td>
                <td>{item.allTrades}</td>
                <td>{item.longTrades}</td>
                <td>{item.shortTrades}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='statistics__more'>
        <h1>more...</h1>
      </div>
    </section>
  );
}

export default Statistics;
