import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from 'src/lib/types/error';
type Market = {
  label: string;
  market_type: string;
};

type MarketSupportedMap = {
  [label: string]: string;
};
export interface MarketInfo {
  markets: string[];
  market_timeframes: string[];
  market_supported_map: MarketSupportedMap;
  index_list: string[];
  index_map: MarketSupportedMap;
}
export interface IDataState {
  isLoading: boolean;
  data: MarketInfo | null;
  error: IError | null;
}
const INITIAL_STATE: IDataState = {
  isLoading: false,
  data: null,
  error: null,
};
const marketInfoSlice = createSlice({
  name: 'marketInfo',
  initialState: INITIAL_STATE,
  reducers: {
    fetchMarketInfo(state) {
      state.isLoading = true;
    },
    fetchMarketInfoSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      const market_supported_map = action.payload.markets_supported.reduce((acc, i) => {
        acc[i.label] = i.market_type;
        return acc;
      }, {});
      const markets = action.payload.markets_supported.map((i) => i.label);
      const index_map = action.payload.tickers_info.index_tickers.reduce((acc, i) => {
        acc[i.index_name] = i.key;
        return acc;
      }, {});
      const index_list = action.payload.tickers_info.index_tickers.map((i) => i.index_name);
      state.data = {
        market_timeframes: action.payload.market_timeframes,
        markets,
        market_supported_map,
        index_map,
        index_list,
      };

      state.error = null;
    },
    fetchMarketInfoFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});
export const { fetchMarketInfo, fetchMarketInfoSuccess, fetchMarketInfoFailure } = marketInfoSlice.actions;
export const marketInfoReducer = marketInfoSlice.reducer;
