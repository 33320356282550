/* eslint-disable indent */
/* eslint-disable max-len */
import { apiClient } from '../apiClient';
import { config } from 'src/lib/config';

export const postMarketNews = async (payload: any) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_NEWS + config.API_MARKET_NEWS;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};

export const getGeneralNews = async () => {
  const apiUrl = config.API_SVC_PATH_IFIRST_NEWS + config.API_GENERAL_NEWS;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
  });
  return res;
};
