/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Button } from '../Button/button.component';

function Survey() {
  const [active, setActive] = useState(1);

  return (
    <section className='survey'>
      <h1>Risk Profiling</h1>
      <div className='survey__body'>
        {/* Tab - 1 */}
        {active === 1 && (
          <div className='survey__main'>
            <div className='survey__introduction'>
              <h1>Risk Profiling combines two key areas</h1>
              <p>
                1) Estimating financial risk-taking capacity and <br />
                2) Understanding the (psychological) risk tolerance level of an individual.
              </p>
              <p>
                We have compiled 20 questions that will help you evaluate yourself on both these parameters. Also ,
                based on your risk profile, we will recommend an asset allocation structure best suited for you.
              </p>
              <p>The RiskAnalyser should take you about 10 minutes to complete.</p>
            </div>
            <hr />
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__btn'>
              <Button label='Next' className='btn-primary' onClick={() => setActive(2)} />
            </div>
          </div>
        )}
        {active === 2 && (
          <div className='survey__main mt-[6rem]'>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__btn'>
              <Button label='Next' className='btn-primary' onClick={() => setActive(3)} />
            </div>
          </div>
        )}
        {active === 3 && (
          <div className='survey__main mt-[6rem]'>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__btn'>
              <Button label='Next' className='btn-primary' onClick={() => setActive(4)} />
            </div>
          </div>
        )}
        {active === 4 && (
          <div className='survey__main mt-[6rem]'>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__btn'>
              <Button label='Next' className='btn-primary' onClick={() => setActive(5)} />
            </div>
          </div>
        )}
        {active === 5 && (
          <div className='survey__main mt-[6rem]'>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__question'>
              <h2>1. Your age is:</h2>
              <div className='survey__form'>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>Under 30</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>30 - 40</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>41 - 60</label>
                </div>
                <div className='survey__element'>
                  <input type='radio' id='age1' name='age' />
                  <label htmlFor='age1'>60 or over</label>
                </div>
              </div>
            </div>
            <div className='survey__btn'>
              <Button label='Submit' className='btn-primary' />
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default Survey;
