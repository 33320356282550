/* eslint-disable max-len */
import { apiClient } from 'src/repositories/apiClient';
import { config } from 'src/lib/config';
export const getReportsMetadata = async (params) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_REPORTS + config.API_REPORTS_METADATA;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};

export const postReportOptions = async (payload) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_REPORTS + config.API_REPORTS_OPTIONS;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: payload,
  });
  return res;
};
