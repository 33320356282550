import { put, takeLatest } from 'redux-saga/effects';
import { postLiveQuote } from 'src/repositories/mgmt/mgmt';
import { fetchLiveQuote, fetchLiveQuoteFailure, fetchLiveQuoteSuccess } from './reducer';

function* fetchLiveQuoteSaga(action) {
  try {
    const data = yield postLiveQuote(action.payload).then((response) => response);
    yield put(fetchLiveQuoteSuccess(data?.data?.data));
  } catch (error) {
    yield put(fetchLiveQuoteFailure(error));
  }
}

export function* watchLifetchLiveQuoteSaga() {
  yield takeLatest(fetchLiveQuote.type, fetchLiveQuoteSaga);
}
