import { createSelector } from 'reselect';

const selectSupportDetailState = (state: { supportDetails: any }) => state.supportDetails;
const selectSupportTicketState = (state: { supportTickets: any }) => state.supportTickets;
const selectSupportFaqState = (state: { supportFaq: any }) => state.supportFaq;
const selectSupportSectionState = (state: { supportSection: any }) => state.supportSection;
const selectSupportVideoState = (state: { supportVideo: any }) => state.supportVideo;
const selectSupportCreateTicketState = (state: { supportCreateTicket: any }) => state.supportCreateTicket;

export const selectSupportDetails = createSelector([selectSupportDetailState], (state) => state);
export const selectSupportTickets = createSelector([selectSupportTicketState], (state) => state);
export const selectSupportFaq = createSelector([selectSupportFaqState], (state) => state);
export const selectSupportSection = createSelector([selectSupportSectionState], (state) => state);
export const selectSupportVideos = createSelector([selectSupportVideoState], (state) => state);
export const selectSupportCreateTicket = createSelector([selectSupportCreateTicketState], (state) => state);
