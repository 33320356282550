/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { Notification, ModalWrapper, Select } from 'src/components';
import { useDispatch, useSelector } from 'react-redux';
import { selectSupportTickets } from './support.selector';
import { fetchSupportTicket } from './support.reducer';
export default function SupportTickets() {
  const dispatch = useDispatch();
  const tickets = useSelector(selectSupportTickets);
  const ACTIVE_STATUS = ['OPEN', 'IN PROGRESS', 'USER ACTION PENDING'];
  const [details, setDetails] = useState<any[]>([]);
  const [statusData, setStatusData] = useState(true);
  const [notification, setNotification] = useState(false);
  const [recents] = useState(10);
  const [errors] = useState(false);
  // const timeDuration = useState(1);
  const [negativeMessage, setNegativeMessage] = useState<boolean | string>(false);
  // const ticketMutation = useMutation(putSupportTickets, {});
  // const { data, isLoading } = useQuery({
  //   queryKey: ['QUERY_SUPPORT_TICKET_KEY', recents],
  //   queryFn: () => getSupportTickets({ recent: recents }).then((res) => res.data.data),
  //   staleTime: Infinity,
  //   keepPreviousData: true,
  // });
  // useEffect(() => {
  //   const items: any[] = [];
  //   if (data) {
  //     data.forEach((item: { status: string }) => {
  //       if (statusData) {
  //         if (ACTIVE_STATUS.includes(item.status)) {
  //           items.push(item);
  //         }
  //       } else if (!ACTIVE_STATUS.includes(item.status)) {
  //         items.push(item);
  //       }
  //     });
  //     setDetails(items);
  //   }
  // }, [tickets, statusData]);
  useEffect(() => {
    dispatch(fetchSupportTicket());
  }, []);
  // useEffect(() => {
  //   error && setErrors(error?.message);
  //   error && setNotification(true);
  // }, [error]);
  // // const handleCancel = (id) => {
  // //   try {
  // //     const result = putRequest(url, { id, status: 'CANCELLED' });
  // //     if (result) {
  // //       setNotification(true);
  // //       setErrors('Ticket Cancelled Successfully');
  // //     }
  // //   } catch (err) {
  // //     setNotification(true);
  // //     setErrors('Sorry!Some Error Occuared');
  // //   } finally {
  // //     setTimeout(window.location.reload(), 3000);
  // //   }
  // // };
  return (
    <>
      <div className='support-requests navbar-fix '>
        <h1>
          My <span>Request Items</span>
        </h1>
        <div className='support-requests-nav'>
          <div className='support-requests-nav-left'>
            <p onClick={() => setStatusData(true)} className={statusData ? 'active-switch' : ''}>
              Active
            </p>
            <p onClick={() => setStatusData(false)} className={!statusData ? 'active-switch' : ''}>
              Close
            </p>
          </div>

          <div className='support-requests-nav-left'>
            <Select options={['1 Month', '3 Months', '6 Months', '9 Months']} />
          </div>
        </div>
        <div className='supoort-requests-table'>
          <table className=''>
            <thead>
              <tr>
                <th>ID</th>
                <th>SECTION</th>
                <th>STATUS</th>
                <th>Created Date</th>
                <th className='queries'>QUERIES</th>
                <th> &nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {/* {isLoading && <p>Data is Loading</p>} */}
              {tickets &&
                tickets?.data?.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id.slice(-6, -1)}</td>
                    <td>{item.section}</td>
                    <td>{item.status}</td>
                    <td>{`${new Date(item.created_at).toLocaleString('en-US', {
                      month: 'long',
                    })} ${new Date(item.created_at).getDate()},${new Date(item.created_at).getFullYear()}`}</td>
                    <td>{item.query}</td>
                    {statusData && (
                      <td
                        className='cancel'
                        onClick={() => {
                          setNegativeMessage(item.id);
                        }}>
                        <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M6.41 5L9.7 1.71C9.89 1.53 10 1.28 10 1C10 0.45 9.55
                               0 9 0C8.72 0 8.47 0.11 8.29 0.29L5 3.59L1.71 0.29C1.53 
                               0.11 1.28 0 1 0C0.45 0 0 0.45 0 1C0 1.28 0.11 1.53 0.29
                                1.71L3.59 5L0.3 8.29C0.11 8.47 0 8.72 0 9C0 9.55 0.45 
                                10 1 10C1.28 10 1.53 9.89 1.71 9.71L5 6.41L8.29 9.7C8.47 
                                9.89 8.72 10 9 10C9.55 10 10 9.55 10 9C10 8.72 9.89 8.47
                                 9.71 8.29L6.41 5Z'
                            fill='white'
                          />
                        </svg>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <Notification showNotification={notification} setNotification={setNotification} error={errors} />
      <ModalWrapper open={negativeMessage} onClose={() => setNegativeMessage(false)}>
        <div className='item_modal'>
          <p>Do you confirm to cancel the ticet?</p>

          <div className='modal_container_buttons} ${styles.itm'>
            <button
              type='button'
              onClick={() => {
                // handleCancel(negativeMes/sage);
                // ticketMutation.mutate({ id: 'op', status: 'CANCELLED' });
                setNegativeMessage(false);
              }}
              className='modal_container_buttons_btn2'>
              Confirm
            </button>
            <button
              style={{ background: 'grey' }}
              type='button'
              onClick={() => setNegativeMessage('')}
              className='modal_container_buttons_btn2} ${styles.ii'>
              Close
            </button>
          </div>
        </div>
      </ModalWrapper>
    </>
  );
}
