import React, { useEffect, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { Link } from 'react-router-dom';
import {
  statXrp,
  statUsdc,
  statSol,
  statMoit,
  statBnb,
  statDot,
  statBtc,
  statEth,
  statUsdt,
  statAda,
  statLink,
  statUni,
  statRic,
} from 'src/assets/Images';
import { MotionDiv, MotionH1, MotionImg } from '../motion/motion.component';
function Stats() {
  const [isVisible, setIsVisible] = useState(false);
  const [counts, setCounts] = useState({
    tradersCount: 0,
    appRating: 0,
    sharedIdeas: 0,
    websiteRank: 0,
  });

  const handleVisibilityChange = (visible) => {
    setIsVisible(visible);
  };

  const [isVisibleOne, setIsVisibleOne] = useState(false);

  const handleVisibilityChangeImages = (isVisible) => {
    setIsVisibleOne(isVisible);
  };

  useEffect(() => {
    if (isVisible) {
      const startCounting = () => {
        const interval = setInterval(() => {
          setCounts((prevCounts) => ({
            tradersCount:
              prevCounts.tradersCount < 10_000_000
                ? Math.min(prevCounts.tradersCount + 100_000, 10_000_000)
                : 10_000_000,
            appRating: prevCounts.appRating < 4.9 ? Math.min(prevCounts.appRating + 0.2, 4.9) : 4.9,
            sharedIdeas:
              prevCounts.sharedIdeas < 10_000_000 ? Math.min(prevCounts.sharedIdeas + 100_000, 10_000_000) : 10_000_000, // Increased increment
            websiteRank: prevCounts.websiteRank < 1 ? Math.min(prevCounts.websiteRank + 0.05, 1) : 1,
          }));
        }, 20);
        return () => clearInterval(interval);
      };

      startCounting();
    }
  }, [isVisible]);
  const isVisibleProp = (visible: boolean, prop1, prop2) => (visible ? prop1 : prop2);
  return (
    <VisibilitySensor partialVisibility onChange={handleVisibilityChange}>
      <section className='marketing-stats'>
        <div className='stats-tickers'>
          <VisibilitySensor partialVisibility onChange={handleVisibilityChangeImages}>
            <MotionDiv
              className='stats-ticker'
              initial={{ opacity: 0, scale: 0.1 }}
              animate={{ opacity: isVisibleProp(isVisibleOne, 1, 0), scale: isVisibleProp(isVisibleOne, 1, 0.1) }}
              transition={{ duration: 1.5 }}>
              <MotionImg
                src={statXrp}
                alt=''
                initial={{ opacity: 0, scale: 0.1 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.5, duration: 1 }}
              />
              <MotionImg
                src={statUsdc}
                alt=''
                initial={{ opacity: 0, scale: 0.1 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.7, duration: 1 }}
              />
            </MotionDiv>
          </VisibilitySensor>
          <VisibilitySensor partialVisibility onChange={setIsVisibleOne}>
            <MotionDiv
              className=' stats-ticker'
              initial={{ opacity: 0, scale: 0.1 }}
              animate={{ opacity: isVisibleProp(isVisibleOne, 1, 0), scale: isVisibleProp(isVisibleOne, 1, 0.1) }}
              transition={{ duration: 1.5 }}>
              <MotionImg
                src={statSol}
                alt=''
                initial={{ opacity: 0, scale: 0.1 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.9, duration: 1 }}
              />
              <MotionImg
                src={statMoit}
                alt=''
                initial={{ opacity: 0, scale: 0.1 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 1.1, duration: 1 }}
              />
              <MotionImg
                src={statBnb}
                alt=''
                initial={{ opacity: 0, scale: 0.1 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 1.3, duration: 1 }}
              />
            </MotionDiv>
          </VisibilitySensor>
          <VisibilitySensor partialVisibility onChange={setIsVisibleOne}>
            <MotionDiv
              className='stats-ticker'
              initial={{ opacity: 0, scale: 0.1 }}
              animate={{ opacity: isVisibleProp(isVisibleOne, 1, 0), scale: isVisibleProp(isVisibleOne, 1, 0.1) }}
              transition={{ duration: 1.5 }}>
              <MotionImg
                src={statDot}
                alt=''
                initial={{ opacity: 0, scale: 0.1 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 1.7, duration: 1 }}
              />
              <MotionImg
                src={statBtc}
                alt=''
                initial={{ opacity: 0, scale: 0.1 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 1.9, duration: 1 }}
              />
              <MotionImg
                src={statEth}
                alt=''
                initial={{ opacity: 0, scale: 0.1 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 2.1, duration: 1 }}
              />
            </MotionDiv>
          </VisibilitySensor>
          <VisibilitySensor partialVisibility onChange={setIsVisibleOne}>
            <MotionDiv
              className='stats-ticker'
              initial={{ opacity: 0, scale: 0.1 }}
              animate={{ opacity: isVisibleProp(isVisibleOne, 1, 0), scale: isVisibleProp(isVisibleOne, 1, 0.1) }}
              transition={{ duration: 1.5 }}>
              <MotionImg
                src={statUsdt}
                alt=''
                initial={{ opacity: 0, scale: 0.1 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 2.5, duration: 1 }}
              />
              <MotionImg
                src={statAda}
                alt=''
                initial={{ opacity: 0, scale: 0.1 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 2.7, duration: 1 }}
              />
              <MotionImg
                src={statLink}
                alt=''
                initial={{ opacity: 0, scale: 0.1 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 2.9, duration: 1 }}
              />
            </MotionDiv>
          </VisibilitySensor>
          <VisibilitySensor partialVisibility onChange={setIsVisibleOne}>
            <MotionDiv
              className='stats-ticker'
              initial={{ opacity: 0, scale: 0.1 }}
              animate={{ opacity: isVisibleProp(isVisibleOne, 1, 0), scale: isVisibleProp(isVisibleOne, 1, 0.1) }}
              transition={{ duration: 1.5 }}>
              <MotionImg
                src={statUni}
                alt=''
                initial={{ opacity: 0, scale: 0.1 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 3.3, duration: 1 }}
              />
              <MotionImg
                src={statRic}
                alt=''
                initial={{ opacity: 0, scale: 0.1 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 3.5, duration: 1 }}
              />
            </MotionDiv>
          </VisibilitySensor>
        </div>
        <div className='stats-data'>
          <div className='stats-data-container'>
            <h1>Stats that matter</h1>
            <p>Here are the stats behind who we are and what we do</p>
            <div className='stats-info-container'>
              <div className='stats-info'>
                <MotionH1
                  className=''
                  initial={{ opacity: 0 }}
                  animate={{ opacity: isVisibleProp(isVisibleOne, 1, 0) }}>
                  {counts.tradersCount < 10_000_000 ? `${Math.floor(counts.tradersCount / 1_000_000)}M` : '10M+'}
                </MotionH1>
                <p>Traders and investors who visit us every month</p>
              </div>
              <div className='stats-info'>
                <MotionH1
                  className=''
                  initial={{ opacity: 0 }}
                  animate={{ opacity: isVisibleProp(isVisibleOne, 1, 0) }}>
                  {counts.appRating.toFixed(1)}
                </MotionH1>
                <p>Finance app-wise, no one else is more loved</p>
              </div>
              <div className='stats-info'>
                <MotionH1
                  className=''
                  initial={{ opacity: 0 }}
                  animate={{ opacity: isVisibleProp(isVisibleOne, 1, 0) }}>
                  {counts.sharedIdeas < 10_000_000 ? `${Math.floor(counts.sharedIdeas / 1_000_000)}M` : '10M+'}
                </MotionH1>
                <p>Custom scripts and ideas shared by our users</p>
              </div>
              <div className='stats-info'>
                <MotionH1
                  className=''
                  initial={{ opacity: 0 }}
                  animate={{ opacity: isVisibleProp(isVisibleOne, 1, 0) }}>
                  {counts.websiteRank < 1 ? counts.websiteRank.toFixed(2) : '#1'}
                </MotionH1>
                <h3>Top website in the world when it comes to all things investing</h3>
              </div>
              <div className='stats-info stats-btn'>
                <Link to='/quantlandingpage' className='btn btn-primary btn-rounded btn-lg'>
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </VisibilitySensor>
  );
}

export default Stats;
