import React, { useState, useEffect, useRef } from 'react';

export const ScrollCarousal = ({ children, speed = 4 }) => {
  const scrollSpeed = 50;
  const carouselRef = useRef(null);
  const carouselContainerRef = useRef(null);
  const [translateX, setTranslateX] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    let scrollInterval = null;

    const startScrolling = () => {
      scrollInterval = setInterval(() => {
        setTranslateX((prev) => prev - speed); // Adjust scroll speed here
        setIsTransitioning(true);
      }, scrollSpeed);
    };

    startScrolling();

    return () => {
      if (scrollInterval) {
        clearInterval(scrollInterval);
      }
    };
  }, [scrollSpeed]);

  useEffect(() => {
    if (isTransitioning) {
      if (translateX <= -carouselRef.current.scrollWidth / 4) {
        setTranslateX(-4);
        setIsTransitioning(false);
      }
      if (translateX >= 0) {
        // if (translateX > carouselRef.current.offsetWidth)
        setTranslateX(-carouselRef.current.scrollWidth / 2);
        setIsTransitioning(false);
      }
    }
  }, [translateX, isTransitioning]);
  const isTransitioned = (isTransitioning) => (isTransitioning ? 'transform 0.5s linear' : 'none');
  return (
    <>
      <div className='carousel-container' ref={carouselContainerRef}>
        <div
          className='carousel'
          ref={carouselRef}
          style={{
            transform: `translateX(${translateX}px)`,
            transition: isTransitioned(isTransitioning),
          }}>
          {children}
          {children}
        </div>
      </div>
    </>
  );
};
