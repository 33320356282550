import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { PressReleaseCard } from 'src/components';
import { selectWatchlistPressRelease } from '../../Watchlist.selector';
import { fetchWatchlistPressRelease } from '../../Watchlist.reducer';
import { PressReleseCardloader } from 'src/components/loadingAnimation/cardloader';
import { useParams } from 'react-router-dom';

export default function PressRelease() {
  const [page, setPage] = useState(1);
  const { ticker, market } = useParams();

  const dispatch = useDispatch();
  const pressRelease = useSelector(selectWatchlistPressRelease);
  useEffect(() => {
    dispatch(fetchWatchlistPressRelease({ ticker }));
  }, [ticker]);
  return (
    <div className='pressrelease'>
      <div className='pressrelease-cards'>
        {pressRelease?.isLoading ? (
          <PressReleseCardloader />
        ) : pressRelease?.data ? (
          pressRelease?.data?.map((e) => <PressReleaseCard data={e} />)
        ) : (
          <h2>No data is present</h2>
        )}
      </div>

      <div className='pressrelease-paginate'>
        {/* <Pagination
          totalPages={pressRelease.data.pages}
          onPageChange={(val) => dispatch(fetchWatchlistPressRelease({ ticker, page: val }))}
        /> */}
      </div>
    </div>
  );
}
