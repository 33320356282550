import React, { useEffect, useRef, useMemo, memo, useCallback } from 'react';
import * as echarts from 'echarts';
import { FinancialDataType, StatementTf } from 'src/lib/enum/enum';
import { convertToNumber } from 'src/lib/utils/utils';

export const FinancialChart = memo(({ data, dataType, tf }) => {
  const chartRef = useRef(null);

  // Prepare the xAxis data
  const xAxisData = useMemo(
    () =>
      data.map((item) =>
        StatementTf.Annual === tf
          ? item.date.split('-')[0]
          : `${item.period} ${item.filling_date.split('-')[0].slice(2)}`,
      ),
    [data, tf],
  );

  // Formatter function for Y-axis labels and tooltips
  const numberFormatter = (value) => {
    const absValue = Math.abs(value); // Get the absolute value to format
    let formattedValue = '';

    if (absValue >= 1e9) {
      formattedValue = `${(absValue / 1e9).toFixed(2)}B`; // Billion
    } else if (absValue >= 1e6) {
      formattedValue = `${(absValue / 1e6).toFixed(2)}M`; // Million
    } else if (absValue >= 1e3) {
      formattedValue = `${(absValue / 1e3).toFixed(2)}K`; // Thousand
    } else {
      formattedValue = absValue; // Less than 1000
    }

    return value < 0 ? `-${formattedValue}` : formattedValue; // Add the negative sign back if needed
  };
  // Y-axis options using the formatter
  const yAxisOption = useMemo(
    () => [
      {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#FFFF',
          },
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255, 255, 255, 0.09)',
          },
        },
        axisLabel: {
          formatter: numberFormatter, // Use custom formatter here
        },
      },
      {
        type: 'value',
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: true,
        },
      },
    ],
    [],
  );

  let legend = [];
  const barSeries = useMemo(() => {
    let barSeries1 = [];

    if (dataType === FinancialDataType.Income) {
      legend = ['Revenue', 'Net Income', 'Profit Margin'];
      barSeries1 = [
        {
          type: 'bar',
          name: 'Revenue',
          data: data.map((item) => convertToNumber(item.revenue)),
          yAxisIndex: 0,
          itemStyle: {
            color: '#448aff',
          },
        },
        {
          type: 'bar',
          name: 'Net Income',
          data: data.map((item) => convertToNumber(item.net_income)),
          yAxisIndex: 0,
          itemStyle: {
            color: '#4dd0e1',
          },
        },
      ];
    }

    if (dataType === FinancialDataType.BalanceSheet) {
      legend = ['Total Current Assets', 'Total current liabilities'];
      barSeries1 = [
        {
          type: 'bar',
          name: 'Total Current Assets',
          data: data.map((item) => convertToNumber(item.total_current_assets)),
          yAxisIndex: 0,
          itemStyle: {
            color: '#448aff',
          },
        },
        {
          type: 'bar',
          name: 'Total current liabilities',
          data: data.map((item) => convertToNumber(item.total_current_liabilities)),
          yAxisIndex: 0,
          itemStyle: {
            color: '#4dd0e1',
          },
        },
      ];
    }

    if (dataType === FinancialDataType.Cashflow) {
      legend = ['Operating Cash Flow', 'Financing Cash Flow', 'Investing Cash Flow'];
      barSeries1 = [
        {
          type: 'bar',
          name: 'Investing Cash Flow',
          data: data.map((item) => convertToNumber(item.net_cash_used_for_investing_activites)),
          yAxisIndex: 0,
          itemStyle: {
            color: '#4dd0e1',
          },
        },
        {
          type: 'bar',
          name: 'Operating Cash Flow',
          data: data.map((item) => convertToNumber(item.operating_cash_flow)),
          yAxisIndex: 0,
          itemStyle: {
            color: '#ffff',
          },
        },
        {
          type: 'bar',
          name: 'Financing Cash Flow',
          data: data.map((item) => convertToNumber(item.net_cash_used_provided_by_financing_activities)),
          yAxisIndex: 0,
          itemStyle: {
            color: 'rgb(240, 98, 146)',
          },
        },
      ];
    }

    return barSeries1;
  }, [data, dataType]);

  const lineSeries = useMemo(() => {
    let lineSeries1 = [];
    if (dataType === 'Income Statement') {
      lineSeries1 = [
        {
          type: 'line',
          name: 'Profit Margin',
          data: data.map((item) => (item.netIncomeRatio * 100).toFixed(2)),
          yAxisIndex: 1,
          lineStyle: {
            width: 3,
          },
          symbol: 'circle',
          symbolSize: 8,
          itemStyle: {
            color: '#f57f17',
          },
        },
      ];
    }
    return lineSeries1;
  }, [data, dataType]);

  const createChart = useCallback(() => {
    const chart = echarts.init(chartRef.current);
    const option = {
      xAxis: {
        type: 'category',
        data: xAxisData,
        axisLabel: {
          color: '#ffffff',
        },
        axisLine: {
          lineStyle: {
            color: '#ffffff',
          },
        },
      },
      yAxis: yAxisOption,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderColor: 'rgba(0, 0, 0, 0.8)',
        textStyle: {
          color: '#ffffff',
        },
        formatter: (params) => {
          // Custom tooltip formatter to display formatted values
          return params.map((param) => `${param.seriesName}: ${numberFormatter(param.data)}`).join('<br/>');
        },
      },
      legend: {
        data: legend,
        textStyle: {
          color: '#ffffff',
        },
        padding: [10, 10, 10, 10],
      },
      series: [...barSeries, ...lineSeries],
    };
    chart.setOption(option);

    // Clean up the chart instance when the component unmounts
    return () => {
      chart.dispose();
    };
  }, [xAxisData, yAxisOption, barSeries, lineSeries]);

  useEffect(() => {
    const cleanup = createChart();

    return () => {
      cleanup();
    };
  }, [createChart]);

  return <div ref={chartRef} style={{ width: '100%', height: '400px' }} />;
});
