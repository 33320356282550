import React from 'react';
import { TOLCircleIcon } from 'src/assets/Images/';
import { data } from 'src/static-data';

function ExecutedOrders() {
  return (
    <section className='executedorders'>
      <table>
        <thead>
          <tr>
            <th>Ticker</th>
            <th>QTY</th>
            <th>WAC</th>
            <th>Action</th>
            <th>P/L</th>
            <th>% P/L</th>
            <th>iFIR$T ID</th>
            <th>Broker ID</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {data.excutedOrders.map((item) => (
            <tr key={item.id}>
              <td>
                <img src={TOLCircleIcon} alt='' />
                {item.ticker}
              </td>
              <td>{item.qty}</td>
              <td>{item.wac}</td>
              <td>{item.action}</td>
              <td>{item.pl}</td>
              <td>{item.plPercent}</td>
              <td>{item.iFirstID}</td>
              <td>{item.brokerID}</td>
              <td>{item.type}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}

export default ExecutedOrders;
