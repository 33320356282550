import React from 'react';
import Survey from 'src/components/Survey/Survey.component';

export default function RiskProfile() {
  return (
    <div className='riskProfile navbar-fix'>
      <Survey />
    </div>
  );
}
