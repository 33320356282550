import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from 'src/lib/types/error';
export interface IDataState {
  isLoading: boolean;
  data: any | null;
  error: IError | null;
}
const INITIAL_STATE: IDataState = {
  isLoading: false,
  data: null,
  error: null,
};
const supportDetailsSlice = createSlice({
  name: 'supportDetails',
  initialState: INITIAL_STATE,
  reducers: {
    fetchSupportDetail(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    fetchSupportDetailSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchSupportDetailFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchSupportDetail, fetchSupportDetailSuccess, fetchSupportDetailFailure } = supportDetailsSlice.actions;
export const supportDetailsReducer = supportDetailsSlice.reducer;

const supportTicketSlice = createSlice({
  name: 'supportTickets',
  initialState: INITIAL_STATE,
  reducers: {
    fetchSupportTicket(state) {
      state.isLoading = true;
    },
    fetchSupportTicketSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchSupportTicketFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchSupportTicket, fetchSupportTicketSuccess, fetchSupportTicketFailure } = supportTicketSlice.actions;
export const supportTicketsReducer = supportTicketSlice.reducer;

const supportFaqSlice = createSlice({
  name: 'supportFaq',
  initialState: INITIAL_STATE,
  reducers: {
    fetchSupportFaq(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    fetchSupportFaqSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchSupportFaqFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchSupportFaq, fetchSupportFaqSuccess, fetchSupportFaqFailure } = supportFaqSlice.actions;
export const supportFaqReducer = supportFaqSlice.reducer;

const supportSectionSlice = createSlice({
  name: 'supportSection',
  initialState: INITIAL_STATE,
  reducers: {
    fetchSupportSection(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    fetchSupportSectionSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchSupportSectionFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchSupportSection, fetchSupportSectionSuccess, fetchSupportSectionFailure } =
  supportSectionSlice.actions;
export const supportSectionReducer = supportSectionSlice.reducer;

const supportVideoSlice = createSlice({
  name: 'supportVideo',
  initialState: INITIAL_STATE,
  reducers: {
    fetchSupportVideo(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    fetchSupportVideoSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchSupportVideoFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchSupportVideo, fetchSupportVideoSuccess, fetchSupportVideoFailure } = supportVideoSlice.actions;
export const supportVideoReducer = supportVideoSlice.reducer;

const supportCreateTicketSlice = createSlice({
  name: 'supportCreateTicket',
  initialState: INITIAL_STATE,
  reducers: {
    supportCreateTicket(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    supportCreateTicketSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    supportCreateTicketFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { supportCreateTicket, supportCreateTicketSuccess, supportCreateTicketFailure } =
  supportCreateTicketSlice.actions;
export const supportCreateTicketReducer = supportCreateTicketSlice.reducer;
