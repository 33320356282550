/* eslint-disable prefer-promise-reject-errors */
import axios, { InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import { ACCESS_TOKEN_KEY } from 'src/constants/token.constant';
import CookieToken from '../lib/session-token';
// import { TokenValidate } from 'src/lib/auth';
import { config } from 'src/lib/config';
interface IWindow extends Window {
  env: {
    REACT_APP_API_URL: string;
    // Add other environment variables as needed
  };
}
declare const window: IWindow;

// Use the API_URL from the environment variables
const baseurl = window.env.REACT_APP_API_URL;
const host = `${baseurl}${config.API_VER}`;

export const apiClient = axios.create({
  baseURL: host,
});

const logOnDev = (message: string, log: any) => {
  // if (config.NODE_ENV === 'development') {
  console.log(message, log);
  // }
};

apiClient.interceptors.request.use((request: InternalAxiosRequestConfig) => {
  const { method, url } = request;
  // logOnDev(`🚀 [${method?.toUpperCase()}] ${url} | Request`, request);
  // if (url?.includes(config.MGMT_SIGNIN_INITIATE)) return request;
  // if (url?.includes(config.MGMT_SIGNIN)) return request;
  // if (url?.includes(config.MGMT_SIGNUP_INITIATE)) return request;
  // if (url?.includes(config.MGMT_CAROUSAL)) return request;
  // if (url?.includes(config.MGMT_SIGNUP)) return request;
  // if (url?.includes(config.MGMT_FORGOT_PASSWORD_INITIATE)) return request;
  // if (url?.includes(config.MGMT_FORGOT_PASSWORD)) return request;
  // if (url?.includes(config.API_SVC_PATH_IFIRST_ACADEMY)) return request;
  // if (url?.includes(config.API_SVC_PATH_IFIRST_CLUB) && method !== 'post') return request;
  // if (url?.includes(config.API_SUPPORT_TICKET) && method !== 'get') return request;
  // if (
  //   url?.includes(config.API_SUPPORT_FAQ) ||
  //   url?.includes(config.API_SUPPORT_FAQ_DETAILS) ||
  //   url?.includes(config.API_SUPPORT_SECTION) ||
  //   url?.includes(config.API_SUPPORT_SHORT_VIDEO)
  // )
  //   return request;
  // const sub = config.API_SVC_PATH_IFIRST_SUBSCRIPTION! + config.SUBSCRIPTION_META_DATA!;
  // if (url?.includes(sub)) return request;
  const jwtToken = CookieToken.getToken(ACCESS_TOKEN_KEY);
  // TokenValidate();
  if (jwtToken) {
    request.headers.Authorization = `Bearer ${jwtToken}`;
    // request.headers['Content-Type'] = 'application/json';
  }

  logOnDev(`🚀 [${method?.toUpperCase()}] ${url} | Request`, request);

  return request;
});

apiClient.interceptors.response.use(
  (response: AxiosResponse) => {
    const { method, url } = response.config;
    const { status } = response;

    logOnDev(`✨ [${method?.toUpperCase()}] ${url} | Response ${status}`, response);

    return response;
  },
  (error) => {
    try {
      const { message } = error;
      const { status, data } = error.response;
      const { method, url } = error.config;
      logOnDev(`🚨 [${method?.toUpperCase()}] ${url} | Error ${status} ${data?.message || ''} | ${message}`, error);
      return Promise.reject({
        status,
        message: error.response.data.error.message,
      });
    } catch (err) {
      if (err === 'response is undefined') {
        return Promise.reject({
          status: 503,
          message: 'You are not connected to the server',
        });
      }
    }
  },
);

// for s3 requests
export const axiosClientS3 = axios.create();
