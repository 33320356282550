import { put, takeLatest } from 'redux-saga/effects';
import { getCarousalInfo } from 'src/repositories/mgmt/mgmt';
import { fetchCarousel, fetchCarouselFailure, fetchCarouselSuccess } from './Topbar.reducer';
import { IError } from 'src/lib/types/error';
function* fetchCarouselSaga(action) {
  try {
    const data = yield getCarousalInfo(action.payload).then((response) => response);
    yield put(fetchCarouselSuccess(data.data.data));
  } catch (error) {
    yield put(fetchCarouselFailure(error as IError));
  }
}

export function* watchCarouselSaga() {
  yield takeLatest(fetchCarousel.type, fetchCarouselSaga);
}
