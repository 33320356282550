import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { fetchHeatmapMetaData } from './heatmap.reducer';
import { useSelector } from 'react-redux';
import { selectHeatmapState } from './heatmap.selector';
import { PrimaryHeader } from 'src/components';
import { HeatmapFilterControls } from 'src/components/heatmapComponents/filterControls';
import { HeatmapColorScale } from 'src/components/heatmapComponents/colorScale';
import { HeatmapBody } from 'src/components/heatmapComponents/heatmapBody';
import { selectHeatmapMetaData } from './heatmap.selector';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { selectHeatmapFilter } from 'src/pages/Heatmap/heatmap.selector';

export default function Heatmap() {
  const dispatch = useDispatch();
  const heatMapData = useSelector(selectHeatmapState);
  const heatMapFilteredData = useSelector(selectHeatmapFilter);
  const metaData = useSelector(selectHeatmapMetaData);
  const [market, setMarket] = useState('UsStocks');
  const [marketIndex, setMarketIndex] = useState('UsStocks');
  const [searchQuery, setSearchQuery] = useState('');
  const [timeFrame, setTimeframe] = useState('null');
  const [filter, setFilter] = useState({ percentage: null, symbol: null });
  const [advancedFilterActive, setAdvancedFilteredActive] = useState(false);
  const [sectors, setSectors] = useState('');
  const [groupBy, setGroupBy] = useState('');

  useEffect(() => {
    dispatch(fetchHeatmapMetaData());
  }, []);

  useEffect(() => {
    if (metaData) {
      const sectors = Object.keys(metaData.sector_industry);
      setSectors(sectors);
    }
  }, [metaData]);

  const filteredData = useMemo(() => {
    if (!heatMapData?.data) return [];

    const searchFilteredData = heatMapData.data.filter((item) =>
      item.ticker.toLowerCase().includes(searchQuery.toLowerCase()),
    );

    if (advancedFilterActive && heatMapFilteredData) {
      const filteredTickers = heatMapFilteredData.map((e) => e.ticker);
      return searchFilteredData.filter((item) => filteredTickers.includes(item.ticker));
    }

    return searchFilteredData;
  }, [heatMapData, searchQuery, advancedFilterActive, heatMapFilteredData]);

  const updateFilter = (percentage, symbol) => {
    if ((percentage, symbol)) setFilter({ percentage: null, symbol: null });
    if (filter.percentage === percentage && filter.symbol === symbol) {
      setFilter({ percentage: null, symbol: null });
    } else setFilter({ percentage, symbol });
  };

  return (
    <>
      <section className='navbar-fix'>
        <PrimaryHeader text='Heatmap' className='header-primary' />
        <div className='heatmap '>
          <HeatmapFilterControls
            market={market}
            sectors={sectors}
            setMarket={setMarket}
            marketIndex={marketIndex}
            setMarketIndex={setMarketIndex}
            setTimeframe={setTimeframe}
            setGroupBy={setGroupBy}
            setAdvancedFilteredActive={setAdvancedFilteredActive}
          />

          <main className='heatmap-body'>
            <div className='heatmap-sidebar'>
              <div className='relative py-6'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className='size-6  absolute top-[50%] -translate-y-1/2 left-4'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z'
                  />
                </svg>

                <DebouncedInput
                  placeholder='Quick search ticker'
                  value={searchQuery}
                  className='heatmap__searchBar border border-[#667083] placeholder:text-[rgba(255, 255, 255, 0.2)]
                   bg-transparent rounded outline-none  focus:border-[$color-primary] text-lg	 font-semibold'
                  onChange={(e) => setSearchQuery(e)}
                />
              </div>
            </div>
            <div className='heatmap-main'>
              <HeatmapColorScale updateFilter={updateFilter} />

              {heatMapData?.isLoading ? (
                <div className='heatmap-card-container'>
                  {Array.from({ length: 10 }).map((_, colIndex) => (
                    <Skeleton height='12.26rem' />
                  ))}
                </div>
              ) : (
                <HeatmapBody
                  heatMapData={heatMapData}
                  filteredData={filteredData}
                  groupBy={groupBy}
                  sectors={sectors}
                  timeFrame={timeFrame}
                  filter={filter}
                />
              )}
            </div>
          </main>
        </div>
      </section>
    </>
  );
}

function DebouncedInput({ value: initialValue, onChange, debounce = 500, ...props }) {
  const [value, setValue] = React.useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />;
}
