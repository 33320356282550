import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectWatchlistRatio } from '../Watchlist.selector';
import { fetchWatchlistRatio } from '../Watchlist.reducer';
import { data as staticData } from 'src/static-data';

const TableRow = ({ ratioName, formula, value, description }) => (
  <tr key={ratioName}>
    <td>{ratioName}</td>
    <td className='ratios-table-formula'>
      <span>{formula}</span>
      <div className='ratios-table-formula-divider' />
      <span>Current Liabilities</span>
    </td>
    <td>{value}</td>
    <td>
      <p>{description}</p>
    </td>
  </tr>
);

const Ratios = () => {
  const { ticker, market } = useParams();
  const [active, setActive] = useState('tab-1');
  const ratio = useSelector(selectWatchlistRatio);
  const dispatch = useDispatch();

  useEffect(() => {
    if (ticker && market) {
      dispatch(fetchWatchlistRatio({ ticker, market }));
    }
  }, [ticker, market, dispatch]);

  const renderTableContent = () => {
    switch (active) {
      case 'tab-1':
        return (
          <div className='ratios-table'>
            <table>
              <tbody>
                {staticData.watchlist.ratios.liquidityMeasurementRatiosData.map(
                  ({ name, formula, valueKey, description }) => (
                    <TableRow
                      key={name}
                      ratioName={name}
                      formula={formula}
                      value={ratio?.data?.liquidity_measurement_ratios?.[valueKey]}
                      description={description}
                    />
                  ),
                )}
              </tbody>
            </table>
          </div>
        );
      case 'tab-2':
        return (
          <div className='ratios-table'>
            <table>
              <tbody>
                {staticData.watchlist.ratios.profitabilityIndicatorRatiosData.map(
                  ({ name, formula, valueKey, description }) => (
                    <TableRow
                      key={name}
                      ratioName={name}
                      formula={formula}
                      value={ratio?.data?.profitability_indicator_ratios?.[valueKey]}
                      description={description}
                    />
                  ),
                )}
              </tbody>
            </table>
          </div>
        );
      case 'tab-3':
        return (
          <div className='ratios-table'>
            <table>
              <tbody>
                {staticData.watchlist.ratios.debtRatiosData.map(({ name, formula, valueKey, description }) => (
                  <TableRow
                    key={name}
                    ratioName={name}
                    formula={formula}
                    value={ratio?.data?.debt_ratios?.[valueKey]}
                    description={description}
                  />
                ))}
              </tbody>
            </table>
          </div>
        );
      case 'tab-4':
        return (
          <div className='ratios-table'>
            <table>
              <tbody>
                {staticData.watchlist.ratios.operatingPerformanceRatiosData.map(
                  ({ name, formula, valueKey, description }) => (
                    <TableRow
                      key={name}
                      ratioName={name}
                      formula={formula}
                      value={ratio?.data?.operating_performance_ratios?.[valueKey]}
                      description={description}
                    />
                  ),
                )}
              </tbody>
            </table>
          </div>
        );
      case 'tab-5':
        return (
          <div className='ratios-table'>
            <table>
              <tbody>
                {staticData.watchlist.ratios.cashFlowIndicatorRatiosData.map(
                  ({ name, formula, valueKey, description }) => (
                    <TableRow
                      key={name}
                      ratioName={name}
                      formula={formula}
                      value={ratio?.data?.cashFlow_indicator_ratios?.[valueKey]}
                      description={description}
                    />
                  ),
                )}
              </tbody>
            </table>
          </div>
        );
      case 'tab-6':
        return (
          <div className='ratios-table'>
            <table>
              <tbody>
                {staticData.watchlist.ratios.investmentValuationRatiosData.map(
                  ({ name, formula, valueKey, description }) => (
                    <TableRow
                      key={name}
                      ratioName={name}
                      formula={formula}
                      value={ratio?.data?.investment_valuation_ratios?.[valueKey]}
                      description={description}
                    />
                  ),
                )}
              </tbody>
            </table>
          </div>
        );
      default:
        return null;
    }
  };
  const getNavclassName = (val) => `ratios-navbar-item ${val ? 'active' : ''}`;
  return (
    <main className='ratios'>
      <div className='ratios-navbar'>
        <button onClick={() => setActive('tab-1')} className={getNavclassName(active === 'tab-1')}>
          Liquidity Measurement ratios
        </button>
        <button onClick={() => setActive('tab-2')} className={getNavclassName(active === 'tab-2')}>
          Profitability Indicator ratios
        </button>
        <button onClick={() => setActive('tab-3')} className={getNavclassName(active === 'tab-3')}>
          Debt ratios
        </button>
        <button onClick={() => setActive('tab-4')} className={getNavclassName(active === 'tab-4')}>
          Operating Performance ratios
        </button>
        <button onClick={() => setActive('tab-5')} className={getNavclassName(active === 'tab-5')}>
          Cash Flow Indicator ratios
        </button>
        <button onClick={() => setActive('tab-6')} className={getNavclassName(active === 'tab-6')}>
          Investment Valuation Ratios
        </button>
      </div>
      <section className='ratios-table-container'>{renderTableContent()}</section>
    </main>
  );
};

export default Ratios;
