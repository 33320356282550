import React, { useEffect } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { BackTestingHeader } from 'src/components/Header/header.component';
import { BacktestingDummyMainChart, BacktestingDummyAppleLogo } from 'src/assets/Images';

function BackTesting() {
  const nav = useNavigate();
  const navigateCall = () => {
    nav('statistics');
  };

  useEffect(() => {
    navigateCall();
  }, []);

  return (
    <section className='navbar-fix'>
      <BackTestingHeader text='Backtesting' />
      <section className='backtesting'>
        <section className='backtesting__body'>
          {/* Top - Details Section */}
          <section className='backtesting__top'>
            <div className='backtesting__details'>
              <img src={BacktestingDummyAppleLogo} alt='' />
              <p>
                AAPL <span>(Apple Inc.)</span>{' '}
              </p>
            </div>
            <div className='backtesting__details'>
              <h2>Date:</h2>
              <p>01-01-1990 - 01-04-2022</p>
            </div>
            <div className='backtesting__details'>
              <h2>INITIAL AMOUNT :</h2>
              <p>$ 100,000</p>
            </div>
            <div className='backtesting__details'>
              <h2>TRANSACTION COST :</h2>
              <p>0.3 %</p>
            </div>
            <div className='backtesting__details'>
              <h2>TIMEFRAME :</h2>
              <p>15 weeks</p>
            </div>
          </section>
          {/* Chart Container */}
          <section className='backtesting__chart'>
            <img src={BacktestingDummyMainChart} alt='' />
          </section>
          {/* Navigation Tabs */}
          <h1>Important Informations</h1>
          <div className='backtesting__navigation'>
            <ul>
              <NavLink end activeClassName='active' to='/backtesting/statistics'>
                <li>Statistics</li>
              </NavLink>
              <NavLink end activeClassName='active' to='/backtesting/charts'>
                <li>Charts</li>
              </NavLink>
              <NavLink end activeClassName='active' to='/backtesting/trades'>
                <li>Trades</li>
              </NavLink>
              <NavLink end activeClassName='active' to='/backtesting/MonteCarloSimulation'>
                <li>MonteCarlo Simulation</li>
              </NavLink>
            </ul>
          </div>
          <hr />
          <section className='backtesting__container'>
            <Outlet />
          </section>
        </section>
      </section>
    </section>
  );
}

export default BackTesting;
