import { createSelector } from 'reselect';

const selectMarketEconomicIndicatorState = (state: { marketEconomicIndicator: any }) => state.marketEconomicIndicator;
const selectMarketHolidayState = (state: { marketHoliday: any }) => state.marketHoliday;
const selectMarketEconomicCalenderState = (state: { marketEconomicCalender: any }) => state.marketEconomicCalender;
const selectMarketTreasuryRateState = (state: { marketTreasuryRate: any }) => state.marketTreasuryRate;
const selectMarketEodPriceState = (state: { marketEodPrice: any }) => state.marketEodPrice;
const selectMarketPERatioState = (state: { marketPERatios: any }) => state.marketPERatios;
const selectMarketSectorPerformanceState = (state: { marketSectorsPerformance: any }) => state.marketSectorsPerformance;
const selectMarketTopStocksState = (state: { marketTopStocks: any }) => state.marketTopStocks;
const selectMarketNewsState = (state: { marketNews: any }) => state.marketNews;

export const selectMarketEconomicIndicator = createSelector([selectMarketEconomicIndicatorState], (state) => state);
export const selectMarketHoliday = createSelector([selectMarketHolidayState], (state) => state);
export const selectMarketEconomicCalender = createSelector([selectMarketEconomicCalenderState], (state) => state);
export const selectMarketTreasuryRate = createSelector([selectMarketTreasuryRateState], (state) => state);
export const selectMarketEodPrice = createSelector([selectMarketEodPriceState], (state) => state);
export const selectMarketPERatio = createSelector([selectMarketPERatioState], (state) => state);
export const selectMarketSectorPerformance = createSelector([selectMarketSectorPerformanceState], (state) => state);
export const selectMarketTopStocks = createSelector([selectMarketTopStocksState], (state) => state);
export const selectMarketNews = createSelector([selectMarketNewsState], (state) => state);
