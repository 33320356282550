class SessionTokenClass {
  // Method to get a token by key
  getToken(key: string): string | null {
    return sessionStorage.getItem(key);
  }

  // Method to set a token with a key
  setToken(key: string, token: string): void {
    sessionStorage.setItem(key, token);
  }

  // Method to remove a token by key
  removeToken(key: string): void {
    sessionStorage.removeItem(key);
  }
}
const SessionToken = new SessionTokenClass();
export default SessionToken;
