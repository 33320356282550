import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NewsCard } from 'src/components';
import { useDispatch, useSelector } from 'react-redux';
import { selectWatchlistNews } from '../../Watchlist.selector';
import { fetchWatchlistNews } from '../../Watchlist.reducer';
import { NewsCardloader } from 'src/components/loadingAnimation/cardloader';
import { selectMarketInfo } from 'src/store/MarketInfo/MarketInfo.selector';
import Pagination from 'src/components/Pagination/pagination.component';
export default function StockNews() {
  const marketInfo = useSelector(selectMarketInfo);

  const { ticker, market } = useParams();
  const [page, setPage] = useState(1);
  const [itemsPerPage] = useState(12);
  const dispatch = useDispatch();
  const news = useSelector(selectWatchlistNews);

  useEffect(() => {
    const mkt = market[0].toUpperCase() + market.slice(1, market.length);
    dispatch(fetchWatchlistNews({ market_type: marketInfo?.market_supported_map[mkt], ticker: ticker?.toUpperCase() }));
  }, [market, ticker, dispatch]);

  const handleChangePage = (value) => {
    setPage(value);
  };

  const paginatedNews = news?.data ? news.data.slice((page - 1) * itemsPerPage, page * itemsPerPage) : [];

  return (
    <main className='watchlistnews'>
      <div className='watchlistnews-container'>
        {news?.isLoading ? (
          Array.from({ length: 9 }).map((_, rowIndex) => <NewsCardloader key={rowIndex} />)
        ) : paginatedNews.length > 0 ? (
          paginatedNews.map((item, index) => <NewsCard item={item} key={index} />)
        ) : (
          <p>No news available.</p>
        )}
      </div>
      <div className='watchlistnews-paginate'>
        {news?.data && (
          <Pagination totalPages={Math.ceil(news.data.length / itemsPerPage)} onPageChange={handleChangePage} />
        )}
      </div>
    </main>
  );
}
