import React from 'react';
import { TOLDummyProgressIcon } from 'src/assets/Images';
import { data } from 'src/static-data';

function PositionHoldings() {
  return (
    <section className='positionholdings'>
      <div className='positionholdings__left'>
        <div className='positionholdings__table'>
          <table>
            <thead>
              <tr>
                <th>Instrument</th>
                <th>Qty</th>
                <th>Avg. cost</th>
                <th>LTP</th>
                <th>Cur. val</th>
                <th>P&L</th>
                <th>Net chg.</th>
                <th>Day chg.</th>
              </tr>
            </thead>
            <tbody>
              {data.positionHolding.map((item) => (
                <tr key={item.id}>
                  <td>{item.instrument}</td>
                  <td>{item.qty}</td>
                  <td>{item.avgCost}</td>
                  <td>{item.ltp}</td>
                  <td>{item.currValue}</td>
                  <td>{item.pl}</td>
                  <td>{item.netChange}</td>
                  <td>{item.dayChange}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className='positionholdings__right'>
        <div className='positionholdings__row'>
          <div className='positionholdings__colFirst'>
            <h4>Total investment</h4>
            <h2>3,015.00</h2>
          </div>
          <div className='positionholdings__colFirst'>
            <h4>Current value</h4>
            <h2>3,015.00</h2>
          </div>
        </div>
        <div className='positionholdings__row'>
          <div className='positionholdings__colThird'>
            <h4>Day’s P&L</h4>
            <h2> -2.24 </h2>
            <p>(-0.07%)</p>
          </div>
          <div className='positionholdings__colFourth'>
            <h4>Total P&L</h4>
            <h2>-2.24 </h2>
            <p>(-0.07%)</p>
          </div>
        </div>
        <div className='positionholdings__bar'>
          <img src={TOLDummyProgressIcon} alt='' />
        </div>
        <div className='positionHoldings__optionsContainer'>
          <div className='positionHoldings__element'>
            <input type='radio' id='age1' name='age' />
            <label htmlFor='age1'>Current value</label>
          </div>
          <div className='positionHoldings__element'>
            <input type='radio' id='age1' name='age' />
            <label htmlFor='age1'>Investment value</label>
          </div>
          <div className='positionHoldings__element'>
            <input type='radio' id='age1' name='age' />
            <label htmlFor='age1'>P&L</label>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PositionHoldings;
