import HTMLReactParser from 'html-react-parser';
import { cardDummyImage } from 'src/assets/Images';
import { formattedDate } from 'src/lib/utils/utils';
export function NewsCard({ item }: { item: any }) {
  return (
    <a href={item.url} target='_blank' rel='noreferrer' className='card bg-base-100   shadow-xl'>
      <figure>
        <img alt='' src={item.image || cardDummyImage} />
      </figure>
      <div className='card-body'>
        <h2 className='card-title'>{item.title}</h2>
        <p>{HTMLReactParser(item.text)}</p>
        <div className='card-actions club '>
          <div>{formattedDate(item.publishedDate)}</div>
        </div>
      </div>
    </a>
  );
}

export function NewsCardSm({ item }) {
  return (
    <a href={item.url} target='_blank' rel='noreferrer' className='overview-news'>
      <figure>
        <img src={item.image} alt={item.site} />
      </figure>
      <div>
        <h3>{item.title}</h3>
        <p>{item.publishedDate.split('T')[0]}</p>
        <p>{item.text}</p>
      </div>
    </a>
  );
}
export function MarketsNews({ data }) {
  return (
    <a href={data?.url} target='_blank' rel='noreferrer'>
      <article className='marketsNews'>
        <div className='marketsNews__left'>
          <img src={data?.image || cardDummyImage} alt='newsImage' />
        </div>
        <div className='marketsNews__right'>
          <div className='marketsNews__rightHeader'>
            <h1>{data?.title}</h1>
          </div>
          <p>{data?.text}</p>
          <div className='marketsNews__bottomSection'>
            <p>Site : {data?.site}</p>
            {data?.symbol ? <p>Symbol : {data?.symbol}</p> : ''}
            <h2>{formattedDate(data?.publishedDate)}</h2>
          </div>
        </div>
      </article>
    </a>
  );
}
