import React from 'react';
import {
  BacktestingChartEightImage,
  BacktestingChartNineImage,
  BacktestingChartSevenImage,
  BacktestingChartSixImage,
} from 'src/assets/Images';
import { data } from 'src/static-data';
function MonteCarloSimulation() {
  return (
    <div className='monteCarlo'>
      {/* Table */}
      <div className='monteCarlo__table'>
        <table>
          <thead>
            <tr>
              <th>Percentile</th>
              <th>Final Equity</th>
              <th>Annual Return</th>
              <th>Max Drawdown $</th>
              <th>Max Drawdown %</th>
              <th>Lowest Eq.</th>
            </tr>
          </thead>
          <tbody>
            {data?.montecarlo.map((item) => (
              <tr key={item.id}>
                <td>{item.percentile}</td>
                <td>{item.equity}</td>
                <td>{item.return}</td>
                <td>{item.maxDollar}</td>
                <td>{item.maxPercentage}</td>
                <td>{item.lowEquity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Charts */}
      <div className='monteCarlo__charts'>
        {/* First */}
        <div className='monteCarlo__container'>
          <div className='monteCarlo__header'>
            <h2>1. MC Min/Max Equity</h2>
          </div>
          <div className='monteCarlo__singleChart'>
            <img src={BacktestingChartSixImage} alt='' />
          </div>
        </div>
        {/* Second */}
        <div className='monteCarlo__container'>
          <div className='monteCarlo__header'>
            <h2>2. MC Final Equity</h2>
          </div>
          <div className='monteCarlo__singleChart'>
            <img src={BacktestingChartSevenImage} alt='' />
          </div>
        </div>
        {/* Third */}
        <div className='monteCarlo__container'>
          <div className='monteCarlo__header'>
            <h2>3. MC Car</h2>
          </div>
          <div className='monteCarlo__singleChart'>
            <img src={BacktestingChartEightImage} alt='' />
          </div>
        </div>
        {/* Fourth */}
        <div className='monteCarlo__container'>
          <div className='monteCarlo__header'>
            <h2>4. MC Drawdown</h2>
          </div>
          <div className='monteCarlo__singleChart'>
            <img src={BacktestingChartNineImage} alt='' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MonteCarloSimulation;
