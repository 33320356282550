import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
export default function SearchBar({ text, className }) {
  return (
    <div className={className}>
      <MagnifyingGlassIcon className='size-8' />
      <input type='search' placeholder={text || 'Search....'} />
    </div>
  );
}

export function DebouncedSearchBar({ className, delay, index = '', marketType = '' }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const nav = useNavigate();

  const handleChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  };

  return (
    <div className={`${className} position: relative;`}>
      <MagnifyingGlassIcon className='size-8' />
      <input type='search' placeholder='Search....' value={searchTerm} onChange={handleChange} />
      {filteredData.length > 0 ? (
        <ul
          className='absolute top-[110%] left-0 bg-[#1E222D] 
       border border-[#ffffff26]  overflow-hidden z-[20] px-4  rounded-md'>
          {filteredData?.map((item, index) => (
            <li
              className='w-full border-b border-[#ffffff26]
                         bg-[#1E222D] py-3 hover:bg-[#21314E]  cursor: pointer;'
              onClick={() => {
                nav(`/watchlist/${item}/${marketType}`);
                setFilteredData([]);
              }}
              key={index}>
              <div className=' display: flex;align-items: center;  justify-content: space-between;'>
                <div className=' display: flex;align-items: center; gap-4 '>
                  <div className='h-9 w-9 rounded-full overflow-hidden'>
                    <img src={item.logo} alt='logo' />
                  </div>
                  <h1 className='font-bold text-xl'>{item.ticker}</h1>
                </div>
                <span className='font-normal text-lg max-w-[8rem] w-[7rem] text-ellipsis whitespace-'>{item.name}</span>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        ''
      )}
    </div>
  );
}
