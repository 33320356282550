import React, { useEffect, useRef, useMemo, memo, useCallback } from 'react';
import * as echarts from 'echarts';
import { StatementTf } from 'src/lib/enum/enum';
export const AnalystEstimateChart = memo(({ data, tf, activeReported, activeEstimated, activeSurprise }) => {
  const chartRef = useRef(null);

  const xAxisData = useMemo(
    () => data.map((item) => (tf === StatementTf.Annual ? item.Date : `${item.period}`)),
    [data],
  );
  const yAxisOption = useMemo(
    () => [
      {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#FFFF',
          },
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255, 255, 255, 0.09)',
          },
        },
        axisLabel: {
          formatter: '{value}',
        },
      },
      {
        type: 'value',
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: true,
        },
      },
    ],
    [],
  );
  const legend = ['Reported', 'Estimate', 'Surprise'];
  const barSeries = useMemo(
    () => [
      activeReported && {
        type: 'bar',
        name: 'Reported',
        data: data.map((item) => (item.reported !== 0 ? item.reported : '')),
        yAxisIndex: 0,
        itemStyle: {
          color: '#448aff',
          borderRadius: [5, 5, 0, 0],
        },
      },
      activeEstimated && {
        type: 'bar',
        name: 'Estimate',
        data: data.map((item) => item.estimated),
        yAxisIndex: 0,
        itemStyle: {
          color: '#434651',
          borderRadius: [5, 5, 0, 0],
        },
      },
    ],
    [data, activeEstimated, activeReported],
  );

  const lineSeries = useMemo(
    () => [
      activeSurprise && {
        type: 'line',
        name: 'Surprise',
        data: data.map((item) => (item.surprise !== 0 && item.surprise !== '0' ? item.surprise : '')),
        yAxisIndex: 1,
        lineStyle: {
          width: 3,
        },
        symbol: 'circle',
        symbolSize: 8,
        itemStyle: {
          color: '#4dd0e1',
        },
      },
    ],
    [data, activeSurprise],
  );

  const createChart = useCallback(() => {
    const chart = echarts.init(chartRef.current);
    const option = {
      xAxis: {
        type: 'category',
        data: xAxisData,
        axisLabel: {
          color: '#ffffff',
        },
        axisLine: {
          lineStyle: {
            color: '#ffffff',
          },
        },
      },
      yAxis: yAxisOption,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderColor: 'rgba(0, 0, 0, 0.8)',
        textStyle: {
          color: '#ffffff',
        },
      },
      legend: {
        data: legend,
        textStyle: {
          color: '#ffffff',
        },
        padding: [10, 10, 10, 10],
      },
      series: [...barSeries, ...lineSeries],
    };
    chart.setOption(option);

    // Clean up the chart instance when the component unmounts
    return () => {
      chart.dispose();
    };
  }, [xAxisData, yAxisOption, barSeries, lineSeries]);

  useEffect(() => {
    const cleanup = createChart();

    return () => {
      cleanup();
    };
  }, [createChart]);

  return <div ref={chartRef} style={{ width: '100%', height: '400px' }} />;
});
