import { Suspense, useEffect } from 'react';
import { useRoutes, BrowserRouter } from 'react-router-dom';
import { Navbar } from './components';
import Loader from './components/loadingAnimation/loadingAnimation.component';
import Checkout from './pages/subscription/checkout/subscriptionCheckout.router';
import { Success, Failed } from './pages/subscription/status/subscriptionStatus.router';
import { useDispatch } from 'react-redux';
import { fetchMarketInfo } from './store/MarketInfo/Marketinfo.reducer';
import { AuthGuard, GuestGaurd } from './hocs/auth/auth.hoc';
import { loggedIn } from './lib/auth';

import Marketing from './pages/Marketing/marketing.router';
import Login from './pages/Authentication/Login.router';
import Register from './pages/Authentication/Register.router';

import Heatmap from './pages/Heatmap/Heatmap.router';
import Reports from './pages/Reports/Reports.router';
import ForgotPassword from './pages/Authentication/ForgotPassword.router';
import MyWorkStation from './pages/MyWorkStation/index.router';
import Academy from './pages/Academy/academy.router';
import AcademyDetails from './pages/Academy/Details/details.router';
import Club from './pages/Club/club.router';
import ClubIndex from './pages/Club/index/index.router';
import ClubDetails from './pages/Club/Details/ClubDetails.router';
import Post from './pages/Club/Post/Post.router';
import Support from './pages/Support/SupportIndex.router';
import RaiseSupportTicket from './pages/Support/SupportRaiseTicket.router';
import FaqDetails from './pages/Support/SupportFaqDetails.router';
import SupportTickets from './pages/Support/SupportViewTickets.router';
import Markets from './pages/Markets/Markets.router';
import Subscription from './pages/subscription/subscription.router';
import Watchlist from './pages/Watchlist/Watchlist.router';
import Overview from './pages/Watchlist/overView/overView.router';
import Financials from './pages/Watchlist/Financials/financials.router';
import Ratios from './pages/Watchlist/Ratios/ratios.router';
import SentimentAnalysis from './pages/SocialSentiment/socialSentiment.router';
import About from './pages/About/About.router';
import Esg from './pages/Watchlist/ESG/esg.router';
import CorporateActions from './pages/Watchlist/CorporateAction/CorporateAction.router';
import News from './pages/Watchlist/News/news.router';
import DeepAnalysis from './pages/Watchlist/DeepAnalysis/DeepAnalysis.router';
import IfirstRanking from './pages/Watchlist/IfirstRanking/ifirstRanking.router';
import SocialSentiment from './pages/Watchlist/DeepAnalysis/SocialSentiment/SocialSentiment.router';
import AnalystEstimate from './pages/Watchlist/DeepAnalysis/AnalystEstimate/AnalystEstimate.router';
import Ownership from './pages/Watchlist/DeepAnalysis/Ownership/Ownership.router';
import RatingGrades from './pages/Watchlist/DeepAnalysis/RatingsGrades/RatingGrades.router';
import PressRelease from './pages/Watchlist/News/pressRelease/pressRelease.router';
import StockNews from './pages/Watchlist/News/stockNews/stockNews.router';
import AnalystRecommendation from './pages/Watchlist/DeepAnalysis/AnalystRecommendations/AnalystRecommendation.router';
import Screener from './pages/Screener/Screener.router';
import Profile from './pages/Profile/Index/Profile.router';
import AccountSubsciption from './pages/Profile/subscription/subscription.router';
import { SkeletonTheme } from 'react-loading-skeleton';
import BacktestResult from './pages/QuantPortfolio/BacktestResult/BacktestResult.router.jsx';
import Portfolio from './pages/Portfolio/Index/Portfolio.router';
import SectorPortfolio from './pages/Portfolio/Sector/Sector.router';
import MarketsPortfolio from './pages/Portfolio/Markets/Markets.router';
import RiskProfile from './pages/RiskProfile/Index/RiskProfile.router';
import RiskResult from './pages/RiskProfile/Result/RiskResult.router';
import TradeOrdersList from './pages/TradeOrdersList/Index/TradeOrdersList.router';
import BackTesting from './pages/BackTesting/Index/BackTesting.router';
import Statistics from './pages/BackTesting/Statistics/Statistics.router';
import Charts from './pages/BackTesting/Charts/Charts.router';
import Trades from './pages/BackTesting/Trades/Trades.router';
import IExpert from './pages/IPackage/IExpert/IExpert.router';
import MonteCarloSimulation from './pages/BackTesting/MonteCarloSimulation/MonteCarloSimulation.router';
import IRecommend from './pages/IPackage/IRecommend/IRecommend.router';
import Ipackage from './pages/IPackage/Index/Ipackage.router';
export function Router() {
  const isAuth = loggedIn();
  return useRoutes([
    {
      path: '/',
      element: <Navbar />,
      children: [
        { path: '', element: <Marketing /> },
        {
          path: 'myworkstation',
          element: (
            <GuestGaurd isAuth={isAuth}>
              <MyWorkStation />
            </GuestGaurd>
          ),
        },

        {
          path: 'login',
          element: (
            <AuthGuard isAuth={isAuth}>
              <Login />
            </AuthGuard>
          ),
        },
        {
          path: 'forgot-password',
          element: (
            <AuthGuard isAuth={isAuth}>
              <ForgotPassword />
            </AuthGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <AuthGuard isAuth={isAuth}>
              <Register />
            </AuthGuard>
          ),
        },

        { path: 'academy', element: <Academy /> },
        { path: 'academy/:id', element: <AcademyDetails /> },
        {
          path: 'club',
          element: <Club />,
          children: [
            { index: true, element: <ClubIndex /> },
            { path: ':id', element: <ClubDetails /> },
          ],
        },
        {
          path: 'create-post/club',
          element: (
            <GuestGaurd isAuth={isAuth}>
              <Post />
            </GuestGaurd>
          ),
        },

        { path: 'support', element: <Support /> },
        { path: 'support/ticket', element: <RaiseSupportTicket /> },
        { path: 'support/faq/:id', element: <FaqDetails /> },
        { path: 'support/tickets', element: <SupportTickets /> },
        {
          path: 'reports',
          element: (
            <GuestGaurd isAuth={isAuth}>
              <Reports />
            </GuestGaurd>
          ),
        },
        {
          path: 'heatmap',
          element: (
            <GuestGaurd isAuth={isAuth}>
              <Heatmap />
            </GuestGaurd>
          ),
        },
        { path: 'markets', element: <Markets /> },
        { path: 'subscription', element: <Subscription /> },
        { path: 'subscription/checkout', element: <Checkout /> },
        {
          path: 'subscription/paymentsuccess',
          element: (
            <GuestGaurd isAuth={isAuth}>
              <Success />
            </GuestGaurd>
          ),
        },
        {
          path: 'subscription/paymentfailed',
          element: (
            <GuestGaurd isAuth={isAuth}>
              <Failed />
            </GuestGaurd>
          ),
        },
        {
          path: 'watchlist/:ticker/:market?',
          element: (
            <GuestGaurd isAuth={isAuth}>
              <Watchlist />
            </GuestGaurd>
          ),
          children: [
            {
              path: '',
              element: (
                <GuestGaurd isAuth={isAuth}>
                  <Overview />
                </GuestGaurd>
              ),
            },
            {
              path: 'ratios',
              element: (
                <GuestGaurd isAuth={isAuth}>
                  <Ratios />
                </GuestGaurd>
              ),
            },
            {
              path: 'esg',
              element: (
                <GuestGaurd isAuth={isAuth}>
                  <Esg />
                </GuestGaurd>
              ),
            },
            {
              path: 'ranking',
              element: (
                <GuestGaurd isAuth={isAuth}>
                  <IfirstRanking />
                </GuestGaurd>
              ),
            },
            {
              path: 'financials',
              element: (
                <GuestGaurd isAuth={isAuth}>
                  <Financials />
                </GuestGaurd>
              ),
            },
            {
              path: 'corporate-actions',
              element: (
                <GuestGaurd isAuth={isAuth}>
                  <CorporateActions />
                </GuestGaurd>
              ),
            },
            {
              path: 'news',
              element: (
                <GuestGaurd isAuth={isAuth}>
                  <News />
                </GuestGaurd>
              ),
              children: [
                {
                  path: 'stock',
                  element: (
                    <GuestGaurd isAuth={isAuth}>
                      <StockNews />
                    </GuestGaurd>
                  ),
                },
                {
                  path: 'press-release',
                  element: (
                    <GuestGaurd isAuth={isAuth}>
                      <PressRelease />
                    </GuestGaurd>
                  ),
                },
              ],
            },
            {
              path: 'analyst-recommendation',
              element: (
                <GuestGaurd isAuth={isAuth}>
                  <AnalystRecommendation />
                </GuestGaurd>
              ),
            },
            {
              path: 'analyst-estimate',
              element: (
                <GuestGaurd isAuth={isAuth}>
                  <AnalystEstimate />
                </GuestGaurd>
              ),
            },
            {
              path: 'sentiment',
              element: (
                <GuestGaurd isAuth={isAuth}>
                  <SocialSentiment />
                </GuestGaurd>
              ),
            },
            {
              path: 'ownership',
              element: (
                <GuestGaurd isAuth={isAuth}>
                  <Ownership />
                </GuestGaurd>
              ),
            },
            {
              path: 'rating',
              element: (
                <GuestGaurd isAuth={isAuth}>
                  <RatingGrades />
                </GuestGaurd>
              ),
            },
            {
              path: 'deep-analysis',
              element: (
                <GuestGaurd isAuth={isAuth}>
                  <DeepAnalysis />
                </GuestGaurd>
              ),
            },
          ],
        },
        {
          path: 'screener',
          element: (
            <GuestGaurd isAuth={isAuth}>
              <Screener />
            </GuestGaurd>
          ),
        },
        {
          path: 'sentiment/:ticker',
          element: (
            <GuestGaurd isAuth={isAuth}>
              <SentimentAnalysis />
            </GuestGaurd>
          ),
        },
        {
          path: 'profile',
          element: (
            <GuestGaurd isAuth={isAuth}>
              <Profile />
            </GuestGaurd>
          ),
          children: [
            {
              path: 'subscription',
              element: (
                <GuestGaurd isAuth={isAuth}>
                  <AccountSubsciption />
                </GuestGaurd>
              ),
            },
          ],
        },
        {
          path: 'backtesting',
          element: (
            <GuestGaurd isAuth={isAuth}>
              <BackTesting />
            </GuestGaurd>
          ),
          children: [
            {
              path: 'statistics',
              element: (
                <GuestGaurd isAuth={isAuth}>
                  <Statistics />
                </GuestGaurd>
              ),
            },
            {
              path: 'charts',
              element: (
                <GuestGaurd isAuth={isAuth}>
                  <Charts />
                </GuestGaurd>
              ),
            },
            {
              path: 'trades',
              element: (
                <GuestGaurd isAuth={isAuth}>
                  <Trades />
                </GuestGaurd>
              ),
            },
            {
              path: 'MonteCarloSimulation',
              element: (
                <GuestGaurd isAuth={isAuth}>
                  <MonteCarloSimulation />
                </GuestGaurd>
              ),
            },
          ],
        },

        {
          path: 'thematic-portfolio',
          element: (
            <GuestGaurd isAuth={isAuth}>
              <Portfolio />
            </GuestGaurd>
          ),
        },
        {
          path: 'sector-portfolio',
          element: (
            <GuestGaurd isAuth={isAuth}>
              <SectorPortfolio />
            </GuestGaurd>
          ),
        },
        {
          path: 'markets-portfolio',
          element: (
            <GuestGaurd isAuth={isAuth}>
              <MarketsPortfolio />
            </GuestGaurd>
          ),
        },
        {
          path: 'custom-portfolio',
          element: (
            <GuestGaurd isAuth={isAuth}>
              <BacktestResult />
            </GuestGaurd>
          ),
        },
        {
          path: 'tradeorderslist',
          element: (
            <GuestGaurd isAuth={isAuth}>
              <TradeOrdersList />
            </GuestGaurd>
          ),
        },
        {
          path: 'iexpert',
          element: (
            <GuestGaurd isAuth={isAuth}>
              <IExpert />
            </GuestGaurd>
          ),
        },

        {
          path: 'irecommend',
          element: (
            <GuestGaurd isAuth={isAuth}>
              <IRecommend />
            </GuestGaurd>
          ),
        },
        {
          path: 'irecommend',
          element: (
            <GuestGaurd isAuth={isAuth}>
              <IRecommend />
            </GuestGaurd>
          ),
        },
        {
          path: 'ipackage',
          element: (
            <GuestGaurd isAuth={isAuth}>
              <Ipackage />
            </GuestGaurd>
          ),
        },
        {
          path: 'risk-profile',
          element: (
            <GuestGaurd isAuth={isAuth}>
              <RiskProfile />
            </GuestGaurd>
          ),
        },
        {
          path: 'risk-result',
          element: (
            <GuestGaurd isAuth={isAuth}>
              <RiskResult />
            </GuestGaurd>
          ),
        },
        { path: 'about', element: <About /> },
      ],
    },
  ]);
}
const App: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMarketInfo());
  }, [dispatch]);

  return (
    <>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <SkeletonTheme baseColor='#081E49' highlightColor='rgb(38, 57, 92)' borderRadius='0.5rem' duration={2}>
            <Router />
          </SkeletonTheme>
        </BrowserRouter>
      </Suspense>
    </>
  );
};

export default App;
