import React, { useState, useEffect, useRef } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
export default function Carousel({ children }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef(null);
  const [cardsToShow, setCardsToShow] = useState(1);

  const totalCards = React.Children.count(children);
  const extendedChildren = totalCards.length > 3 ? [...children, ...children, ...children] : [...children];

  useEffect(() => {
    const updateCardsToShow = () => {
      const carouselWidth = carouselRef.current?.offsetWidth;
      const cardWidth = carouselRef.current.querySelector('.card-carousel')?.offsetWidth;
      const newCardsToShow = Math.floor(carouselWidth / cardWidth);
      setCardsToShow(newCardsToShow);
    };

    updateCardsToShow();
    window.addEventListener('resize', updateCardsToShow);

    return () => {
      window.removeEventListener('resize', updateCardsToShow);
    };
  }, []);

  useEffect(() => {
    const handleTransitionEnd = () => {
      if (currentIndex >= totalCards) {
        setCurrentIndex(0);
        carouselRef.current.style.transition = 'none';
        carouselRef.current.style.transform = `translateX(0)`;
      } else if (currentIndex < 0) {
        setCurrentIndex(totalCards - 1);
        carouselRef.current.style.transition = 'none';
        carouselRef.current.style.transform = `translateX(-${(totalCards - 1) * (100 / cardsToShow)}%)`;
      }
    };

    const carouselElement = carouselRef.current;
    carouselElement.addEventListener('transitionend', handleTransitionEnd);
    return () => {
      carouselElement.removeEventListener('transitionend', handleTransitionEnd);
    };
  }, [currentIndex, totalCards, cardsToShow]);

  const updateCarousel = (newIndex) => {
    carouselRef.current.style.transition = 'transform 0.5s ease';
    setCurrentIndex(newIndex);
    carouselRef.current.style.transform = `translateX(-${newIndex * (100 / cardsToShow)}%)`;
  };

  const handleNext = () => {
    updateCarousel(currentIndex + 1);
  };

  const handlePrev = () => {
    updateCarousel(currentIndex - 1);
  };

  return (
    <div className='carousel-container '>
      <div className=' prev-button' onClick={handlePrev}>
        <ChevronLeftIcon className='size-8' />
      </div>

      <div className='carousel-wrapper'>
        <div
          className='carousel'
          ref={carouselRef}
          style={{ transform: `translateX(-${currentIndex * (102 / cardsToShow)}%)` }}>
          {React.Children.map(extendedChildren, (child, index) => (
            <div className='card-carousel' key={index}>
              {child}
            </div>
          ))}
        </div>
      </div>
      <div className='next-button' onClick={handleNext}>
        <ChevronRightIcon className='size-8' />
      </div>
    </div>
  );
}
