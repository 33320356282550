import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
export default function Tableloader({ count }) {
  return (
    <tbody>
      {Array.from({ length: 10 }).map((_, rowIndex) => (
        <tr key={rowIndex}>
          {Array.from({ length: count }).map((_, colIndex) => (
            <td className='td-loading' key={colIndex}>
              <Skeleton height='3.6rem' />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
}
