import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import VisibilitySensor from 'react-visibility-sensor';
import { data } from 'src/static-data';
import { MotionDiv } from '../motion/motion.component';
export default function Cta() {
  const [isVisible, setIsVisible] = useState(false);
  const isVisibleProp = (valid: boolean, prop1, prop2) => (valid ? prop1 : prop2);
  return (
    <VisibilitySensor partialVisibility onChange={(visible) => setIsVisible(visible)}>
      <div className='cta'>
        {data.CtaData.map((item, index) => (
          <MotionDiv
            key={item.id}
            className='cta__box'
            initial={{ opacity: 0, x: isVisibleProp(index < 2, -100, 100) }}
            animate={{
              opacity: isVisibleProp(isVisible, 1, 0),
              x: isVisibleProp(isVisible, 0, isVisibleProp(index < 2, -100, 100)),
            }}
            transition={{ duration: 0.5, delay: index * 0.4 }}>
            <img src={item.src} alt={item.text} />
            <p>
              <Link to={item.to}>{item.text}</Link>
            </p>
            <ChevronRightIcon className='w-16 h-16  ' />
          </MotionDiv>
        ))}
      </div>
    </VisibilitySensor>
  );
}
