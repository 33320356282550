import React, { useEffect, useRef, useState, memo, useMemo, useCallback } from 'react';
import { DataCardTwo, DataCardThree } from 'src/components/Card/card.component';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import * as echarts from 'echarts/core';

import {
  selectMarketEodPrice,
  selectMarketPERatio,
  selectMarketSectorPerformance,
  selectMarketTopStocks,
} from 'src/pages/Markets/markets.selector';
import {
  fetchMarketEodPrice,
  fetchMarketPERatios,
  fetchMarketSectorsPerformance,
  fetchMarketTopStocks,
} from 'src/pages/Markets/markets.reducer';
import { IMarketTopStocks, IEconomicIndicatorData } from 'src/lib/types/markets';
import { activeElement } from 'src/lib/utils/utils';
export default function MarketSidebar() {
  const [itemsToShowStock, setItemsToShowStock] = useState(4);
  const [stockType, setStockType] = useState('active');
  const [timelimit, setTimeLimit] = useState('Month1');
  const [activeTicker, setActiveTicker] = useState('');
  const dispatch = useDispatch();
  const eodData = useSelector(selectMarketEodPrice);
  const peRatio = useSelector(selectMarketPERatio);
  const topStocks = useSelector(selectMarketTopStocks);
  const sectorPerformance = useSelector(selectMarketSectorPerformance);
  const handleShowMoreStock = () => {
    setItemsToShowStock((prev) => prev + 10);
  };

  useEffect(() => {
    dispatch(fetchMarketSectorsPerformance());
    dispatch(fetchMarketPERatios());
    dispatch(fetchMarketTopStocks());
  }, []);
  useEffect(() => {
    if (topStocks?.data) {
      setActiveTicker(topStocks?.data[stockType][0].symbol);
      dispatch(fetchMarketEodPrice({ ticker: topStocks?.data[stockType][0].symbol, time_limit: timelimit }));
    }
  }, [topStocks.data, stockType]);
  const tfHandler = (tf: string) => {
    setTimeLimit(tf);
    dispatch(fetchMarketEodPrice({ ticker: activeTicker, time_limit: tf }));
  };

  return (
    <div className='markets-sidebar'>
      <section className={`markets-sidebar-charts ${itemsToShowStock > 10 ? 'overflow-y' : ''} `}>
        <div className='markets-sidebar-charts-header'>
          <h2>Stocks</h2>
          <h2>NYSE</h2>
        </div>
        <ul className=' menu menu-horizontal '>
          <li className={`${activeElement(stockType === 'active')} `} onClick={() => setStockType('active')}>
            Active
          </li>
          <li className={`${activeElement(stockType === 'losers')} `} onClick={() => setStockType('losers')}>
            Losers
          </li>
          <li className={`${activeElement(stockType === 'gainers')} `} onClick={() => setStockType('gainers')}>
            Gainers
          </li>
        </ul>

        {topStocks?.data && topStocks?.data[stockType] ? (
          <>
            {eodData?.data?.length > 0 ? (
              <FinancialTrendGraph2
                data={eodData.data}
                color='rgba(0, 122, 255, 1)'
                height='150px'
                dateKey='datetime'
                valueKey='close'
              />
            ) : (
              ''
            )}

            <ul className=' menu menu-horizontal '>
              <li className={`${activeElement(timelimit === 'Month1')} =`} onClick={() => tfHandler('Month1')}>
                1M
              </li>
              <li className={`${activeElement(timelimit === 'Month3')} `} onClick={() => tfHandler('Month3')}>
                3M
              </li>
              <li className={`${activeElement(timelimit === 'Month6')} `} onClick={() => tfHandler('Month6')}>
                6M
              </li>
              <li className={`${activeElement(timelimit === 'Year1')} `} onClick={() => tfHandler('Year1')}>
                1Y
              </li>
              <li className={`${activeElement(timelimit === 'Year3')} `} onClick={() => tfHandler('Year3')}>
                3Y
              </li>
              <li className={`${activeElement(timelimit === 'Year5')} `} onClick={() => tfHandler('Year5')}>
                5Y
              </li>
              <li className={`${activeElement(timelimit === 'Year10')} `} onClick={() => tfHandler('Year10')}>
                10Y
              </li>
            </ul>

            <div className='markets__StocksDetails '>
              {topStocks?.data[stockType]?.slice(0, itemsToShowStock).map((item: IMarketTopStocks, index: number) => (
                <div
                  className='markets__dataCardOne__container'
                  key={index}
                  onClick={() => {
                    setActiveTicker(item.symbol);
                    dispatch(fetchMarketEodPrice({ ticker: item.symbol, time_limit: timelimit }));
                  }}>
                  <div className={`${activeTicker === item.symbol ? 'bg-[#25334D]' : ''} markets__dataCardOne__list`}>
                    <div className='markets__dataCardOne__Left'>
                      <div className='markets__dataCardOne__LeftSecond'>
                        <p className='font-[400]'>{item.symbol}</p>
                        <p title={item.name}>{item.name}</p>
                      </div>
                    </div>
                    <div className='markets__dataCardOne__Right'>
                      <p className='color:$color-white'>{item.price}</p>

                      {item.change >= 0 ? (
                        <p style={{ color: '$color-primary' }}>
                          {item.change} ({item.changesPercentage.toFixed(2)}%)
                        </p>
                      ) : (
                        <p style={{ color: '#cc0133' }}>
                          {item.change} ({item.changesPercentage.toFixed(2)}%)
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              {topStocks?.data && topStocks?.data[stockType] && itemsToShowStock < topStocks?.data[stockType].length ? (
                <div className='markets__sectionTwoMore' onClick={handleShowMoreStock}>
                  More
                </div>
              ) : (
                ''
              )}
            </div>
          </>
        ) : (
          ''
        )}
      </section>
      <section>
        {sectorPerformance?.data && <DataCardTwo data={sectorPerformance?.data} title='Sector Performance' />}
      </section>
      <section>{peRatio?.data && <DataCardThree data={peRatio?.data} title='PE' />}</section>
    </div>
  );
}
const FinancialTrendGraph2 = memo(({ data, color, height = '250px', dateKey, valueKey }: IFinancialgraph) => {
  const chartRef = useRef(null);

  const xAxisData = useMemo(
    () => data.map((item: IEconomicIndicatorData) => item[dateKey].split(' ')[0]),
    [data, dateKey],
  );

  const createChart = useCallback(() => {
    const chart = echarts.init(chartRef.current);
    const option = {
      xAxis: {
        type: 'category',
        data: xAxisData,
        show: true,
        axisLine: {
          lineStyle: {
            color: '#ffffff', // White axis line
          },
        },
        axisLabel: {
          color: '#ffffff',
        },
      },
      yAxis: {
        type: 'value',
        min: 'dataMin',
        max: 'dataMax',
        axisLine: {
          lineStyle: {
            color: '#ffffff', // White axis line
          },
        },
        axisLabel: {
          color: '#ffffff', // White axis labels
        },
        splitLine: {
          lineStyle: {
            color: '#25334D', // Grey grid lines
          },
        },
      },
      grid: {
        left: 50,
        right: 10,
        top: 30,
        bottom: 30,
      },
      tooltip: {
        trigger: 'axis',
        formatter: (
          params: {
            data: any;
            dataIndex: string | number;
          }[],
        ) => {
          const data = params[0].data;
          const date = xAxisData[params[0].dataIndex];
          const formattedDate = new Date(date).toLocaleDateString();
          const valueColor = data >= 0 ? '$color-primary' : '#cc0133';
          return `Date: ${formattedDate}<br/><span style="color: ${valueColor}">Value: ${data}</span>`;
        },
        axisPointer: {
          type: 'shadow',
        },
        backgroundColor: '#091A3A',
        borderColor: '#25334D',
        textStyle: {
          color: '#ffffff',
        },
      },
      series: [
        {
          type: 'line',
          showSymbol: false, // Hide symbols (dots)
          color: color,
          areaStyle: {
            color: color,
          },
          data: data.map((item: { [x: string]: any }) => item[valueKey]),
        },
      ],
    };
    chart.setOption(option);

    // Clean up the chart instance when the component unmounts
    return () => {
      chart.dispose();
    };
  }, [xAxisData, data, color, valueKey]);

  useEffect(() => {
    const cleanup = createChart();

    return () => {
      cleanup();
    };
  }, [createChart]);

  return <div ref={chartRef} style={{ width: '100%', height: height }} />;
});
export interface IFinancialgraph {
  data: IEconomicIndicatorData[];
  color: string;
  height?: string;
  dateKey: string;
  valueKey: string;
}
export interface IFinancialgraph {
  data: IEconomicIndicatorData[];
  color: string;
  height?: string;
  dateKey: string;
  valueKey: string;
}
