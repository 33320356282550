import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from 'src/lib/types/error';
export interface IDataState {
  isLoading: boolean;
  data: any | null;
  error: IError | null;
}
const INITIAL_STATE: IDataState = {
  isLoading: false,
  data: null,
  error: null,
};
const carouselSlice = createSlice({
  name: 'carousel',
  initialState: INITIAL_STATE,
  reducers: {
    fetchCarousel(state) {
      state.isLoading = true;
    },
    fetchCarouselSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchCarouselFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});
export const { fetchCarousel, fetchCarouselSuccess, fetchCarouselFailure } = carouselSlice.actions;
export const carouselReducer = carouselSlice.reducer;
