import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from 'src/components/loadingAnimation/loadingAnimation.component';
import Carousel from 'src/components/carousel/carousel.component';
import { useDispatch, useSelector } from 'react-redux';
import { selectSubscriptionCheckout, selectSubscriptionMetaData } from './subscription.selector';
import { dummySubcription } from 'src/assets/Images';
import {
  fetchSubscriptionCheckout,
  fetchSubscriptionMetaData,
  setSubscriptionCheckoutInfo,
  setSubscriptionCheckoutSubId,
} from './subscription.reducer';
import { ISubscriptionProduct, ISubscriptionProductInfo, ICompareplan } from 'src/lib/types/subscription';
import Skeleton from 'react-loading-skeleton';
export default function Subscriptions() {
  const [isYearly, setIsYearly] = useState(false);
  const metaData = useSelector(selectSubscriptionMetaData);
  const checkoutData = useSelector(selectSubscriptionCheckout);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchSubscriptionMetaData());
  }, []);
  useEffect(() => {
    if (checkoutData.data) {
      const { subscriptionId, clientSecret } = checkoutData.data;
      dispatch(setSubscriptionCheckoutSubId({ subscriptionId }));
      navigate('checkout', {
        state: { subscriptionId, clientSecret },
      });
    }
  }, [checkoutData.data]);

  const Products = ({ isMobile }: { isMobile: boolean }) =>
    (isYearly ? metaData?.data?.yearlyPlans : metaData?.data?.monthlyPlans)?.map(
      (e: ISubscriptionProductInfo, i: number) => (
        <Product item={e} key={`${i}`} price={metaData?.data?.monthlyPlans[i]?.price} isMobile={isMobile} />
      ),
    );
  const getClassname = (val) => (val ? 'subscription-selected' : '');
  return (
    <>
      <div className=''>
        <>
          <div className='subscription'>
            <h1 className=''>iFIRST Subscription Plan</h1>
            <div className='subscription-types'>
              <div role='button' className={` ${getClassname(isYearly)}`} onClick={() => setIsYearly(!isYearly)}>
                Monthly
              </div>
              <div role='button' className={` ${getClassname(!isYearly)}`} onClick={() => setIsYearly(!isYearly)}>
                Yearly
              </div>
            </div>

            <div className='subscription-options'>
              {metaData?.isLoading ? (
                <>
                  {Array.from({ length: 3 }).map((_, colIndex) => (
                    <Skeleton className='subscription-product' width='40rem' borderRadius='3rem' />
                  ))}
                </>
              ) : (
                <Products isMobile={false} />
              )}
            </div>
            {metaData?.data && (
              <div className='subscription-options-mobile '>
                <Carousel>
                  {(isYearly ? metaData?.data?.yearlyPlans : metaData?.data?.monthlyPlans)?.map(
                    (e: ISubscriptionProductInfo, i: number) => (
                      <Product item={e} key={`${i}`} price={metaData?.data?.monthlyPlans[i]?.price} isMobile={true} />
                    ),
                  )}
                </Carousel>
              </div>
            )}
          </div>
          {metaData?.data && (
            <ComparePlans features={metaData?.data?.features} featuresMap={metaData?.data?.featuresMap} />
          )}

          <div className='pb-[15rem] max-md:pb-[2rem]' />
        </>
      </div>
    </>
  );
}

function Product({ item, isMobile = false, price }: ISubscriptionProduct) {
  const dispatch = useDispatch();
  const getColorBasedOnName = () => {
    if (item?.product_name.includes('Enterprise')) {
      return 'color:$color-primary';
    }
    if (item?.product_name.includes('Pro')) {
      return 'text-[#800ddb]';
    }
    return 'text-[#ffffff80]';
  };
  const getDpOnName = () => {
    if (item?.product_name.includes('Enterprise')) {
      return 'dp2';
    }
    if (item?.product_name.includes('Pro')) {
      return 'dp3';
    }
    return 'dp1';
  };
  const nameColor = getColorBasedOnName();
  const nameDp = getDpOnName();

  if (!isMobile) {
    return (
      <div className={`subscription-product ${nameDp} `}>
        <img src={!item?.product_image ? item?.product_image : dummySubcription} alt='' className='' />
        <span className={` ${nameColor}`}>{item?.product_name}</span>

        <p className='subscription-description'>{item?.description}</p>
        <div className='subscription-product-pricing'>
          <h2>${item?.price}</h2>
          <span>/Mo</span>
        </div>
        {item?.yearly && (
          <p className='subscription-product-anual-pricing'>
            ${item.price * 12} Annually -Saving you ${price * 12 - item.price * 12}
          </p>
        )}
        <button
          className='subscription-get-started '
          onClick={() => {
            dispatch(fetchSubscriptionCheckout({ id: [item?.id] }));
            dispatch(
              setSubscriptionCheckoutInfo({ name: item?.product_name, price: item?.price, isYearly: item?.yearly }),
            );
          }}
          type='submit'>
          Get Started
        </button>
      </div>
    );
  }
  return (
    <div className={` subscription-product-mobile  ${nameDp} `}>
      <img src={!item?.product_image ? item?.product_image : dummySubcription} alt='' className='' />
      <span className={` ${nameColor}`}>{item?.product_name}</span>
      <p className='subscription-description'>{item?.description}</p>
      <div className='subscription-product-pricing'>
        <h2>${item?.yearly ? item.price : item?.price}</h2>
        <span>/Mo</span>
      </div>
      {item?.yearly && (
        <p className='subscription-product-anual-pricing'>
          ${item?.price * 12} Annually -Saving you ${price * 12 - item.price * 12}
        </p>
      )}
      <button
        className='subscription-get-started'
        onClick={() => {
          dispatch(fetchSubscriptionCheckout({ id: [item?.id] }));
          dispatch(
            setSubscriptionCheckoutInfo({
              name: item?.product_name,
              price: item?.price,
              isYearly: item?.yearly,
              subscriptionId: '',
            }),
          );
        }}
        type='submit'>
        Get Started
      </button>
    </div>
  );
}

function ComparePlans({ features, featuresMap }: ICompareplan) {
  return (
    <div className=' subscription-features gap-1 '>
      <div className='subscription-features'>
        <div className='subscription-features-header'>
          <div className='w-2/12'>Compare Plans</div>
          <div className='w-1/6 text-center'>Basic</div>
          <div className='w-4/12 text-center'>Enterprise</div>
          <div className='w-3/12 text-center'>Professional</div>
        </div>
      </div>
      {features.map((e, index) => (
        <div key={`${index}`}>
          <h1 className='bg-[#081E49] p-4 rounded-lg text-2xl  font-bold'>{e.category}</h1>
          <div className='subscription-feature-list '>
            {e.features.map((i, v) => (
              <div key={`${v}`} className='subscription-feature-item'>
                <div
                  className='w-2/12  text-left text-[1.4rem]
                 font-semibold text-[#ffffff80] '>
                  {i}
                </div>
                <div className='w-1/6 text-center '>
                  <Included isIncluded={featuresMap.Basic.includes(i)} />
                </div>
                <div className='w-4/12 text-center'>
                  <Included isIncluded={featuresMap.Enterprise.includes(i)} />
                </div>
                <div className='w-3/12 text-center'>
                  <Included isIncluded={featuresMap.Pro.includes(i)} />
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

function Included({ isIncluded }: { isIncluded: boolean }) {
  return (
    <span className='inline-flex'>
      {isIncluded ? (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='size-6 stroke-green-500 stroke-[4]  '>
          <path strokeLinecap='round' strokeLinejoin='round' d='M4.5 12.75l6 6 9-13.5' />
        </svg>
      ) : (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='size-6 stroke-red-500 stroke-[4]  '>
          <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
        </svg>
      )}
    </span>
  );
}
