import React from 'react';
import { BacktestingChartTwoImage, BacktestingChartThreeImage, BacktestingChartFiveImage } from 'src/assets/Images';
import { data } from 'src/static-data';

function Charts() {
  return (
    <section className='charts'>
      <div className='charts__main'>
        {/* First */}
        <div className='charts__container'>
          <div className='charts__header'>
            <h2>1. Portfolio Equity</h2>
          </div>
          <div className='charts__singleChart'>
            <img src={BacktestingChartTwoImage} alt='' />
          </div>
        </div>
        {/* Second */}
        <div className='charts__container'>
          <div className='charts__header'>
            <h2>1. Underwater Equity</h2>
          </div>
          <div className='charts__singleChart'>
            <img src={BacktestingChartThreeImage} alt='' />
          </div>
        </div>
        {/* Third */}
        <div className='charts__container'>
          <div className='charts__header'>
            <h2>3. Porit Table</h2>
          </div>
          <div className='charts__table'>
            <table>
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Jan</th>
                  <th>Feb</th>
                  <th>Mar</th>
                  <th>Apr</th>
                  <th>May</th>
                  <th>Jun</th>
                  <th>Jul</th>
                  <th>Aug</th>
                  <th>Sep</th>
                  <th>Oct</th>
                  <th>Nov</th>
                  <th>Dec</th>
                  <th>Yr %</th>
                </tr>
              </thead>
              <tbody>
                {data.backtestingCharts?.map((item) => (
                  <tr key={item.id}>
                    <td>{item.year}</td>
                    <td>{item.jan}</td>
                    <td>{item.feb}</td>
                    <td>{item.mar}</td>
                    <td>{item.apr}</td>
                    <td>{item.may}</td>
                    <td>{item.jun}</td>
                    <td>{item.jul}</td>
                    <td>{item.aug}</td>
                    <td>{item.sep}</td>
                    <td>{item.oct}</td>
                    <td>{item.nov}</td>
                    <td>{item.dec}</td>
                    <td>{item.yearPercent}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* Fourth */}
        <div className='charts__container'>
          <div className='charts__header'>
            <h2>4. Profit Distribution</h2>
          </div>
          <div className='charts__singleChart'>
            <img src={BacktestingChartFiveImage} alt='' />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Charts;
