/* eslint-disable import/prefer-default-export */
/* eslint-disable indent */
/* eslint-disable max-len */
import { apiClient } from '../apiClient';
import { config } from 'src/lib/config';

export const getMarketsNews = async (params) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MARKETS + config.API_MARKETS_NEWS;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const getMarketInfo = async (params: undefined) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MARKETS + config.MGMT_MARKET_INFO;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
export const getMarketsTopStocks = async (params) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MARKETS + config.API_MARKETS_TOP_STOCKS;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};

export const getMarketsEconomicCalendar = async () => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MARKETS + config.API_MARKETS_ECONOMIC_CALENDAR;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
  });
  return res;
};

export const getMarketsTreasuryRates = async () => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MARKETS + config.API_MARKETS_TREASURY_RATES;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
  });
  return res;
};

export const getMarketsSectorsPerformance = async () => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MARKETS + config.API_MARKETS_SECTORS_PERFORMANCE;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
  });
  return res;
};

export const getMarketsTradingHours = async () => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MARKETS + config.API_MARKETS_TRADING_HOURS;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
  });
  return res;
};

export const getMarketsEconomicIndicator = async () => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MARKETS + config.API_MARKETS_ECONOMIC_INDICATOR;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
  });
  return res;
};

export const getMarketsPERatios = async () => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MARKETS + config.API_MARKETS_PE_RATIOS;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
  });
  return res;
};

export const postMarketGetEodPrice = async (payload) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_MARKETS + config.API_MARKETS_EOD_PRICES;
  const res = await apiClient({
    method: 'post',
    url: apiUrl,
    data: { ...payload },
  });
  return res;
};
