export function VideoCard({ item }) {
  return (
    <section className='videocard'>
      <div className='videocard__container'>
        <div className='videocard__content'>
          <article key={item.id} className='videocard__contentVideos'>
            <iframe src={`https://www.youtube.com/embed/${item.link.split('=')[1]}`} frameBorder='0' allowFullScreen />

            <p>{item.headline}</p>
          </article>
        </div>
      </div>
    </section>
  );
}
