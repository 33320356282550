import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { Fragment, useState, useEffect } from 'react';
interface ISelect {
  className?: string;
  options: string[];
  onChange?: (s: string) => void;
  preSelected?: string;
  preSelectDisabled?: boolean;
}

export default function Select({
  className,
  options = [],
  onChange,
  preSelected = '',
  preSelectDisabled = false,
}: ISelect) {
  const [selectedOption, setSelectedOption] = useState('Select');
  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
    if (onChange) {
      onChange(option);
    }
  };
  useEffect(() => {
    if (preSelected) {
      // If selected prop is provided, set the selectedOption to that value
      setSelectedOption(preSelected);
      if (onChange) {
        onChange(preSelected);
      }
    }
  }, [preSelected, setSelectedOption]);
  useEffect(() => {
    if (!preSelectDisabled) {
      if (options.length > 0) {
        setSelectedOption(options[0]);
        if (onChange) {
          onChange(options[0]);
        }
      }
    }
  }, [options]);

  return (
    <div className={`select ${className}`}>
      <Listbox value={selectedOption} onChange={handleOptionChange}>
        <div className='select-btn '>
          <Listbox.Button className='select-display '>
            <span className='block truncate'>{selectedOption}</span>
            <span className='select-display-drop'>
              <ChevronDownIcon className='size-8 text-white' aria-hidden='true' />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
            <Listbox.Options className='select-option-list'>
              {options.map((item, Idx) => (
                <Listbox.Option
                  key={Idx}
                  className={({ active }: { active: boolean }) => `select-option ${active ? 'active' : 'in-active'}`}
                  value={item}>
                  {({ selected }: { selected: boolean }) => (
                    <>
                      <span className={`selected-option ${selected ? 'font-medium' : 'font-normal'}`} title={item}>
                        {item}
                      </span>
                      {selected ? (
                        <span className='selected-option-tick'>
                          <CheckIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
