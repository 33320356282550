/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button } from 'src/components';
import { useForm } from '@tanstack/react-form';
import { yupValidator } from '@tanstack/yup-form-adapter';
import { useCountdownTimer } from 'src/hooks/hooks';
import * as Yup from 'yup';
import TextField from 'src/components/InputElements/textField.component';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import YupPassword from 'yup-password';
import validator from 'validator';
import {
  selectForgotPass,
  selectForgotPassOtp,
  selectAuthorizationCred,
  selectResendCount,
} from 'src/pages/Authentication/auth.selector';
import {
  fetchForgotPass,
  fetchOtpForgotPass,
  setAuthorizationCred,
  updateCounter,
} from 'src/pages/Authentication/auth.reducer';

import { useSelector, useDispatch } from 'react-redux';
import { MotionDiv } from 'src/components/motion/motion.component';

YupPassword(Yup);

Yup.addMethod(Yup.string, 'mobileCheck', function (errorMessage) {
  return this.test(` `, errorMessage, function (value) {
    const { path, createError } = this;
    if (typeof value === 'string')
      return validator.isMobilePhone(value) || createError({ path, message: errorMessage });
  });
});
export default function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [seconds, resetTimer] = useCountdownTimer();
  const resendTimeCount = useSelector(selectResendCount);
  const authorizationCreds = useSelector(selectAuthorizationCred);
  const forgotPassOtpData = useSelector(selectForgotPassOtp);
  const forgotPassData = useSelector(selectForgotPass);

  const forgotPasswordInitiateForm = useForm({
    defaultValues: {
      username: '',
      mobile: { fullNumber: '', status: false },
    },
    onSubmit: async ({ value }) => passwordResetInitiateHandler(value),
    validatorAdapter: yupValidator(),
  });
  const forgotPasswordForm = useForm({
    defaultValues: {
      username: '',
      otp: '',
      password: '',
      confirmPassword: '',
    },
    onSubmit: async ({ value }) => passwordResetHandler(value),
    validatorAdapter: yupValidator(),
  });
  useEffect(() => {
    if (forgotPassData?.data) {
      navigate('/login', { replace: true });
    }
  }, [forgotPassData]);
  const passwordResetInitiateHandler = (values: { username: any; mobile: any }) => {
    const { username, mobile } = values;
    let mobileNum = mobile.split('_')[0].replaceAll('-', '').replaceAll(' ', '');
    dispatch(fetchOtpForgotPass({ username, mobile_num: mobileNum }));
    dispatch(
      setAuthorizationCred({
        username,
        mobile: mobileNum,
      }),
    );
    resetTimer(30);
    dispatch(updateCounter());
  };
  const passwordResetHandler = (values: { username?: string; otp: any; password: any; confirmPassword: any }) => {
    const { otp, password, confirmPassword } = values;
    if (password !== confirmPassword) {
      setError('Password & confirm should match');
      return;
    }
    dispatch(
      fetchForgotPass({
        username: authorizationCreds.username,
        otp_id: forgotPassOtpData?.data?.otpId,
        otp_val: otp,
        password,
      }),
    );
  };
  const getClassName = (isValid) => (isValid ? ' btn-disabled' : 'btn-primary');

  return (
    <section className='auth'>
      <div className='auth-container'>
        <div className='auth-header'>
          <h1>
            Forgot <span>Password</span>
          </h1>
        </div>
        {!forgotPassOtpData?.data?.otpId ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              forgotPasswordInitiateForm.handleSubmit();
            }}
            className='auth-form'>
            <div className=' auth-form-ui'>
              <forgotPasswordInitiateForm.Field
                name='username'
                validators={{
                  onChange: Yup.string().required('Username is required'),
                }}
                children={(field) => {
                  return <TextField field={field} svg='user' placeholder='Username' />;
                }}
              />
              <forgotPasswordInitiateForm.Field
                name='mobile'
                validators={{
                  onChange: ({ value }) => {
                    if (!value.status) return 'Enter a valid mobile number';
                  },
                }}
                children={(field) => {
                  return (
                    <>
                      <IntlTelInput
                        fieldName={field.name}
                        onSelectFlag={(_v, _s, fullNumber, status) => {
                          field.handleChange({ status, fullNumber });
                        }}
                        onPhoneNumberChange={(status, _v, _c, fullNumber) => {
                          field.handleChange({ status, fullNumber });
                        }}
                        containerClassName='intl-tel-input'
                        inputClassName='form-control'
                      />
                      <div className='label'>
                        {field.state.meta.touchedErrors ? (
                          <span className='label-text-alt'>{field.state.meta.touchedErrors}</span>
                        ) : null}
                      </div>
                    </>
                  );
                }}
              />

              {error && <div className='auth-error'>{error}</div>}
              {forgotPassOtpData.error && <div className='auth-error '>{forgotPassOtpData.error.message}</div>}
            </div>

            <forgotPasswordInitiateForm.Subscribe
              selector={(state) => state}
              children={({ isFieldsValid }) => (
                <Button
                  type='submit'
                  disabled={!isFieldsValid}
                  className={`btn btn btn-rounded btn-lg ${getClassName(!isFieldsValid)}`}>
                  {forgotPassOtpData?.isLoading ? (
                    <div className='loader-container'>
                      <div className='loader' />
                    </div>
                  ) : (
                    'Reset'
                  )}
                </Button>
              )}
            />
            <div className='auth-addtional-data'>
              <p>
                <Link to='/login' className='flex items-center justify-center'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={2}
                    stroke='currentColor'
                    className='size-6 '>
                    <path strokeLinecap='round' strokeLinejoin='round' d='M15.75 19.5 8.25 12l7.5-7.5' />
                  </svg>
                  Back to Sign In
                </Link>
              </p>
            </div>
          </form>
        ) : (
          <>
            <MotionDiv
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ delay: 0.2, duration: 1.5, type: 'spring', stiffness: 80, damping: 20 }}
              className='auth-form'>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  forgotPasswordForm.handleSubmit();
                }}
                className='auth-form-ui'>
                <>
                  <forgotPasswordForm.Field
                    name='otp'
                    validators={{
                      onChange: Yup.string()
                        .length(6, 'OTP must be of length 6')
                        .matches(/^\d+$/, 'OTP must contain only digits')
                        .required('OTP is required'),
                    }}
                    children={(field) => {
                      return <TextField field={field} svg='otp' placeholder='One Time Password' />;
                    }}
                  />

                  <forgotPasswordForm.Field
                    name='password'
                    validators={{
                      onChange: Yup.string()
                        .required('Password is required')
                        .min(
                          8,
                          'password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special',
                        )
                        .minLowercase(1, 'password must contain at least 1 lower case letter')
                        .minUppercase(1, 'password must contain at least 1 upper case letter')
                        .minNumbers(1, 'password must contain at least 1 number')
                        .minSymbols(1, 'password must contain at least 1 special character'),
                    }}
                    children={(field) => {
                      return <TextField field={field} type='password' svg='password' placeholder='Password' />;
                    }}
                  />
                  <forgotPasswordForm.Field
                    name='confirmPassword'
                    validators={{
                      onChange: Yup.string()
                        .required('Password is required')
                        .min(
                          8,
                          'password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special',
                        )
                        .minLowercase(1, 'password must contain at least 1 lower case letter')
                        .minUppercase(1, 'password must contain at least 1 upper case letter')
                        .minNumbers(1, 'password must contain at least 1 number')
                        .minSymbols(1, 'password must contain at least 1 special character'),
                    }}
                    children={(field) => {
                      return <TextField field={field} type='password' svg='password' placeholder='Confirm Password' />;
                    }}
                  />

                  {error && <div className='auth-error'>{error}</div>}
                  {forgotPassData.error && <div className='auth-error '>{forgotPassData.error.message}</div>}
                </>
                <div className=' auth-form-action'>
                  <button
                    type='button'
                    className={`btn btn-rounded ${getClassName(seconds || resendTimeCount >= 3)}`}
                    disabled={Boolean(seconds || resendTimeCount.counter >= 3)}
                    onClick={() => {
                      dispatch(
                        forgotPassOtpData({
                          username: authorizationCreds.username,
                          mobile_num: authorizationCreds.mobile,
                        }),
                      );
                      dispatch(updateCounter());
                      resetTimer(30);
                    }}>
                    Resend{' '}
                    {seconds > 0 ? (
                      <span className='countdown'>
                        <span style={{ '--value': seconds } as React.CSSProperties} />
                      </span>
                    ) : (
                      ''
                    )}
                  </button>
                  <forgotPasswordForm.Subscribe
                    selector={(state) => state}
                    children={({ isTouched, isFieldsValid }) => (
                      <Button
                        type='submit'
                        disabled={!(isTouched && isFieldsValid)}
                        className={`btn btn btn-rounded lg ${getClassName(!(isTouched && isFieldsValid))}`}>
                        {forgotPassData?.isLoading ? (
                          <div className='loader-container'>
                            <div className='loader' />
                          </div>
                        ) : (
                          'Submit'
                        )}
                      </Button>
                    )}
                  />
                </div>
              </form>
            </MotionDiv>
          </>
        )}
      </div>
    </section>
  );
}
