/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { MultipleSentimentGraph } from 'src/components';
import { findMaxIndex } from 'src/lib/utils/utils';
// import { useGetSentimentBiggestChangeQuery } from 'src/queries/sentiment.query';
import { getSentiment } from 'src/repositories/sentiment/sentiment';
// import { useMutation } from 'react-query';
function BiggestChangeSocialSentiment({ type = 'Bullish', source = 'Stocktwits' }) {
  const [symbolToDataMap, setSymbolToDataMap] = useState(new Map());
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedSymbolsData, setSelectedSymbolsData] = useState([]);
  const [max, setMax] = useState(0);
  // const mutate = useMutation(postStockTickerDetails, {
  //   onSuccess: (resp) => {
  //     // console.log(resp);
  //     const map = new Map();
  //     // Iterate through the dataArray and add symbol:data pairs to the map
  //     resp.data.data.forEach((item) => {
  //       map.set(item.ticker, item);
  //     });
  //     setSymbolToDataMap(map);
  //   },
  // });
  const fetchSelectedSymbolData = async (symbol) => {
    try {
      const response = await getSentiment({ ticker: symbol });
      return response?.data?.data?.sentiment.reverse();
    } catch (error) {
      return null;
    }
  };
  const handleRowClick = async (symbol) => {
    if (selectedRows.includes(symbol)) {
      setSelectedRows((prevSelectedRows) => prevSelectedRows.filter((rowSymbol) => rowSymbol !== symbol));

      setSelectedSymbolsData((prevSelectedSymbolsData) => {
        const filteredData = prevSelectedSymbolsData.filter(
          (data) => data && data.length > 0 && data[0].symbol !== symbol,
        );
        // Add a check for data length
        setMax(findMaxIndex(filteredData));
        return filteredData;
      });
    } else if (selectedRows.length < 5) {
      try {
        const selectedSymbolData = await fetchSelectedSymbolData(symbol);
        setSelectedRows((prevSelectedRows) => [...prevSelectedRows, symbol]);
        setSelectedSymbolsData((prevSelectedSymbolsData) => {
          const newData = [...prevSelectedSymbolsData, selectedSymbolData];
          setMax(findMaxIndex(newData));
          return newData;
        });
      } catch (error) {
        console.error(`Error fetching data for symbol ${symbol}:`, error);
      }
    }
  };

  // const { data: biggestChangeData } = useGetSentimentBiggestChangeQuery();

  useEffect(() => {
    const fetchInitialSymbolData = async (symbol) => {
      const initialSymbolData = await fetchSelectedSymbolData(symbol);
      if (initialSymbolData) {
        setSelectedSymbolsData([initialSymbolData]);
      }
    };

    // if (biggestChangeData) {
    //   let newData = [];
    //   let newSelectedRows = [];

    //   if (type === 'Bullish') {
    //     const bullishData =
    //       source === 'Stocktwits' ? biggestChangeData?.bullish_stocktwits : biggestChangeData?.bullish_twitter;

    //     if (bullishData?.length > 0) {
    //       newData = bullishData;
    //       newSelectedRows = [bullishData[0].symbol];
    //       fetchInitialSymbolData(bullishData[0].symbol);
    //       const ticks = bullishData?.map((e) => e.symbol);
    //       // mutate.mutate({ market_type: 'UsStocks', tickers: ticks });
    //     } else {
    //       setTableData([]);
    //       newSelectedRows = [];
    //       setSelectedSymbolsData([]);
    //     }
    //   } else {
    //     const bearishData =
    //       source === 'Stocktwits' ? biggestChangeData?.bearish_stocktwits : biggestChangeData?.bearish_twitter;

    //     if (bearishData?.length > 0) {
    //       newData = bearishData;
    //       newSelectedRows = [bearishData[0].symbol];
    //       fetchInitialSymbolData(bearishData[0].symbol);
    //       const ticks = bearishData?.map((e) => e.symbol);
    //       // mutate.mutate({ market_type: 'UsStocks', tickers: ticks });
    //     } else {
    //       setTableData([]);
    //       newSelectedRows = [];
    //       setSelectedSymbolsData([]);
    //     }
    //   }

    //   setTableData(newData);
    //   setSelectedRows(newSelectedRows);
    // }
  }, [type, source]);
  return (
    <section className='trending'>
      <div className='trending__body'>
        <div className='trending__left'>
          <div className='trending__table'>
            <table>
              <thead>
                <th>&nbsp;</th>
                <th colSpan='5'>{source}</th>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{
                      color: '#ffffff66',
                      margin: '2rem 0rem',
                    }}
                    className='hover:bg-transparent'>
                    Symbol
                  </td>
                  <td style={{ color: '#FFB300' }} className='hover:bg-transparent'>
                    Name
                  </td>
                  <td style={{ color: '#FFB300' }} className='hover:bg-transparent'>
                    Rank
                  </td>
                  <td style={{ color: '#FFB300' }} className='hover:bg-transparent'>
                    Sentiment
                  </td>
                  <td style={{ color: '#FFB300' }} className='hover:bg-transparent'>
                    Last Sentiment
                  </td>
                </tr>
                {/* {biggestChangeData &&
                  tableData?.map((item) => (
                    <tr
                      key={item.symbol}
                      onClick={() => handleRowClick(item.symbol)}
                      // className={`${rowstyles[`selectedRow${selectedRows.indexOf(item.symbol) % 5}`]}`}>
                    >
                      <td>
                        {symbolToDataMap.get(item?.symbol) ? (
                          <img src={symbolToDataMap.get(item?.symbol)?.image} alt='logo' className='  ' />
                        ) : (
                          ''
                        )}
                        <p>{item.symbol}</p>
                      </td>
                      <td>
                        <p>{item.name}</p>
                      </td>
                      <td>{item.rank}</td>
                      <td>{item.sentiment}</td>
                      <td>{item.sentimentChange}</td>
                    </tr>
                  ))} */}
              </tbody>
            </table>
          </div>
        </div>
        <div className='trending__right'>
          <div className='trending__head'>
            <h1> Visual Representations</h1>
          </div>
          <div className='trending__chart'>
            {selectedSymbolsData && selectedSymbolsData.length !== 0 && (
              <MultipleSentimentGraph
                isTwitter={source === 'Twitter'}
                selectedData={selectedSymbolsData}
                selectedRows={selectedRows}
                max={max}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default BiggestChangeSocialSentiment;
