import { put, takeLatest } from 'redux-saga/effects';
import {
  fetchAcademyMetaData,
  fetchAcademyMetaDataSuccess,
  fetchAcademyMetaDataFailure,
  fetchAcademyContent,
  fetchAcademyContentSuccess,
  fetchAcademyContentFailure,
  fetchAcademyDetail,
  fetchAcademyDetailSuccess,
  fetchAcademyDetailFailure,
} from './academy.reducer';
import { getAcademyMetaData, getAcademyContentCards, getAcademyDetails } from 'src/repositories/academy/academy';
import { IError } from 'src/lib/types/error';

function* fetchAcademyMetaDataSaga(_action) {
  try {
    const data = yield getAcademyMetaData().then((response) => response);
    yield put(fetchAcademyMetaDataSuccess(data.data.data));
  } catch (error) {
    yield put(fetchAcademyMetaDataFailure(error as IError));
  }
}

export function* watchAcademyMetaDataSaga() {
  yield takeLatest(fetchAcademyMetaData.type, fetchAcademyMetaDataSaga);
}

function* fetchAcademyContentSaga(action) {
  try {
    const data = yield getAcademyContentCards(action.payload).then((response) => response);
    yield put(fetchAcademyContentSuccess(data.data.data));
  } catch (error) {
    yield put(fetchAcademyContentFailure(error as IError));
  }
}

export function* watchAcademyContentSaga() {
  yield takeLatest(fetchAcademyContent.type, fetchAcademyContentSaga);
}

function* fetchAcademyDetailSaga(action) {
  try {
    const data = yield getAcademyDetails(action.payload).then((response) => response);
    yield put(fetchAcademyDetailSuccess(data.data.data));
  } catch (error) {
    yield put(fetchAcademyDetailFailure(error as IError));
  }
}

export function* watchAcademyDetailSaga() {
  yield takeLatest(fetchAcademyDetail.type, fetchAcademyDetailSaga);
}
