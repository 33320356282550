import React from 'react';
import { QuantHeaderSecond } from '../../../components/Header/header.component';
import { SearchBar } from 'src/components';
import {
  QuantDeleteIcon,
  QuantPortfolioDownloadIcon,
  QuantPortfolioRightArrowIcon,
  QuantPortfolioDummyChartOne,
  QuantPortfolioDummyChartTwo,
} from 'src/assets/Images';
import { data } from 'src/static-data';

function BacktestResult() {
  return (
    <section className='navbar-fix'>
      <QuantHeaderSecond text='Build My Portfolio' subText='Template Creation Items' />
      <main className='quantresult'>
        <section className='quantresult__header'>
          <SearchBar className='quantresult__searchBar' text='Search symbol here...' />
          <img src={QuantPortfolioDownloadIcon} alt='' />
        </section>
        <hr />
        <section className='quantresult__body'>
          <section className='quantresult__Left'>
            <h1>Profile Performance Items</h1>
            {/* Card */}
            {data.backtestResult.cardData.map((data) => (
              <article key={data.id} className='quantresult__card'>
                <div className='quantresult__cardMain'>
                  <div className='quantresult__cardLeft'>
                    <img src={data.logo} alt='' />
                    <div className='quantresult__cardLeft--R'>
                      <h1>{data.ticker}</h1>
                      <p>{data.fullName}</p>
                    </div>
                  </div>
                  <div className='quantresult__cardRight'>
                    <h1>
                      Timeframes: <span>{data.timeframe}</span>
                    </h1>
                    <div className='quantresult__cardRight--B'>
                      <img src={QuantDeleteIcon} alt='' />
                      <p>Delete</p>
                    </div>
                  </div>
                </div>
                <img src={QuantPortfolioRightArrowIcon} alt='' />
              </article>
            ))}
          </section>
          <section className='quantresult__Right'>
            {/* Section One */}
            <div className='quantresult__One'>
              {/* Chart - 1 */}
              <div className='quantresult__Box'>
                <h1>EQUITY CURVE</h1>
                <div className='quantresult__Box--Chart'>
                  <img src={QuantPortfolioDummyChartOne} alt='' />
                </div>
              </div>
              {/* Chart - 2 */}
              <div className='quantresult__Box'>
                <h1>DRAWDOWN CURVE</h1>
                <div className='quantresult__Box--Chart'>
                  <img src={QuantPortfolioDummyChartTwo} alt='' />
                </div>
              </div>
            </div>
            {/* Section Two */}
            <div className='quantresult__Two'>
              {/* Chart - 1 */}
              <div className='quantresult__Boxx'>
                <div className='quantresult__Heading'>
                  <h1>CORRELATION PLOT</h1>
                </div>
                <div className='quantresult__D'>
                  {data.backtestResult.BoxData.map((item) => (
                    <article key={item.id} style={{ background: '#FEC107' }}>
                      <p>{item.value}</p>
                    </article>
                  ))}
                </div>
              </div>
              {/* Chart - 2 */}
              <div className='quantresult__Boxx'>
                <div className='quantresult__Heading'>
                  <h1>EQUITY SMOOTHNESS</h1>
                </div>
                <div className='quantresult__E'>
                  <div className='quantresult__E--Info'>
                    <h3>MSFT (Microsoft Limited.) :</h3>
                    <h3>0.6</h3>
                  </div>
                  <div className='quantresult__E--Info'>
                    <h3>GOOG (Google India Ltd.) :</h3>
                    <h3>0.6</h3>
                  </div>
                  <div className='quantresult__E--Info'>
                    <h3>Portfolio :</h3>
                    <h3>0.6</h3>
                  </div>
                </div>
              </div>
            </div>
            {/* Section Three */}
            <div className='quantresult__Three'>
              <div className='quantresult__table'>
                <table>
                  <thead>
                    <tr>
                      <th>Statistics</th>
                      <th>All Trades</th>
                      <th>Long Trades</th>
                      <th>Short Trades</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.backtestResult.dataOne?.map((item) => (
                      <tr key={item.id}>
                        <td>{item.title}</td>
                        <td>{item.allTrades}</td>
                        <td>{item.longTrades}</td>
                        <td>{item.shortTrades}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* </div> */}
                <h3>All Trades</h3>
                {/* <div className='backtestResult__tableTwo'> */}
                <table>
                  <tbody>
                    {data.backtestResult.data1?.map((item) => (
                      <tr key={item.id}>
                        <td>{item.title}</td>
                        <td>{item.allTrades}</td>
                        <td>{item.longTrades}</td>
                        <td>{item.shortTrades}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* </div> */}
                <h3>WINNERS</h3>
                {/* <div className='backtestResult__tableTwo'> */}
                <table>
                  <tbody>
                    {data.backtestResult.data1?.map((item) => (
                      <tr key={item.id}>
                        <td>{item.title}</td>
                        <td>{item.allTrades}</td>
                        <td>{item.longTrades}</td>
                        <td>{item.shortTrades}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </section>
      </main>
    </section>
  );
}

export default BacktestResult;
