/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { EsgGraph, EsgArc } from 'src/components';
import { selectWatchlistEsg } from '../Watchlist.selector';
import { fetchWatchlistEsg } from '../Watchlist.reducer';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { data as staticData } from 'src/static-data';

export default function Esg() {
  const { ticker, market } = useParams();

  const dispatch = useDispatch();
  const esg = useSelector(selectWatchlistEsg);

  useEffect(() => {
    dispatch(fetchWatchlistEsg({ ticker }));
  }, [ticker]);

  return (
    <main className='esg'>
      <h1 className='financials-section-header'>ESG</h1>

      <div className='esg-container'>
        <div className='esg-card'>
          {esg.isLoading ? (
            <>
              <Skeleton height='2rem' />
              <Skeleton height='2rem' />
            </>
          ) : (
            <>
              <h1>{esg?.data?.risk_rating?.company_name}</h1>
              <div className='esg-ticker-info'>
                <table>
                  <tr>
                    <td>Industry Group:</td>
                    <td>{esg?.data?.risk_rating?.industry}</td>
                  </tr>
                  <tr>
                    <td>Identifier</td>
                    <td>{esg?.data?.risk_rating?.symbol}</td>
                  </tr>
                </table>
              </div>
              <div className='esg-rating'>
                <h1>ESG Risk Rating</h1>
                <h2>{esg?.data?.risk_rating?.esg_risk_rating} </h2>
                <div className='esg-scale'>
                  {staticData.ScaleValues.map(({ value }, i) => (
                    <div className='esg-scale-item' key={i}>
                      {value}
                    </div>
                  ))}
                </div>
              </div>
              <div className='esg-ranking'>
                <h1>Ranking</h1>
                <div className='esg-ranking-industry'>
                  <div className='esg-ranking-industry-title'>
                    <h3>Industry Group:</h3>
                    <h1>{esg?.data?.risk_rating?.industry}</h1>
                  </div>
                  <div className='esg-ranking-industry-title'>
                    <h3>Industry Rank:</h3>
                    <h1>{esg?.data?.risk_rating?.industry_rank}</h1>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className='esg-chart'>
          {esg.isLoading ? (
            <Skeleton height='40rem' />
          ) : esg?.data && esg?.data?.scores?.length > 0 ? (
            <EsgGraph data={esg?.data?.scores} />
          ) : null}
        </div>
      </div>

      <h1 className='financials-section-header'>OVERALL SCORE SUMMERY</h1>
      {esg.isLoading ? (
        <Skeleton className='esg-meters' />
      ) : (
        <div className='esg-meters'>
          {esg?.data?.scores ? (
            <>
              <div className='esg-meters-item'>
                <EsgArc
                  Width={150}
                  Height={75}
                  innerRadius={3}
                  needleGap={4}
                  segments={5}
                  textSize={5}
                  name='ESG'
                  needleValue={esg?.data?.scores[0]?.esg_score}
                  needleWidth={1}
                  needleRadii={1}
                  colorReading='#DC3546'
                />
              </div>
              <div className='esg-meters-item'>
                <EsgArc
                  Width={150}
                  Height={75}
                  innerRadius={3}
                  needleGap={4}
                  segments={5}
                  textSize={5}
                  name='Environmental'
                  needleValue={esg?.data.scores[0].environmental_score}
                  needleWidth={1}
                  needleRadii={1}
                  colorReading='#A14AA3'
                />
              </div>
              <div className='esg-meters-item'>
                <EsgArc
                  Width={150}
                  Height={75}
                  innerRadius={3}
                  needleGap={4}
                  segments={5}
                  textSize={5}
                  name='Social'
                  needleValue={esg?.data.scores[0].social_score}
                  needleWidth={1}
                  needleRadii={1}
                  colorReading='#00ff00'
                />
              </div>
              <div className='esg-meters-item'>
                <EsgArc
                  Width={150}
                  Height={75}
                  innerRadius={3}
                  needleGap={4}
                  segments={5}
                  textSize={5}
                  name='Governance'
                  needleValue={esg?.data.scores[0].governance_score}
                  needleWidth={1}
                  needleRadii={1}
                  colorReading='#1C91D1'
                />
              </div>
            </>
          ) : null}
        </div>
      )}
    </main>
  );
}
