import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import {
  watchOtpLoginSaga,
  watchLoginSaga,
  watchOtpRegisterSaga,
  watchRegisterSaga,
  watchOtpForgotPassSaga,
  watchForgotPassSaga,
} from 'src/pages/Authentication/auth.saga';
import {
  watchAcademyContentSaga,
  watchAcademyDetailSaga,
  watchAcademyMetaDataSaga,
} from 'src/pages/Academy/academy.saga';
import {
  watchClubCommentSaga,
  watchClubContentSaga,
  watchClubDetailSaga,
  watchClubLikeSaga,
  watchClubMetaDataSaga,
  watchClubPostSaga,
} from 'src/pages/Club/club.saga';
import {
  watchSupportCreateTicketSaga,
  watchSupportDetailsSaga,
  watchSupportFaqsSaga,
  watchSupportSectionsSaga,
  watchSupportTicketsSaga,
  watchSupportVideosSaga,
} from 'src/pages/Support/support.saga';
import watchReportData, { watchReportMetaDataSaga } from 'src/pages/Reports/Reports.saga';
import {
  watchHeatmapMetaDataSaga,
  watchHeatmapDataSaga,
  watchHeatmapFilterDataSaga,
} from 'src/pages/Heatmap/heatmap.saga';
import { watchSubscriptionCheckoutSaga, watchSubscriptionMetaDataSaga } from 'src/pages/subscription/subscription.saga';
import {
  watchMarketTreasuryRateDataSaga,
  watchMarketNewsDataSaga,
  watchMarketEconomicIndicatorDataSaga,
  watchMarketHolidayDataSaga,
  watchMarketEconomicCalenderDataSaga,
  watchMarketEodPriceDataSaga,
  watchMarketPERatiosDataSaga,
  watchMarketSectorsPerformanceDataSaga,
  watchMarketTopStocksDataSaga,
} from 'src/pages/Markets/market.saga';
import {
  watchWatchlistAnalystEstimatesSaga,
  watchWatchlistAnalystRecommendationsSaga,
  watchWatchlistBarsSaga,
  watchWatchlistDividendsSaga,
  watchWatchlistEsgSaga,
  watchWatchlistFinancialsSaga,
  watchWatchlistNewsSaga,
  watchWatchlistOverviewSaga,
  watchWatchlistOwnershipsSaga,
  watchWatchlistPressReleasesSaga,
  watchWatchlistRatingssSaga,
  watchWatchlistRatioSaga,
  watchWatchlistSocialSentimentsSaga,
  watchWatchlistStockSplitsSaga,
} from 'src/pages/Watchlist/Watchlist.saga';
import {
  watchScreenerMetaDataSaga,
  watchScreenerFlaggedColumnSaga,
  watchScreenerFlaggedColumnPostSaga,
  watchScreenerFilterDataSaga,
} from 'src/pages/Screener/Screener.saga';
import {
  watchSocialSentimentBiggestChangeSaga,
  watchSocialSentimentSaga,
  watchSocialSentimentTrendingChangeSaga,
} from 'src/pages/SocialSentiment/socialsentiment.saga';
import { watchCarouselSaga } from 'src/components/Topbar/Topbar.saga';
import { watchMarketInfoSaga } from './MarketInfo/Marketinfo.saga';
import { watchTvConfigSaga, watchTvSymbolSaga } from 'src/components/TvContainer/tv.saga';
import { webSocketSaga } from './Websocket/Websocket.saga';
import { persistStore } from 'redux-persist';
import { persistedReducer } from './root-reducer';
import { watchSearchSaga } from 'src/components/searchbox/saga';
import { watchLifetchLiveQuoteSaga } from './livequote/saga';
import {
  watchEmailUpdateDataSaga,
  watchEmailUpdateInitiateDataSaga,
  watchPasswordUpdateDataSaga,
  watchPasswordUpdateInitiateDataSaga,
  watchPostProfileDataSaga,
  watchProfileDataSaga,
  watchProfilePicUpdateDataSaga,
} from 'src/pages/Profile/profile.saga';
import { carouselWebSocketSaga } from './Websocket/carouselWebsocket';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(sagaMiddleware),
});
const persistor = persistStore(store);
sagaMiddleware.run(watchOtpLoginSaga);
sagaMiddleware.run(watchLoginSaga);
sagaMiddleware.run(watchOtpRegisterSaga);
sagaMiddleware.run(watchRegisterSaga);
sagaMiddleware.run(watchOtpForgotPassSaga);
sagaMiddleware.run(watchForgotPassSaga);
sagaMiddleware.run(watchAcademyMetaDataSaga);
sagaMiddleware.run(watchAcademyContentSaga);
sagaMiddleware.run(watchAcademyDetailSaga);
sagaMiddleware.run(watchClubMetaDataSaga);
sagaMiddleware.run(watchClubContentSaga);
sagaMiddleware.run(watchClubDetailSaga);
sagaMiddleware.run(watchClubCommentSaga);
sagaMiddleware.run(watchClubLikeSaga);
sagaMiddleware.run(watchClubPostSaga);
sagaMiddleware.run(watchSupportDetailsSaga);
sagaMiddleware.run(watchSupportFaqsSaga);
sagaMiddleware.run(watchSupportSectionsSaga);
sagaMiddleware.run(watchSupportVideosSaga);
sagaMiddleware.run(watchSupportTicketsSaga);
sagaMiddleware.run(watchSupportCreateTicketSaga);
sagaMiddleware.run(watchReportData);
sagaMiddleware.run(watchReportMetaDataSaga);
sagaMiddleware.run(watchHeatmapMetaDataSaga);
sagaMiddleware.run(watchHeatmapDataSaga);
sagaMiddleware.run(watchHeatmapFilterDataSaga);
sagaMiddleware.run(watchSubscriptionMetaDataSaga);
sagaMiddleware.run(watchSubscriptionCheckoutSaga);
sagaMiddleware.run(watchMarketEconomicIndicatorDataSaga);
sagaMiddleware.run(watchMarketHolidayDataSaga);
sagaMiddleware.run(watchMarketTreasuryRateDataSaga);
sagaMiddleware.run(watchMarketEconomicCalenderDataSaga);
sagaMiddleware.run(watchMarketEodPriceDataSaga);
sagaMiddleware.run(watchMarketSectorsPerformanceDataSaga);
sagaMiddleware.run(watchMarketPERatiosDataSaga);
sagaMiddleware.run(watchMarketTopStocksDataSaga);
sagaMiddleware.run(watchMarketNewsDataSaga);
sagaMiddleware.run(watchSocialSentimentSaga);
sagaMiddleware.run(watchSocialSentimentBiggestChangeSaga);
sagaMiddleware.run(watchSocialSentimentTrendingChangeSaga);
sagaMiddleware.run(watchCarouselSaga);
sagaMiddleware.run(watchMarketInfoSaga);
sagaMiddleware.run(watchWatchlistOverviewSaga);
sagaMiddleware.run(watchWatchlistRatioSaga);
sagaMiddleware.run(watchWatchlistEsgSaga);
sagaMiddleware.run(watchWatchlistFinancialsSaga);
sagaMiddleware.run(watchWatchlistDividendsSaga);
sagaMiddleware.run(watchWatchlistStockSplitsSaga);
sagaMiddleware.run(watchWatchlistAnalystEstimatesSaga);
sagaMiddleware.run(watchWatchlistAnalystRecommendationsSaga);
sagaMiddleware.run(watchWatchlistOwnershipsSaga);
sagaMiddleware.run(watchWatchlistSocialSentimentsSaga);
sagaMiddleware.run(watchWatchlistRatingssSaga);
sagaMiddleware.run(watchWatchlistPressReleasesSaga);
sagaMiddleware.run(watchWatchlistNewsSaga);
sagaMiddleware.run(watchWatchlistBarsSaga);

sagaMiddleware.run(watchScreenerMetaDataSaga);
sagaMiddleware.run(watchTvConfigSaga);
sagaMiddleware.run(watchTvSymbolSaga);
sagaMiddleware.run(webSocketSaga);
sagaMiddleware.run(carouselWebSocketSaga);
sagaMiddleware.run(watchScreenerFlaggedColumnSaga);
sagaMiddleware.run(watchScreenerFlaggedColumnPostSaga);
sagaMiddleware.run(watchScreenerFilterDataSaga);
sagaMiddleware.run(watchSearchSaga);
sagaMiddleware.run(watchLifetchLiveQuoteSaga);
sagaMiddleware.run(watchProfileDataSaga);
sagaMiddleware.run(watchPostProfileDataSaga);
sagaMiddleware.run(watchProfilePicUpdateDataSaga);
sagaMiddleware.run(watchEmailUpdateDataSaga);
sagaMiddleware.run(watchEmailUpdateInitiateDataSaga);
sagaMiddleware.run(watchPasswordUpdateDataSaga);
sagaMiddleware.run(watchPasswordUpdateInitiateDataSaga);

export { store, persistor };
