import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  isLoading: false,
  error: null,
  data: null,
};
const INITIAL_STATE1 = {
  isLoading: false,
  error: null,
  data: [],
};
const reportMetaDataSlice = createSlice({
  name: 'reportsMetaData',
  initialState: INITIAL_STATE,
  reducers: {
    fetchReportMetaData(state) {
      state.isLoading = true;
    },
    fetchReportMetaDataSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchReportMetaDataFailure(state, action) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});
export const { fetchReportMetaData, fetchReportMetaDataSuccess, fetchReportMetaDataFailure } =
  reportMetaDataSlice.actions;
export const reportMetaDataReducer = reportMetaDataSlice.reducer;

export const reportSlice = createSlice({
  name: 'reportData',
  initialState: INITIAL_STATE1,
  reducers: {
    fetchReports(state) {
      state.isLoading = true;
      state.data = [];
    },
    fetchReportsSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchReportsFailure(state, action) {
      state.isLoading = false;
      state.data = [];
      state.error = action.payload;
    },
  },
});

export const { fetchReports, fetchReportsSuccess, fetchReportsFailure } = reportSlice.actions;
export const reportReducer = reportSlice.reducer;
const reportFilter = createSlice({
  name: 'reportFilter',
  initialState: {
    market_type: '',
    market_index: '',
    options: '',
    technical: '',
    time_frame: '',
    analyst_company: '',
  },
  reducers: {
    updateMarket(state, action) {
      state.market_type = action.payload.market_type;
    },
    updateIndex(state, action) {
      state.market_index = action.payload.market_index;
    },
  },
});
export const { updateMarket, updateIndex } = reportFilter.actions;
export const reportFilterReducer = reportFilter.reducer;
