import { apiClient } from '../apiClient';
import { config } from 'src/lib/config';
import { IAcademyReq } from 'src/lib/types/academy';
export const getAcademyContentCards = async (params: IAcademyReq) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_ACADEMY + config.API_ACADEMY_CONTENT_CARDS;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};

export const getAcademyMetaData = async () => {
  const apiUrl = config.API_SVC_PATH_IFIRST_ACADEMY + config.API_CLUB_URI_COMPONENT;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
  });
  return res;
};
export const getAcademyDetails = async (params: IAcademyReq) => {
  const apiUrl = config.API_SVC_PATH_IFIRST_ACADEMY + config.API_ACADEMY_CONTENT_DETAILS;
  const res = await apiClient({
    method: 'get',
    url: apiUrl,
    params,
  });
  return res;
};
