import { createSelector } from 'reselect';

const selectCurrentReport = (state: { reportData: any }) => state.reportData;

export const selectCurrentReportState = createSelector([selectCurrentReport], (state) => state);
const selectReportFilter = (state: { reportFilter: any }) => state.reportFilter;

export const selectReportFilterState = createSelector([selectReportFilter], (state) => state);
const selectReportFilterMarket = (state: { reportFilter: any }) => state.reportFilter.market_type;

export const selectReportFilterMarketState = createSelector([selectReportFilterMarket], (state) => state);
const selectReportMeta = (state: { reportMetaData: { data: any } }) => state.reportMetaData.data;

export const selectReportMetaDataState = createSelector([selectReportMeta], (state) => state);
