import React from 'react';
import { PortfolioHeader } from 'src/components/Header/header.component';
import {
  PortfolioMarketsImageTwo,
  PortfolioMarketsImageThree,
  PortfolioMarketsImageFour,
  PortfolioMarketsImageFive,
  PortfolioMarketsImageSix,
  PortfolioMarketsImageSeven,
} from 'src/assets/Images';

function Markets() {
  return (
    <section className='navbar-fix'>
      <PortfolioHeader text={['Manage your investment with ', <br />, 'Market Portfolio']} />
      <div className='portfoliomarkets'>
        <div className='portfoliomarkets__top'>
          <img src={PortfolioMarketsImageTwo} alt='' />
          <div className='portfoliomarkets__left'>
            <h4>What is Market Portfolio?</h4>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
              industry's stand- ard dummy text ever since the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release
              of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software
              like Aldus PageMaker including versions of Lorem.
            </p>
            <p>
              In a context of market turmoil, thematic investing stands out as an alternative to cyclical hazards. The
              Equity market has been polarizing between passive strategies (index funds and ETFs) and higher conviction
              active strategies. Deviating from a constraining reference index, thematic funds are gaining ground as
              core holdings within a portfolio by bringing extra alpha.
            </p>
            <p>
              Note: A thematic investment approach is not for all investors. Anybody with a short-term investment
              horizon should not consider our investment funds. Our investment strategies might substantially differ
              from standard benchmar​ks and can experience periods of high volatility. Longer-term investors could
              potentially benefit from the attractive growth prospects of the megatrend.
            </p>
          </div>
          <div className='portfoliomarkets__right'>
            <h4>Who is it for ?</h4>
            <p>
              A thematic approach offers an understandable way to invest in tomorrow’s winners and act for the future.
              By selecting a theme, investors could give a sense besides its investments and return: a sense as the
              ability to under- stand concretely the companies’ activities.
            </p>
            <p>Thematic investing is suited for investors: </p>
            <ul>
              <li>Having a long-term investment horizon and consen-ting to a high level of risk</li>
              <li>
                Wishing to benefit from a higher return than that of equity markets on the long term while accepting
                periods of underperformance due to short term market variations
              </li>
            </ul>
          </div>
        </div>
        <div className='portfoliomarkets__bottom'>
          <img src={PortfolioMarketsImageThree} alt='' />
          <div className='portfoliomarkets__Container'>
            {/* <img src={PortfolioMarketsImageOne} alt='' /> */}
            <div className='portfoliomarkets__box'>
              <img src={PortfolioMarketsImageFour} alt='' className='portfoliomarkets__box--ImgOne' />
              <div>
                <img src={PortfolioMarketsImageFive} alt='' />
                <div style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                  <h1>Commodities Portfolio</h1>
                  <p>view more</p>
                </div>
              </div>
              <img src={PortfolioMarketsImageSeven} alt='' className='portfoliomarkets__box--ImgTwo' />
              <div
                style={{
                  position: 'absolute',
                  top: '58%',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'row-reverse',
                  left: '37%',
                }}>
                <img src={PortfolioMarketsImageSix} alt='' />
                <div>
                  <h1>Commodities Portfolio</h1>
                  <p>view more</p>
                </div>
              </div>
              {/* <img src={curvedArrowThree} alt='' className='markets__box--ImgThree' /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Markets;
