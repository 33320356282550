import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from 'src/lib/types/error';
import { ISocialSentimentResp } from 'src/lib/types/socialsentiment';
const INITIAL_STATE: ISocialSentimentResp = {
  isLoading: false,
  data: null,
  error: null,
};

const socialSentimentSlice = createSlice({
  name: 'socialSentiment',
  initialState: INITIAL_STATE,
  reducers: {
    fetchSocialSentiment(state) {
      state.isLoading = true;
    },
    fetchSocialSentimentSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchSocialSentimentFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});
export const { fetchSocialSentiment, fetchSocialSentimentSuccess, fetchSocialSentimentFailure } =
  socialSentimentSlice.actions;
export const socialSentimentReducer = socialSentimentSlice.reducer;

const socialSentimentBiggestChangeSlice = createSlice({
  name: 'socialSentimentBiggestChange',
  initialState: INITIAL_STATE,
  reducers: {
    fetchSocialSentimentBiggestChange(state) {
      state.isLoading = true;
    },
    fetchSocialSentimentBiggestChangeSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchSocialSentimentBiggestChangeFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});
export const {
  fetchSocialSentimentBiggestChange,
  fetchSocialSentimentBiggestChangeSuccess,
  fetchSocialSentimentBiggestChangeFailure,
} = socialSentimentBiggestChangeSlice.actions;
export const socialSentimentBiggestChangeReducer = socialSentimentBiggestChangeSlice.reducer;

const socialSentimentTrendingChangeSlice = createSlice({
  name: 'socialSentimentTrendingChange',
  initialState: INITIAL_STATE,
  reducers: {
    fetchSocialSentimentTrendingChange(state) {
      state.isLoading = true;
    },
    fetchSocialSentimentTrendingChangeSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchSocialSentimentTrendingChangeFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});
export const {
  fetchSocialSentimentTrendingChange,
  fetchSocialSentimentTrendingChangeSuccess,
  fetchSocialSentimentTrendingChangeFailure,
} = socialSentimentTrendingChangeSlice.actions;
export const socialSentimentTrendingChangeReducer = socialSentimentTrendingChangeSlice.reducer;
