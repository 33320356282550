import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getSiblings } from 'src/lib/utils/utils';
import BiggestChangeSocialSentiment from './TrendingSocialSentiment/BiggestChangeSocialSentiment.router';
import TrendingSocialSentiment from './TrendingSocialSentiment/TrendingSocialSentiment.router';
import SocialSentiments from './Index/SocialSentiment.router';
import Select from 'src/components/InputElements/select.component';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { selectMarketInfo } from 'src/store/MarketInfo/MarketInfo.selector';
import { useDispatch, useSelector } from 'react-redux';
import Searchbox from 'src/components/searchbox/searchbox';

const dropDown1 = [
  'Historical Social Media Sentiment',
  'Most Trending Social Sentiment',
  'Biggest Change in Social Sentiment Rank',
];

const sourceDropdown = ['Stocktwits', 'Twitter'];
const sourceDropdown2 = ['Bullish', 'Bearish'];

export default function SentimentAnalysis() {
  const nav = useNavigate();
  const marketInfo = useSelector(selectMarketInfo);

  const [marketIndex, setMarketIndex] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  // const [tickerValue, setTickerValue] = useRecoilState(watchListTicker);
  const [type, setType] = useState('');
  const [source, setSource] = useState('Stocktwits');
  const [headerText, setHeaderText] = useState('');
  const { ticker } = useParams();
  // const searchMutation = useMutation(postTickerSearch, {
  //   onSuccess: (resp) => setFilteredData(resp.data.data.tickers),
  // });
  const dataTypeHandler1 = (e) => {
    const sibs = getSiblings(e.target);
    sibs.forEach((el) => {
      el.classList.remove('socialSentiment__selector-item--active');
    });
    if (e.target.innerText === 'Stocktwits') {
      setSource('Stocktwits');
    }
    if (e.target.innerText === 'Twitter') {
      setSource('Twitter');
    }
  };

  return (
    <main className='navbar-fix'>
      <section
        className={`sentimentHeader__main  ${
          headerText === 'Most Trending Social Sentiment' ? 'h-[22rem]' : 'h-[16rem]'
        }`}>
        <div className='sentimentHeader__container'>
          <div className='sentimentHeader__title'>
            <span>{headerText}</span>
          </div>
          {(headerText === 'Most Trending Social Sentiment' ||
            headerText === 'Biggest Change in Social Sentiment Rank') && (
            <div className='sentimentHeader__dropdowns'>
              <Select options={sourceDropdown} selected onChange={(e) => setSource(e)} />
              <Select options={sourceDropdown2} selected onChange={(e) => setType(e)} />
            </div>
          )}
        </div>
      </section>
      <div className='socialSentiment'>
        <div className='socialSentiment__dropdowns'>
          <Select options={dropDown1} onChange={(e) => setHeaderText(e)} selected />
          {headerText === 'Most Trending Social Sentiment' ||
          headerText === 'Biggest Change in Social Sentiment Rank' ? (
            ''
          ) : (
            <>
              <Select options={marketInfo?.index_list || []} onChange={(e) => setMarketIndex(e)} selected />
              <Searchbox market='Stocks' marketIndex={marketIndex} onClick={(ticker) => nav(`/sentiment/${ticker}`)} />
            </>
          )}
        </div>
        <hr />
        <div className='socialSentiment__body'>
          <div className='socialSentiment__options'>
            <div className='socialSentiment__selector'>
              {!(
                headerText === 'Most Trending Social Sentiment' ||
                headerText === 'Biggest Change in Social Sentiment Rank'
              ) && (
                <div className='socialSentiment__selector--sub'>
                  <div
                    className={
                      source === 'Stocktwits'
                        ? 'socialSentiment__selector-item socialSentiment__selector-item--active'
                        : 'socialSentiment__selector-item'
                    }
                    onClick={dataTypeHandler1}>
                    Stocktwits
                  </div>
                  <div
                    className={
                      source === 'Twitter'
                        ? 'socialSentiment__selector-item socialSentiment__selector-item--active'
                        : 'socialSentiment__selector-item'
                    }
                    onClick={dataTypeHandler1}>
                    Twitter
                  </div>
                </div>
              )}
            </div>
          </div>
          {headerText === 'Historical Social Media Sentiment' ? (
            <SocialSentiments source={source} ticker={ticker} />
          ) : null}

          {headerText === 'Most Trending Social Sentiment' ? (
            <TrendingSocialSentiment type={type} source={source} />
          ) : null}
          {headerText === 'Biggest Change in Social Sentiment Rank' ? (
            <BiggestChangeSocialSentiment type={type} source={source} />
          ) : null}
        </div>
      </div>
    </main>
  );
}
