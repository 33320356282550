import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuthCredentails, IAuthResp, ILoginOtp, ILogin } from 'src/lib/types/auth';
import { IError } from 'src/lib/types/error';

interface CounterState {
  counter: number;
}

// Initial states
const INITIAL_STATE: IAuthCredentails = { username: '', password: '', email: '', mobile: '' };
const INITIAL_STATE_API: IAuthResp = {
  isLoading: false,
  error: null,
  data: null,
};

// credentials slice
const credSlice = createSlice({
  name: 'creds',
  initialState: INITIAL_STATE,
  reducers: {
    setAuthorizationCred(state, action: PayloadAction<IAuthCredentails>) {
      state.username = action.payload?.username;
      state.password = action.payload?.password;
      state.email = action.payload?.email;
      state.mobile = action.payload?.mobile;
    },
  },
});

export const { setAuthorizationCred } = credSlice.actions;
export const authorizationCredReducer = credSlice.reducer;

// Auth OTP login slice
const authOtpLoginSlice = createSlice({
  name: 'authOtpLogin',
  initialState: INITIAL_STATE_API,
  reducers: {
    fetchOtpLogin(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    fetchOtpLoginSuccess(state, action: PayloadAction<ILoginOtp>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchOtpLoginFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
    setOtpLogin(state) {
      state.isLoading = false;
      state.data = null;
      state.error = null;
    },
  },
});

export const { fetchOtpLogin, fetchOtpLoginSuccess, fetchOtpLoginFailure, setOtpLogin } = authOtpLoginSlice.actions;
export const authOtpLoginReducer = authOtpLoginSlice.reducer;

const authLoginSlice = createSlice({
  name: 'authLogin',
  initialState: INITIAL_STATE_API,
  reducers: {
    fetchLogin(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    fetchLoginSuccess(state, action: PayloadAction<ILogin>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchLoginFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
    setLogin(state) {
      state.isLoading = false;
      state.data = null;
      state.error = null;
    },
  },
});

export const { fetchLogin, fetchLoginSuccess, fetchLoginFailure, setLogin } = authLoginSlice.actions;
export const authLoginReducer = authLoginSlice.reducer;

// Resend counter slice
const resendCounter = createSlice({
  name: 'resendCounter',
  initialState: { counter: 0 } as CounterState,
  reducers: {
    updateCounter(state) {
      state.counter += 1;
    },
  },
});

export const { updateCounter } = resendCounter.actions;
export const resendCounterReducer = resendCounter.reducer;

const authOtpRegisterSlice = createSlice({
  name: 'authOtpRegister',
  initialState: INITIAL_STATE_API,
  reducers: {
    fetchOtpRegister(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    fetchOtpRegisterSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchOtpRegisterFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
    setOtpRegister(state) {
      state.isLoading = false;
      state.data = null;
      state.error = null;
    },
  },
});

export const { fetchOtpRegister, fetchOtpRegisterSuccess, fetchOtpRegisterFailure, setOtpRegister } =
  authOtpRegisterSlice.actions;
export const authOtpRegisterReducer = authOtpRegisterSlice.reducer;

// Auth login slice
const authRegisterSlice = createSlice({
  name: 'authRegister',
  initialState: INITIAL_STATE_API,
  reducers: {
    fetchRegister(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    fetchRegisterSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchRegisterFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
    setRegister(state) {
      state.isLoading = false;
      state.data = null;
      state.error = null;
    },
  },
});

export const { fetchRegister, fetchRegisterSuccess, fetchRegisterFailure, setRegister } = authRegisterSlice.actions;
export const authRegisterReducer = authRegisterSlice.reducer;

const authOtpForgotPassSlice = createSlice({
  name: 'authOtpForgotPass',
  initialState: INITIAL_STATE_API,
  reducers: {
    fetchOtpForgotPass(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    fetchOtpForgotPassSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchOtpForgotPassFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchOtpForgotPass, fetchOtpForgotPassSuccess, fetchOtpForgotPassFailure } =
  authOtpForgotPassSlice.actions;
export const authOtpForgotPassReducer = authOtpForgotPassSlice.reducer;

const authForgotPassSlice = createSlice({
  name: 'authForgotPass',
  initialState: INITIAL_STATE_API,
  reducers: {
    fetchForgotPass(state, action: PayloadAction<any>) {
      state.isLoading = true;
    },
    fetchForgotPassSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchForgotPassFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchForgotPass, fetchForgotPassSuccess, fetchForgotPassFailure } = authForgotPassSlice.actions;
export const authForgotPassReducer = authForgotPassSlice.reducer;
