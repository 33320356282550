import { put, takeLatest } from 'redux-saga/effects';
import { getReportsMetadata, postReportOptions } from 'src/repositories/reports/reports';
import {
  fetchReports,
  fetchReportsFailure,
  fetchReportsSuccess,
  fetchReportMetaData,
  fetchReportMetaDataFailure,
  fetchReportMetaDataSuccess,
} from './Reports.reducer';
import { IError } from 'src/lib/types/error';
function* fetchReportsData(action) {
  try {
    const json = yield postReportOptions(action.payload).then((response) => response);
    yield put(fetchReportsSuccess(json.data.data));
  } catch (error) {
    yield put(fetchReportsFailure(error as IError));
  }
}

export default function* watchReportData() {
  yield takeLatest(fetchReports.type, fetchReportsData);
}

function* fetchReportMetaDataSaga(action) {
  try {
    const data = yield getReportsMetadata().then((response) => response);
    yield put(fetchReportMetaDataSuccess(data.data.data));
  } catch (error) {
    yield put(fetchReportMetaDataFailure(error as IError));
  }
}

export function* watchReportMetaDataSaga() {
  yield takeLatest(fetchReportMetaData.type, fetchReportMetaDataSaga);
}
