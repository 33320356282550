import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import { userReducer } from './user/user.reducer';
import {
  authOtpLoginReducer,
  authorizationCredReducer,
  authLoginReducer,
  resendCounterReducer,
  authOtpRegisterReducer,
  authRegisterReducer,
  authForgotPassReducer,
  authOtpForgotPassReducer,
} from '../pages/Authentication/auth.reducer';
import {
  academyContentReducer,
  academyDetailsReducer,
  academyMetaDataReducer,
} from 'src/pages/Academy/academy.reducer';
import {
  clubMetaDataReducer,
  clubContentReducer,
  clubDetailsReducer,
  clubLikeReducer,
  clubCommentReducer,
  clubPostReducer,
} from 'src/pages/Club/club.reducer';
import {
  supportCreateTicketReducer,
  supportDetailsReducer,
  supportFaqReducer,
  supportSectionReducer,
  supportTicketsReducer,
  supportVideoReducer,
} from 'src/pages/Support/support.reducer';
import { reportFilterReducer, reportMetaDataReducer, reportReducer } from 'src/pages/Reports/Reports.reducer';
import { heatmapMetaDataReducer, heatmapReducer, heatmapFilterDataReducer } from 'src/pages/Heatmap/heatmap.reducer';
import {
  SubscriptionCheckoutInfoReducer,
  SubscriptionCheckoutReducer,
  SubscriptionMetaDataReducer,
} from 'src/pages/subscription/subscription.reducer';
import {
  marketEconomicCalenderReducer,
  marketEconomicIndicatorReducer,
  marketEodPriceReducer,
  marketHolidayReducer,
  marketNewsReducer,
  marketPERatiosReducer,
  marketSectorsPerformanceReducer,
  marketTopStocksReducer,
  marketTreasuryRateReducer,
} from 'src/pages/Markets/markets.reducer';
import {
  watchlistOverviewReducer,
  watchlistRatioReducer,
  watchlistEsgReducer,
  watchlistFinancialReducer,
  watchlistDividendReducer,
  watchlistStockSplitReducer,
  watchlistAnalystEstimateReducer,
  watchlistAnalystRecommendationReducer,
  watchlistOwnershipReducer,
  watchlistRatingsReducer,
  watchlistSocialSentimentReducer,
  watchlistPressReleaseReducer,
  watchlistNewsReducer,
  watchlistBarsReducer,
} from 'src/pages/Watchlist/Watchlist.reducer';
import {
  screenerFilterDataReducer,
  screenerFlaggedColumnReducer,
  screenerMarketReducer,
  screenerMetaDataReducer,
} from 'src/pages/Screener/Screener.reducer';
import {
  socialSentimentBiggestChangeReducer,
  socialSentimentReducer,
  socialSentimentTrendingChangeReducer,
} from 'src/pages/SocialSentiment/socialsentiment.reducer';
import { carouselReducer } from 'src/components/Topbar/Topbar.reducer';
import { marketInfoReducer } from './MarketInfo/Marketinfo.reducer';
import { tvConfigReducer, tvSymbolChangeReducer, tvSymbolReducer } from 'src/components/TvContainer/tv.reducer';
import { messageCarouselReducer, messageReducer } from './Websocket/Websocket.reducer';
import { searchReducer } from 'src/components/searchbox/reducer';
import { liveQuoteReducer } from './livequote/reducer';
import {
  emailUpdateInitiateReducer,
  emailUpdateReducer,
  passwordUpdateInitiateReducer,
  passwordUpdateReducer,
  postProfileReducer,
  profilePicUpdateReducer,
  profileReducer,
} from 'src/pages/Profile/profile.reducer';

const persistConfig = {
  key: 'root',
  storage: sessionStorage,
  whitelist: ['user', 'marketInfo', 'carousel'],
};
const rootReducer = combineReducers({
  user: userReducer,
  cred: authorizationCredReducer,
  resendCounter: resendCounterReducer,
  loginOtp: authOtpLoginReducer,
  login: authLoginReducer,
  registerOtp: authOtpRegisterReducer,
  register: authRegisterReducer,
  forgotPasswordOtp: authOtpForgotPassReducer,
  forgotPassword: authForgotPassReducer,
  academyMetaData: academyMetaDataReducer,
  academyContent: academyContentReducer,
  academyDetails: academyDetailsReducer,
  clubMetaData: clubMetaDataReducer,
  clubContent: clubContentReducer,
  clubDetails: clubDetailsReducer,
  clubLike: clubLikeReducer,
  clubComment: clubCommentReducer,
  clubPost: clubPostReducer,
  supportDetails: supportDetailsReducer,
  supportTickets: supportTicketsReducer,
  supportFaq: supportFaqReducer,
  supportSection: supportSectionReducer,
  supportVideo: supportVideoReducer,
  supportCreateTicket: supportCreateTicketReducer,
  reportFilter: reportFilterReducer,
  reportData: reportReducer,
  reportMetaData: reportMetaDataReducer,
  heatmapData: heatmapReducer,
  heatmapMetaData: heatmapMetaDataReducer,
  heatmapFilterData: heatmapFilterDataReducer,
  subscriptionMetaData: SubscriptionMetaDataReducer,
  subscriptionCheckout: SubscriptionCheckoutReducer,
  subscriptionCheckoutInfo: SubscriptionCheckoutInfoReducer,
  marketEconomicIndicator: marketEconomicIndicatorReducer,
  marketHoliday: marketHolidayReducer,
  marketEconomicCalender: marketEconomicCalenderReducer,
  marketTreasuryRate: marketTreasuryRateReducer,
  marketEodPrice: marketEodPriceReducer,
  marketTopStocks: marketTopStocksReducer,
  marketPERatios: marketPERatiosReducer,
  marketSectorsPerformance: marketSectorsPerformanceReducer,
  marketNews: marketNewsReducer,
  screenerMarket: screenerMarketReducer,
  screenerMetaData: screenerMetaDataReducer,
  screenerFlaggedColumn: screenerFlaggedColumnReducer,
  screenerFilter: screenerFilterDataReducer,
  socialSentiment: socialSentimentReducer,
  socialSentimentBiggestChange: socialSentimentBiggestChangeReducer,
  socialSentimentTrendingChange: socialSentimentTrendingChangeReducer,
  carousel: carouselReducer,
  marketInfo: marketInfoReducer,
  watchlistOverview: watchlistOverviewReducer,
  watchlistRatio: watchlistRatioReducer,
  watchlistEsg: watchlistEsgReducer,
  watchlistFinancial: watchlistFinancialReducer,
  watchlistDividend: watchlistDividendReducer,
  watchlistStockSplit: watchlistStockSplitReducer,
  watchlistAnalystEstimate: watchlistAnalystEstimateReducer,
  watchlistAnalystRecommendation: watchlistAnalystRecommendationReducer,
  watchlistOwnership: watchlistOwnershipReducer,
  watchlistRatings: watchlistRatingsReducer,
  watchlistSocialSentiment: watchlistSocialSentimentReducer,
  watchlistPressRelease: watchlistPressReleaseReducer,
  watchlistNews: watchlistNewsReducer,
  watchlistBars: watchlistBarsReducer,
  tvConfig: tvConfigReducer,
  tvSymbol: tvSymbolReducer,
  websocket: messageReducer,
  search: searchReducer,
  liveQuote: liveQuoteReducer,
  tvSymbolChange: tvSymbolChangeReducer,
  profile: profileReducer,
  postProfile: postProfileReducer,
  profilePicUpdate: profilePicUpdateReducer,
  carouselWebsocket: messageCarouselReducer,
  emailUpdateInitiate: emailUpdateInitiateReducer,
  emailUpdate: emailUpdateReducer,
  passwordUpdateInitiate: passwordUpdateInitiateReducer,
  passwordUpdate: passwordUpdateReducer,
});

export const persistedReducer = persistReducer(persistConfig, rootReducer);
