import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

interface PaginationProps {
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ totalPages, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    onPageChange(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1); // Reset to page 1 when totalPages changes
  }, [totalPages]);
  const handlePageChange = (page: number) => {
    if (page !== currentPage && page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers: (number | string)[] = [];

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);

      pageNumbers.push(1); // First page

      if (startPage > 2) {
        pageNumbers.push('...');
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (endPage < totalPages - 1) {
        pageNumbers.push('...');
      }

      pageNumbers.push(totalPages); // Last page
    }

    return pageNumbers;
  };

  const renderPageNumbers = () => {
    const pageNumbers = getPageNumbers();

    return pageNumbers.map((page, index) => (
      <button
        key={index}
        className={` paginate-btn ${currentPage === page ? 'paginate-btn-active' : ''}`}
        onClick={() => handlePageChange(page as number)}
        disabled={typeof page === 'string'}>
        {page}
      </button>
    ));
  };
  if (totalPages === 0) return null;
  return (
    <div className='paginate'>
      <button className='paginate-btn-side' onClick={handlePrevious} disabled={currentPage === 1}>
        <ChevronLeftIcon className='paginate-btn' />
      </button>
      {renderPageNumbers()}
      <button className='paginate-btn' onClick={handleNext} disabled={currentPage === totalPages}>
        <ChevronRightIcon className='paginate-btn' />
      </button>
    </div>
  );
};

export default Pagination;
