import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Link, Outlet } from 'react-router-dom';
import { Button, PrimaryHeader } from 'src/components';

export default function Club() {
  return (
    <section className='club-container navbar-fix'>
      <PrimaryHeader text='iFIR$T Club' className='color-primary' />
      <div className='club-container-body'>
        <div className='club-search'>
          <label className=' input-bordered input-field-label input club-search'>
            <input type='text' name='' id='' placeholder='Search...' />
            <MagnifyingGlassIcon className='size-8' />
          </label>
          <Link to='/create-post/club'>
            <Button className='btn-primary  btn-lg' label='Create Post' />
          </Link>
        </div>
        <Outlet />
      </div>
    </section>
  );
}
