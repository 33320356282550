export const WS_EVENT = {
  WS_EVENT_SERVER_STREAMING_QUOTE: 'EVENT_SERVER_STREAMING_QUOTE',
  WS_EVENT_SERVER_LIVE_QUOTE: 'EVENT_SERVER_LIVE_QUOTE',
  WS_EVENT_SERVER_SIGNAL: 'EVENT_SERVER_SIGNAL',
  WS_EVENT_SERVER_LOGIN_SUCCESS: 'EVENT_SERVER_LOGIN_SUCCESS',
  WS_EVENT_SERVER_LOGIN_FAILED: 'EVENT_SERVER_LOGIN_FAILED',
  WS_EVENT_SERVER_SUBSCRIBE_SUCCESS: 'EVENT_SERVER_SUBSCRIBE_SUCCESS',
  WS_EVENT_SERVER_SUBSCRIBE_FAILED: 'EVENT_SERVER_SUBSCRIBE_FAILED',
  WS_EVENT_SERVER_UNSUBSCRIBE_SUCCESS: 'EVENT_SERVER_UNSUBSCRIBE_SUCCESS',
  WS_EVENT_SERVER_UNSUBSCRIBE_FAILED: 'EVENT_SERVER_UNSUBSCRIBE_FAILED',
  WS_EVENT_SERVER_DICONNECT: 'EVENT_SERVER_DISCONNECT',
  WS_EVENT_SERVER_CONNECTED: 'EVENT_SERVER_CONNECTED',
  WS_EVENT_CLIENT_LOGIN: 'EVENT_CLIENT_LOGIN',
  WS_EVENT_CLIENT_LOGOUT: 'EVENT_CLIENT_LOGOUT',
  WS_EVENT_CLIENT_SUBSCRIBE: 'EVENT_CLIENT_SUBSCRIBE',
  WS_EVENT_CLIENT_UNSUBSCRIBE: 'EVENT_CLIENT_UNSUBSCRIBE',
};
