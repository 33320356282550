/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/prefer-default-export */
import React, { useEffect, useRef, useMemo, memo, useCallback } from 'react';
import * as echarts from 'echarts';
const dt = (od) => {
  // Parse the date and time from the string
  const date = new Date(od);

  // Get the day, month, year, and time components
  const day = String(date.getDate()).padStart(2, '0'); // pad with leading zero
  const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth is zero-based, pad with leading zero
  const year = String(date.getFullYear()).slice(-2); // get last two digits of the year

  const hours = String(date.getHours()).padStart(2, '0'); // pad with leading zero
  const minutes = String(date.getMinutes()).padStart(2, '0'); // pad with leading zero

  // Create the formatted date-time string
  const fd = `${day}-${month}-${year}-${hours}:${minutes}`;
  return fd;
};
export const SocialSentimentGraph = memo(({ data, isTwitter }) => {
  const chartRef = useRef(null);

  const xAxisData = useMemo(() => data?.map((item) => dt(item.date)), [data]);

  const yAxisOption = useMemo(
    () => [
      {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: '#FFFF',
          },
        },
        splitLine: {
          lineStyle: {
            color: 'rgba(255, 255, 255, 0.09)',
          },
        },
        axisLabel: {
          formatter: '{value} ',
        },
      },
      {
        type: 'value',
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: true,
        },
      },
    ],
    [],
  );
  const legend = ['SENTIMENT', 'POSTS', 'LIKES', 'COMMENTS'];

  const lineSeries = useMemo(
    () => [
      {
        type: 'line',
        name: 'POSTS',
        data: data.map((value) => (!isTwitter ? value.stocktwits_posts : value.twitter_posts)),
        yAxisIndex: 0,
        itemStyle: {
          color: '#14C17E',
        },
        symbol: 'none',
      },
      {
        type: 'line',
        name: 'LIKES',
        data: data.map((value) => (!isTwitter ? value.stocktwits_likes : value.twitter_likes)),
        yAxisIndex: 0,
        itemStyle: {
          color: '#007DFF',
        },
        symbol: 'none',
      },
      {
        type: 'line',
        name: 'COMMENTS',
        data: data.map((value) => (!isTwitter ? value.stocktwits_comments : value.twitter_comments)),
        yAxisIndex: 0,
        itemStyle: {
          color: '#DE334A',
        },
        symbol: 'none',
      },
      {
        type: 'bar',
        name: 'SENTIMENT',
        data: data.map((value) =>
          ((!isTwitter ? value.stocktwits_sentiment : value.twitter_sentiment) * 100).toFixed(2),
        ),
        yAxisIndex: 1,
        barWidth: 4,
        itemStyle: {
          color: '#FFB300',
        },
        symbol: 'none',
      },
    ],
    [data, isTwitter],
  );

  const createChart = useCallback(() => {
    const chart = echarts.init(chartRef.current);
    const option = {
      xAxis: {
        type: 'category',
        data: xAxisData,
        axisLabel: {
          color: '#ffffff',
          rotate: 35,
        },
        axisLine: {
          lineStyle: {
            color: '#ffffff',
          },
        },
      },
      yAxis: yAxisOption,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        borderColor: 'rgba(0, 0, 0, 0.8)',
        textStyle: {
          color: '#ffffff',
        },
      },
      legend: {
        data: legend,
        textStyle: {
          color: '#ffffff',
        },
        padding: [10, 10, 10, 10],
      },
      series: [...lineSeries],
    };
    chart.setOption(option);

    // Clean up the chart instance when the component unmounts
    return () => {
      chart.dispose();
    };
  }, [xAxisData, yAxisOption, lineSeries]);

  useEffect(() => {
    const cleanup = createChart();

    return () => {
      cleanup();
    };
  }, [createChart]);

  return <div ref={chartRef} style={{ width: '100%', height: '400px' }} />;
});
