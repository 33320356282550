import { createSlice } from '@reduxjs/toolkit';
const INITIAL_STATE = {
  isLoading: false,
  error: null,
  data: null,
};
export const heatmapSlice = createSlice({
  name: 'heatmapData',
  initialState: INITIAL_STATE,
  reducers: {
    fetchHeatmap(state) {
      state.isLoading = true;
    },
    fetchHeatmapSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchHeatmapFailure(state, action) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchHeatmap, fetchHeatmapSuccess, fetchHeatmapFailure } = heatmapSlice.actions;
export const heatmapReducer = heatmapSlice.reducer;

export const heatmapFilterDataSlice = createSlice({
  name: 'heatmapFilterData',
  initialState: INITIAL_STATE,
  reducers: {
    fetchHeatmapFilterData(state, action) {
      state.isLoading = true;
    },
    fetchHeatmapFilterDataSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchHeatmapFilterDataFailure(state, action) {
      state.isLoading = false;
      state.data = [];
      state.error = action.payload;
    },
  },
});

export const { fetchHeatmapFilterData, fetchHeatmapFilterDataSuccess, fetchHeatmapFilterDataFailure } =
  heatmapFilterDataSlice.actions;
export const heatmapFilterDataReducer = heatmapFilterDataSlice.reducer;

export const heatmapMetaDataSlice = createSlice({
  name: 'heatmapMetaData',
  initialState: INITIAL_STATE,
  reducers: {
    fetchHeatmapMetaData(state) {
      state.isLoading = true;
    },
    fetchHeatmapMetaDataSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchHeatmapMetaDataFailure(state, action) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchHeatmapMetaData, fetchHeatmapMetaDataSuccess, fetchHeatmapMetaDataFailure } =
  heatmapMetaDataSlice.actions;
export const heatmapMetaDataReducer = heatmapMetaDataSlice.reducer;
