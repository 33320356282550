import React from 'react';
import Skeleton from 'react-loading-skeleton';

export function Stockgrades({ data, isLoading }) {
  return (
    <div>
      <h1 className='financials-section-header'>STOCK GRADE (Upgrades & Downgrades)</h1>
      <div className='rating-matrics-grades'>
        {isLoading ? (
          <>
            {Array.from({ length: 7 }).map((_, colIndex) => (
              <Skeleton height='3rem' key={colIndex} />
            ))}
          </>
        ) : (
          data?.grades?.map((item, i) => (
            <div className='rating-matrics-grades-row' key={`ov${i}`}>
              <div className='rating-matrics-grades-row-left'>
                <p>{item.grading_company}</p>
                <p>
                  Previous : <span>{item.previous_grade}</span>
                </p>
              </div>
              <div className='rating-matrics-grades-row-right'>
                <p>{item.date}</p>
                <p>
                  New :<span>{item.new_grade}</span>
                </p>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
