import { useEffect, useState } from 'react';
import { WS_EVENT } from 'src/constants/ws.constant';
import { ScrollCarousal } from '../carousel/ScrollCarousel.component';
import { TopBarCard } from '../Card/card.component';
import { useSelector, useDispatch } from 'react-redux';
import { selectCarousel } from './Topbar.selector';
import { fetchCarousel } from './Topbar.reducer';
import { connectCarouselWebSocket, disconnectCarouselWebSocket } from 'src/store/Websocket/carouselWebsocket';
const Topbar = () => {
  const dispatch = useDispatch();
  const carousel = useSelector(selectCarousel);
  const websocketData = useSelector((state) => state.carouselWebsocket);

  useEffect(() => {
    dispatch(fetchCarousel());

    if (websocketData?.data?.status !== WS_EVENT.WS_EVENT_SERVER_CONNECTED) {
      dispatch(connectCarouselWebSocket());
    }
    return () => {
      dispatch(disconnectCarouselWebSocket());
    };
  }, []);

  return (
    <div className='topbar '>
      {carousel ? (
        <div className='w-[90vw] max-w-[90vw] overflow-hidden '>
          <ScrollCarousal autoplay autoplaySpeed={8} speed={5}>
            {carousel?.map((item, index) => (
              <TopBarCard key={index} item={item} liveQuote={websocketData} />
            ))}
          </ScrollCarousal>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Topbar;
