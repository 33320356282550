import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAcademyMetaData, IAcademyResp, IAcademyContent, IAcademyDetails, IAcademyReq } from 'src/lib/types/academy';
import { IError } from 'src/lib/types/error';
const INITIAL_STATE: IAcademyResp = {
  isLoading: false,
  data: null,
  error: null,
};

const academyMetaDataSlice = createSlice({
  name: 'academyMetaData',
  initialState: INITIAL_STATE,
  reducers: {
    fetchAcademyMetaData(state) {
      state.isLoading = true;
    },
    fetchAcademyMetaDataSuccess(state, action: PayloadAction<IAcademyMetaData>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchAcademyMetaDataFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchAcademyMetaData, fetchAcademyMetaDataSuccess, fetchAcademyMetaDataFailure } =
  academyMetaDataSlice.actions;
export const academyMetaDataReducer = academyMetaDataSlice.reducer;

const academyContentSlice = createSlice({
  name: 'academyContent',
  initialState: INITIAL_STATE,
  reducers: {
    fetchAcademyContent(state, action: PayloadAction<IAcademyReq>) {
      state.isLoading = true;
    },
    fetchAcademyContentSuccess(state, action: PayloadAction<IAcademyContent>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchAcademyContentFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchAcademyContent, fetchAcademyContentSuccess, fetchAcademyContentFailure } =
  academyContentSlice.actions;
export const academyContentReducer = academyContentSlice.reducer;

const academyDetailsSlice = createSlice({
  name: 'academyDetails',
  initialState: INITIAL_STATE,
  reducers: {
    fetchAcademyDetail(state, action: PayloadAction<IAcademyReq>) {
      state.isLoading = true;
    },
    fetchAcademyDetailSuccess(state, action: PayloadAction<IAcademyDetails>) {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    fetchAcademyDetailFailure(state, action: PayloadAction<IError>) {
      state.isLoading = false;
      state.data = null;
      state.error = action.payload;
    },
  },
});

export const { fetchAcademyDetail, fetchAcademyDetailSuccess, fetchAcademyDetailFailure } = academyDetailsSlice.actions;
export const academyDetailsReducer = academyDetailsSlice.reducer;
