import { createSelector } from 'reselect';

const selectSocialSentimentState = (state: { socialSentiment: any }) => state.socialSentiment;

export const selectsocialSentiment = createSelector([selectSocialSentimentState], (state) => state);

const selectSocialSentimentBiggestChangeState = (state: { socialSentimentBiggestChange: any }) =>
  state.socialSentimentBiggestChange;

export const selectsocialSentimentBiggestChange = createSelector(
  [selectSocialSentimentBiggestChangeState],
  (state) => state,
);

const selectSocialSentimentTrendingChangeState = (state: { socialSentimentTrendingChange: any }) =>
  state.socialSentimentTrendingChange;

export const selectsocialSentimentTrendingChange = createSelector(
  [selectSocialSentimentTrendingChangeState],
  (state) => state,
);
