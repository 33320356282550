/* eslint-disable indent */
import React, { useState } from 'react';
import { iRecommendDownloadIcon } from 'src/assets/Images';
import { Select, SearchBar, IRecommendFundamentalView, IRecommendTechnicalView } from 'src/components';
import { IRecommendHeader } from 'src/components/Header/header.component';
import { data } from 'src/static-data';

function IRecommend() {
  const [headerText] = useState('Fundamental View');
  return (
    <section className='navbar-fix'>
      <IRecommendHeader text='i-Recommend' />
      <main className='irecommend'>
        <section className='irecommend__dropdowns'>
          <div className='irecommend__Left'>
            <SearchBar className='irecommend__searchbar' />
            <Select options={data.irecommend.dropdownMarkets} />
            <Select options={data.irecommend.dropdownOptions} />
          </div>
          <div className='irecommend__Right'>
            <img src={iRecommendDownloadIcon} alt='download-icon' />
          </div>
        </section>
        <hr />
        <section className='irecommend__body'>
          <section className='irecommend__main'>
            {headerText === 'Fundamental View' ? (
              <IRecommendFundamentalView fundamentalData={data.irecommend.fundamentalData} />
            ) : null}
            {headerText === 'Technical View' ? (
              <IRecommendTechnicalView technicalData={data.irecommend.technicalData} />
            ) : null}
          </section>
        </section>
      </main>
    </section>
  );
}

export default IRecommend;
