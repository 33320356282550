import { take, put, call, fork, ActionPattern, delay, race } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import { createAction, Action } from '@reduxjs/toolkit';
import { putMessage, putMessageCarousel } from './Websocket.reducer';
import { WS_EVENT } from 'src/constants/ws.constant';
import { IWebsocket } from 'src/lib/types/websocket';
import SessionToken from 'src/lib/session-token';
import { ACCESS_TOKEN_KEY } from 'src/constants/token.constant';

export function webSocketListener(serviceWebSocket: WebSocket) {
  return eventChannel((emitter) => {
    serviceWebSocket.onmessage = ({ data }: MessageEvent) => emitter(data);
    serviceWebSocket.onclose = () => emitter(END);
    serviceWebSocket.onerror = () => emitter(END);

    return () => serviceWebSocket.close();
  });
}

export function* webSocketSaga(): Generator {
  let serviceWebSocket: WebSocket | null = null;

  while (true) {
    // Wait for either a connect or disconnect action
    const result = (yield race({
      connect: take(connectWebSocket.toString()) as Action,
      disconnect: take(disconnectWebSocket.toString()) as Action,
    })) as { connect?: Action; disconnect?: Action };

    const { connect, disconnect } = result;
    // Handle disconnect logic
    if (disconnect && serviceWebSocket) {
      (serviceWebSocket as WebSocket).close(); // Close the WebSocket connection
      serviceWebSocket = null;
      yield put(putMessage({ status: WS_EVENT.WS_EVENT_SERVER_DICONNECT }));
      continue; // Restart the loop, waiting for a new connection
    }

    // Handle connect logic
    if (connect && !serviceWebSocket) {
      try {
        serviceWebSocket = new WebSocket('wss://olympus.ifirst.trade/api/v1/mgmt-svc/ws/authd');
        const socket = yield call(webSocketListener, serviceWebSocket);
        yield put(putMessage({ status: WS_EVENT.WS_EVENT_SERVER_CONNECTED }));
        yield fork(sendMessageSaga, serviceWebSocket);

        // Send auth token after a small delay
        yield delay(1000);
        if (SessionToken.getToken(ACCESS_TOKEN_KEY)) {
          yield put(
            sendMessage({ event: WS_EVENT.WS_EVENT_CLIENT_LOGIN, data: `${SessionToken.getToken(ACCESS_TOKEN_KEY)}` }),
          );
        }

        // Handle incoming messages
        while (true) {
          const messageResult = (yield race({
            payload: take(socket as ActionPattern) as unknown as string, // Assume payload is a string
            disconnect: take(disconnectWebSocket.toString()) as unknown as boolean,
          })) as { payload?: string; disconnect?: boolean };

          const { payload, disconnect } = messageResult;

          // If disconnect is triggered, break out of the loop
          if (disconnect) {
            break;
          }

          // Handle incoming WebSocket message
          if (payload) {
            const data: IWebsocket = JSON.parse(payload as string);
            yield put(putMessage({ event: data.event, data: data.data }));
          }
        }
      } finally {
        // Always handle disconnect in the finally block
        if (serviceWebSocket) {
          serviceWebSocket.close(); // Close the WebSocket connection
        }
        yield put(
          putMessage({
            event: WS_EVENT.WS_EVENT_SERVER_DICONNECT,
            status: WS_EVENT.WS_EVENT_SERVER_DICONNECT,
            data: null,
          }),
        );
        serviceWebSocket = null;
      }
    }
  }
}

export interface MessageType {
  event: string;
  data: Object | string;
  status?: boolean;
}
export const sendMessage = createAction<MessageType>('message/sendMessage');
export function* sendMessageSaga(serviceWebSocket: WebSocket) {
  while (true) {
    const { payload } = yield take(sendMessage.toString());
    try {
      if (payload.event === WS_EVENT.WS_EVENT_SERVER_STREAMING_QUOTE)
        yield put(putMessage({ event: payload.event, data: payload.data }));
      yield call([serviceWebSocket, 'send'], JSON.stringify(payload));
    } catch (error) {
      yield put(
        putMessage({
          event: WS_EVENT.WS_EVENT_SERVER_DICONNECT,
          data: null,
        }),
      );
    }
  }
}
export const reconnectWebSocket = createAction('websocket/reconnect');
export const connectWebSocket = createAction('websocket/connect');
export const disconnectWebSocket = createAction('websocket/disconnect');
